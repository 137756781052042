import React, { Fragment, useEffect, useContext, useState } from 'react'

import { Layout, Button, Typography, Tabs, Select, Table, Divider, Row, Col } from 'antd'
import { CustomHeader } from '../../components'
import AspirationAPI from '../../services/AspirationAPI'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import gravatar from 'gravatar'
import { Link } from 'react-router-dom'
import API from '../../services/API'
import _ from 'lodash'
import Create from './Create'
import { da } from 'date-fns/locale'

const { Content } = Layout
const { Title } = Typography
const { TabPane } = Tabs
const { Option } = Select

const Appreciation = () => {
	const [leaderboard, setLeaderboard] = useState({ data: [], isLoading: true })
	const [leaderboardPPNPN, setLeaderboardPPNPN] = useState({ data: [], isLoading: true })
	const [appreciationList, setAppreciationList] = useState({ data: [], isLoading: true, pagination: {} })
	const [params, setParams] = useState({ employeeID: '', pageSize: 10, currentPage: 1, sort: 'created', order: 'desc' })
	const [employeeList, setEmployeeList] = useState({ data: [], isLoading: true })
	const [query, setQuery] = useState({ pageSize: 0, keyword: '' })

	const fetchLeaderboard = async () => {
		try {
			setLeaderboard({ ...leaderboard, isLoading: true })
			const response = await AspirationAPI.getLeaderboard({ type: 'PNS', pageSize: 10 })

			setLeaderboard({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchLeaderboardPPNPN = async () => {
		try {
			setLeaderboardPPNPN({ ...leaderboardPPNPN, isLoading: true })
			const response = await AspirationAPI.getLeaderboard({ type: 'PPNPN', pageSize: 10 })

			setLeaderboardPPNPN({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchAppreciation = async () => {
		try {
			setAppreciationList({ ...appreciationList, isLoading: true })
			const response = await AspirationAPI.getAppreciation(params)

			setAppreciationList({
				data: response.data.list,
				isLoading: false,
				pagination: response.data.pagination,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const columns = [
		{
			title: 'No',
			dataIndex: 'rank',
			key: 'rank',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},

		{
			title: 'Point',
			dataIndex: 'totalPoint',
			key: 'totalPoint',
		},
	]

	const columnsPPNPN = [
		{
			title: 'Rank',
			dataIndex: 'rank',
			key: 'rank',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},

		{
			title: 'Point',
			dataIndex: 'totalPoint',
			key: 'totalPoint',
		},
	]

	const columnList = [
		{
			title: 'Name',
			dataIndex: 'employee',
			render: (obj) => <Link to={`/employee/${obj.ID}`}>{`${obj.name}`}</Link>,
			key: 'name',
		},

		{
			title: 'Point',
			dataIndex: 'point',
			key: 'point',
		},
		{
			title: 'Label',
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: 'Action',
			key: 'action',
			render: (data) => (
				<Link to={`/edit-appreciation/${data.ID}`}>
					<Button style={{ marginLeft: '6px', borderColor: '#40a9ff' }} icon={<EditOutlined style={{ color: '#40a9ff' }} />} type='link' size='small' />
				</Link>
			),
		},
	]

	useEffect(() => {
		fetchLeaderboard()
		fetchLeaderboardPPNPN()
	}, [])

	const fetchEmployeeList = async () => {
		try {
			const response = await API.getAllEmploye(query)

			setEmployeeList({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchEmployeeList()
	}, [query.keyword])

	useEffect(() => {
		fetchAppreciation()
	}, [params])

	return (
		<Fragment>
			<CustomHeader title='Aspirasi' />
			<Content style={{ padding: '16px', background: '#fff' }}>
				<div className='dudi_asp_container'>
					<Link to='/create-appreciation'>
						<Button type='primary' style={{ float: 'right' }}>
							Tambah apresiasi
						</Button>
					</Link>

					<br />

					<Title level={4}>Top 10</Title>

					<Tabs defaultActiveKey='pns-tab'>
						<TabPane tab='PNS' key='pns-tab'>
							<Table
								dataSource={leaderboard.data.map((leader, index) => ({ ...leader, key: index, rank: index + 1 }))}
								loading={leaderboard.isLoading}
								columns={columns}
								pagination={false}
							/>
						</TabPane>
						<TabPane tab='PPNPN' key='ppnpn-tab'>
							<Table
								dataSource={leaderboardPPNPN.data.map((leader, index) => ({ ...leader, key: index, rank: index + 1 }))}
								loading={leaderboardPPNPN.isLoading}
								columns={columnsPPNPN}
								pagination={false}
							/>
						</TabPane>
					</Tabs>
					<Divider />
					<br />

					<Title level={4}>Semua apresiasi</Title>

					<Row gutter={[16, 16]}>
						<Col>
							<div>
								<div>
									<span style={{ color: 'black' }}>Pilih Karyawan</span>
								</div>
								<Select
									allowClear
									filterOption={false}
									onSearch={_.debounce((value) => setQuery({ ...query, keyword: value }), 500)}
									onChange={(val) => setParams({ ...params, employeeID: val })}
									loading={employeeList.isLoading}
									placeholder='Pilih Karyawan'
									showSearch
									style={{ marginTop: '8px' }}>
									{employeeList.data.map((employee) => (
										<Option key={employee.ID} value={employee.ID}>
											{employee.name}
										</Option>
									))}
								</Select>
							</div>
						</Col>

						<Col>
							<div>
								<div>
									<span style={{ color: 'black' }}>Urutan</span>
								</div>
								<Select
									allowClear
									filterOption={false}
									onChange={(val) => setParams({ ...params, order: val })}
									value={params.order}
									placeholder='Pilih urutan'
									showSearch
									style={{ marginTop: '8px' }}>
									<Option value={'desc'}>Terbaru</Option>
									<Option value={'asc'}>Terlama</Option>
								</Select>
							</div>
						</Col>
					</Row>

					<br />

					<Table
						dataSource={appreciationList.data.map((leader, index) => ({ ...leader, key: index, rank: index + 1 }))}
						loading={appreciationList.isLoading}
						columns={columnList}
						pagination={{
							defaultPageSize: 10,
							pageSize: params.pageSize,
							total: appreciationList.pagination.itemCount ?? 10,
							onChange: (page) => setParams({ ...params, currentPage: page }),
							onShowSizeChange: (current, size) => setParams({ ...params, currentPage: current, pageSize: size }),
							current: params.currentPage,
							defaultCurrent: 1,
						}}
					/>
				</div>
			</Content>
		</Fragment>
	)
}

export default Appreciation
