import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../../components'
import { Layout, Card, Table, Button, Col, Row, Modal, Calendar, Checkbox, Tabs, Spin, Progress, Badge, DatePicker, Form, notification } from 'antd'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'

import API from '../../../services/API'
import thousandSeparator from '../../../utils/thousandSeparator'

import NumberFormat from 'react-number-format'

import { parse, format } from 'date-fns'
import id_ID from 'date-fns/locale/id'

const { TabPane } = Tabs

const { Content } = Layout
const DetailComponent = ({ match }) => {
	const [detailComponent, setDetailComponent] = useState({ data: {}, isLoading: true })
	const [reminderCeomponent, setReminderComponent] = useState({ data: [], isLoading: true })
	const [modalVisible, setModalVisible] = useState(false)
	const [totalValue, setTotalValue] = useState('')
	const [errors, setErrors] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [submittingUpdate, setSubmittingUpdate] = useState(false)

	const [formValue, setFormValue] = useState({
		componentID: match.params.id,
		total: '',
		dateTime: '',
	})

	const fetchDetailComponent = async () => {
		try {
			setDetailComponent({ ...detailComponent, isLoading: true })
			const response = await API.getDetailComponent(match.params.id)

			setDetailComponent({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchReminderComponent = async () => {
		try {
			setReminderComponent({ ...reminderCeomponent, isLoading: true })
			const response = await API.getComponentReminder({ componentID: match.params.id })

			setReminderComponent({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const addReminder = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.addComponentReminder(formValue)

				setSubmitting(false)
				fetchDetailComponent()
				fetchReminderComponent()
				setModalVisible(false)
				notification.success({ message: 'Sukses Tambah Reminder' })
			} catch (error) {
				setErrors(error.response?.data.errors)
				setSubmitting(false)
			}
		}
	}

	useEffect(() => {
		fetchReminderComponent()
		fetchDetailComponent()
	}, [])

	const column = [
		{
			title: 'Tanggal',
			dataIndex: 'date',
			align: 'center',
			render: (text) => <span>{format(parse(text, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMMM yyyy', { locale: id_ID })}</span>,
		},
		{
			title: 'Total',
			dataIndex: 'total',
			align: 'center',
			width: '70%',
			render: (text) => <span>Rp. {thousandSeparator(text)}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'isTaken',
			align: 'center',
			render: (data, record, index) =>
				submittingUpdate ? (
					<LoadingOutlined />
				) : (
					<Checkbox
						checked={data}
						onChange={({ target: { checked } }) => {
							let temp = [...reminderCeomponent.data]
							temp[index].isWithdraw = checked

							if (checked) {
								setSubmittingUpdate(true)
								API.updateComponentReminder({ isWithdraw: '1' }, record.key)
									.then((response) => {
										setSubmittingUpdate(false)
										notification.success({ message: 'Sukses Update' })
										setReminderComponent({
											...reminderCeomponent,
											data: temp,
										})
										fetchDetailComponent()
									})
									.catch((e) => {
										setSubmittingUpdate(false)
										notification.error({ message: 'Gagal Update' })
									})
							} else {
								setSubmittingUpdate(true)
								API.updateComponentReminder({ isWithdraw: '0' }, record.key)
									.then((response) => {
										setSubmittingUpdate(false)
										notification.success({ message: 'Sukses Update' })
										setReminderComponent({
											...reminderCeomponent,
											data: temp,
										})
										fetchDetailComponent()
									})
									.catch((e) => {
										setSubmittingUpdate(false)
										notification.error({ message: 'Gagal Update' })
									})
							}
						}}
					/>
				),
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Detail Activity' />

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					{detailComponent.isLoading ? (
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Spin size='large' />
						</div>
					) : (
						<Fragment>
							<ModalAdd
								errors={errors}
								max={detailComponent.data.total - detailComponent.data.withdraw.totalMoney}
								visible={modalVisible}
								onCancel={() => setModalVisible(false)}
								onSubmit={() => addReminder()}
								onChangeDate={(date) => setFormValue({ ...formValue, dateTime: `${date} 00:00:00` })}
								onChangeTotal={(total) => {
									setTotalValue(`${total}`)
									setFormValue({ ...formValue, total })
								}}
								totalValue={totalValue}
								loading={submitting}
							/>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Row gutter={[16, 16]}>
										<Col span={6}>Kode</Col>
										<Col span={16}> : {detailComponent.data.fullCode}</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={6}>Nama Kegiatan</Col>
										<Col span={16}> : {detailComponent.data.name}</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={6}>Jumlah</Col>
										<Col span={16}> : Rp. {thousandSeparator(detailComponent.data.total)}</Col>
									</Row>
								</Col>

								<Col span={12}>
									<Row gutter={[16, 16]}>
										<Col span={12}>
											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
												<Progress
													type='circle'
													percent={(
														(isNaN(detailComponent.data.withdraw.totalMoney / detailComponent.data.total)
															? 0
															: detailComponent.data.withdraw.totalMoney / detailComponent.data.total) * 100
													).toFixed(2)}
												/>
												<span style={{ marginTop: '16px', fontSize: '18px' }}>Cakupan Rencana</span>
												<span>
													Rp. {thousandSeparator(detailComponent.data.withdraw.totalMoney)} / Rp. {thousandSeparator(detailComponent.data.total)}
												</span>
											</div>
										</Col>
										<Col span={12}>
											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
												<Progress
													type='circle'
													percent={(
														(isNaN(detailComponent.data.withdraw.totalWithdraw / detailComponent.data.withdraw.totalMoney)
															? 0
															: detailComponent.data.withdraw.totalWithdraw / detailComponent.data.withdraw.totalMoney) * 100
													).toFixed(2)}
												/>
												<span style={{ marginTop: '16px', fontSize: '18px' }}>Progress Pengambilan</span>
												<span>
													Rp. {thousandSeparator(detailComponent.data.withdraw.totalWithdraw)} / Rp.{' '}
													{thousandSeparator(detailComponent.data.withdraw.totalMoney)}
												</span>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Fragment>
					)}

					<Tabs defaultActiveKey='1'>
						<TabPane tab='Table View' key='1'>
							{reminderCeomponent.isLoading ? (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Spin size='large' />
								</div>
							) : (
								<Fragment>
									<Row gutter={[0, 16]} align='middle' justify='end'>
										<Col>
											{detailComponent.isLoading ? null : (
												<Button
													disabled={detailComponent.data.total - detailComponent.data.withdraw.totalMoney === 0}
													onClick={() => setModalVisible(true)}
													style={{ borderRadius: '4px' }}
													type='primary'
													icon={<PlusOutlined />}>
													Tambah Reminder
												</Button>
											)}
										</Col>
									</Row>
									<Table
										bordered
										columns={column}
										dataSource={reminderCeomponent.data.map((reminder) => ({
											key: reminder.ID,
											date: reminder.dateTime,
											total: reminder.total,
											isTaken: reminder.isWithdraw,
										}))}
										loading={reminderCeomponent.isLoading}
									/>
								</Fragment>
							)}
						</TabPane>
						<TabPane tab='Calendar View' key='2'>
							{reminderCeomponent.isLoading ? (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Spin size='large' />
								</div>
							) : (
								<Calendar
									dateCellRender={(value) => {
										return (
											<div>
												<ul style={{ listStyle: 'none' }}>
													{reminderCeomponent.isLoading
														? null
														: reminderCeomponent.data.map((reminder) =>
																value.format('YYYY-MM-DD') ===
																format(parse(reminder.dateTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy-MM-dd') ? (
																	<li>
																		<Badge
																			status={reminder.isWithdraw ? 'success' : 'error'}
																			text={`Rp. ${thousandSeparator(reminder.total)}`}
																		/>
																	</li>
																) : null
														  )}
												</ul>
											</div>
										)
									}}
								/>
							)}
						</TabPane>
					</Tabs>
				</Card>
			</Content>
		</Fragment>
	)
}

const ModalAdd = ({ visible, onCancel, onChangeDate, onSubmit, onChangeTotal, max = 0, totalValue = '', errors, loading = false }) => {
	return (
		<Modal onCancel={onCancel} visible={visible} title='Tambah Reminder' onOk={onSubmit} confirmLoading={loading}>
			<Form layout='vertical'>
				<Form.Item
					validateStatus={errors ? (errors.dateTime || errors.errors ? 'error' : undefined) : undefined}
					help={errors ? (errors.dateTime ? errors.dateTime[0] : errors.errors ? errors.errors[0] : null) : null}
					label='Pilih Tanggal Pengambilan'
					required
					rules={[{ required: true, message: 'Silahkan Pilih Tanggal' }]}>
					<DatePicker format='YYYY-MM-DD' onChange={(moment, dateString) => onChangeDate(dateString)} allowClear={false} />
				</Form.Item>

				<Form.Item
					validateStatus={errors ? (errors.total || errors.errors ? 'error' : undefined) : undefined}
					help={errors ? (errors.total ? errors.total[0] : errors.errors ? errors.errors[0] : null) : null}
					label={`Input Jumlah (max : Rp. ${thousandSeparator(max)})`}
					required
					rules={[{ required: true, message: 'Silahkan Masukan Total' }]}>
					<NumberFormat
						onValueChange={(e) => onChangeTotal(e.value)}
						className='ant-input'
						value={totalValue}
						thousandSeparator={'.'}
						decimalSeparator={','}
						prefix={'Rp. '}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default DetailComponent
