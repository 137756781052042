import React, { Fragment } from 'react'
import { CustomHeader } from '../../components'

import { Layout, Button, Typography, Tabs, List, notification, Modal, Input, Row, Col } from 'antd'
import FormAPI from '../../services/FormAPI'
import moment from 'moment'

const { Content } = Layout
const { Title } = Typography

const FormPage = () => {
	const [listForm, setListForm] = React.useState({ data: [], isLoading: false })
	const [listFormArchive, setListFormArchive] = React.useState({ data: [], isLoading: false })
	const [modalAddVisible, setModalAddVisible] = React.useState(false)
	const [modalDetailisible, setModalDetailisible] = React.useState(false)
	const [submitting, setSubmitting] = React.useState(false)
	const [activeID, setActiveID] = React.useState('')

	const [formAdd, setFormAdd] = React.useState({
		name: '',
		content: '',
		banner: null,
		startDate: '',
		endDate: '',
	})

	const [formEdit, setFormEdit] = React.useState({
		name: '',
		content: '',
		banner: null,
		startDate: '',
		endDate: '',
	})

	const fetchListForm = async () => {
		try {
			const response = await FormAPI.listForm({ deleted: 0 })
			setListForm({ data: response.data.list, isLoading: false })
		} catch (error) {
			console.log(error)
		}
	}

	const fetchListFormArchive = async () => {
		try {
			const response = await FormAPI.listForm({ deleted: 1 })
			setListFormArchive({ data: response.data.list, isLoading: false })
		} catch (error) {
			console.log(error)
		}
	}

	const onAdd = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await FormAPI.addForm(formAdd)
				fetchListForm()
				fetchListFormArchive()
				setSubmitting(false)
				notification.success({ message: 'Sukses Tambah Form' })
				setModalAddVisible(false)
				setFormAdd({
					name: '',
					content: '',
					banner: null,
					startDate: '',
					endDate: '',
				})
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal Tambah Form' })
			}
		}
	}

	const onUpdate = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await FormAPI.updateForm({ ...formEdit, id: activeID })
				fetchListForm()
				fetchListFormArchive()
				setSubmitting(false)
				notification.success({ message: 'Sukses Ubah Form' })
				setModalDetailisible(false)
				setFormEdit({
					name: '',
					content: '',
					banner: null,
					startDate: '',
					endDate: '',
				})
				setActiveID('')
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal Ubah Form' })
			}
		}
	}

	const onArchive = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await FormAPI.archiveForm({ id: activeID })
				fetchListForm()
				fetchListFormArchive()
				setSubmitting(false)
				notification.success({ message: 'Sukses Arsip Form' })
				setModalDetailisible(false)
				setFormEdit({
					name: '',
					content: '',
					banner: null,
					startDate: '',
					endDate: '',
				})
				setActiveID('')
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal Arsip Form' })
			}
		}
	}

	React.useEffect(() => {
		fetchListForm()
		fetchListFormArchive()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Form' />
			<Content style={{ padding: '16px', background: '#fff' }}>
				<div className='dudi_asp_container'>
					<div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
						<Title level={4}>List Form Aktif</Title>
						<Button key='add' type='primary' onClick={() => setModalAddVisible(true)} loading={submitting}>
							Tambah Form
						</Button>
					</div>

					<br />
					<List
						bordered
						loading={listForm.isLoading}
						dataSource={listForm.data}
						renderItem={(item) => (
							<List.Item>
								<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
									<img style={{ height: 100, width: 100, objectFit: 'cover' }} src={item.bannerURL} alt={item.name} />
									<div style={{ flex: 1, paddingLeft: 32 }}>
										<Typography>{item.name}</Typography>
									</div>
									<Button
										onClick={() => {
											setModalDetailisible(true)
											setFormEdit({
												content: item.content,
												endDate: moment(item.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
												name: item.name,
												startDate: moment(item.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
											})
											setActiveID(item.ID)
										}}>
										Detail
									</Button>
								</div>
							</List.Item>
						)}
					/>

					<br />
					<br />

					<div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
						<Title level={4}>List Form Arsip</Title>
					</div>

					<br />
					<List
						bordered
						loading={listFormArchive.isLoading}
						dataSource={listFormArchive.data}
						renderItem={(item) => (
							<List.Item>
								<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
									<img style={{ height: 100, width: 100, objectFit: 'cover' }} src={item.bannerURL} alt={item.name} />
									<div style={{ flex: 1, paddingLeft: 32 }}>
										<Typography>{item.name}</Typography>
									</div>
									<Button
										onClick={() => {
											setModalDetailisible(true)
											setFormEdit({
												content: item.content,
												endDate: moment(item.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
												name: item.name,
												startDate: moment(item.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
											})
											setActiveID(item.ID)
										}}>
										Detail
									</Button>
								</div>
							</List.Item>
						)}
					/>
				</div>
			</Content>

			<Modal
				title='Tambah Form'
				visible={modalAddVisible}
				onCancel={() => {
					setModalAddVisible(false)
				}}
				footer={[
					<Button
						key='cancel'
						type='dashed'
						onClick={() => {
							setModalAddVisible(false)
						}}>
						Batal
					</Button>,
					<Button key='add' type='primary' onClick={onAdd} loading={submitting}>
						Tambah
					</Button>,
				]}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Nama Form
							</span>
						</div>
						<Input
							placeholder='Nama Form'
							style={{ marginTop: '8px', width: '100%' }}
							value={formAdd.name}
							onChange={(e) => setFormAdd({ ...formAdd, name: e.target.value })}
						/>
					</Col>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Banner Form
							</span>
						</div>
						<Input
							type='file'
							placeholder='Banner Form'
							style={{ marginTop: '8px', width: '100%' }}
							onChange={(e) => (e.target.files.length ? setFormAdd({ ...formAdd, banner: e.target.files[0] }) : null)}
						/>
					</Col>

					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> URL FormFacade
							</span>
						</div>

						<small>contoh:</small>
						<img style={{ width: '100%' }} src='https://ik.imagekit.io/iyansr/Screen_Shot_2021-06-12_at_17.14.57_afYEnGt2z.png' alt='Preview' />
						<Input
							placeholder='URL FormFacade'
							style={{ marginTop: '8px', width: '100%' }}
							value={formAdd.content}
							onChange={(e) => setFormAdd({ ...formAdd, content: e.target.value })}
						/>
					</Col>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Tanggal Mulai
							</span>
						</div>
						<Input
							type='date'
							placeholder='Tanggal Mulai'
							style={{ marginTop: '8px', width: '100%' }}
							value={formAdd.startDate}
							onChange={(e) => setFormAdd({ ...formAdd, startDate: e.target.value })}
						/>
					</Col>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Tanggal Selesai
							</span>
						</div>
						<Input
							type='date'
							placeholder='Tanggal Selesai'
							style={{ marginTop: '8px', width: '100%' }}
							value={formAdd.endDate}
							onChange={(e) => setFormAdd({ ...formAdd, endDate: e.target.value })}
						/>
					</Col>
				</Row>
			</Modal>

			<Modal
				title='Detail Form'
				visible={modalDetailisible}
				onCancel={() => {
					setModalDetailisible(false)
					setFormEdit({
						name: '',
						content: '',
						banner: null,
						startDate: '',
						endDate: '',
					})
					setActiveID('')
				}}
				footer={[
					<Button
						key='cancel'
						type='dashed'
						onClick={() => {
							setModalDetailisible(false)
							setFormEdit({
								name: '',
								content: '',
								banner: null,
								startDate: '',
								endDate: '',
							})
							setActiveID('')
						}}>
						Tutup
					</Button>,
					<Button key='delete' onClick={onArchive} type='danger' loading={submitting}>
						Hapus
					</Button>,
					<Button key='add' type='primary' onClick={onUpdate} loading={submitting}>
						Ubah
					</Button>,
				]}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Nama Form
							</span>
						</div>
						<Input
							placeholder='Nama Form'
							style={{ marginTop: '8px', width: '100%' }}
							value={formEdit.name}
							onChange={(e) => setFormEdit({ ...formEdit, name: e.target.value })}
						/>
					</Col>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Banner Form
							</span>
						</div>
						<Input
							type='file'
							placeholder='Banner Form'
							style={{ marginTop: '8px', width: '100%' }}
							onChange={(e) => (e.target.files.length ? setFormEdit({ ...formEdit, banner: e.target.files[0] }) : null)}
						/>
					</Col>

					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> URL FormFacade
							</span>
						</div>

						<small>contoh:</small>
						<img style={{ width: '100%' }} src='https://ik.imagekit.io/iyansr/Screen_Shot_2021-06-12_at_17.14.57_afYEnGt2z.png' alt='Preview' />
						<Input
							placeholder='URL FormFacade'
							style={{ marginTop: '8px', width: '100%' }}
							value={formEdit.content}
							onChange={(e) => setFormEdit({ ...formEdit, content: e.target.value })}
						/>
					</Col>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Tanggal Mulai
							</span>
						</div>
						<Input
							type='date'
							placeholder='Tanggal Mulai'
							style={{ marginTop: '8px', width: '100%' }}
							value={formEdit.startDate}
							onChange={(e) => setFormEdit({ ...formEdit, startDate: e.target.value })}
						/>
					</Col>
					<Col span={24}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Tanggal Selesai
							</span>
						</div>
						<Input
							type='date'
							placeholder='Tanggal Selesai'
							style={{ marginTop: '8px', width: '100%' }}
							value={formEdit.endDate}
							onChange={(e) => setFormEdit({ ...formEdit, endDate: e.target.value })}
						/>
					</Col>
				</Row>
			</Modal>
		</Fragment>
	)
}

export default FormPage
