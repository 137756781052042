import React, { useEffect } from 'react'
import 'antd/dist/antd.css'
import './App.css'
import { Layout } from 'antd'

import {
	Login,
	Employee,
	Home,
	Dashboard,
	DetailEmployee,
	AddEmployee,
	SubOutput,
	Outputs,
	Components,
	SubComponent,
	DetailActivity,
	DetailSubComponent,
	DetailComponent,
	AddSubcomponentActivity,
	UpdateSubcomponentActivity,
	Leave,
	LeaveDetail,
	EmployeeLeave,
	EditEmployee,
	Program,
	AddProgram,
	DetailProgram,
	EditProgram,
	Institution,
	BudgetDashboard,
	EmployeeDashboard,
	Access,
	Absent,
	AbsentDashboard,
	Aspiration,
	AspirationDetail,
	Appreciation,
	Duty,
	CreateAppreciation,
	EditAppreciation,
	JobSkill,
	BudgetPlanDetail,
	Contract,
	CreateClauseGroup,
	CreateTemplate,
	FormPage,
} from './pages'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SideNav from './components/SideNav'

const { Footer } = Layout

const App = () => {
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Router>
				<Switch>
					<Route exact path='/login' component={Login} />
					<Route path='/' component={AuthorizedContent} />
					<Route render={() => <div>not found</div>} />
				</Switch>
			</Router>
		</Layout>
	)
}

const AuthorizedContent = ({ history }) => {
	useEffect(() => {
		if (!localStorage.getItem('@user_token') && !sessionStorage.getItem('@user_token')) {
			history.push('/login')
		}
		// eslint-disable-next-line
	}, [])

	return (
		<Router>
			<Switch>
				<Layout style={{ minHeight: '100vh' }}>
					<SideNav />
					<Layout className='site-layout'>
						<Switch>
							<Route exact path='/' component={Home} />
							<Route exact path='/dashboard' component={Dashboard} />
							<Route exact path='/dashboard/employee' component={EmployeeDashboard} />
							<Route exact path='/leave' component={Leave} />
							<Route exact path='/duty' component={Duty} />
							<Route exact path='/leave/:id' component={LeaveDetail} />
							<Route exact path='/employee' component={Employee} />
							<Route exact path='/employee/:id' component={DetailEmployee} />
							<Route exact path='/employee/:id/leave' component={EmployeeLeave} />
							<Route exact path='/employee/:id/edit' component={EditEmployee} />
							<Route exact path='/add-employee' component={AddEmployee} />
							<Route exact path='/budget/activity/:id' component={DetailActivity} />
							<Route exact path='/budget/sub-component/:id' component={DetailSubComponent} />
							<Route exact path='/budget/component/:id' component={DetailComponent} />
							<Route exact path='/budget/sub-component/activity/add/:id' component={AddSubcomponentActivity} />
							<Route exact path='/budget/sub-component/activity/update/:id' component={UpdateSubcomponentActivity} />
							<Route exact path='/budget/2020/:activityID.:outputID.:subOutputID.:componentID' component={SubComponent} />
							<Route exact path='/budget/2020/:activityID.:outputID.:subOutputID' component={Components} />
							<Route exact path='/budget/2020/:activityID.:outputID' component={SubOutput} />
							<Route exact path='/budget/2020/:activityID' component={Outputs} />
							<Route exact path='/budget' component={BudgetDashboard} />
							<Route exact path='/budget' component={Outputs} />
							<Route exact path='/budget-plan/:id' component={BudgetPlanDetail} />
							<Route exect path='/program/:id/edit' component={EditProgram} />
							<Route exect path='/program/:id' component={DetailProgram} />
							<Route exect path='/program' component={Program} />
							<Route exect path='/add-program' component={AddProgram} />
							<Route exect path='/institution' component={Institution} />
							<Route exect path='/access' component={Access} />
							<Route exect path='/absent' component={Absent} />
							<Route exect path='/absent-dashboard' component={AbsentDashboard} />
							<Route exect path='/aspiration' component={Aspiration} />
							<Route exect path='/appreciation' component={Appreciation} />
							<Route exect path='/edit-appreciation/:id' component={EditAppreciation} />
							<Route exect path='/create-appreciation' component={CreateAppreciation} />
							<Route exect path='/aspiration-detail/:id' component={AspirationDetail} />
							<Route exect path='/jobs/skills' component={JobSkill} />
							<Route exect path='/contract/clause-group/:id' component={CreateClauseGroup} />
							<Route exect path='/contract/clause-group' component={Contract} />
							<Route exect path='/contract/template' component={CreateTemplate} />
							<Route exect path='/form/:id' component={FormPage} />
							<Route exect path='/form' component={FormPage} />
							<Route render={() => <div>not found</div>} />
						</Switch>
						<Footer style={{ textAlign: 'center', background: '#fff' }}>©2020</Footer>
					</Layout>
				</Layout>
			</Switch>
		</Router>
	)
}

export default App
