import { portingAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

export default class ContractAPI {
	static listTemplateClauseGroup(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/contract/template-clause-group?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static listTemplate(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/contract/template?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static detailTemplateClauseGroup(ID, parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/contract/template-clause-group/${ID}?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static listTemplateClause(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/contract/template-clause?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addGroupClause(title, template) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('title', title)

				if (!!template) {
					formData.append('title', title)
					formData.append('template', template)
				}

				const response = await Axios.post(`/contract/template-clause-group`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateGroupClause(id, title) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('title', title)

				const response = await Axios.post(`/contract/template-clause-group/${id}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createClause(templateClauseGroupID, content) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('content', content)
				formData.append('templateClauseGroupID', templateClauseGroupID)

				const response = await Axios.post(`/contract/template-clause`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateClause(ID, content) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('content', content)

				const response = await Axios.post(`/contract/template-clause/${ID}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteClause(ID) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/contract/template-clause/${ID}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteClauseGroup(ID) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/contract/template-clause-group/${ID}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateTemplate(ID, name) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('name', name)

				const response = await Axios.post(`/contract/template/${ID}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteTemplate(ID) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/contract/template/${ID}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
