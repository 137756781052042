import React, { useContext } from 'react'

import { Row, Col, Card, Divider } from 'antd'

import Chart from 'react-apexcharts'
import { EmployeeContext } from '../../../context/EmployeeContext'

const BPJSChart = ({ history }) => {
	const employeeContext = useContext(EmployeeContext)
	const { BPJSSummary } = employeeContext

	return (
		<Col span={24}>
			{BPJSSummary.isLoading ? null : (
				<Card bordered={false} style={styles.bigCard}>
					<h1>Kepemilikan BPJS</h1>
					<Divider />

					<Row gutter={[16, 16]} align='middle' justify='center'>
						<Col span={8}>
							<Chart
								options={{
									plotOptions: {
										pie: {
											donut: {
												labels: {
													show: true,
													name: {
														show: true,
													},
													value: {
														show: true,
													},
													total: {
														show: true,
														label: 'PNS',
													},
												},
											},
										},
									},

									chart: {
										id: 'employe-chart',
										events: {
											dataPointSelection: (a, b, c) => {
												switch (c.dataPointIndex) {
													case 0:
														history.push('/employee?bpjs=0&employeeType=PNS')
														break
													case 1:
														history.push('/employee?bpjs=1&employeeType=PNS')
														break
													default:
														break
												}
											},
										},
									},
									labels: ['Belum Terdata', 'Sudah Terdata'],
								}}
								type='donut'
								series={[BPJSSummary.data['notAvailable']['pns'], BPJSSummary.data['availableBPJS']['PNS']]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>
						<Col span={8}>
							<Chart
								options={{
									plotOptions: {
										pie: {
											donut: {
												labels: {
													show: true,
													name: {
														show: true,
													},
													value: {
														show: true,
													},
													total: {
														show: true,
														label: 'PPNPN',
													},
												},
											},
										},
									},

									chart: {
										id: 'employe-chart',
										events: {
											dataPointSelection: (a, b, c) => {
												switch (c.dataPointIndex) {
													case 0:
														history.push('/employee?bpjs=0&employeeType=PPNPN')
														break
													case 1:
														history.push('/employee?bpjs=1&employeeType=PPNPN')
														break
													default:
														break
												}
											},
										},
									},
									labels: ['Belum Terdata', 'Sudah Terdata'],
								}}
								type='donut'
								series={[BPJSSummary.data['notAvailable']['PPNPN'], BPJSSummary.data['availableBPJS']['PPNPN']]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>
					</Row>
				</Card>
			)}
		</Col>
	)
}
const styles = {
	magentaCard: {
		background: '#ffadd2',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #ffd6e7',

		borderColor: 'none',
	},
	blueCard: {
		background: '#91d5ff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #bae7ff',
	},
	greenCard: {
		background: '#b7eb8f',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #d9f7be',
	},

	bigCard: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
		// height: '520px',
	},
	bigCard1: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
	},
}
export default BPJSChart
