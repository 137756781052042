import React, { useContext, useState, Fragment } from 'react'
import { Divider, Row, Col, Button, Input, notification, Select, DatePicker } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'
import moment from 'moment'

import _ from 'lodash'

const { Option } = Select

const EmployeeSpouse = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee, religion, cities, setCities } = employeeContext

	const form = { employeeID, name: '', isCivilian: 0, NIP: '' }
	const [spouseForm, setSpouseForm] = useState([])
	const [spouseFormAdd, setSpouseFormAdd] = useState([])
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.employeeSpouses.length > 0) {
			setSpouseForm(employeeEdit.employeeSpouses)
		}
	}, [employee.data])

	const onChangeTextAdd = (index, key, value) => {
		let temp = [...spouseFormAdd]
		temp[index][key] = value
		setSpouseFormAdd(temp)
	}

	const onChangeText = (index, key, value) => {
		let temp = [...spouseForm]
		temp[index][key] = value
		setSpouseForm(temp)
	}

	/**
	 * @returns {boolean}
	 */
	const disabled = () => {
		let status = false
		const merge = spouseForm.concat(spouseFormAdd)

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach((calc) => {
				if (
					`${calc.name}`.trim().length === 0 ||
					`${calc.isCivilian}`.trim().length === 0 ||
					(parseInt(calc.isCivilian === 1) && `${calc.NIP}`.trim().length === 0)
				) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	const onAddSpouse = async () => {
		if (!submitting) {
			setSubmitting(true)

			if (spouseForm.length > 0) {
				for (let spouse of spouseForm) {
					try {
						await MasterAPI.updateEmployeeSpouse(spouse, spouse.ID)
						notification.success({ message: 'Sukses Ubah Data Pasangan' })
					} catch (error) {
						notification.error({ message: 'Gagal Ubah Data Pasangan' })
					}
				}
			}

			if (spouseFormAdd.length > 0) {
				for (let spouse of spouseFormAdd) {
					try {
						await MasterAPI.addEmployeeSpouse(spouse)
						notification.success({ message: 'Sukses Tambah Data Pasangan' })
					} catch (error) {
						notification.error({ message: 'Gagal Tambah Data Pasangan' })
					}
				}
			}
			fetchDetailEmployee(employeeID).then((res) => {
				setSpouseFormAdd([])
				setSubmitting(false)
			})
		}
	}

	return (
		<div>
			<h1>Data Pasangan</h1>

			<Divider />

			{spouseForm.length > 0 ? (
				<Fragment>
					{spouseForm.map((data, childrenIndex) => (
						<Row gutter={[16, 16]} key={childrenIndex}>
							<Col span={24}>
								<DeleteOutlined
									style={{ position: 'absolute', right: '8px', color: 'red' }}
									onClick={() => {
										let temp = spouseForm.filter((__, idx) => idx !== childrenIndex)
										setSpouseForm(temp)
									}}
								/>

								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Pasangan
									</span>
								</div>
								<Input
									placeholder='Masukkan Nama Pasangan'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.name}
									onChange={(e) => onChangeText(childrenIndex, 'name', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Status Pekerjaan
									</span>
								</div>
								<Select
									placeholder='Pilih Status Pekerjaan'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.isCivilian}
									onChange={(val) => onChangeText(childrenIndex, 'isCivilian', val)}>
									<Option value={1}>PNS</Option>
									<Option value={0}>Non PNS</Option>
								</Select>
							</Col>

							{data.isCivilian === 1 && (
								<Col span={12}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> NIP
										</span>
									</div>
									<Input
										placeholder='Masukkan NIP'
										style={{ marginTop: '8px', width: '100%' }}
										value={data.NIP}
										onChange={(e) => onChangeText(childrenIndex, 'NIP', e.target.value)}
									/>
								</Col>
							)}

							<Divider />
						</Row>
					))}
				</Fragment>
			) : spouseFormAdd.length > 0 ? null : (
				<Col span={24}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setSpouseFormAdd([...spouseFormAdd, form])}>
						<PlusOutlined /> Tambah Data Pasangan
					</Button>
				</Col>
			)}

			{spouseFormAdd.length > 0 ? (
				<Fragment>
					{spouseFormAdd.map((data, childrenIndex) => (
						<Row gutter={[16, 16]} key={childrenIndex}>
							<Col span={24}>
								<DeleteOutlined
									style={{ position: 'absolute', right: '8px', color: 'red' }}
									onClick={() => {
										let temp = spouseFormAdd.filter((__, idx) => idx !== childrenIndex)
										setSpouseFormAdd(temp)
									}}
								/>

								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Pasangan
									</span>
								</div>
								<Input
									placeholder='Masukkan Nama Pasangan'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.name}
									onChange={(e) => onChangeTextAdd(childrenIndex, 'name', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Status Pekerjaan
									</span>
								</div>
								<Select
									placeholder='Pilih Status Pekerjaan'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.isCivilian}
									onChange={(val) => onChangeTextAdd(childrenIndex, 'isCivilian', val)}>
									<Option value={1}>PNS</Option>
									<Option value={0}>PPNPN</Option>
								</Select>
							</Col>

							{data.isCivilian === 1 && (
								<Col span={12}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> NIP
										</span>
									</div>
									<Input
										placeholder='Masukkan NIP'
										style={{ marginTop: '8px', width: '100%' }}
										value={data.NIP}
										onChange={(e) => onChangeTextAdd(childrenIndex, 'NIP', e.target.value)}
									/>
								</Col>
							)}

							<Divider />
						</Row>
					))}
				</Fragment>
			) : null}
			{spouseFormAdd.concat(spouseForm).length > 0 && (
				<Row gutter={[16, 16]} justify='end'>
					<Col span={12}>
						<Button block onClick={() => setSpouseFormAdd([...spouseFormAdd, form])} loading={submitting}>
							<PlusOutlined /> Tambah Data Pasangan
						</Button>
					</Col>
					<Col span={12}>
						<Button block type='primary' loading={submitting} onClick={onAddSpouse} disabled={disabled()}>
							Simpan Data Pasangan
						</Button>
					</Col>
				</Row>
			)}
		</div>
	)
}

export default EmployeeSpouse
