import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

class API {
	static login(username, password, role) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()
				formData.append('username', username)
				formData.append('password', password)
				const response = await Axios.post('/access/login', formData, {
					headers: {
						Role: role,
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static checkAccount() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.post('/access/account')
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	/*
	 ===== BUDGET =====
	 */

	static getAllMainActivity(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/activity?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	//OUTPUT

	static getAllOutput(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/output?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addOutput(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post('/budget/output', formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteOutput(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/budget/output/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateOutput(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/budget/output/${id}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	//SUB-OUTPUT

	static getAllSubOutput(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/sub-output?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addSubOutput(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post('/budget/sub-output', formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateSubOutput(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/budget/sub-output/${id}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteSubOutput(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/budget/sub-output/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	//COMPONENT

	static addComponent(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post('/budget/component', formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getDetailComponent(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/component/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteComponent(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/budget/component/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateComponent(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/budget/component/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getComponent(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/component?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getComponentReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/component?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addComponentReminder(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/reminder/component`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateComponentReminder(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/reminder/component/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	//SUB COMPONENT

	static getSubComponent(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/sub-component?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addSubComponent(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/budget/sub-component`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateSubComponent(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/budget/sub-component/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteSubComponent(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/budget/sub-component/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getSubComponentReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/sub-component?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateSubComponentReminder(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/reminder/sub-component/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addSubComponentReminder(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/reminder/sub-component`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDetailSubComponent(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/sub-component/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getSubComponentActivity(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/detail-activity?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addActivity(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/budget/detail-activity`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateActivity(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/budget/detail-activity/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteActivity(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/budget/detail-activity/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static detailActivity(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/detail-activity/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static addCalculations(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/budget/calculation`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateCalculations(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/budget/calculation/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteCalculation(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/budget/calculation/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getDetailSubComponentActivity(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/budget/detail-activity/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDetailActivityReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/detail-activity?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addReminderActivity(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post('/reminder/detail-activity', formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateReminderActivity(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/reminder/detail-activity/${id}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	/*
	 ===== EMPLOYEE =====
	 */

	static getAllEmploye(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDetailEmploye(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployee(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployee(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/employee`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateEmployeeAvatar(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/employee/avatar/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static addEmployeeAvatar(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/employee/avatar`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateEmployee(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/employee/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getEmployeeSummary() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/resume`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAllProgram(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAllProgramPhase(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/phase?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static detailProgram(ID) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/${ID}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static createProgram(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/program`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateProgram(programID, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/program/${programID}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteProgram(ID) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/program/${ID}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static createProgramPhase(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/program/phase`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateProgramPhase(programPhaseID, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/program/phase/${programPhaseID}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteProgramPhase(programPhaseID) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/program/phase/${programPhaseID}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static confirmApplicant(applicantID, confirmed, reason = null) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('isConfirmed', confirmed)
				if (reason !== null) {
					formData.append('reason', reason)
				}

				const response = await Axios.post(`/program/applicant/${applicantID}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static programAttachment(programID, files = [], removes = []) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Array.from(files).forEach((file) => {
					formData.append('files[]', file)
				})

				removes.map((remove) => {
					formData.append('removes[]', remove)
				})

				const response = await Axios.post(`/program/attachment/${programID}`, formData)

				resolve(response.data)
			} catch (error) {
				console.log(error)
				reject(error.response.data)
			}
		})
	}

	static getDiscusses(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/discuss?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createDiscuss(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/discuss`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static createReplyDiscuss(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/discuss/reply`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getAllInstitution(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/institution?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getListApplicant(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/applicant?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getSummaryCivilianCategory() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/employee-civilian-category/resume`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static getSummaryEducation() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/employee-education/resume`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeBlood(id, type) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('type', type)
				formData.append('employeeID', id)

				const response = await Axios.post(`/employee/employee-blood-type`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateEmployeeBlood(id, type) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('type', type)
				formData.append('employeeID', id)

				const response = await Axios.post(`/employee/employee-blood-type/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
	static getBPJSResume() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/bpjs/resume`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getProgramReqruitment(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/requirement?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static saveProgramReqruitment(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				if (body.ID) {
					formData.append('ID', body.ID)
				}
				formData.append('programID', body.programID)
				formData.append('description', body.description)
				formData.append('isNeedFile', body.isNeedFile ? '1' : '0')
				formData.append('type', body.type)

				const response = await Axios.post(`/program/requirement`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteProgramReqruitment(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/program/requirement/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateInstitutionRequirement(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('programRequirementID', body.programRequirementID)
				formData.append('institutionID', body.institutionID)
				formData.append('isChecked', body.isChecked)

				const response = await Axios.post(`/program/requirement/institution-requirement`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static deleteInstitution(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete('/institution/' + id)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramBudgetHistory(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramBudgetOutput(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget/output?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static getProgramBudgetSubOutput(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget/sub-output?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getProgramBudgetHistoryDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/program/budget/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static responseBudgetHistory(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				if (body.reason) {
					formData.append('reason', body.reason)
				}
				formData.append('status', body.status)

				const response = await Axios.post(`/program/budget/${id}/response`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
}

export default API
