import React from 'react'
import { Card } from 'antd'

// employeeBank
const BankCard = ({ employee }) => {
	return (
		<Card
			className={`__main_card_table ${
				!employee.data.employeeBank || !employee.data.employeeBank.bank.isPrioritize ? '__bank-card_not_important' : '__bank-card'
			}`}
			style={{
				padding: '16px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				// alignItems: 'center',
				height: 'auto',
			}}>
			<div
				style={{
					padding: '34px 0',
					textAlign: 'center',
				}}>
				{employee.data.employeeBank && employee.data.employeeBank.bank.realPath ? (
					<img
						style={{ height: 'auto', width: '20%', position: 'absolute', top: '16px', right: '16px' }}
						src={employee.data.employeeBank.bank.realPath}
						alt='bank'
					/>
				) : null}

				<h1>No. Rekening</h1>
				{employee.data.employeeBank ? (
					<h1 style={{ fontSize: '24px' }}>{employee.data.employeeBank.number}</h1>
				) : (
					<i style={{ fontSize: '12px' }}>Data Bank Belum Ada</i>
				)}
			</div>
		</Card>
	)
}

export default BankCard
