import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

export default class JobFairAPI {
	static getSkills(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/mitra-vokasi/job-seeker/data/job-seeker-skill?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static mergedSkills(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((obj) => {
					if (body[obj]) {
						formData.append(obj, body[obj])
					}
				})

				const response = await Axios.post(`/mitra-vokasi/job-seeker/data/job-seeker-skill/confirm`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
