import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import EmployeeContextProvider from './context/EmployeeContext'
import LeaveContextProvider from './context/LeaveContext'
import ProgramContextProvider from './context/ProgramContext'
import AuthContextProvider from './context/AuthContext'
import AbsentContextProvider from './context/AbsentContext'

ReactDOM.render(
	<AuthContextProvider>
		<EmployeeContextProvider>
			<LeaveContextProvider>
				<ProgramContextProvider>
					<AbsentContextProvider>
						<App />
					</AbsentContextProvider>
				</ProgramContextProvider>
			</LeaveContextProvider>
		</EmployeeContextProvider>
	</AuthContextProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
