import React, { useState, useEffect, useContext } from 'react'
import { Layout, Form, Input, Card, Checkbox, Button, Row, Col, Select } from 'antd'

import styles from './style.module.css'

import API from '../../services/API'
import PortingAPI from '../../services/PortingAPI'

import { AuthContext } from '../../context/AuthContext'

import Logo from '../../assets/mendikbud.png'

const { Content } = Layout

const IS_CONTRACT_OP = 'Contract-Operator'
const IS_SUPER_ADMIN = 'SuperAdministrator'

const Login = ({ history }) => {
	const authContext = useContext(AuthContext)
	const { userID, setUserID } = authContext

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [role, setRole] = useState('Administrator')
	const [remember, setRemember] = useState(true)
	const [error, setError] = useState(false)
	const [errorMsg, setEerrorMsg] = useState(null)
	const [isSubmitting, setIsSubmitting] = useState(false)

	useEffect(() => {
		if (localStorage.getItem('@user_token') || localStorage.getItem('@user_token')) {
			history.push('/')
		}
		// eslint-disable-next-line
	}, [])

	const onSubmit = async () => {
		if (!isSubmitting) {
			setIsSubmitting(true)
		}
		if (role === IS_CONTRACT_OP) {
			try {
				const response = await PortingAPI.login(username, password, role)
				if (response.data.token) {
					setUserID(response.data.account.ID)
					if (remember) {
						localStorage.setItem('@user_token', response.data.token)
						localStorage.setItem('@role', response.data.requestAs)
						window.location.href = '/contract'
					} else {
						sessionStorage.setItem('@user_token', response.data.token)
						sessionStorage.setItem('@role', response.data.requestAs)
						window.location.href = '/contract'
					}
				}
			} catch (error) {
				setError(true)
				setEerrorMsg(error.errors.errors[0])
				setIsSubmitting(false)
			}
		} else if (role === IS_SUPER_ADMIN) {
			try {
				const response = await API.login(username, password, role)
				if (response.data.token) {
					setUserID(response.data.account.ID)
					if (remember) {
						localStorage.setItem('@user_token', response.data.token)
						localStorage.setItem('@role', response.data.requestAs)
						window.location.href = '/dashboard'
					} else {
						sessionStorage.setItem('@user_token', response.data.token)
						sessionStorage.setItem('@role', response.data.requestAs)
						window.location.href = '/dashboard'
					}
				}
			} catch (error) {
				setError(true)
				setEerrorMsg(error.errors.errors[0])
				setIsSubmitting(false)
			}
		} else {
			try {
				const response = await API.login(username, password, role)
				if (response.data.token) {
					setUserID(response.data.account.ID)
					if (remember) {
						localStorage.setItem('@user_token', response.data.token)
						localStorage.setItem('@role', response.data.requestAs)
						window.location.href = '/program'
					} else {
						sessionStorage.setItem('@user_token', response.data.token)
						sessionStorage.setItem('@role', response.data.requestAs)
						window.location.href = '/program'
					}
				}
			} catch (error) {
				setError(true)
				setEerrorMsg(error.errors.errors[0])
				setIsSubmitting(false)
			}
		}
	}

	return (
		<Content style={{ background: '#69c0ff', minHeight: '100vh', padding: '0 1rem' }}>
			<Content style={{ marginTop: 64 }}>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<img src={Logo} alt='kemendikbud' style={{ width: '16rem' }} />
				</div>
				<Row justify='center'>
					<Col md={12} sm={24} xs={24} lg={8}>
						<Card className={styles['card-parent']}>
							<div className={styles['card-body']}>
								<h1 style={{ fontSize: 24 }}>Silahkan Login</h1>
							</div>

							<Form style={{ paddingLeft: 16, paddingRight: 16 }} onFinish={onSubmit} layout='vertical'>
								<Form.Item
									help={errorMsg}
									validateStatus={error ? 'error' : undefined}
									name='username'
									rules={[{ required: true, message: 'Silahkan Masukkan Username/NIK' }]}>
									<Input type='text' onChange={({ target: { value } }) => setUsername(value)} value={username} placeholder='Username/NIK' />
								</Form.Item>

								<Form.Item
									help={errorMsg}
									validateStatus={error ? 'error' : undefined}
									name='password'
									rules={[{ required: true, message: 'Silahkan Masukkan Password' }]}>
									<Input type='password' onChange={({ target: { value } }) => setPassword(value)} value={password} placeholder='Password' />
								</Form.Item>

								<Form.Item
									label='Masuk Sebagai : '
									help={errorMsg}
									validateStatus={error ? 'error' : undefined}
									rules={[{ required: true, message: 'Silahkan Masukkan Password' }]}>
									<Select value={role} onChange={(val) => setRole(val)}>
										<Select.Option value='SuperAdministrator'>Super Administrator</Select.Option>
										<Select.Option value='Administrator'>Program Administrator</Select.Option>
										{/* <Select.Option value='ProgramAdministrator'>Reviewer Program</Select.Option>
										<Select.Option value='Employee'>Karyawan</Select.Option>
										<Select.Option value='LeaveOperator'>Operator Cuti</Select.Option>
										<Select.Option value='BudgetReminderOperator'>Operator Pengingat Anggaran</Select.Option>
										<Select.Option value='BudgetOperator'>Operator Anggaran</Select.Option>
										<Select.Option value='EmployeeOperator'>Operator Karyawan</Select.Option>
										<Select.Option value='Contract-Operator'>Operator Kontrak</Select.Option> */}
									</Select>
								</Form.Item>

								<Form.Item initialValue={remember} name='remember' valuePropName='checked'>
									<Checkbox onChange={(e) => setRemember(e.target.checked)}>Remember me</Checkbox>
								</Form.Item>

								<Form.Item>
									<Button loading={isSubmitting} style={{ width: '100%', borderRadius: '0.4rem' }} type='primary' htmlType='submit'>
										Login
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</Content>
		</Content>
	)
}

export default Login
