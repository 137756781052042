import { portingAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

export default class PortingAPI {
	static login(username, password, role) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()
				formData.append('username', username)
				formData.append('password', password)
				const response = await Axios.post('/access/login', formData, {
					headers: {
						Role: role,
					},
				})

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
}
