import React, { useContext, useState, useEffect } from 'react'
import { EmployeeContext } from '../../../context/EmployeeContext'
import { Input, Divider, Row, Col, Select, Button, Popconfirm, notification } from 'antd'

import { PlusOutlined, MinusCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import MasterAPI from '../../../services/MasterAPI'

const { Option } = Select

const BankForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, employee, banks, fetchDetailEmployee } = employeeContext

	const form = { employeeID, number: '', bankID: '' }

	const [employeeBank, setEmployeeBank] = useState(null)
	const [employeeBankAdd, setEmployeeBankAdd] = useState(null)

	const [submitting, setSubmitting] = useState(false)

	const onSubmit = async () => {
		if (!submitting) {
			setSubmitting(true)

			if (employeeBankAdd !== null) {
				try {
					await MasterAPI.addEmployeeBank(employeeBankAdd)

					fetchDetailEmployee(employeeID).then((res) => {
						setSubmitting(false)
						setEmployeeBankAdd(null)
						notification.success({ message: 'Sukses Kirim Data Bank' })
					})
				} catch (error) {
					notification.error({ message: 'Gagal Kirim Data Bank' })
					setSubmitting(false)
					console.log(error)
				}
			}

			if (employeeBank !== null) {
				try {
					await MasterAPI.updateEmployeeBank(employeeBank)

					fetchDetailEmployee(employeeID).then((res) => {
						setSubmitting(false)
						setEmployeeBankAdd(null)
						notification.success({ message: 'Sukses Kirim Data Bank' })
					})
				} catch (error) {
					notification.error({ message: 'Gagal Kirim Data Bank' })
					setSubmitting(false)
					console.log(error)
				}
			}
		}
	}

	const disabled = () => {
		let status = false

		if (employeeBankAdd) {
			if (`${employeeBankAdd.bankID}`.trim() === '' || `${employeeBankAdd.number}`.trim() === '') {
				status = true
			}
		}
		if (employeeBank) {
			if (`${employeeBank.bankID}`.trim() === '' || `${employeeBank.number}`.trim() === '') {
				status = true
			}
		}

		if (!employeeBank && !employeeBankAdd) {
			status = true
		}

		return status
	}

	useEffect(() => {
		if (employeeEdit.employeeBank) {
			setEmployeeBank(employeeEdit.employeeBank)
		}
	}, [employee.data])

	return (
		<div>
			<h1>Informasi Bank</h1>
			<Divider />
			{!employeeBank ? null : (
				<Row gutter={[16, 16]} align='middle' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Nama Bank
							</span>
						</div>
						<Select
							onChange={(val) => setEmployeeBank({ ...employeeBank, bankID: val })}
							loading={banks.isLoading}
							defaultActiveFirstOption={false}
							style={{ marginTop: '8px', width: '100%' }}
							placeholder='Pilih Bank'
							value={employeeBank.bankID}>
							{banks.data.map((bank) => (
								<Option name='educationTierID' key={bank.ID} value={bank.ID}>
									{bank.name}
								</Option>
							))}
						</Select>
					</Col>

					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> No.Rekening
							</span>
						</div>
						<Input
							type='number'
							style={{ marginTop: '8px', width: '100%' }}
							value={employeeBank.number}
							placeholder='Input No. Rekening'
							onChange={(e) => setEmployeeBank({ ...employeeBank, number: e.target.value })}
						/>
					</Col>
				</Row>
			)}

			{!employeeBankAdd ? null : (
				<Row gutter={[16, 16]} align='middle' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Nama Bank
							</span>
						</div>
						<Select
							onChange={(val) => setEmployeeBankAdd({ ...employeeBankAdd, bankID: val })}
							loading={banks.isLoading}
							defaultActiveFirstOption={false}
							style={{ marginTop: '8px', width: '100%' }}
							placeholder='Pilih Bank'
							value={employeeBankAdd.bankID}>
							{banks.data.map((bank) => (
								<Option name='educationTierID' key={bank.ID} value={bank.ID}>
									{bank.name}
								</Option>
							))}
						</Select>
					</Col>

					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> No.Rekening
							</span>
						</div>
						<Input
							type='number'
							style={{ marginTop: '8px', width: '100%' }}
							value={employeeBankAdd.number}
							placeholder='Input No. Rekening'
							onChange={(e) => setEmployeeBankAdd({ ...employeeBankAdd, number: e.target.value })}
						/>
					</Col>
				</Row>
			)}

			<Row gutter={[16, 16]} justify='end'>
				{!employeeBank ? (
					<Col span={12}>
						<Button block style={{ marginBottom: '16px' }} onClick={() => setEmployeeBankAdd(form)}>
							<PlusOutlined /> Tambah Akun Bank
						</Button>
					</Col>
				) : null}

				<Col span={12}>
					<Button disabled={disabled()} loading={submitting} block type='primary' onClick={onSubmit}>
						Kirim Akun Bank
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default BankForm
