import React, { useEffect, useState } from 'react'
import { Table, Checkbox } from 'antd'
import BudgetAPI from '../../../services/BudgetAPI'
import moment from 'moment'

import { parse, format } from 'date-fns'
import id_ID from 'date-fns/locale/id'
import thousandSeparator from '../../../utils/thousandSeparator'
import { Link } from 'react-router-dom'

export default () => {
	const [componentReminder, setComponentReminder] = useState({ data: [], isLoading: true })

	const getComponentReminder = async () => {
		try {
			setComponentReminder({ ...componentReminder, isLoading: true })
			const startOfWeek = moment().startOf('week').format('YYYY-MM-DD 00:00:00')
			const endOfWeek = moment().endOf('week').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetAPI.getComponentReminder({ sort: 'asc', date: `ge${startOfWeek},le${endOfWeek}` })

			setComponentReminder({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getComponentReminder()
	}, [])

	const column = [
		{
			title: 'Tanggal',
			dataIndex: 'dateTime',
			align: 'center',
			render: (text, record) => (
				<span>
					<Link to={`/budget/component/${record.componentID}`}>
						{format(parse(text, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMMM yyyy', { locale: id_ID })}
					</Link>
				</span>
			),
		},
		{
			title: 'Total',
			dataIndex: 'total',
			align: 'center',
			width: '70%',
			render: (text) => <span>Rp. {thousandSeparator(text)}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'isWithdraw',
			align: 'center',
			render: (data, record, index) => <Checkbox checked={data} />,
		},
	]

	return (
		<div>
			<br />
			{componentReminder.isLoading ? null : (
				<Table dataSource={componentReminder.data.map((data) => ({ ...data, key: data.ID }))} columns={column} bordered />
			)}
		</div>
	)
}
