import React, { useState, useContext } from 'react'
import { Modal, Input, DatePicker } from 'antd'
import { LeaveContext } from '../../../context/LeaveContext'

const ModalReject = ({ onSuccess, ...rest }) => {
	const leaveContext = useContext(LeaveContext)
	const [form, setForm] = useState({ reason: '', canLeave: 0 })

	const { fetchEmployeeLeaveRange, leaveRange, fetchEmployeeLeave, employeeLeave, responseLeave, responseSubmitting } = leaveContext

	return (
		<Modal
			{...rest}
			title='Tolak Pengajuan Cuti'
			onOk={async () => {
				const success = await responseLeave(form)
				if (success) {
					onSuccess()
				}
			}}>
			<div>
				<span>
					<span style={{ color: 'red' }}>*</span> Alasan Tolak
				</span>
				<Input.TextArea
					style={{ marginTop: 8 }}
					placeholder='Alasan Tolak'
					onChange={(e) => {
						setForm({ ...form, reason: e.target.value })
					}}
				/>
			</div>
		</Modal>
	)
}

export default ModalReject
