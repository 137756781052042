import React, { useContext, useState, useEffect, Fragment } from 'react'
import { EmployeeContext } from '../../../context/EmployeeContext'
import { Input, Divider, Row, Col, Select, Button, Popconfirm, notification, Tooltip } from 'antd'

import { PlusOutlined, DownloadOutlined, LoadingOutlined, QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import MasterAPI from '../../../services/MasterAPI'

const { Option } = Select

const EducationHistoryForm = ({ educationTier, employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee } = employeeContext

	const [loadingDelete, setLoadingDelete] = useState(false)

	const employeeEducationForm = { employeeID, educationTierID: '', schoolName: '', subjectName: '', graduationYear: '', referenceNumber: '', image: null }
	const [employeeEducation, setEmployeeEducation] = useState([])
	const [employeeEducationAdd, setEmployeeEducationAdd] = useState([])

	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.historyEducation.length > 0) {
			setEmployeeEducation(
				employeeEdit.historyEducation.map((education) => ({
					...education,
					subjectName: education.employeeEducationSubject?.subject ?? '',
					image: education.imageURL,
				}))
			)
		}
		console.log('recall')
	}, [employee.data])

	const onChangeTier = (value, e) => {
		let temp = [...employeeEducation]
		temp[e['data-idx']][e.name] = value
		setEmployeeEducation(temp)
	}

	const onChangeText = (e) => {
		let temp = [...employeeEducation]
		temp[e.target.dataset.idx][e.target.name] = e.target.value
		setEmployeeEducation(temp)
	}

	const removeEducation = async (id, index) => {
		if (!loadingDelete) {
			setLoadingDelete(true)
			try {
				await MasterAPI.deleteEmployeeEducation(id)
				fetchDetailEmployee(employeeID).then((res) => {
					setLoadingDelete(false)
					let tmp = employeeEducation.filter((__, idx) => index !== idx)
					setEmployeeEducation(tmp)
					notification.success({ message: 'Sukses Hapus Data' })
				})
			} catch (error) {
				notification.error({ message: 'Gagal Hapus Data' })
				setLoadingDelete(false)
			}
		}
	}

	//----

	const onChangeTierAdd = (value, e) => {
		let temp = [...employeeEducationAdd]
		temp[e['data-idx']][e.name] = value
		setEmployeeEducationAdd(temp)
	}

	const removeEducationAdd = (index) => {
		let tmp = employeeEducationAdd.filter((edu, idx) => idx !== index)
		setEmployeeEducationAdd(tmp)
	}

	const onChangeTextAdd = (e) => {
		let temp = [...employeeEducationAdd]
		temp[e.target.dataset.idx][e.target.name] = e.target.value
		setEmployeeEducationAdd(temp)
	}

	/**
	 * @returns {boolean}
	 */
	const disabled = () => {
		let status = false
		const merge = employeeEducation.concat(employeeEducationAdd)

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach((calc) => {
				if (
					`${calc.educationTierID}`.trim().length === 0 ||
					`${calc.schoolName}`.trim().length === 0 ||
					`${calc.graduationYear}`.trim().length === 0 ||
					`${calc.referenceNumber}`.trim().length === 0
				) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	return (
		<div>
			<h1>History Pendidikan</h1>
			<Divider />
			{employeeEducation.map((education, index) => (
				<Fragment key={education.ID}>
					<Row gutter={[16, 16]} align='middle' justify='center'>
						<Col span={8}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tingkat Pendidikan
								</span>
							</div>
							<Select
								onChange={onChangeTier}
								defaultActiveFirstOption={false}
								style={{ marginTop: '8px', width: '100%' }}
								placeholder='Pilih Tingkat Pendidikan'
								value={education.educationTierID}>
								{educationTier.data.map((education) => (
									<Option data-idx={index} name='educationTierID' key={education.ID} value={education.ID}>
										{education.name}
									</Option>
								))}
							</Select>
						</Col>
						<Col span={8}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Nama Sekolah / Universitas
								</span>
							</div>
							<Input
								placeholder='Masukan Nama Sekolah / Universitas'
								style={{ marginTop: '8px', width: '100%' }}
								data-idx={index}
								name='schoolName'
								value={education.schoolName}
								onChange={onChangeText}
							/>
						</Col>
						<Col span={8}>
							{loadingDelete ? (
								<LoadingOutlined style={{ position: 'absolute', right: '8px', color: 'red' }} />
							) : (
								<Popconfirm
									disabled={loadingDelete}
									okType='dashed'
									onConfirm={() => {
										removeEducation(education.ID, index)
									}}
									title={<span>Apakah anda yakin untuk menghapus data ?</span>}
									icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
									<DeleteOutlined style={{ position: 'absolute', right: '8px', color: 'red' }} />
								</Popconfirm>
							)}

							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Jurusan
								</span>
							</div>
							<Input
								placeholder='Masukkan Nama Jurusan'
								style={{ marginTop: '8px', width: '100%' }}
								data-idx={index}
								name='subjectName'
								value={education.subjectName}
								onChange={onChangeText}
							/>
						</Col>

						<Col span={8}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Nomor Ijazah
								</span>
							</div>
							<Input
								placeholder='Masukkan Nomor Ijazah'
								style={{ marginTop: '8px', width: '100%' }}
								name='referenceNumber'
								data-idx={index}
								value={education.referenceNumber}
								onChange={onChangeText}
							/>
						</Col>
						<Col span={8}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tahun Lulus
								</span>
							</div>
							<Input
								maxLength={4}
								data-idx={index}
								placeholder='Masukkan Tahun Lulus'
								style={{ marginTop: '8px', width: '100%' }}
								name='graduationYear'
								value={education.graduationYear}
								onChange={onChangeText}
							/>
						</Col>
						<Col span={8}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Upload Ijazah
									{education.imageURL && (
										<Tooltip title='Lihat Ijazah'>
											{' '}
											<a href={education.imageURL} rel='noopener noreferrer' target='_blank'>
												<DownloadOutlined style={{ marginLeft: '10px' }} />
											</a>
										</Tooltip>
									)}
								</span>
							</div>
							<Input
								type='file'
								maxLength={4}
								data-idx={index}
								placeholder='Upload Ijazah'
								style={{ marginTop: '8px', width: '100%' }}
								name='image'
								onChange={(e) => {
									let temp = [...employeeEducation]
									temp[index]['image'] = e.target.files[0]
									setEmployeeEducation(temp)
								}}
							/>
						</Col>
					</Row>

					<br />
				</Fragment>
			))}

			{employeeEducationAdd.length
				? employeeEducationAdd.map((education, index) => (
						<Fragment key={index}>
							<Row gutter={[16, 16]} align='middle' justify='center'>
								<Col span={8}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Tingkat Pendidikan
										</span>
									</div>
									<Select
										onChange={onChangeTierAdd}
										defaultActiveFirstOption={false}
										style={{ marginTop: '8px', width: '100%' }}
										placeholder='Tingkat Pendidikan'
										value={education.educationTierID}>
										{educationTier.data.map((tier) => (
											<Option data-idx={index} name='educationTierID' key={tier.ID} value={tier.ID}>
												{tier.name}
											</Option>
										))}
									</Select>
								</Col>
								<Col span={8}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Nama Sekolah / Universitas
										</span>
									</div>
									<Input
										placeholder='Masukan Nama Sekolah / Universitas'
										style={{ marginTop: '8px', width: '100%' }}
										data-idx={index}
										name='schoolName'
										value={education.schoolName}
										onChange={onChangeTextAdd}
									/>
								</Col>
								<Col span={8}>
									<DeleteOutlined style={{ position: 'absolute', right: '8px', color: 'red' }} onClick={() => removeEducationAdd(index)} />
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Jurusan
										</span>
									</div>
									<Input
										placeholder='Masukkan Nama Jurusan'
										style={{ marginTop: '8px', width: '100%' }}
										data-idx={index}
										name='subjectName'
										value={education.subjectName}
										onChange={onChangeTextAdd}
									/>
								</Col>
								<Col span={8}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Nomor Ijazah
										</span>
									</div>
									<Input
										placeholder='Masukkan Nomor Ijazah'
										style={{ marginTop: '8px', width: '100%' }}
										name='referenceNumber'
										data-idx={index}
										value={education.referenceNumber}
										onChange={onChangeTextAdd}
									/>
								</Col>
								<Col span={8}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Tahun Lulus
										</span>
									</div>
									<Input
										maxLength={4}
										data-idx={index}
										placeholder='Masukkan Tahun Lulus'
										style={{ marginTop: '8px', width: '100%' }}
										name='graduationYear'
										value={education.graduationYear}
										onChange={onChangeTextAdd}
									/>
								</Col>
								<Col span={8}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Upload Ijazah
										</span>
									</div>
									<Input
										type='file'
										maxLength={4}
										data-idx={index}
										placeholder='Upload Ijazah'
										style={{ marginTop: '8px', width: '100%' }}
										name='image'
										onChange={(e) => {
											let temp = [...employeeEducationAdd]
											temp[index]['image'] = e.target.files[0]
											setEmployeeEducationAdd(temp)
										}}
									/>
								</Col>
								{/* <Col span={3}>
								<MinusCircleOutlined onClick={() => removeEducationAdd(index)} />
							</Col> */}
							</Row>

							<br />
						</Fragment>
				  ))
				: null}

			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setEmployeeEducationAdd([...employeeEducationAdd, { ...employeeEducationForm }])}>
						<PlusOutlined /> Tambah History Pendidikan
					</Button>
				</Col>

				<Col span={12}>
					<Button
						disabled={disabled()}
						block
						loading={submitting}
						type='primary'
						onClick={async () => {
							if (!submitting) {
								setSubmitting(true)

								if (employeeEducation.length > 0) {
									for (let education of employeeEducation) {
										try {
											await MasterAPI.updateEmployeeEducation(education, education.ID)
											notification.success({ message: 'Sukses Kirim History Pendidikan' })
											setSubmitting(false)
										} catch (error) {
											notification.error({ message: 'Gagal Kirim History Pendidikan' })
										}
									}
								}

								if (employeeEducationAdd.length > 0) {
									for (let education of employeeEducationAdd) {
										try {
											await MasterAPI.addEmployeeEducation(education)
											notification.success({ message: 'Sukses Kirim History Pendidikan' })
											setSubmitting(false)
										} catch (error) {
											notification.error({ message: 'Gagal Kirim History Pendidikan' })
										}
									}
								}
								fetchDetailEmployee(employeeID).then((res) => {
									setEmployeeEducationAdd([])
									setSubmitting(false)
								})
							}
						}}>
						Kirim History Pendidikan
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default EducationHistoryForm
