import React, { useContext, useState } from 'react'
import { Divider, Row, Col, Button, Input, notification } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'

const NIPForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee } = employeeContext

	const form = { employeeID, number: '' }
	const [nip, setNip] = useState(null)
	const [nipAdd, setNipAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.NIP) {
			setNip(employeeEdit.NIP)
		}
	}, [employee.data])

	const onUpdateNip = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.updateEmployeeNIP(nip)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Ubah NIP' })
					setSubmitting(false)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Ubah NIP' })
				setSubmitting(false)
			}
		}
	}

	const onAddNip = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.addEmployeeNIP(nipAdd)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Menambahkan NIP' })
					setSubmitting(false)
					setNipAdd(null)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Menambahkan NIP' })
				setSubmitting(false)
			}
		}
	}

	return (
		<div>
			<h1>NIP</h1>

			<Divider />

			{nip ? (
				<Row gutter={[16, 16]} align='bottom' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> NIP
							</span>
						</div>
						<Input
							placeholder='Masukan NIP'
							style={{ marginTop: '8px', width: '100%' }}
							value={nip.number}
							onChange={(e) => setNip({ ...nip, number: e.target.value })}
						/>
					</Col>

					<Col span={12}>
						<Button onClick={onUpdateNip} block loading={submitting} type='primary'>
							Ubah NIP
						</Button>
					</Col>
				</Row>
			) : nipAdd ? null : (
				<Col span={24}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setNipAdd(form)}>
						<PlusOutlined /> Tambah NIP
					</Button>
				</Col>
			)}

			{nipAdd ? (
				<Row gutter={[16, 16]} align='bottom' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> NIP
							</span>
						</div>
						<Input
							placeholder='Masukan NIP'
							style={{ marginTop: '8px', width: '100%' }}
							value={nipAdd.number}
							onChange={(e) => setNipAdd({ ...nipAdd, number: e.target.value })}
						/>
					</Col>

					<Col span={12}>
						<Button onClick={onAddNip} block loading={submitting} type='primary'>
							Kirim NIP
						</Button>
					</Col>
				</Row>
			) : null}
		</div>
	)
}

export default NIPForm
