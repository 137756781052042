import React, { Fragment, useEffect, useContext, useState } from 'react'

import { Layout, Row, Col, notification, Divider, Button, Typography, Input, Select, Popconfirm } from 'antd'
import { CustomHeader } from '../../components'
import AspirationAPI from '../../services/AspirationAPI'
import { CloseOutlined, MinusOutlined } from '@ant-design/icons'
import moment from 'moment'
import gravatar from 'gravatar'
import { Link } from 'react-router-dom'
import API from '../../services/API'
import _ from 'lodash'
import { da } from 'date-fns/locale'

const { Content } = Layout
const { Title } = Typography
const { Option } = Select

const Edit = ({ history, match }) => {
	const [employeeList, setEmployeeList] = useState({ data: [], isLoading: true })
	const [detail, setDetail] = useState({ data: null, isLoading: true })
	const [query, setQuery] = useState({ pageSize: 0, keyword: '' })
	const [submitting, setSubmitting] = useState(false)

	const [form, setForm] = useState({
		employeeID: '',
		label: '',
		point: '',
		description: '',
	})
	const [removedFiles, setRemovedFiles] = useState([])

	const [files, setFiles] = useState([
		{
			file: null,
		},
	])

	const onGiftAppreciation = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await AspirationAPI.updateAppreciation(
					detail.data,
					files.map((file) => file.file),
					removedFiles,
					match.params.id
				)
				notification.success({ message: 'Sukses ubah apresiasi' })
				setSubmitting(false)
				setForm({
					employeeID: '',
					label: '',
					point: '',
					description: '',
				})

				history.push('/appreciation')
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal ubah apresiasi' })
				setSubmitting(false)
			}
		}
	}

	const onDeleteAppreciation = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await AspirationAPI.deleteAppreciation(match.params.id)
				notification.success({ message: 'Sukses hapus apresiasi' })
				setSubmitting(false)

				history.push('/appreciation')
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal hapus apresiasi' })
				setSubmitting(false)
			}
		}
	}

	const fetchDetail = async () => {
		try {
			const response = await AspirationAPI.detailAppreciation(match.params.id)

			setDetail({
				data: {
					...response.data,
					description: response.data.appreciationDescription?.description ?? '',
				},
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}
	const fetchEmployeeList = async () => {
		try {
			const response = await API.getAllEmploye(query)

			setEmployeeList({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchEmployeeList()
	}, [query.keyword])

	useEffect(() => {
		fetchDetail()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Aspirasi' />
			<Content style={{ padding: '16px', background: '#fff' }}>
				<div className='dudi_asp_container'>
					<Title level={4}>Ubah apresiasi</Title>

					{detail.isLoading ? null : (
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Pilih Karyawan
									</span>
								</div>
								<Select
									filterOption={false}
									onSearch={_.debounce((value) => setQuery({ ...query, keyword: value }), 500)}
									onChange={(val) => setDetail({ ...detail, data: { ...detail.data, employeeID: val } })}
									loading={employeeList.isLoading}
									placeholder='Pilih Karyawan'
									showSearch
									style={{ marginTop: '8px', width: '100%' }}
									value={detail.data.employeeID}>
									{employeeList.data.map((employee) => (
										<Option key={employee.ID} value={employee.ID}>
											{employee.name}
										</Option>
									))}
								</Select>
							</Col>
							<Col span={24}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Label
									</span>
								</div>
								<Input
									placeholder='Label'
									style={{ marginTop: '8px', width: '100%' }}
									value={detail.data.label}
									onChange={(e) => setDetail({ ...detail, data: { ...detail.data, label: e.target.value } })}
								/>
							</Col>
							<Col span={24}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Poin
									</span>
								</div>
								<Input
									type='number'
									max={5}
									placeholder='5'
									step={1}
									maxLength={1}
									style={{ marginTop: '8px', width: '100%' }}
									value={detail.data.point}
									onChange={(e) => {
										if (parseInt(e.target.value) > 5) {
											setDetail({ ...detail, data: { ...detail.data, point: 5 } })
										} else if (parseInt(e.target.value) < 1) {
											setDetail({ ...detail, data: { ...detail.data, point: 1 } })
										} else {
											setDetail({ ...detail, data: { ...detail.data, point: e.target.value } })
										}
									}}
								/>
							</Col>
							<Col span={24}>
								<div>
									<span style={{ color: 'black' }}>Deskripsi</span>
								</div>
								<Input.TextArea
									placeholder='Deskripsi'
									style={{ marginTop: '8px', width: '100%' }}
									value={detail.data.description.description}
									onChange={(e) => setDetail({ ...detail, data: { ...detail.data, description: e.target.value } })}
								/>
							</Col>
							<Col span={24}>
								<div>
									<span style={{ color: 'black' }}>Berkas</span>
								</div>

								{detail.data.appreciationFiles.length > 0 && (
									<div style={{ marginTop: 16 }}>
										<span>Berkas / Lampiran :</span>

										<ul>
											{detail.data.appreciationFiles.map((file) => (
												<li key={file.ID} style={{ fontSize: '0.8rem' }}>
													<a href={file.fileURL} target='_blank' rel='noopener noreferrer'>
														{file.file.split('/')[file.file.split('/').length - 1]}
													</a>

													{removedFiles.includes(file.ID) ? (
														<MinusOutlined
															style={{ marginLeft: 12 }}
															onClick={() => {
																let tmp = [...removedFiles]
																setRemovedFiles(tmp.filter((temp) => temp !== file.ID))
															}}
														/>
													) : (
														<CloseOutlined
															onClick={() => {
																setRemovedFiles([...removedFiles, file.ID])
															}}
															style={{ marginLeft: 12 }}
														/>
													)}
												</li>
											))}
										</ul>
									</div>
								)}

								{files.map((file, index) => (
									<Row gutter={[16, 16]} align='middle' key={index}>
										<Col span={18}>
											<Input
												type='file'
												style={{ marginTop: '8px', width: '100%' }}
												onChange={(e) => {
													let temp = [...files]
													temp[index].file = e.target.files[0]
													setFiles(temp)
												}}
											/>
										</Col>

										<Col span={6}>
											<Button
												type='primary'
												style={{ float: 'right' }}
												onClick={() => {
													setFiles([...files, { file: null }])
												}}>
												Tambah Berkas
											</Button>
										</Col>
									</Row>
								))}
							</Col>
							<Col span={24}>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Popconfirm trigger='click' title='Yakin Hapus ?' onConfirm={onDeleteAppreciation}>
											<Button block type='dashed' loading={submitting}>
												Hapus Apresiasi
											</Button>
										</Popconfirm>
									</Col>
									<Col span={12}>
										<Button block type='primary' loading={submitting} onClick={onGiftAppreciation}>
											Ubah Apresiasi
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					)}
				</div>
			</Content>
		</Fragment>
	)
}

export default Edit
