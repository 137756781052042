import React, { createContext, useState, useEffect } from 'react'
import LeaveAPI from '../services/LeaveAPI'
import { notification } from 'antd'
import moment from 'moment'

export const LeaveContext = createContext()
export const LeaveContextConsumer = LeaveContext.Consumer

const LeaveContextProvider = ({ children }) => {
	const [employeeLeave, setEmployeeLeave] = useState({ data: [], isLoading: true, pagination: {} })
	const [leaveRange, setLeaveRange] = useState({ data: [], isLoading: true })
	const [leaveRangeMonth, setLeaveRangeMonth] = useState({ data: [], isLoading: true })
	const [todayResume, setTodayResume] = useState({ data: [], isLoading: true })
	const [responseSubmitting, setResponseSubmitting] = useState(false)
	const [activeID, setActiveID] = useState(null)

	const fetchEmployeeLeave = async (params = {}) => {
		try {
			setEmployeeLeave({ ...employeeLeave, isLoading: true })
			const response = await LeaveAPI.getAllemployeeLeave(params)

			if (response.code === 200) {
				setEmployeeLeave({ data: response.data.list, isLoading: false, pagination: response.data.pagination })
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchEmployeeLeaveRange = async (params = {}) => {
		try {
			setLeaveRange({ ...leaveRange, isLoading: true })
			const response = await LeaveAPI.getAllemployeeLeaveRange(params)

			if (response.code === 200) {
				setLeaveRange({ data: response.data, isLoading: false })
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchEmployeeLeaveRangeMonth = async () => {
		try {
			setLeaveRangeMonth({ ...leaveRangeMonth, isLoading: true })
			const response = await LeaveAPI.getAllemployeeLeaveRangeMonth({ year: moment().format('YYYY') })

			if (response.code === 200) {
				setLeaveRangeMonth({ data: response.data, isLoading: false })
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchTodayResume = async () => {
		try {
			setTodayResume({ ...todayResume, isLoading: true })

			const response = await LeaveAPI.getTodayResume()
			if (response.code === 200) {
				setTodayResume({
					data: response.data,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const responseLeave = (body = {}) => {
		return new Promise(async (resolve, reject) => {
			if (!responseSubmitting) {
				try {
					setResponseSubmitting(true)
					await LeaveAPI.responseLeave({ ...body, employeeLeaveID: activeID })

					notification.success({ message: 'Sukses Respon' })
					setResponseSubmitting(false)
					resolve(true)
				} catch (error) {
					notification.error({ message: 'Gagal Respon' })
					resolve(false)
					setResponseSubmitting(false)
					console.log(error)
				}
			}
		})
	}

	useEffect(() => {
		fetchEmployeeLeaveRangeMonth()
		fetchTodayResume()
	}, [])

	return (
		<LeaveContext.Provider
			value={{
				fetchEmployeeLeave,
				employeeLeave,

				fetchEmployeeLeaveRange,
				leaveRange,

				responseLeave,
				responseSubmitting,

				setActiveID,
				leaveRangeMonth,

				todayResume,
				activeID,
			}}>
			{children}
		</LeaveContext.Provider>
	)
}

export default LeaveContextProvider
