import React from 'react'
import { Col, Row, Card, Divider } from 'antd'

import { parse, format } from 'date-fns'
import idLocale from 'date-fns/locale/id'

const PersonalData = ({ employee }) => {
	return (
		<Card className='__main_card_table'>
			<div>
				<h1>Data Pribadi</h1>
				<Divider />
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<span style={{ fontWeight: '500' }}>Tempat / Tanggal Lahir</span>
					</Col>
					<Col span={14}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<span>:</span>
							<div style={{ marginLeft: '8px' }}>
								{employee.getBornPlace.name} /{' '}
								{format(parse(employee.bornDate, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy', {
									locale: idLocale,
								})}
							</div>
						</div>
					</Col>
				</Row>
				{/* -- */}

				<Row gutter={[16, 16]}>
					<Col span={8}>
						<span style={{ fontWeight: '500' }}>Agama</span>
					</Col>
					<Col span={14}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<span>:</span>
							<div style={{ marginLeft: '8px' }}>{employee.religion.name}</div>
						</div>
					</Col>
				</Row>

				{/* -- */}
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<span style={{ fontWeight: '500' }}>Jenis Kelamin</span>
					</Col>
					<Col span={14}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<span>:</span>
							<div style={{ marginLeft: '8px' }}>{employee.gender === '0' ? 'Laki-Laki' : 'Perempuan'}</div>
						</div>
					</Col>
				</Row>

				{/* -- */}
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<span style={{ fontWeight: '500' }}>Status Pernikahan</span>
					</Col>
					<Col span={14}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<span>:</span>
							<div style={{ marginLeft: '8px' }}>{employee.isMarried ? 'Menikah' : 'Belum Menikah'}</div>
						</div>
					</Col>
				</Row>

				{/* -- */}
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<span style={{ fontWeight: '500' }}>Golongan Darah</span>
					</Col>
					<Col span={14}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<span>:</span>
							<div style={{ marginLeft: '8px' }}>{employee.employeeBloodType?.type ?? '-'}</div>
						</div>
					</Col>
				</Row>

				{/* -- */}
			</div>
		</Card>
	)
}

export default PersonalData
