import React, { useState, useEffect } from 'react'
import { CaretRightOutlined, CaretDownOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, List, Modal, Input, notification } from 'antd'
import { Link } from 'react-router-dom'
import ContractAPI from '../../../services/ContractAPI'

const TemplateItem = ({ item, templateClauseGroups, onSuccesUpdate }) => {
	const [showChildren, setShowChildren] = useState(false)
	const [showModalEdit, setShowModalEdit] = useState(false)
	const [templateName, setTemplateName] = useState(item.name)
	const [submitting, setSubmitting] = useState(false)

	const onUpdateTemplate = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await ContractAPI.updateTemplate(item.ID, templateName)
				notification.success({ message: 'Sukses ubah template' })
				onSuccesUpdate()
				setShowModalEdit(false)
				setSubmitting(false)
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal ubah template' })
			}
		}
	}

	const onDeleteTemplate = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await ContractAPI.deleteTemplate(item.ID)
				setSubmitting(false)
				notification.success({ message: 'Sukses hapus pasal' })
				Modal.destroyAll()
				onSuccesUpdate()
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal hapus pasal' })
				console.log(error)
			}
		}
	}

	const modalDelete = () => {
		Modal.confirm({
			title: 'Apakah anda yakin ingin menghapus template ?',
			content: 'Setelah hapus, anda tidak dapat mengembalikan',
			okButtonProps: {
				loading: submitting,
				disabled: submitting,
			},
			cancelText: 'Batal',
			okText: 'Hapus',
			onOk: () => onDeleteTemplate(),
		})
	}

	return (
		<div style={{ width: '100%' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
				<Button type='link' style={{ color: '#000', overflow: 'hidden' }} onClick={() => setShowChildren((prev) => !prev)}>
					{showChildren ? <CaretDownOutlined /> : <CaretRightOutlined />}
					{item.name}
				</Button>

				<div>
					<Button type='dashed' size='small' onClick={() => setShowModalEdit(true)}>
						<EditOutlined />
					</Button>
					<Button type='dashed' size='small' style={{ marginLeft: 10 }} onClick={modalDelete}>
						<CloseOutlined style={{ color: 'red' }} />
					</Button>
				</div>
			</div>

			{showChildren && (
				<div style={{ width: '100%', marginTop: '16px' }}>
					<List
						bordered
						dataSource={templateClauseGroups}
						renderItem={(item) => (
							<List.Item>
								<div>
									<Link to={`/contract/clause-group/${item.ID}`}>{item.title}</Link>
								</div>
							</List.Item>
						)}
					/>
				</div>
			)}

			<Modal
				visible={showModalEdit}
				onCancel={() => setShowModalEdit(false)}
				title='Ubah template'
				onOk={onUpdateTemplate}
				okText='Ubah'
				cancelText='Batal'
				confirmLoading={submitting}>
				<div style={{ marginBottom: 10 }}>
					<label htmlFor='templateName'>Nama Template</label>
					<Input
						name='templateName'
						id='templateName'
						placeholder='Nama Template'
						value={templateName}
						onChange={(event) => setTemplateName(event.target.value)}
						style={{ marginTop: '8px' }}
					/>
				</div>
			</Modal>
		</div>
	)
}

export default TemplateItem
