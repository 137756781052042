import React, { useContext, useState, useEffect, Fragment } from 'react'
import { EmployeeContext } from '../../../context/EmployeeContext'
import { Input, Divider, Row, Col, Select, Button, Popconfirm, DatePicker, notification, Upload, Tooltip } from 'antd'

import { PlusOutlined, DeleteOutlined, InboxOutlined, LoadingOutlined, DownloadOutlined } from '@ant-design/icons'

import MasterAPI from '../../../services/MasterAPI'

import moment from 'moment'

const { Option } = Select
const { Dragger } = Upload

const CareerForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employeeCategory, employee } = employeeContext

	const form = { employeeID, civilianCategoryID: '', date: '', file: null, numberReference: '' }

	const [historyEmployeeCivilianCategory, setHistoryEmployeeCivilianCategory] = useState([])
	const [historyEmployeeCivilianCategoryAdd, setHistoryEmployeeCivilianCategoryAdd] = useState([])

	const [submitting, setSubmitting] = useState(false)

	const [loadingDelete, setLoadingDelete] = useState(false)

	useEffect(() => {
		if (employeeEdit.historyEmployeeCivilianCategory.length > 0) {
			const tmp = employeeEdit.historyEmployeeCivilianCategory.map((civilian) => ({
				...civilian,
				file: civilian.realPath,
				numberReference: civilian.number_reference,
			}))
			setHistoryEmployeeCivilianCategory(tmp)
		}
	}, [employee.data])

	const disable = () => {
		let status = false
		let merged = historyEmployeeCivilianCategory.concat(historyEmployeeCivilianCategoryAdd)

		if (merged.length === 0) {
			status = true
		}

		if (merged.length > 0) {
			merged.forEach((calc) => {
				if (
					`${calc.civilianCategoryID}`.trim().length === 0 ||
					`${calc.date}`.trim().length === 0 ||
					calc.numberReference.trim().length === 0 ||
					calc.file === null
				) {
					status = true
				} else {
					status = false
				}
			})
		}

		return status
	}

	const submit = async () => {
		if (!submitting) {
			setSubmitting(true)
			if (historyEmployeeCivilianCategory.length > 0) {
				for (const iterator of historyEmployeeCivilianCategory) {
					try {
						await MasterAPI.updateEmployeeCivilianCategory(iterator, iterator.ID)
						notification.success({ message: 'Sukses Update Golongan' })
					} catch (error) {
						notification.error({ message: 'Gagal Update Golongan' })
					}
				}
			}

			if (historyEmployeeCivilianCategoryAdd.length > 0) {
				for (const iterator of historyEmployeeCivilianCategoryAdd) {
					try {
						await MasterAPI.addCivilianCategory(iterator)
						notification.success({ message: 'Sukses Menambahkan Golongan' })
					} catch (error) {
						notification.error({ message: 'Gagal Menambahkan Golongan' })
					}
				}
			}
			fetchDetailEmployee(employeeID).then((res) => {
				setSubmitting(false)
				setHistoryEmployeeCivilianCategoryAdd([])
			})
		}
	}

	const onDelete = async (id, index) => {
		if (!loadingDelete) {
			setLoadingDelete(true)
			try {
				await MasterAPI.deleteEmployeeCivilianCategory(id)
				fetchDetailEmployee(employeeID).then((res) => {
					let tmp = historyEmployeeCivilianCategory.filter((__, idx) => idx !== index)
					setHistoryEmployeeCivilianCategory(tmp)
					setLoadingDelete(false)
					notification.success({ message: 'Sukses Hapus Data' })
				})
			} catch (error) {
				setLoadingDelete(false)
				notification.error({ message: 'Gagal Hapus Data' })
			}
		}
	}

	return (
		<div>
			<h1>History Golongan</h1>
			<Divider />

			{historyEmployeeCivilianCategory.length > 0
				? historyEmployeeCivilianCategory.map((civilian, index) => (
						<Fragment key={index}>
							<Row gutter={[16, 16]} justify='end'>
								<Col span={12}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Kategori
										</span>
									</div>
									<Select
										onChange={(val) => {
											let tmp = [...historyEmployeeCivilianCategory]
											tmp[index].civilianCategoryID = val
											setHistoryEmployeeCivilianCategory(tmp)
										}}
										loading={employeeCategory.isLoading}
										defaultActiveFirstOption={false}
										style={{ marginTop: '8px', width: '100%' }}
										placeholder='Pilih Categori'
										value={civilian.civilianCategoryID}>
										{employeeCategory.data.map((category) => (
											<Option name='civilianCategoryID' key={category.ID} value={category.ID}>
												{category.civilianName}
											</Option>
										))}
									</Select>
								</Col>
								<Col span={12}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Tanggal
										</span>
									</div>

									<DatePicker
										allowClear={false}
										value={moment(civilian.date, 'YYYY-MM-DD')}
										onChange={(val, dateString) => {
											let tmp = [...historyEmployeeCivilianCategory]
											tmp[index].date = dateString
											setHistoryEmployeeCivilianCategory(tmp)
										}}
										style={{ marginTop: '8px', width: '100%' }}
									/>
								</Col>
								{loadingDelete ? (
									<LoadingOutlined style={{ position: 'absolute', right: '8px' }} />
								) : (
									<Popconfirm title='Apakah anda yakin untuk menghapus ?' onConfirm={() => onDelete(civilian.ID, index)}>
										<DeleteOutlined style={{ position: 'absolute', right: '8px', color: 'red' }} />
									</Popconfirm>
								)}
							</Row>

							<Row gutter={[16, 16]} justify='end'>
								<Col span={24}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Tanggal
										</span>
									</div>

									<Input
										value={civilian.numberReference}
										onChange={(e) => {
											let tmp = [...historyEmployeeCivilianCategory]
											tmp[index].numberReference = e.target.value
											setHistoryEmployeeCivilianCategory(tmp)
										}}
										style={{ marginTop: '8px', width: '100%' }}
									/>
								</Col>
							</Row>
							<div>
								<div style={{ marginBottom: '8px', width: '100%' }}>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Surat{' '}
										{civilian.file && (
											<Tooltip title='Lihat Ijazah'>
												{' '}
												<a href={civilian.file} rel='noopener noreferrer' target='_blank'>
													<DownloadOutlined style={{ marginLeft: '10px' }} />
												</a>
											</Tooltip>
										)}
									</span>
								</div>

								<Dragger
									accept='.docx,.doc,.pdf,.png,.jpg,.jpeg'
									name='file'
									multiple={false}
									showUploadList={false}
									style={{ marginBottom: '16px' }}
									onChange={(e) => {
										let tmp = [...historyEmployeeCivilianCategory]
										historyEmployeeCivilianCategory[index].file = e.file.originFileObj
										setHistoryEmployeeCivilianCategory(tmp)
									}}>
									<p className='ant-upload-drag-icon'>
										<InboxOutlined />{' '}
									</p>
									{civilian.file && typeof civilian.file === 'string' ? (
										<p>{civilian.file.split('/')[civilian.file.split('/').length - 1]}</p>
									) : civilian.file instanceof File ? (
										<p>{civilian.file.name}</p>
									) : (
										<p className='ant-upload-text'>Pilih File</p>
									)}
								</Dragger>
							</div>
							<Divider />
						</Fragment>
				  ))
				: null}

			{historyEmployeeCivilianCategoryAdd.length > 0
				? historyEmployeeCivilianCategoryAdd.map((civilian, index) => (
						<Fragment key={index}>
							<Row gutter={[16, 16]} justify='end'>
								<Col span={12}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Kategori
										</span>
									</div>
									<Select
										onChange={(val) => {
											let tmp = [...historyEmployeeCivilianCategoryAdd]
											tmp[index].civilianCategoryID = val
											setHistoryEmployeeCivilianCategoryAdd(tmp)
										}}
										loading={employeeCategory.isLoading}
										defaultActiveFirstOption={false}
										style={{ marginTop: '8px', width: '100%' }}
										placeholder='Pilih Categori'
										value={civilian.civilianCategoryID}>
										{employeeCategory.data.map((category) => (
											<Option name='civilianCategoryID' key={category.ID} value={category.ID}>
												{category.civilianName}
											</Option>
										))}
									</Select>
								</Col>
								<Col span={12}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Tanggal
										</span>
									</div>

									<DatePicker
										allowClear={false}
										onChange={(val, dateString) => {
											let tmp = [...historyEmployeeCivilianCategoryAdd]
											tmp[index].date = dateString
											setHistoryEmployeeCivilianCategoryAdd(tmp)
										}}
										style={{ marginTop: '8px', width: '100%' }}
									/>
								</Col>
								<DeleteOutlined
									onClick={() => setHistoryEmployeeCivilianCategoryAdd(historyEmployeeCivilianCategoryAdd.filter((_, idx) => idx !== index))}
									style={{ position: 'absolute', right: '8px', color: 'red' }}
								/>
							</Row>

							<Row gutter={[16, 16]} justify='end'>
								<Col span={24}>
									<div>
										<span style={{ color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> No. SK
										</span>
									</div>

									<Input
										value={civilian.numberReference}
										onChange={(e) => {
											let tmp = [...historyEmployeeCivilianCategoryAdd]
											tmp[index].numberReference = e.target.value
											setHistoryEmployeeCivilianCategoryAdd(tmp)
										}}
										style={{ marginTop: '8px', width: '100%' }}
									/>
								</Col>
							</Row>

							<div>
								<div style={{ marginBottom: '8px', width: '100%' }}>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Surat
									</span>
								</div>

								<Dragger
									accept='.docx,.doc,.pdf,.png,.jpg,.jpeg'
									name='file'
									multiple={false}
									showUploadList={false}
									style={{ marginBottom: '16px' }}
									onChange={(e) => {
										let tmp = [...historyEmployeeCivilianCategoryAdd]
										tmp[index].file = e.file.originFileObj
										setHistoryEmployeeCivilianCategoryAdd(tmp)
									}}>
									<p className='ant-upload-drag-icon'>
										<InboxOutlined />{' '}
									</p>
									{civilian.file ? <p>{civilian.file.name}</p> : <p className='ant-upload-text'>Pilih File</p>}
								</Dragger>
							</div>

							<Divider />
						</Fragment>
				  ))
				: null}

			<Row gutter={[16, 16]} justify='end'>
				<Col span={12}>
					<Button
						block
						style={{ marginBottom: '16px' }}
						onClick={() => setHistoryEmployeeCivilianCategoryAdd([...historyEmployeeCivilianCategoryAdd, { ...form }])}>
						<PlusOutlined /> Tambah History Golongan
					</Button>
				</Col>

				<Col span={12}>
					<Button block type='primary' onClick={submit} disabled={disable()} loading={submitting}>
						Kirim History Golongan
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default CareerForm
