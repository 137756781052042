import React, { Fragment } from 'react'
import { Card, Divider, Row, Col } from 'antd'

import moment from 'moment'

const FamilyData = ({ employee }) => {
	return (
		<Card className='__main_card_table' style={{ marginTop: '16px' }}>
			<div>
				<h1>Data Keluarga</h1>
				<Divider />

				<Row gutter={[16, 16]}>
					<Col span={8}>
						<span style={{ fontWeight: '500' }}>Status Pasangan</span>
					</Col>
					<Col span={14}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<span>:</span>
							<div style={{ marginLeft: '8px' }}>{!employee.data.employeeFamily ? '-' : employee.data.employeeFamily.marriedStatus.name}</div>
						</div>
					</Col>
				</Row>
				{employee.data.employeeFamily && (
					<Row gutter={[16, 16]}>
						<Col span={8}>
							<span style={{ fontWeight: '500' }}>Jumlah Anak</span>
						</Col>
						<Col span={14}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<span>:</span>
								<div style={{ marginLeft: '8px' }}>{!employee.data.employeeFamily ? '-' : employee.data.employeeFamily.totalChild}</div>
							</div>
						</Col>
					</Row>
				)}

				{employee.data.father && (
					<Fragment>
						<Divider />
						<h1>Ayah : </h1>
						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Nama</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.father.name}</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>TTL</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>
										{employee.data.father.getBornPlace.name} / {moment(employee.data.father.bornDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
									</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Agama</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.father.religion.name}</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Alamat</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.father.address}</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Kota</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.father.city.name}</div>
								</div>
							</Col>
						</Row>
					</Fragment>
				)}

				{employee.data.mother && (
					<Fragment>
						<Divider />
						<h1>Ayah : </h1>
						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Nama</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.mother.name}</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>TTL</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>
										{employee.data.mother.getBornPlace.name} / {moment(employee.data.mother.bornDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
									</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Agama</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.mother.religion.name}</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Alamat</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.mother.address}</div>
								</div>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col span={8}>
								<span style={{ fontWeight: '500' }}>Kota</span>
							</Col>
							<Col span={14}>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<span>:</span>
									<div style={{ marginLeft: '8px' }}>{employee.data.mother.city.name}</div>
								</div>
							</Col>
						</Row>
					</Fragment>
				)}
			</div>
		</Card>
	)
}

export default FamilyData
