import { Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import API from '../../../services/API'
import thousandSeparator from '../../../utils/thousandSeparator'

const BudgetPlanTable = ({ programBudgetOutputID }) => {
	const [listSubOutput, setListSubOutput] = useState({ data: [], isLoading: true })

	const fetchListSubOutput = async () => {
		try {
			const response = await API.getProgramBudgetSubOutput({ programBudgetOutputID, pageSize: 0, sort: 'asc', order: 'ID' })
			setListSubOutput({
				isLoading: false,
				data: response.data.list,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchListSubOutput()
	}, [])

	if (listSubOutput.isLoading) {
		return (
			<div style={{ display: 'flex', height: '40vh', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
				<Spin />
			</div>
		)
	}

	return (
		<table className='custom-budget-table budget-plan-table'>
			{listSubOutput.data.map((data) => (
				<Fragment key={data.ID}>
					<thead>
						<tr className='component-detail-main'>
							<td colSpan='6' className='budget-plan-table-title'>
								{data.name}
							</td>
						</tr>
						<tr className='component-detail-main'>
							<td>No.</td>
							<td>Komponen</td>
							<td>Uraian</td>
							<td>Vol</td>
							<td>Harga Satuan</td>
							<td>Jumlah</td>
						</tr>
					</thead>
					<tbody style={{ textAlign: 'left' }}>
						{data.programBudgetComponents.map((programBudgetComponents, idx) => (
							<Fragment key={programBudgetComponents.ID}>
								<tr>
									<td>{idx + 1}</td>
									<td>
										{programBudgetComponents.name}{' '}
										{programBudgetComponents.programBudgetActivities.length +
											programBudgetComponents.programBudgetActivities.map((programBudgetActivities) => programBudgetActivities).length +
											programBudgetComponents.programBudgetActivities.map(
												(programBudgetActivities) => programBudgetActivities.programBudgetDetailActivities
											)[0].length}
									</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								{programBudgetComponents.programBudgetActivities.map((programBudgetActivities) => (
									<Fragment key={programBudgetActivities.ID}>
										<tr>
											<td></td>
											<td></td>
											<td> {programBudgetActivities.name} </td>
											<td>{thousandSeparator(programBudgetActivities.totalQuantity)}</td>
											<td>Rp.{thousandSeparator(programBudgetActivities.totalPrice)}</td>
											<td>
												Rp.
												{thousandSeparator(
													(programBudgetActivities.totalPrice === 0
														? 0
														: programBudgetActivities.totalPrice * programBudgetActivities.totalQuantity
													).toFixed(0)
												)}
											</td>
										</tr>
										{programBudgetActivities.programBudgetDetailActivities.map((programBudgetDetailActivities) => (
											<tr key={programBudgetDetailActivities.ID}>
												<td></td>
												<td></td>
												<td>{programBudgetDetailActivities.name}</td>
												<td>{thousandSeparator(programBudgetDetailActivities.quantity)}</td>
												<td>Rp.{thousandSeparator(programBudgetDetailActivities.price)}</td>

												<td>
													Rp.
													{thousandSeparator(programBudgetDetailActivities.totalPrice)}
												</td>
											</tr>
										))}
									</Fragment>
								))}
							</Fragment>
						))}
					</tbody>
				</Fragment>
			))}
		</table>
	)
}

export default BudgetPlanTable
