import React, { useState, useEffect } from 'react'
import { CaretRightOutlined, CaretDownOutlined, EditOutlined } from '@ant-design/icons'
import { Button, List } from 'antd'
import ContractAPI from '../../../services/ContractAPI'
import { Link } from 'react-router-dom'

const ClauseGroup = ({ item }) => {
	const [showChildren, setShowChildren] = useState(false)
	const [listClause, setListClause] = useState({ data: [], isLoading: true })

	const fetchListTemplateClause = async () => {
		try {
			const response = await ContractAPI.listTemplateClause({ templateClauseGroupID: item.ID })
			setListClause({
				isLoading: false,
				data: response.data.list,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (showChildren) {
			fetchListTemplateClause()
		}
	}, [showChildren])

	return (
		<div style={{ width: '100%' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
				<Button type='link' style={{ color: '#000', overflow: 'hidden' }} onClick={() => setShowChildren((prev) => !prev)}>
					{showChildren ? <CaretDownOutlined /> : <CaretRightOutlined />}
					{item.title}
				</Button>

				<Button type='dashed' size='small'>
					<Link to={`/contract/clause-group/${item.ID}`}>
						<EditOutlined />
					</Link>
				</Button>
			</div>

			{showChildren && (
				<div style={{ width: '100%', marginTop: '16px' }}>
					<List
						loading={listClause.isLoading}
						bordered
						dataSource={listClause.data}
						renderItem={(item) => (
							<List.Item>
								<div dangerouslySetInnerHTML={{ __html: item.content }} />
							</List.Item>
						)}
					/>
				</div>
			)}
		</div>
	)
}

export default ClauseGroup
