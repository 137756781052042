import React, { Fragment, useEffect, useContext, useState } from 'react'

import { Layout, Row, Col, Card, Divider, Tabs, Avatar } from 'antd'
import { CustomHeader } from '../../components'
import AspirationAPI from '../../services/AspirationAPI'
import { CalendarOutlined } from '@ant-design/icons'
import moment from 'moment'
import gravatar from 'gravatar'
import { Link } from 'react-router-dom'

const { Content } = Layout

const Aspiration = () => {
	const [spirations, setAspirations] = useState({ data: [], isLoading: true })

	const fetchAspirations = async () => {
		try {
			const response = await AspirationAPI.getAspirations({ sort: 'responded', order: 'desc' })
			setAspirations({ isLoading: false, data: response.data.list })
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchAspirations()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Aspirasi' />
			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<div className='dudi_asp_container'>
					<h1>Daftar Aspirasi</h1>

					<Row gutter={[16, 16]}>
						{spirations.isLoading
							? null
							: spirations.data.map((aspiration, index) => (
									<Col span={24} key={index}>
										<Card>
											<Row>
												<Col span={2}>
													<Avatar src={gravatar.url(aspiration.employee.email, { d: 'identicon', s: 36 })} />
												</Col>
												<Col span={22}>
													<div>
														<Link to={`/aspiration-detail/${aspiration.ID}`}>
															<h1>{aspiration.subject}</h1>
														</Link>
														<small>
															<CalendarOutlined /> {moment(aspiration.created, 'YYYY-MM-DD').format('DD MMM YYYY')} <strong>&#903;</strong>{' '}
															&nbsp;
															{aspiration.aspirationReject
																? 'Ditolak ❌'
																: aspiration.aspirationConfirmed === null
																? 'Menunggu konfirmasi 🔄'
																: 'Terkonfirmasi ✅'}
														</small>
													</div>
												</Col>
											</Row>
										</Card>
									</Col>
							  ))}
					</Row>
				</div>
			</Content>
		</Fragment>
	)
}

export default Aspiration
