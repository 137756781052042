import React, { Fragment, useEffect, useContext, useState } from 'react'

import { Layout, Row, Col, notification, Divider, Button, Typography, Input, Select, Table } from 'antd'
import { CustomHeader } from '../../components'
import AspirationAPI from '../../services/AspirationAPI'
import { CalendarOutlined } from '@ant-design/icons'
import moment from 'moment'
import gravatar from 'gravatar'
import { Link } from 'react-router-dom'
import API from '../../services/API'
import _ from 'lodash'

const { Content } = Layout
const { Title } = Typography
const { Option } = Select

const Create = ({ history }) => {
	const [employeeList, setEmployeeList] = useState({ data: [], isLoading: true })
	const [query, setQuery] = useState({ pageSize: 10, keyword: '' })
	const [submitting, setSubmitting] = useState(false)

	const [form, setForm] = useState({
		employeeID: '',
		label: '',
		point: '',
		description: '',
	})
	const [files, setFiles] = useState([
		{
			file: null,
		},
	])

	const onGiftAppreciation = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await AspirationAPI.addAppreciation(
					form,
					files.map((file) => file.file)
				)
				notification.success({ message: 'Sukses beri apresiasi' })
				setSubmitting(false)
				setForm({
					employeeID: '',
					label: '',
					point: '',
					description: '',
				})

				history.push('/appreciation')
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal beri apresiasi' })
				setSubmitting(false)
			}
		}
	}

	const fetchEmployeeList = async () => {
		try {
			const response = await API.getAllEmploye(query)

			setEmployeeList({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchEmployeeList()
	}, [query.keyword])

	return (
		<Fragment>
			<CustomHeader title='Aspirasi' />
			<Content style={{ padding: '16px', background: '#fff' }}>
				<div className='dudi_asp_container'>
					<Title level={4}>Beri apresiasi</Title>

					<Row gutter={[16, 16]}>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Pilih Karyawan
								</span>
							</div>
							<Select
								filterOption={false}
								onSearch={_.debounce((value) => setQuery({ ...query, keyword: value }), 500)}
								onChange={(val) => setForm({ ...form, employeeID: val })}
								loading={employeeList.isLoading}
								placeholder='Pilih Karyawan'
								showSearch
								style={{ marginTop: '8px', width: '100%' }}
								value={form.employeeID}>
								{employeeList.data.map((employee) => (
									<Option key={employee.ID} value={employee.ID}>
										{employee.name}
									</Option>
								))}
							</Select>
						</Col>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Label
								</span>
							</div>
							<Input
								placeholder='Label'
								style={{ marginTop: '8px', width: '100%' }}
								value={form.label}
								onChange={(e) => setForm({ ...form, label: e.target.value })}
							/>
						</Col>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Poin
								</span>
							</div>
							<Input
								type='number'
								max={5}
								placeholder='5'
								step={1}
								maxLength={1}
								style={{ marginTop: '8px', width: '100%' }}
								value={form.point}
								onChange={(e) => {
									if (parseInt(e.target.value) > 5) {
										setForm({ ...form, point: 5 })
									} else if (parseInt(e.target.value) < 1) {
										setForm({ ...form, point: 1 })
									} else {
										setForm({ ...form, point: e.target.value })
									}
								}}
							/>
						</Col>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>Deskripsi</span>
							</div>
							<Input.TextArea
								placeholder='Deskripsi'
								style={{ marginTop: '8px', width: '100%' }}
								value={form.description}
								onChange={(e) => setForm({ ...form, description: e.target.value })}
							/>
						</Col>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>Berkas</span>
							</div>
							{files.map((file, index) => (
								<Row gutter={[16, 16]} align='middle' key={index}>
									<Col span={18}>
										<Input
											type='file'
											style={{ marginTop: '8px', width: '100%' }}
											onChange={(e) => {
												let temp = [...files]
												temp[index].file = e.target.files[0]
												setFiles(temp)
											}}
										/>
									</Col>

									<Col span={6}>
										<Button
											type='primary'
											style={{ float: 'right' }}
											onClick={() => {
												setFiles([...files, { file: null }])
											}}>
											Tambah Berkas
										</Button>
									</Col>
								</Row>
							))}
						</Col>
						<Col span={24}>
							<Row gutter={[16, 16]} justify='end'>
								<Col span={12}></Col>
								<Col span={12}>
									<Button block type='primary' loading={submitting} onClick={onGiftAppreciation}>
										Beri Apresiasi
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Content>{' '}
		</Fragment>
	)
}

export default Create
