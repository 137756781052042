import React, { useState, Fragment } from 'react'
import { Menu, Layout } from 'antd'
import {
	DesktopOutlined,
	TeamOutlined,
	UserOutlined,
	UserAddOutlined,
	ContainerOutlined,
	ToTopOutlined,
	LogoutOutlined,
	PicCenterOutlined,
	ScheduleOutlined,
	HighlightOutlined,
	MessageOutlined,
	StarOutlined,
	ProfileOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo_dudi.png'

const { Sider } = Layout
const { SubMenu } = Menu

const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const IS_LEAVE_OPERATOR =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'LeaveOperator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'LeaveOperator')

const IS_ADMIN_PROGRAM =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Administrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Administrator')

const IS_PROGRAM_REVIEWER =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'ProgramAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'ProgramAdministrator')

const IS_CONTRACT_OPERATOR =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Contract-Operator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Contract-Operator')

const SideNav = () => {
	const [isCollapsed, setCollapsed] = useState(window.innerWidth < 600 ? true : false)

	const onCollapse = (collapsed) => {
		setCollapsed(collapsed)
	}

	return (
		<Sider theme='light' collapsible collapsed={isCollapsed} onCollapse={onCollapse} style={{ background: '#e6f7ff' }}>
			<Link to='/'>
				<div className='logo' style={{ height: '64px', overflow: 'hidden' }}>
					<img src={Logo} alt='logodudi' style={{ height: 64 }} />
				</div>
			</Link>

			{IS_ADMIN_PROGRAM || IS_PROGRAM_REVIEWER ? (
				<Menu theme='light' defaultSelectedKeys={['6']} mode='inline' style={{ background: '#e6f7ff' }}>
					<Menu.Item key='6' icon={<PicCenterOutlined />}>
						<Link to='/program'>Program</Link>
					</Menu.Item>

					<Menu.Item key='7' icon={<ScheduleOutlined />}>
						<Link to='/institution'>Lembaga</Link>
					</Menu.Item>
					<Menu.Item key='8' icon={<LogoutOutlined />}>
						<a
							href='/'
							onClick={() => {
								localStorage.clear()
								sessionStorage.clear()
							}}>
							Log Out
						</a>
					</Menu.Item>
				</Menu>
			) : IS_LEAVE_OPERATOR ? (
				<Menu theme='light' defaultSelectedKeys={['1']} mode='inline' style={{ background: '#e6f7ff' }}>
					<SubMenu key='absensi-sub' icon={<HighlightOutlined />} title='Absensi & Cuti'>
						<Menu.Item key='5' icon={<HighlightOutlined />}>
							<Link to='/absent-dashboard'>Dashboard</Link>
						</Menu.Item>
						<Menu.Item key='absensi' icon={<HighlightOutlined />}>
							<Link to='/absent'>Daftar Absensi</Link>
						</Menu.Item>

						<Menu.Item key='cuti' icon={<ToTopOutlined />}>
							<Link to='/leave'>Daftar Cuti</Link>
						</Menu.Item>
					</SubMenu>

					<Menu.Item key='8' icon={<LogoutOutlined />}>
						<a
							href='/'
							onClick={() => {
								localStorage.clear()
								sessionStorage.clear()
							}}>
							Log Out
						</a>
					</Menu.Item>
				</Menu>
			) : IS_CONTRACT_OPERATOR ? (
				<Menu theme='light' defaultSelectedKeys={['1']} mode='inline' style={{ background: '#e6f7ff' }}>
					<SubMenu key='contract' icon={<PicCenterOutlined />} title='Kontrak'>
						<Menu.Item key='clause-group' icon={<PicCenterOutlined />}>
							<Link to='/contract/clause-group'>Pasal</Link>
						</Menu.Item>
						<Menu.Item key='clause-group-template' icon={<ProfileOutlined />}>
							<Link to='/contract/template'>Template</Link>
						</Menu.Item>
					</SubMenu>

					<Menu.Item key='8' icon={<LogoutOutlined />}>
						<a
							href='/'
							onClick={() => {
								localStorage.clear()
								sessionStorage.clear()
							}}>
							Log Out
						</a>
					</Menu.Item>
				</Menu>
			) : (
				<Menu theme='light' defaultSelectedKeys={['1']} mode='inline' style={{ background: '#e6f7ff' }}>
					<Menu.Item key='1' icon={<DesktopOutlined />}>
						<Link to='/dashboard'>Dashboard</Link>
					</Menu.Item>
					<SubMenu key='sub1' icon={<UserOutlined />} title='Karyawan'>
						<Menu.Item key='employee-dashboard' icon={<TeamOutlined />}>
							<Link to='/dashboard/employee'>Dashboard Karyawan</Link>
						</Menu.Item>
						<Menu.Item key='2' icon={<TeamOutlined />}>
							<Link to='/employee'>Semua Karyawan</Link>
						</Menu.Item>
						<Menu.Item key='3' icon={<UserAddOutlined />}>
							<Link to='/add-employee'>Tambah Karyawan</Link>
						</Menu.Item>
					</SubMenu>

					<SubMenu key='sub2' icon={<ContainerOutlined />} title='Anggaran'>
						<Menu.Item key='4' icon={<ContainerOutlined />}>
							<Link to='/budget'>Dahboard</Link>
						</Menu.Item>

						<Menu.Item key='4ang' icon={<ContainerOutlined />}>
							<Link to='/budget/2020/4264'>Anggaran</Link>
						</Menu.Item>
					</SubMenu>

					<Menu.Item key='6' icon={<PicCenterOutlined />}>
						<Link to='/program'>Program</Link>
					</Menu.Item>

					<SubMenu key='jobFair' icon={<MessageOutlined />} title='Mitra Vokasi'>
						<Menu.Item key='jobFair1' icon={<MessageOutlined />}>
							<Link to='/jobs/skills'>Skills</Link>
						</Menu.Item>
					</SubMenu>

					<Menu.Item key='7' icon={<ScheduleOutlined />}>
						<Link to='/institution'>Lembaga</Link>
					</Menu.Item>

					<Menu.Item key='roles-permission' icon={<UserOutlined />}>
						<Link to='/access'>Akses</Link>
					</Menu.Item>

					<Menu.Item key='contract' icon={<PicCenterOutlined />}>
						<Link to='/contract'>Contract</Link>
					</Menu.Item>

					<Menu.Item key='form' icon={<PicCenterOutlined />}>
						<Link to='/form'>Form</Link>
					</Menu.Item>

					<SubMenu key='absensi-sub' icon={<HighlightOutlined />} title='Absensi & Cuti'>
						<Menu.Item key='5' icon={<HighlightOutlined />}>
							<Link to='/absent-dashboard'>Dashboard</Link>
						</Menu.Item>
						<Menu.Item key='absensi' icon={<HighlightOutlined />}>
							<Link to='/absent'>Daftar Absensi</Link>
						</Menu.Item>

						<Menu.Item key='dinas' icon={<ScheduleOutlined />}>
							<Link to='/duty'>Dinas</Link>
						</Menu.Item>

						<Menu.Item key='cuti' icon={<ToTopOutlined />}>
							<Link to='/leave'>Daftar Cuti</Link>
						</Menu.Item>
					</SubMenu>

					<SubMenu key='aspiration' icon={<MessageOutlined />} title='Aspirasi dan Apresiasi'>
						<Menu.Item key='12312' icon={<MessageOutlined />}>
							<Link to='/aspiration'>Aspirasi</Link>
						</Menu.Item>
						<Menu.Item key='apretiation' icon={<StarOutlined />}>
							<Link to='/appreciation'>Apresiasi</Link>
						</Menu.Item>
					</SubMenu>

					<Menu.Item key='8' icon={<LogoutOutlined />}>
						<a
							href='/'
							onClick={() => {
								localStorage.clear()
								sessionStorage.clear()
							}}>
							Log Out
						</a>
					</Menu.Item>
				</Menu>
			)}
		</Sider>
	)
}

export default SideNav
