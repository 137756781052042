import React, { useState, Fragment } from 'react'
import { Card, Row, Col, Avatar, Button, List, Tooltip, Tabs, Steps, Input, Select, Modal, Form, notification, Empty, Spin } from 'antd'
import {
	CheckSquareOutlined,
	FileWordOutlined,
	DownloadOutlined,
	DollarCircleOutlined,
	LineChartOutlined,
	CloseSquareOutlined,
	CommentOutlined,
	FileTextOutlined,
	ArrowLeftOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
	RollbackOutlined,
} from '@ant-design/icons'
import { useEffect } from 'react'
import { ProgramContext } from '../../context/ProgramContext'
import { useContext } from 'react'
import API from '../../services/API'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import thousandSeparator from '../../utils/thousandSeparator'
import ProgramReq from './Components/ProgramReq'
import InstitutionReq from './Components/InstitutionReq'

import NumberFormat from 'react-number-format'
import HistoryAplicant from './Components/HistoryAplicant'
import Files from './Components/Files'
import BudgetPlan from './Components/BudgetPlan'

const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const IS_ADMIN_PROGRAM =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Administrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Administrator')

const Detail = ({ history, match }) => {
	const programContext = useContext(ProgramContext)
	const {
		getProgramDetail,
		program,
		setProgram,
		programPhaseList,
		setQueryProgramPhase,
		queryProgramPhase,
		setProgramPhaseList,
		programPhaseForm,
		setProgramPhaseForm,
		createProgramPhase,
		fetchProgramPhaseList,
		applyInstitution,
		updateProgram,
		updateProgramPhase,
		deleteProgramPhase,
		discussList,
		discuss,
		setDiscuss,
		createDiscuss,
		activeApplicant,
		fetchApplicant,
		error,

		discussReply,
		setDiscussReply,

		createDiscussReply,

		setProgramReqParams,
		programReqParams,
	} = programContext

	const [isFileModalActive, setIsFileModalActive] = useState(false)
	const [isModalProgressActive, setIsModalProgressActive] = useState(false)
	const [activeApplicantPhase, setActiveApplicantPhase] = useState({ applicant: null })

	const [modalRejectVisible, setModalRejectVisible] = useState(false)

	const [filesModal, setFileModal] = useState([])
	const [submitting, setSubmitting] = useState(false)

	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [selectedData, setSelectedData] = useState({
		state: '',
		data: null,
	})
	const [budgetProgramHistory, setBudgetProgramHistory] = useState({
		isLoading: true,
		data: [],
	})
	const [isActivePhase, setIsActivePhase] = useState(false)

	const [discusReplyId, setDiscusReplyId] = useState(null)

	const [activeAplicantUpdate, setActiveAplicantUpdate] = useState(null)
	const [rejectReasonValue, setRejectReasonValue] = useState('')

	useEffect(() => {
		if (activeApplicantPhase.applicant) {
			if (activeApplicantPhase.applicant.ID) {
				setQueryProgramPhase({ ...queryProgramPhase, applicantID: activeApplicantPhase.applicant.ID })
				setIsActivePhase(true)
				setProgramReqParams({ ...programReqParams, institutionID: activeApplicantPhase.applicant.institution.ID })
			}
		}
	}, [activeApplicantPhase.applicant])

	const initialFetch = () => {
		getProgramDetail(match.params.id)
		fetchApplicant({
			programID: match.params.id,
		})
	}

	useEffect(() => {
		initialFetch()
		fetchApplicant({
			programID: match.params.id,
		})
	}, [])

	useEffect(() => {
		setProgramReqParams({ ...programReqParams, programID: match.params.id })
	}, [])

	const onSubmit = async () => {
		try {
			const responseCreateProgramPhase = await createProgramPhase()
			if (responseCreateProgramPhase.code === 200) {
				fetchProgramPhaseList()
				notification.success({ message: 'Sukses Menambahkan Progres' })
			}

			setIsModalProgressActive(false)
		} catch (error) {
			setIsModalProgressActive(false)
		}
	}

	// const onD

	const onSubmitModal = async () => {
		try {
			switch (selectedData.state) {
				case 'process':
					const responseDeleteProgramPhase = await deleteProgramPhase(selectedData.data.ID)
					if (responseDeleteProgramPhase.code === 200) {
						fetchProgramPhaseList()
						notification.success({ message: 'Sukses Menambahkan Progres' })
					}
					break
				case 'attachment':
					const responseAttachment = await API.programAttachment(program.data.ID, [], [selectedData.data.ID])
					if (responseAttachment.code === 200) {
						getProgramDetail(match.params.id)
						notification.success({ message: 'Sukses Menghapus Berkas' })
					}
			}

			setModalDeleteVisible(false)
			setSelectedData({})
		} catch (error) {
			setModalDeleteVisible(false)
			setSelectedData({})
		}
	}

	const fetchBudgetProgramHistory = async () => {
		if (activeApplicantPhase.applicant !== null) {
			try {
				setBudgetProgramHistory({ data: [], isLoading: true })

				const response = await API.getProgramBudgetHistory({
					sort: 'desc',
					order: 'ID',
					institutionID: activeApplicantPhase.applicant.institutionID,
					programID: match.params.id,
				})
				setBudgetProgramHistory({
					isLoading: false,
					data: response.data.list,
				})
			} catch (error) {
				setBudgetProgramHistory({ data: [], isLoading: false })
				notification.error({ message: 'Gagal mengambil data' })
			}
		}
	}

	useEffect(() => {
		fetchBudgetProgramHistory()
	}, [activeApplicantPhase.applicant])

	const responseAplicant = async (item, value) => {
		if (!submitting) {
			setSubmitting(true)
			applyInstitution(item.ID, value)
				.then(() => {
					initialFetch()
					notification.success({ message: 'Sukses respon aplikan' })
					setSubmitting(false)
				})
				.catch((e) => {
					setSubmitting(false)
					notification.error({ message: 'Gagal respon aplikan' })
				})
		}
	}

	return (
		<Fragment>
			{program.isLoading ? (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
					<Spin />
				</div>
			) : (
				<div
					style={{
						padding: '2% 5%',
					}}>
					<br />

					<Modal
						confirmLoading={submitting}
						title='Masukkan Alasan Penolakan'
						visible={modalRejectVisible}
						onCancel={() => {
							setModalRejectVisible(false)
							setActiveAplicantUpdate(null)
						}}
						onOk={() => {
							if (!submitting) {
								setSubmitting(true)
								if (activeAplicantUpdate !== null) {
									applyInstitution(activeAplicantUpdate.ID, '2', rejectReasonValue)
										.then(() => {
											initialFetch()

											notification.success({ message: 'Sukses respon aplikan' })
											setRejectReasonValue('')
											setActiveAplicantUpdate(null)
											setSubmitting(false)
											setModalRejectVisible(false)
										})
										.catch((e) => {
											setSubmitting(false)
											notification.error({ message: 'Gagal respon aplikan' })
										})
								}
							}
						}}>
						<Input.TextArea placeholder='Alasan Penolakan' rows={3} value={rejectReasonValue} onChange={(e) => setRejectReasonValue(e.target.value)} />
					</Modal>

					<Row gutter={[12, 8]}>
						<Col lg={18}>
							<Card style={{ boxShadow: '0 0 15px -7px #9b9b9b', borderRadius: 4, overflow: 'hidden', height: '100%' }}>
								<ArrowLeftOutlined onClick={() => history.push('/program')} />
								{(IS_ADMIN || IS_ADMIN_PROGRAM) && (
									<Link to={'/program/' + match.params.id + '/edit'}>
										<Button style={{ float: 'right' }}>Edit Program</Button>
									</Link>
								)}

								<Row>
									<Col xs={24} sm={4} md={4} lg={2} style={{ padding: 10 }}>
										<p style={{ textAlign: 'center', margin: 0, padding: 0, width: '100%' }}>
											<Avatar
												src='https://upload.wikimedia.org/wikipedia/commons/9/9c/Logo_of_Ministry_of_Education_and_Culture_of_Republic_of_Indonesia.svg'
												style={{ width: '100%', height: '100%' }}
											/>
										</p>
									</Col>
									<Col xs={24} sm={20} md={20} lg={22} style={{ padding: '20px' }}>
										<h4 style={{ fontWeight: 600, fontSize: 15, margin: 0 }}>{program.data.name}</h4>
										<p style={{ fontSize: 12 }}>{program.data.description}</p>
									</Col>
								</Row>

								<br />
								<Files
									program={program}
									initialFetch={initialFetch}
									setSelectedData={(data) => setSelectedData(data)}
									setModalDeleteVisible={(visible) => setModalDeleteVisible(visible)}
								/>

								<br />

								<Tabs defaultActiveKey={'1'}>
									<Tabs.TabPane key='1' tab='Detail'>
										<p style={{ fontWeight: 500, fontSize: 15 }}>Detail Program :</p>
										<div className='desc-wrapper'>
											<ReactMarkdown source={program.data.detail} escapeHtml={false} />
										</div>

										<br />

										<Row>
											<Col md={8}>
												<h4>
													<DollarCircleOutlined style={{ color: '#14213d' }} /> &nbsp; Nominal
												</h4>
												<p>Rp. {thousandSeparator(program.data.nominal)}</p>
											</Col>
											<Col md={8}>
												<h4>
													<LineChartOutlined style={{ color: '#14213d' }} /> &nbsp; Status
												</h4>
												{IS_ADMIN || IS_ADMIN_PROGRAM ? (
													<Select
														style={{ width: 150 }}
														defaultValue={program.data.status}
														onChange={(e) => {
															updateProgram(program.data.ID, { ...program.data, status: e })
														}}>
														<Select.Option value='1'>🔸 Belum Mulai</Select.Option>
														<Select.Option value='2'>🔄 Dalam Proses</Select.Option>
														<Select.Option value='3'>✅ Selesai</Select.Option>
													</Select>
												) : (
													<span>
														{program.data.status === '1'
															? '🔸 Belum Mulai'
															: program.data.status === '2'
															? '🔄 Dalam Proses'
															: program.data.status === '3'
															? '✅ Selesai'
															: null}
													</span>
												)}
											</Col>
											<Col md={8}>
												<h4>&nbsp;</h4>
											</Col>
										</Row>
									</Tabs.TabPane>
									<Tabs.TabPane key='2' tab='Persyaratan'>
										<p style={{ fontWeight: 500, fontSize: 15 }}>Persyaratan Program :</p>
										<ProgramReq match={match} />
									</Tabs.TabPane>
								</Tabs>
							</Card>
						</Col>
						<Col xs={24} md={24} lg={6}>
							<Card style={{ boxShadow: '0 0 15px -7px #9b9b9b', borderRadius: 4, overflow: 'hidden' }}>
								<h4>Aplikan</h4>

								<List
									className='demo-loadmore-list'
									itemLayout='horizontal'
									dataSource={program.data.programApplicants}
									renderItem={(item) => (
										<List.Item>
											<List.Item.Meta
												avatar={
													<Avatar style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }}>
														{item.institution.name.substr(0, 1)}
													</Avatar>
												}
												title={item.institution.name}
											/>
											{/* <Checkbox
												style={{ marginLeft: '10px' }}
												onChange={(e) =>
													applyInstitution(item.ID, e.target.checked ? '1' : '0').then(() => {
														fetchApplicant({
															programID: match.params.id,
														})
													})
												}
												defaultChecked={item.confirmed}
											/> */}
											{item.lastHistory === null || (item.lastHistory !== null && item.lastHistory.isConfirmed === 0) ? (
												<Fragment>
													<Tooltip title='Terima Aplikan'>
														<Button
															size='middle'
															icon={<CheckSquareOutlined />}
															type='link'
															onClick={() => {
																responseAplicant(item, '1')
															}}
														/>
													</Tooltip>
													<Tooltip title='Tolak Aplikan'>
														<Button
															size='middle'
															danger
															icon={<CloseSquareOutlined />}
															onClick={() => {
																setActiveAplicantUpdate(item)
																setModalRejectVisible(true)
															}}
															type='link'
														/>
													</Tooltip>
												</Fragment>
											) : item.lastHistory !== null && item.lastHistory.isConfirmed === 1 ? (
												<Fragment>
													<Tooltip title='Sudah Diterima'>
														<Button size='middle' icon={<CheckSquareOutlined />} type='link' disabled />
													</Tooltip>

													<Tooltip title='Batalkan Terima'>
														<Button
															size='middle'
															style={{ color: 'orange' }}
															icon={<RollbackOutlined />}
															onClick={() => {
																responseAplicant(item, '0')
															}}
															type='link'
														/>
													</Tooltip>
												</Fragment>
											) : item.lastHistory !== null && item.lastHistory.isConfirmed === 2 ? (
												<Fragment>
													<Tooltip title='Sudah Ditolak'>
														<Button size='middle' icon={<CloseSquareOutlined />} type='link' disabled />
													</Tooltip>

													<Tooltip title='Batalkan Tolak'>
														<Button
															size='middle'
															style={{ color: 'orange' }}
															icon={<RollbackOutlined />}
															onClick={() => {
																responseAplicant(item, '0')
															}}
															type='link'
														/>
													</Tooltip>
												</Fragment>
											) : null}
										</List.Item>
									)}
								/>
							</Card>
						</Col>
					</Row>

					<br />

					<Row>
						<Col xs={24}>
							<Card style={{ boxShadow: '0 0 15px -7px #9b9b9b', borderRadius: 4, overflow: 'hidden' }}>
								<Tabs defaultActiveKey='1'>
									<Tabs.TabPane tab='Diskusi' key='1'>
										<List
											className='demo-loadmore-list'
											itemLayout='horizontal'
											dataSource={discussList.data}
											renderItem={(item) => {
												return (
													<Fragment>
														<List.Item>
															<List.Item.Meta
																avatar={
																	<Avatar style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }}>
																		{item.account.accountName !== null
																			? item.account.accountName
																			: item.account.institutionAccess !== null
																			? item.account.institutionAccess.institution.name
																			: item.account.email}
																	</Avatar>
																}
																title={
																	item.account.accountName !== null
																		? item.account.accountName
																		: item.account.institutionAccess !== null
																		? item.account.institutionAccess.institution.name
																		: item.account.email
																}
																description={
																	<div>
																		<p>{item.comment}</p>
																		{item.replyDiscusses.length > 0 ? (
																			<List
																				className='demo-loadmore-list'
																				itemLayout='horizontal'
																				dataSource={item.replyDiscusses}
																				renderItem={(items) => (
																					<List.Item>
																						<List.Item.Meta
																							avatar={
																								<Avatar style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }}>
																									{items.account.accountName !== null
																										? items.account.accountName
																										: items.account.institutionAccess !== null
																										? items.account.institutionAccess.institution.name
																										: items.account.email}
																								</Avatar>
																							}
																							title={
																								items.account.accountName !== null
																									? items.account.accountName
																									: items.account.institutionAccess !== null
																									? items.account.institutionAccess.institution.name
																									: items.account.email
																							}
																							description={items.comment}
																						/>
																					</List.Item>
																				)}
																			/>
																		) : null}
																	</div>
																}
															/>

															<Tooltip title={'Jawab'}>
																<CommentOutlined
																	style={{ fontSize: 18, color: '#14213d' }}
																	onClick={() => {
																		if (discusReplyId === item.ID) {
																			setDiscusReplyId(null)
																			setDiscussReply({ ...discussReply, discussID: '' })
																		} else {
																			setDiscusReplyId(item.ID)
																			setDiscussReply({ ...discussReply, discussID: item.ID })
																		}
																	}}
																/>
															</Tooltip>
														</List.Item>
														{discusReplyId === item.ID && (
															<Fragment>
																<div>
																	<Input.TextArea
																		rows={4}
																		value={discussReply.comment}
																		onChange={(e) => {
																			setDiscussReply({ ...discussReply, comment: e.target.value })
																		}}
																	/>
																	<br />
																	<br />
																	<Button
																		type='primary'
																		onClick={() => {
																			createDiscussReply().then(() => {
																				setDiscusReplyId(null)
																				setDiscussReply({ ...discussReply, discussID: '' })
																			})
																		}}>
																		Kirim Balasan
																	</Button>
																</div>
																<br />
																<br />
															</Fragment>
														)}
													</Fragment>
												)
											}}
										/>
										{discuss ? (
											<div>
												<Input.TextArea
													rows={4}
													value={discuss.comment}
													onChange={(e) => {
														setDiscuss({ ...discuss, comment: e.target.value })
													}}
												/>
												<br />
												<br />
												<Button
													type='primary'
													onClick={() => {
														createDiscuss()
													}}>
													Kirim Diskusi
												</Button>
											</div>
										) : (
											<Button
												type='primary'
												onClick={() => {
													setDiscuss(true)
												}}>
												Buat Diskusi
											</Button>
										)}
									</Tabs.TabPane>
									<Tabs.TabPane tab='Update' key='2'>
										{isActivePhase && (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<ArrowLeftOutlined
													onClick={(e) => {
														setActiveApplicantPhase({ applicant: null })
														setIsActivePhase(false)
													}}
												/>

												<h4> &nbsp; {activeApplicantPhase.applicant.institution.name}</h4>
											</div>
										)}
										{isActivePhase ? (
											programPhaseList.isLoading ? (
												<p>Loading</p>
											) : (
												<Tabs
													defaultActiveKey={
														activeApplicantPhase.applicant &&
														activeApplicantPhase.applicant.lastHistory !== null &&
														activeApplicantPhase.applicant.lastHistory.isConfirmed === 1
															? '1'
															: '2'
													}
													style={{ marginLeft: '32px' }}>
													{activeApplicantPhase.applicant &&
														activeApplicantPhase.applicant.lastHistory !== null &&
														activeApplicantPhase.applicant.lastHistory.isConfirmed === 1 && (
															<Tabs.TabPane key='1' tab='Termin'>
																<Button
																	type='primary'
																	style={{
																		float: 'right',
																	}}
																	onClick={() => {
																		setIsModalProgressActive(true)
																		setProgramPhaseForm({ ...programPhaseForm, applicantID: activeApplicantPhase.applicant.ID })
																	}}>
																	Tambah Progres
																</Button>
																<div>
																	<br />
																	<br />
																	{programPhaseList.data.length > 0 ? (
																		<Steps direction='vertical' size='small' current={0}>
																			{programPhaseList.data.map((data, index) => (
																				<Steps.Step
																					icon={<Avatar>{programPhaseList.data.length - index}</Avatar>}
																					title={data.deadline}
																					key={index}
																					description={
																						<Card
																							style={{
																								boxShadow: '0 0 15px -7px #9b9b9b',
																								borderRadius: 4,
																								overflow: 'hidden',
																								position: 'relative',
																							}}>
																							<Button
																								icon={<EditOutlined />}
																								style={{
																									position: 'absolute',
																									right: 20,
																									top: 20,
																								}}
																								onClick={() => {
																									setSelectedData({
																										state: 'process',
																										data: data,
																									})
																									setModalDeleteVisible(true)
																								}}>
																								Hapus Proses
																							</Button>

																							<p style={{ fontWeight: 500, fontSize: 15 }}>Update Program :</p>
																							{data.detailProgramPhase ? (
																								<div dangerouslySetInnerHTML={{ __html: data.detailProgramPhase.update }}></div>
																							) : (
																								<p>Belum ada update program</p>
																							)}

																							<br />

																							<p style={{ fontWeight: 500, fontSize: 15 }}>Kendala Pembuatan Program :</p>
																							{data.detailProgramPhase ? (
																								<div dangerouslySetInnerHTML={{ __html: data.detailProgramPhase.problem }}></div>
																							) : (
																								<p>Belum ada update kendala</p>
																							)}

																							<br />

																							<Row>
																								<Col md={8}>
																									<h4>
																										<DollarCircleOutlined style={{ color: '#14213d' }} /> &nbsp; Nominal Yang Diajukan
																									</h4>
																									<p>{data.nominal ? `Rp ${thousandSeparator(data.nominal)}` : 'Rp. 0'}</p>
																									<h4>
																										<DollarCircleOutlined style={{ color: '#14213d' }} /> &nbsp; Nominal Terpakai
																									</h4>
																									<p>
																										{data.detailProgramPhase
																											? `Rp. ${thousandSeparator(data.detailProgramPhase.nominal)}`
																											: 'Rp. 0'}
																									</p>
																								</Col>
																								<Col md={8}>
																									<Select
																										style={{ width: 150 }}
																										defaultValue={data.status}
																										onChange={(e) => {
																											updateProgramPhase(data.ID, { ...data, status: e })
																										}}>
																										<Select.Option value='1'>Belum Dimulai</Select.Option>
																										<Select.Option value='2'>Telah Selesai</Select.Option>
																										<Select.Option value='3'>Terverivikasi</Select.Option>
																									</Select>
																								</Col>
																								<Col md={8}>
																									<h4>
																										<FileTextOutlined style={{ color: '#14213d' }} /> &nbsp; Unduh Berkas
																									</h4>
																									<Row gutter={[4, 4]}>
																										<Col key={index}>
																											<Button
																												type='primary'
																												shape='round'
																												size={'small'}
																												onClick={() => {
																													setIsFileModalActive(true)
																													setFileModal([
																														{
																															name: 'receipt',
																															realPath: data.receiptFileURL,
																														},
																													])
																												}}>
																												{'Bukti Transfer'}
																											</Button>
																										</Col>
																										{data.detailProgramPhase
																											? Object.keys(data.detailProgramPhase.fileProgramPhases).map((file, index) => (
																													<Col key={index}>
																														<Button
																															type='primary'
																															shape='round'
																															size={'small'}
																															onClick={() => {
																																setIsFileModalActive(true)
																																setFileModal(data.detailProgramPhase.fileProgramPhases[file])
																															}}>
																															{file}
																														</Button>
																													</Col>
																											  ))
																											: null}
																									</Row>
																								</Col>
																							</Row>
																						</Card>
																					}
																				/>
																			))}
																		</Steps>
																	) : (
																		<Empty />
																	)}
																</div>
															</Tabs.TabPane>
														)}

													<Tabs.TabPane key='2' tab='Persyaratan'>
														<InstitutionReq activeInstitutionID={activeApplicantPhase.applicant?.institutionID} />
													</Tabs.TabPane>
													<Tabs.TabPane key='3' tab='Riwayat'>
														<HistoryAplicant activeInstitution={activeApplicantPhase.applicant} />
													</Tabs.TabPane>
													<Tabs.TabPane key='4' tab='Rencana Anggaran'>
														<BudgetPlan budgetProgramHistory={budgetProgramHistory} />
													</Tabs.TabPane>
												</Tabs>
											)
										) : activeApplicant.isLoading ? (
											<p>Loading</p>
										) : (
											<Row gutter={[16, 16]}>
												{activeApplicant.data.map((data, index) => (
													<Col span={6} key={index}>
														<Card
															style={{ cursor: 'pointer' }}
															onClick={() => {
																setActiveApplicantPhase({
																	applicant: data,
																})
															}}>
															<span>
																{' '}
																<Avatar style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }}>
																	{data.institution.name.substr(0, 1)}
																</Avatar>{' '}
																{data.institution.name}
															</span>
														</Card>
													</Col>
												))}
											</Row>
										)}
									</Tabs.TabPane>
								</Tabs>
							</Card>
						</Col>
					</Row>
				</div>
			)}

			<Modal
				title='Tambah Progres'
				visible={isModalProgressActive}
				onOk={() => {
					onSubmit()
				}}
				onCancel={() => {
					setIsModalProgressActive(false)
				}}>
				<Form>
					<label style={{ fontSize: 12, fontWeight: 300 }}>Deadline</label>
					<Input
						placeholder={'Deadline'}
						style={{
							padding: 10,
							borderRadius: 4,
						}}
						type={'date'}
						value={programPhaseForm.deadline}
						onChange={(e) => {
							setProgramPhaseForm({ ...programPhaseForm, deadline: e.target.value })
						}}
					/>
					<span style={{ color: 'red' }}>{error ? (error.errors.deadline ? error.errors.deadline[0] : null) : null}</span>
					<br />
					<br />
					<label style={{ fontSize: 12, fontWeight: 300 }}>Nominal</label>
					<NumberFormat
						placeholder={'Rp. 100000'}
						style={{
							padding: 10,
							borderRadius: 4,
						}}
						onValueChange={(e) => setProgramPhaseForm({ ...programPhaseForm, nominal: e.value })}
						className='ant-input'
						value={programPhaseForm.nominal}
						thousandSeparator={'.'}
						decimalSeparator={','}
						prefix={'Rp. '}
					/>
					<span style={{ color: 'red' }}>{error ? (error.errors.deadline ? error.errors.deadline[0] : null) : null}</span>
					<br />
					<br />
					<label style={{ fontSize: 12, fontWeight: 300 }}>Unggah Bukti Transfer</label> <br />
					<input
						accept='.jpg,.jpeg,.png'
						type='file'
						name=''
						id='avatar-upload'
						onChange={(e) => setProgramPhaseForm({ ...programPhaseForm, receiptFile: e.target.files[0] })}
					/>
					<span style={{ color: 'red' }}>{error ? (error.errors.receiptFile ? error.errors.receiptFile[0] : null) : null}</span>
				</Form>
			</Modal>

			<Modal
				title='Apakah Anda Yakin ?'
				visible={modalDeleteVisible}
				onCancel={() => setModalDeleteVisible(false)}
				footer={[
					<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} onClick={onSubmitModal} loading={loadingDelete}>
						Ya
					</Button>,
					<Button
						key='delete'
						type='primary'
						onClick={() => {
							setModalDeleteVisible(false)
							setSelectedData({})
						}}>
						Tidak
					</Button>,
				]}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<ExclamationCircleOutlined style={{ color: '#ffd666', fontSize: '32px' }} />
					<span style={{ marginLeft: '16px' }}>Apakah anda yakin untuk menghapus data ?</span>
				</div>
			</Modal>

			<Modal
				title='Unduh Berkas'
				visible={isFileModalActive}
				onOk={() => {
					setIsFileModalActive(false)
				}}
				onCancel={() => {
					setIsFileModalActive(false)
				}}>
				{filesModal.map((file, index) => (
					<Row gutter={[12, 12]} key={index}>
						<Col sm={4}>
							<Avatar size={40} icon={<FileWordOutlined />} style={{ backgroundColor: 'rgba(85,110,230,.25)', color: '#213869' }} />
						</Col>
						<Col sm={16} style={{ paddingTop: 15, paddingLeft: 17 }}>
							<a href={file.realPath} target='_blank'>
								<p style={{ fontSize: 14, fontWeight: 500 }}>{file.name}</p>
							</a>
						</Col>
						<Col sm={4} style={{ paddingTop: 15 }}>
							<a href={file.realPath} target='_blank'>
								<DownloadOutlined style={{ fontSize: 18 }} />
							</a>
						</Col>
					</Row>
				))}
			</Modal>
		</Fragment>
	)
}

export default Detail
