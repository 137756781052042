import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Layout, Col, Row, Card, Radio, Button, Divider, Select, Input, DatePicker, Form, Steps, notification } from 'antd'
import { CustomHeader } from '../../components'

import { ProgramContext } from '../../context/ProgramContext'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ReactMarkdown from 'react-markdown'
import TurnDown from 'turndown'
import moment from 'moment'

import _ from 'lodash'

import NumberFormat from 'react-number-format'
import { Redirect } from 'react-router-dom'

const { Content } = Layout
const { Option } = Select
const { Step } = Steps

const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const IS_ADMIN_PROGRAM =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Administrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Administrator')

const EditProgram = ({ history, match }) => {
	const turndownService = new TurnDown()

	const programContext = useContext(ProgramContext)

	const { getProgramDetail, program, setProgram, updateProgram, fetchProgramList, submitting, error, programDetailDesc, setProgramDetailDesc } = programContext

	useEffect(() => {
		getProgramDetail(match.params.id)
	}, [])

	const onSubmit = async () => {
		try {
			const responseCreateProgram = await updateProgram(program.data.ID, { ...program.data, detail: turndownService.turndown(programDetailDesc) })
			if (responseCreateProgram.code === 200) {
				history.push('/program/' + program.data.ID)
				fetchProgramList()
				notification.success({ message: 'Sukses Mengubah Program' })
			}
		} catch (error) {
			console.log(error)
		}
	}

	if (IS_ADMIN || IS_ADMIN_PROGRAM) {
		return (
			<Fragment>
				<CustomHeader title='Program' />
				{program.isLoading ? (
					<h1>Loading</h1>
				) : (
					<Content style={{ background: '#f8f9fa', padding: '16px', paddingLeft: '32px', paddingRight: '32px' }}>
						<Card className='__main_card_table' bordered={false}>
							<Row gutter={[0, 16]} align='middle' justify='center'>
								<Col span={14}>
									<Form layout='vertical'>
										<Fragment>
											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Nama Program
												</span>
												<Input
													name='name'
													value={program.data.name}
													onChange={(e) => setProgram({ ...program, data: { ...program.data, name: e.target.value } })}
													placeholder='Nama Program'
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.name ? error.errors.name[0] : null) : null}</span>
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Description
												</span>
												<Input
													name='description'
													value={program.data.description}
													onChange={(e) => setProgram({ ...program, data: { ...program.data, description: e.target.value } })}
													placeholder='Description'
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.description ? error.errors.description[0] : null) : null}</span>
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Detail
												</span>
												{/* <Input
                                            name='detail'
                                            value={program.data.detail}
                                            onChange={(e) => setProgram({ ...program, data: { ...program.data, detail: e.target.value} })}
                                            placeholder='Detail'
                                        /> */}
												<ReactQuill
													value={programDetailDesc || ''}
													theme='snow'
													placeholder='Detail'
													onChange={(value) => setProgramDetailDesc(value)}
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.detail ? error.errors.detail[0] : null) : null}</span>
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Detail
												</span>

												<NumberFormat
													onValueChange={(e) => setProgram({ ...program, data: { ...program.data, nominal: e.value } })}
													className='ant-input'
													value={program.data.nominal}
													thousandSeparator={'.'}
													decimalSeparator={','}
													prefix={'Rp. '}
													placeholder='Nominal'
													name='nominal'
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.nominal ? error.errors.nominal[0] : null) : null}</span>
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Tanggal MUlai
												</span>

												<DatePicker
													allowClear={false}
													format='YYYY-MM-DD HH:mm:ss'
													value={moment(program.data.start, 'YYYY-MM-DD HH:mm:ss')}
													showTime
													onChange={(value, dateString) => setProgram({ ...program, data: { ...program.data, start: dateString } })}
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.start ? error.errors.start[0] : null) : null}</span>
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Kedaluwarsa
												</span>

												<DatePicker
													allowClear={false}
													format='YYYY-MM-DD HH:mm:ss'
													value={moment(program.data.expired, 'YYYY-MM-DD HH:mm:ss')}
													showTime
													onChange={(value, dateString) => setProgram({ ...program, data: { ...program.data, expired: dateString } })}
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.expired ? error.errors.expired[0] : null) : null}</span>
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Image
												</span>
												<input
													accept='.jpg,.jpeg,.png'
													type='file'
													name=''
													id='avatar-upload'
													onChange={(e) => setProgram({ ...program, data: { ...program.data, image: e.target.files[0] } })}
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.image ? error.errors.image[0] : null) : null}</span>
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Status Publikasi
												</span>
												<Select
													placeholder='Pilih Status Publikasi'
													value={program.data.isPublished.toString()}
													onChange={(val) => setProgram({ ...program, data: { ...program.data, isPublished: val } })}>
													<Option value='1'>Dipublikasikan</Option>
													<Option value='0'>Tidak Dipublikasikan</Option>
												</Select>

												<span style={{ color: 'red' }}>{error ? (error.errors.isPublished ? error.errors.isPublished[0] : null) : null}</span>
											</div>

											<Form.Item>
												<Row gutter={[16, 16]} align='middle' justify='end' style={{ width: '100%' }}>
													<Col span={6}>
														<Button block loading={submitting} onClick={onSubmit} type='primary'>
															Simpan
														</Button>
													</Col>
												</Row>
											</Form.Item>
										</Fragment>
									</Form>
								</Col>
							</Row>
						</Card>
					</Content>
				)}
			</Fragment>
		)
	} else {
		return <Redirect to={`/program/${match.params.id}`} />
	}
}

export default EditProgram
