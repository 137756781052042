import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

export default class AspirationAPI {
	static getAspirations(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/aspiration?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getThread(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/aspiration/thread/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAspirationDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/aspiration/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getLeaderboard(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/appreciation/leaderboard?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static getAppreciation(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/appreciation?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static detailAppreciation(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/appreciation/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static replyAspiration(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((data) => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/aspiration/thread/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static responseAspiration(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((data) => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/aspiration/${id}/status`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addAspiration(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((data) => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post('/aspiration', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addAppreciation(body = {}, files = []) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((data) => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				files.forEach((file) => {
					if (file) {
						formData.append('files[]', file)
					}
				})
				const response = await Axios.post('/appreciation', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateAppreciation(body = {}, files = [], removes = [], id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach((data) => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				files.forEach((file) => {
					if (file) {
						formData.append('files[]', file)
					}
				})

				removes.forEach((file) => {
					if (file) {
						formData.append('removes[]', file)
					}
				})
				const response = await Axios.post(`/appreciation/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static deleteAppreciation(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/appreciation/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
