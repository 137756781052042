import React, { useContext, useState, Fragment } from 'react'
import { Divider, Row, Col, Button, Radio, notification, Select } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'

const { Option } = Select

const FamilyForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee } = employeeContext

	const form = { employeeID, marriedStatus: '', totalChild: 0 }

	const [familyData, setFamilyData] = useState(null)
	const [familyDataAdd, setFamilyDataAdd] = useState(null)

	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.employeeFamily) {
			setFamilyData(employeeEdit.employeeFamily)
		} else {
			setFamilyDataAdd(form)
		}
	}, [employee.data])

	const onAddFamily = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.addEmployeeFamily(familyDataAdd)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Menambahkan Data Keluarga' })
					setFamilyDataAdd(null)
					setSubmitting(false)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Menambahkan Data Keluarga' })
				setSubmitting(false)
			}
		}
	}

	const onUpdateFamily = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.updateEmployeeFamily(familyData)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Ubah Data Keluarga' })
					setFamilyDataAdd(null)
					setSubmitting(false)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Ubah Data Keluarga' })
				setSubmitting(false)
			}
		}
	}

	return (
		<div>
			<h1>Data Keluarga</h1>

			<Divider />

			{familyData ? (
				<Fragment>
					<Row gutter={[16, 16]} align='middle' justify='center'>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Status Pernikahan
								</span>
							</div>
							{/* <Select
								onChange={(val) => setFamilyData({ ...familyData, marriedStatus: val })}
								defaultActiveFirstOption={false}
								style={{ marginTop: '8px', width: '100%' }}
								placeholder='Pilih Status Pernikahan'
								value={familyData.marriedStatus}>
								<Option name='educationTierID' value={1}>
									Pasangan PNS
								</Option>
								<Option name='educationTierID' value={2}>
									Pasangan Non-PNS
								</Option>
								<Option name='educationTierID' value={3}>
									Single
								</Option>
								<Option name='educationTierID' value={4}>
									Janda/Duda
								</Option>
							</Select> */}

							<Radio.Group
								value={familyData.marriedStatus}
								buttonStyle='solid'
								onChange={(e) => setFamilyData({ ...familyData, marriedStatus: e.target.value })}>
								<Radio.Button value={1}>Pasangan PNS</Radio.Button>
								<Radio.Button value={2}>Pasangan Non-PNS</Radio.Button>
								<Radio.Button value={3}>Single</Radio.Button>
								<Radio.Button value={4}>Janda/Duda</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>

					<Row gutter={[16, 16]} justify='end'>
						<Col span={12}>
							<Button block type='primary' onClick={onUpdateFamily} loading={submitting}>
								Ubah Data Keluarga
							</Button>
						</Col>
					</Row>
				</Fragment>
			) : familyDataAdd ? null : (
				<Col span={24}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setFamilyDataAdd(form)}>
						<PlusOutlined /> Ubah Status Pernikahan
					</Button>
				</Col>
			)}

			{familyDataAdd ? (
				<Fragment>
					<Row gutter={[16, 16]} align='middle' justify='center'>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Status Pernikahan
								</span>
							</div>
							<Radio.Group buttonStyle='solid' onChange={(e) => setFamilyDataAdd({ ...familyDataAdd, marriedStatus: e.target.value })}>
								<Radio.Button value={1}>Pasangan PNS</Radio.Button>
								<Radio.Button value={2}>Pasangan Non-PNS</Radio.Button>
								<Radio.Button value={3}>Single</Radio.Button>
								<Radio.Button value={4}>Janda/Duda</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>

					<Row gutter={[16, 16]} justify='end'>
						<Col span={12}>
							<Button block type='primary' loading={submitting} onClick={onAddFamily}>
								Tambah Status Pernikahan
							</Button>
						</Col>
					</Row>
				</Fragment>
			) : null}
		</div>
	)
}

export default FamilyForm
