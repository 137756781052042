import React, { useContext, useState, useEffect } from 'react'
import { EmployeeContext } from '../../../context/EmployeeContext'
import { Input, Divider, Row, Col, Select, Button, Popconfirm, notification } from 'antd'

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import MasterAPI from '../../../services/MasterAPI'

const { Option } = Select

const NewEducationHistoryForm = ({ currentUser, onNext }) => {
	const employeeContext = useContext(EmployeeContext)
	const { educationTier } = employeeContext

	const employeeEducationForm = {
		employeeID: currentUser.ID,
		educationTierID: '',
		schoolName: '',
		subjectName: '',
		graduationYear: '',
		referenceNumber: '',
		image: null,
	}
	const [employeeEducation, setEmployeeEducation] = useState([])

	const [submitting, setSubmitting] = useState(false)

	const onChangeTier = (value, e) => {
		let temp = [...employeeEducation]
		temp[e['data-idx']][e.name] = value
		setEmployeeEducation(temp)
	}

	const onChangeText = (e) => {
		let temp = [...employeeEducation]
		temp[e.target.dataset.idx][e.target.name] = e.target.value
		setEmployeeEducation(temp)
	}

	const removeEducation = (index) => {
		let tmp = employeeEducation.filter((edu, idx) => idx !== index)
		setEmployeeEducation(tmp)
	}

	/**
	 * @returns {boolean}
	 */
	const disabled = () => {
		let status = false
		const merge = employeeEducation

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach((calc) => {
				if (`${calc.educationTierID}`.trim().length === 0 || `${calc.schoolName}`.trim().length === 0 || `${calc.subjectName}`.trim().length === 0) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	return (
		<div>
			<h1>History Pendidikan</h1>
			<Divider />
			{employeeEducation.map((education, index) => (
				<Row gutter={[16, 16]} key={education.ID} align='middle' justify='center'>
					<Col span={8}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Tingkat Pendidikan
							</span>
						</div>
						<Select
							onChange={onChangeTier}
							defaultActiveFirstOption={false}
							style={{ marginTop: '8px', width: '100%' }}
							placeholder='Pilih Tingkat Pendidikan'>
							{educationTier.data.map((education) => (
								<Option data-idx={index} name='educationTierID' key={education.ID} value={education.ID}>
									{education.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col span={8}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Nama Sekolah / Universitas
							</span>
						</div>
						<Input
							placeholder='Masukan Nama Sekolah / Universitas'
							style={{ marginTop: '8px', width: '100%' }}
							data-idx={index}
							name='schoolName'
							value={education.schoolName}
							onChange={onChangeText}
						/>
					</Col>
					<Col span={8}>
						<DeleteOutlined style={{ position: 'absolute', right: '8px', color: 'red' }} onClick={() => removeEducation(index)} />
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Jurusan
							</span>
						</div>
						<Input
							placeholder='Masukkan Nama Jurusan'
							style={{ marginTop: '8px', width: '100%' }}
							data-idx={index}
							name='subjectName'
							value={education.subjectName}
							onChange={onChangeText}
						/>
					</Col>
					<Col span={8}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Nomor Ijazah
							</span>
						</div>
						<Input
							placeholder='Masukkan Nomor Ijazah'
							style={{ marginTop: '8px', width: '100%' }}
							name='referenceNumber'
							data-idx={index}
							value={education.referenceNumber}
							onChange={onChangeText}
						/>
					</Col>
					<Col span={8}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Tahun Lulus
							</span>
						</div>
						<Input
							maxLength={4}
							data-idx={index}
							placeholder='Masukkan Tahun Lulus'
							style={{ marginTop: '8px', width: '100%' }}
							name='graduationYear'
							value={education.graduationYear}
							onChange={onChangeText}
						/>
					</Col>
					<Col span={8}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Upload Ijazah
							</span>
						</div>
						<Input
							type='file'
							maxLength={4}
							data-idx={index}
							placeholder='Upload Ijazah'
							style={{ marginTop: '8px', width: '100%' }}
							name='image'
							onChange={(e) => {
								let temp = [...employeeEducation]
								temp[index]['image'] = e.target.files[0]
								setEmployeeEducation(temp)
							}}
						/>
					</Col>
					{/* <Col span={3}>
						{' '}
						<MinusCircleOutlined onClick={() => removeEducation(index)} />
					</Col> */}
				</Row>
			))}

			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setEmployeeEducation([...employeeEducation, { ...employeeEducationForm }])}>
						<PlusOutlined /> Tambah History Pendidikan
					</Button>
				</Col>
			</Row>

			<Row gutter={[16, 16]} align='middle' justify='end' style={{ width: '100%' }}>
				<Col span={6}>
					<Button block onClick={onNext}>
						Lewati
					</Button>
				</Col>

				<Col span={6}>
					<Button
						disabled={disabled()}
						block
						loading={submitting}
						type='primary'
						onClick={async () => {
							if (!submitting) {
								setSubmitting(true)
								if (employeeEducation.length > 0) {
									for (let education of employeeEducation) {
										try {
											await MasterAPI.addEmployeeEducation(education)
											notification.success({ message: 'Sukses Kirim History Pendidikan' })
										} catch (error) {
											notification.error({ message: 'Gagal Kirim History Pendidikan' })
											console.log(error)
										}
									}
									setSubmitting(false)
									onNext()
								}
							}
						}}>
						Kirim Pendidikan
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default NewEducationHistoryForm
