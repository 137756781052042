import React from 'react'
import { Card } from 'antd'

const BPJScard = ({ employee }) => {
	return (
		<Card
			className='__main_card_table __bpjs-card'
			style={{
				padding: '0',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: 'auto',
				marginTop: '16px',
			}}>
			<div style={{ margin: '50px 0', textAlign: 'center' }}>
				<h1>No. BPJS</h1>
				{employee.data.BPJS ? <h1 style={{ fontSize: '24px' }}>{employee.data.BPJS.number}</h1> : <i style={{ fontSize: '12px' }}>Data BPJS Belum Ada</i>}
			</div>
		</Card>
	)
}

export default BPJScard
