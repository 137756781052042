import React, { Fragment, useState, useEffect } from 'react'
import { Layout, Row, Col, Button, Tabs, Spin, Modal, notification, Input } from 'antd'
import { CustomHeader } from '../../components'
import BudgetPlanTable from './Components/BudgetPlanTable'
import { useRouteMatch, useHistory } from 'react-router-dom'
import API from '../../services/API'
import { ExclamationCircleOutlined, QuestionOutlined, ArrowLeftOutlined } from '@ant-design/icons'

const { Content } = Layout
const { TabPane } = Tabs
const { info, confirm } = Modal

const BudgetPlanDetail = () => {
	const match = useRouteMatch('/budget-plan/:id')
	const history = useHistory()

	const [listOutput, setListOutput] = useState({ data: [], isLoading: true })
	const [historyDetail, setHistoryDetail] = useState({ data: null, isLoading: true })
	const [reason, setReason] = useState('')
	const [showModalDecline, setShowModalDecline] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [activeTabKey, setActiveTabKey] = useState(null)

	const fetchListOutput = async () => {
		try {
			const response = await API.getProgramBudgetOutput({ applicantBudgetHistoryID: match.params.id, pageSize: 0, sort: 'asc', order: 'ID' })
			setListOutput({
				isLoading: false,
				data: response.data.list,
			})
			setActiveTabKey(response.data.list[0].ID)
		} catch (error) {
			console.log(error)
		}
	}

	const fetchDetail = async () => {
		try {
			const response = await API.getProgramBudgetHistoryDetail(match.params.id)
			setHistoryDetail({
				isLoading: false,
				data: response.data,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const showConfirmModal = () => {
		info({
			closable: true,
			title: 'Yakin menerima?',
			icon: <QuestionOutlined />,
			content: 'Apakah anda yakin untuk menerima Rencana Anggaran ini ?',
			okText: 'Terima',
			async onOk() {
				try {
					await API.responseBudgetHistory(match.params.id, { status: '1' })
					notification.success({ message: 'Berhasil terima Anggaran' })
					fetchDetail()
				} catch (e) {
					notification.error({ message: 'Gagal terima anggaran' })
				}
			},
			onCancel() {
				Modal.destroyAll()
			},
		})
	}

	const showRollbackModal = () => {
		info({
			closable: true,
			title: 'Yakin mengembalikan respon?',
			icon: <QuestionOutlined />,
			content: 'Apakah anda yakin untuk mengembalikan respon Rencana Anggaran ini ?',
			okText: 'Ya',
			async onOk() {
				try {
					await API.responseBudgetHistory(match.params.id, { status: '3' })
					notification.success({ message: 'Berhasil mengembalikan respon Anggaran' })
					fetchDetail()
				} catch (e) {
					notification.error({ message: 'Gagal mengembalikan respon anggaran' })
				}
			},
			onCancel() {
				Modal.destroyAll()
			},
		})
	}

	const onDecline = async () => {
		if (!submitting) {
			setSubmitting(true)
			if (reason.trim() === '') {
				notification.error({ message: 'Harap berikan alasan' })
				setSubmitting(false)
			} else {
				try {
					await API.responseBudgetHistory(match.params.id, { status: '2', reason })
					notification.success({ message: 'Berhasil menolak Anggaran' })
					fetchDetail()
					setReason('')
					setSubmitting(false)
					setShowModalDecline(false)
				} catch (e) {
					notification.error({ message: 'Gagal menolak anggaran' })
					setSubmitting(false)
				}
			}
		}
	}

	const renderAction = () => {
		return historyDetail.isLoading ? null : historyDetail.data.programApplicantBudgetResponse === null ? (
			<Row gutter={[16, 16]} justify='end' align='middle'>
				<Col span={2}>
					<Button type='dashed' block onClick={() => setShowModalDecline(true)}>
						Tolak
					</Button>
				</Col>
				<Col span={2}>
					<Button type='primary' block onClick={showConfirmModal}>
						Terima
					</Button>
				</Col>
			</Row>
		) : historyDetail.data.programApplicantBudgetResponse.status === 3 ? (
			<Row gutter={[16, 16]} justify='end' align='middle'>
				<Col span={2}>
					<Button type='dashed' block onClick={() => setShowModalDecline(true)}>
						Tolak
					</Button>
				</Col>
				<Col span={2}>
					<Button type='primary' block onClick={showConfirmModal}>
						Terima
					</Button>
				</Col>
			</Row>
		) : (
			<Row gutter={[16, 16]} justify='end' align='middle'>
				<Col span={2}>
					{historyDetail.data.programApplicantBudgetResponse.status === 1 ? (
						<Button style={{ color: 'green' }} type='link'>
							Diterima
						</Button>
					) : (
						<Button style={{ color: 'red' }} type='link'>
							Ditolak
						</Button>
					)}
				</Col>
				<Col span={2}>
					<Button type='dashed' block onClick={showRollbackModal}>
						Rollback
					</Button>
				</Col>
			</Row>
		)
	}

	useEffect(() => {
		fetchListOutput()
		fetchDetail()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Rencana Anggaran' />
			<Content style={{ padding: '0 32px', background: '#f8f9fa', paddingTop: 64 }}>
				<div>
					<ArrowLeftOutlined onClick={() => history.goBack()} />
				</div>
				{renderAction()}
				{listOutput.isLoading ? (
					<div style={{ display: 'flex', height: '40vh', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
						<Spin />
					</div>
				) : (
					<div>
						{listOutput.data.map((output) => (
							<Button
								key={output.ID}
								style={{ border: 'none', color: activeTabKey === output.ID ? '#1890ff' : '#000' }}
								onClick={() => {
									setActiveTabKey(output.ID)
								}}>
								{output.name}
							</Button>
						))}

						<Tabs
							type='card'
							tabPosition='bottom'
							animated={true}
							style={{ background: '#fff' }}
							activeKey={`${activeTabKey}`}
							defaultActiveKey={`${activeTabKey}`}
							onChange={(key) => setActiveTabKey(key)}>
							{listOutput.data.map((output) => (
								<TabPane tab={output.name} key={output.ID}>
									<div style={{ padding: 16 }}>
										<BudgetPlanTable programBudgetOutputID={output.ID} />
									</div>
								</TabPane>
							))}
						</Tabs>
					</div>
				)}

				<br />
				{renderAction()}
			</Content>

			<Modal
				confirmLoading={submitting}
				title={
					<p>
						<ExclamationCircleOutlined /> Yakin menolak anggaran ?
					</p>
				}
				visible={showModalDecline}
				onOk={onDecline}
				onCancel={() => setShowModalDecline(false)}
				okText='Tolak'
				cancelText='Batal'
				okButtonProps={{ type: 'dashed' }}
				cancelButtonProps={{ type: 'primary' }}>
				<div>
					<p>Apakah anda yakin untuk menolak Rencana Anggaran ini ? Jika ya, maka berikan alasan penolakan</p>
					<Input type='text' onChange={(event) => setReason(event.target.value)} placeholder='Alasan' value={reason} style={{ width: '100%' }} />
				</div>
			</Modal>
		</Fragment>
	)
}

export default BudgetPlanDetail
