import React from 'react'
import { Layout } from 'antd'

import PropTypes from 'prop-types'

const { Header } = Layout

const CustomHeader = ({ title }) => {
	return (
		<Header>
			<h1 className='__header_title'>{title}</h1>
		</Header>
	)
}

CustomHeader.propTypes = {
	title: PropTypes.string.isRequired,
}

export default CustomHeader
