import Axios from 'axios'

let token
let role

try {
	if (localStorage.getItem('@user_token')) {
		token = localStorage.getItem('@user_token')
	}
	if (sessionStorage.getItem('@user_token')) {
		token = sessionStorage.getItem('@user_token')
	}
} catch (error) {
	token = null
}

try {
	if (localStorage.getItem('@role')) {
		role = localStorage.getItem('@role')
	}
	if (sessionStorage.getItem('@role')) {
		role = sessionStorage.getItem('@role')
	}
} catch (error) {
	role = null
}

const headersConfig = {
	Authorization: `Bearer ${token}`,
	Role: role,
	'Content-Type': `multipart/form-data`,
}

const defaultAxios = Axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	headers: headersConfig,
})

const portingAxios = Axios.create({
	baseURL: '/api',
	headers: headersConfig,
})

export { defaultAxios, portingAxios }
