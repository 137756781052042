import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Layout, Col, Row, Card, Radio, Button, Divider, Select, Input, DatePicker, Form, Steps, notification } from 'antd'
import { CustomHeader } from '../../components'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { ProgramContext } from '../../context/ProgramContext'
import NumberFormat from 'react-number-format'

import _ from 'lodash'

const { Content } = Layout
const { Option } = Select
const { Step } = Steps

const Add = ({ history }) => {
	const programContext = useContext(ProgramContext)

	const { submitting, error, programForm, setProgramForm, createProgram, fetchProgramList } = programContext

	const onSubmit = async () => {
		try {
			const responseCreateProgram = await createProgram()
			if (responseCreateProgram.code === 200) {
				history.push('/program')
				fetchProgramList()
				notification.success({ message: 'Sukses Menambahkan Program' })
			}
		} catch (error) {
			console.log(error)
			notification.error({ message: 'Gagal Menambahkan Program' })
		}
	}

	return (
		<Fragment>
			<CustomHeader title='Program' />

			<Content style={{ background: '#f8f9fa', padding: '16px', paddingLeft: '32px', paddingRight: '32px' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[0, 16]} align='middle' justify='center'>
						<Col span={14}>
							<Form layout='vertical'>
								<Fragment>
									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Nama Program
										</span>
										<Input
											name='name'
											value={programForm.name}
											onChange={(e) => setProgramForm({ ...programForm, name: e.target.value })}
											placeholder='Nama Program'
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.name ? error.errors.name[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Description
										</span>
										<Input.TextArea
											name='description'
											value={programForm.description}
											onChange={(e) => setProgramForm({ ...programForm, description: e.target.value })}
											placeholder='Masukkan Deskripsi Singkat Program'
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.description ? error.errors.description[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Detail
										</span>
										{/* <Input
											name='detail'
											value={programForm.detail}
											onChange={(e) => setProgramForm({ ...programForm, detail: e.target.value })}
											placeholder='Detail'
										/> */}
										<ReactQuill theme='snow' placeholder='Detail' onChange={(value) => setProgramForm({ ...programForm, detail: value })} />
										<span style={{ color: 'red' }}>{error ? (error.errors.detail ? error.errors.detail[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Nominal
										</span>

										<NumberFormat
											onValueChange={(e) => setProgramForm({ ...programForm, nominal: e.value })}
											className='ant-input'
											value={programForm.nominal}
											thousandSeparator={'.'}
											decimalSeparator={','}
											prefix={'Rp. '}
											placeholder='Nominal'
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.nominal ? error.errors.nominal[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Tanggal Mulai
										</span>
										{/* <Input
											type='date'
											name='expired'
											value={programForm.expired}
											onChange={(e) => setProgramForm({ ...programForm, expired: e.target.value })}
											placeholder='Nama Program'
										/> */}
										<DatePicker
											allowClear={false}
											format='YYYY-MM-DD HH:mm:ss'
											showTime
											onChange={(value, dateString) => setProgramForm({ ...programForm, start: dateString })}
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.start ? error.errors.start[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Kedaluwarsa
										</span>
										{/* <Input
											type='date'
											name='expired'
											value={programForm.expired}
											onChange={(e) => setProgramForm({ ...programForm, expired: e.target.value })}
											placeholder='Nama Program'
										/> */}
										<DatePicker
											allowClear={false}
											format='YYYY-MM-DD HH:mm:ss'
											showTime
											onChange={(value, dateString) => setProgramForm({ ...programForm, expired: dateString })}
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.expired ? error.errors.expired[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Gambar
										</span>
										<input
											accept='.jpg,.jpeg,.png'
											type='file'
											name=''
											id='avatar-upload'
											onChange={(e) => setProgramForm({ ...programForm, image: e.target.files[0] })}
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.image ? error.errors.image[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Status Publikasi
										</span>
										<Select placeholder='Pilih Status Publikasi' onChange={(val) => setProgramForm({ ...programForm, isPublished: val })}>
											<Option value='1'>Dipublikasikan</Option>
											<Option value='0'>Tidak Dipublikasikan</Option>
										</Select>

										<span style={{ color: 'red' }}>{error ? (error.errors.isPublished ? error.errors.isPublished[0] : null) : null}</span>
									</div>

									<Form.Item>
										<Row gutter={[16, 16]} align='middle' justify='end' style={{ width: '100%' }}>
											<Col span={6}>
												<Button block loading={submitting} onClick={onSubmit} type='primary'>
													Tambah
												</Button>
											</Col>
										</Row>
									</Form.Item>
								</Fragment>
							</Form>
						</Col>
					</Row>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Add
