import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../../components'
import { Layout, Card, Row, Col, Button, Select, Form, Input, notification, Divider, Space } from 'antd'
import thousandSeparator from '../../../utils/thousandSeparator'

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import MasterAPI from '../../../services/MasterAPI'
import NumberFormat from 'react-number-format'
import API from '../../../services/API'

const { Content } = Layout

const { Option } = Select

const AddSubcomponentActivity = ({ match, history }) => {
	const [budgetAccount, setBudgetAccount] = useState({ data: [], isLoading: true })
	const [budgetAccountSearch, setBudgetAccountSearch] = useState([])
	const [units, setUnits] = useState({ data: [], isLoading: true })
	const [unitSearch, setUnitSearch] = useState([])
	const [calculationFields, setCalculationFields] = useState([])
	const [submitting, setSubmitting] = useState(false)

	const [formValue, setFormValue] = useState({
		subComponentID: match.params.id,
		unitID: '',
		budgetAccountID: '',
		name: '',
		unitValue: '',
		price: '',
	})

	const fetchBudgetAccount = async () => {
		try {
			setBudgetAccount({ ...budgetAccount, isLoading: true })
			const response = await MasterAPI.getBudgetAccount()

			setBudgetAccount({
				isLoading: false,
				data: response.data.list,
			})

			setBudgetAccountSearch(response.data.list)
		} catch (error) {
			console.log(error)
		}
	}

	const fetchUnit = async () => {
		try {
			setUnits({ ...units, isLoading: true })
			const response = await MasterAPI.getUnits()

			setUnits({
				isLoading: false,
				data: response.data.list,
			})

			setUnitSearch(response.data.list)
		} catch (error) {
			console.log(error)
		}
	}

	const onSearchBudget = (val) => {
		let temp = [...budgetAccount.data]
		let search = temp.filter((data) => `${data.name}`.toLowerCase().includes(`${val}`.toLowerCase()))
		setBudgetAccountSearch(search)
	}

	const onSearchUnit = (val) => {
		let temp = [...units.data]
		let search = temp.filter((data) => `${data.name}`.toLowerCase().includes(`${val}`.toLowerCase()))
		setUnitSearch(search)
	}

	const onValuesChange = (val, allVal) => {
		if (typeof val.calculations !== 'undefined') {
			setCalculationFields(allVal.calculations)
		}
	}

	const onFinish = async () => {
		if (!submitting)
			try {
				setSubmitting(true)

				const response = await API.addActivity(formValue)

				if (response.code === 200) {
					if (calculationFields.length > 0) {
						let calc = calculationFields.map((c) => ({
							...c,
							detailActivityID: response.data.ID,
						}))

						for (let i of calc) {
							await API.addCalculations(i)
						}
						notification.success({ message: 'Sukses Tambah data' })
						history.push(`/budget/2020/${response.data.subComponent.component.fullCode}`)
						setSubmitting(false)
					} else {
						notification.success({ message: 'Sukses Tambah data' })

						history.push(`/budget/2020/${response.data.subComponent.component.fullCode}`)
						setSubmitting(false)
					}
				}
			} catch (error) {
				console.log(error)
				setSubmitting(false)
			}
	}

	useEffect(() => {
		fetchBudgetAccount()
		fetchUnit()
	}, [])

	const disabled =
		Object.values(formValue).includes('') ||
		(calculationFields.length > 0 && calculationFields.some((calc) => typeof calc === 'undefined')) ||
		(calculationFields.filter((c) => c !== undefined && c.unitValue).length > 0
			? calculationFields.filter((c) => c !== undefined && c.unitValue).reduce((acc, curr) => parseInt(acc) * parseInt(curr.unitValue), 1) !==
			  parseInt(formValue.unitValue)
				? true
				: false
			: null)

	return (
		<Fragment>
			<CustomHeader title='Add Activity' />
			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					{/* <button onClick={() => history.push('/budget/2020/4264.970.001.051')}>aa</button> */}
					<Row gutter={[32, 16]}>
						<Col span={8} style={{ borderRight: '1px solid #d9d9d9' }}>
							<h1>Tambah Data</h1>
							<Divider />

							<Form layout='vertical' onValuesChange={onValuesChange} onFinish={onFinish}>
								<Form.Item label='Akun Anggaran' name='budgetAccountID' rules={[{ required: true, message: 'Akun Anggaran tidak boleh kosong' }]}>
									<Select
										onSelect={(val) => setFormValue({ ...formValue, budgetAccountID: val })}
										showSearch
										filterOption={false}
										onSearch={onSearchBudget}
										placeholder='Pilih Akun Anggaran'
										loading={budgetAccount.isLoading}>
										{budgetAccountSearch.map((budget) => (
											<Option value={budget.ID} key={budget.ID}>{`${budget.code} - ${budget.name}`}</Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item label='Nama' name='name' rules={[{ required: true, message: 'Nama tidak boleh kosong' }]}>
									<Input.TextArea
										onChange={({ target: { value } }) => setFormValue({ ...formValue, name: value })}
										placeholder='Contoh: Administrasi, surat menyurat, penggandaan, foto copy dan Pelaporan'
									/>
								</Form.Item>

								<Form.Item label='Satuan' name='unitID' rules={[{ required: true, message: 'Satuan tidak boleh kosong' }]}>
									<Select
										onSelect={(val) => setFormValue({ ...formValue, unitID: val })}
										showSearch
										filterOption={false}
										onSearch={onSearchUnit}
										placeholder='Pilih Satuan'
										loading={budgetAccount.isLoading}>
										{unitSearch.map((unit) => (
											<Option value={unit.ID} key={unit.ID}>{`${unit.name}`}</Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item label='Volume' name='unitValue' rules={[{ required: true, message: 'Volume tidak boleh kosong' }]}>
									<NumberFormat
										placeholder='Contoh: 100'
										onValueChange={(e) => setFormValue({ ...formValue, unitValue: e.value })}
										className='ant-input'
										thousandSeparator={'.'}
										decimalSeparator={','}
										prefix={''}
									/>
								</Form.Item>

								<Form.Item label='Harga' name='price' rules={[{ required: true, message: 'Harga tidak boleh kosong' }]}>
									<NumberFormat
										placeholder='Contoh: 10.000.000'
										onValueChange={(e) => setFormValue({ ...formValue, price: e.value })}
										className='ant-input'
										thousandSeparator={'.'}
										decimalSeparator={','}
										prefix={'Rp. '}
									/>
								</Form.Item>

								<Divider />

								<Form.List name='calculations'>
									{(fields, { add, remove }) => {
										return (
											<div>
												{fields.map((field) => (
													<Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align='center'>
														<Form.Item
															{...field}
															label='Satuan'
															name={[field.name, 'unitID']}
															fieldKey={[field.fieldKey, 'unitID']}
															rules={[{ required: true, message: 'Satuan tidak boleh kosong' }]}>
															<Select
																showSearch
																filterOption={false}
																onSearch={onSearchUnit}
																placeholder='Pilih Satuan'
																loading={budgetAccount.isLoading}>
																{unitSearch.map((unit) => (
																	<Option value={unit.ID} key={unit.ID}>{`${unit.name}`}</Option>
																))}
															</Select>
														</Form.Item>
														<Form.Item
															{...field}
															label='Volume'
															name={[field.name, 'unitValue']}
															fieldKey={[field.fieldKey, 'unitValue']}
															rules={[{ required: true, message: 'Volume tidak boleh kosong' }]}>
															<Input min='1' step='1' pattern='[0-9]' type='number' placeholder='Contoh: 100' />
														</Form.Item>

														<MinusCircleOutlined
															onClick={() => {
																remove(field.name)
															}}
														/>
													</Space>
												))}

												<span style={{ color: 'red' }}>
													{calculationFields.filter((c) => c !== undefined && c.unitValue).length > 0
														? calculationFields
																.filter((c) => c !== undefined && c.unitValue)
																.reduce((acc, curr) => parseInt(acc) * parseInt(curr.unitValue), 1) !== parseInt(formValue.unitValue)
															? 'Total Volume Kalkulasi harus sama dengan Total Volume Anggaran'
															: ''
														: null}
												</span>

												<Form.Item>
													<Button
														type='dashed'
														onClick={() => {
															add()
														}}
														block>
														<PlusOutlined /> Tambah Detail Kalkulasi
													</Button>
												</Form.Item>

												<Form.Item>
													<Button disabled={disabled} loading={submitting} htmlType='submit' type='primary' block>
														Submit
													</Button>
												</Form.Item>
											</div>
										)
									}}
								</Form.List>
							</Form>
						</Col>

						<Col span={16}>
							<h1>Preview</h1>
							<Divider />

							<table className='custom-budget-table'>
								<thead>
									<tr>
										<th rowSpan={2}>Kode</th>
										<th rowSpan={2} style={{ width: '40%' }}>
											Uraian
										</th>
										<th colSpan={4}>Perhitungan Tahun Anggaran</th>
									</tr>
									<tr>
										<th>Volume</th>
										<th>Satuan</th>
										<th>Harga Satuan</th>
										<th>Jumlah</th>
									</tr>
								</thead>

								<tbody>
									<tr className='componentactv-tr'>
										<td>
											{`${formValue.budgetAccountID}`.trim().length === 0
												? null
												: budgetAccount.data.filter((budget) => `${budget.ID}` === `${formValue.budgetAccountID}`)[0].code}
										</td>
										<td style={{ textAlign: 'left', textDecoration: 'underline' }}>
											{`${formValue.budgetAccountID}`.trim().length === 0
												? null
												: budgetAccount.data.filter((budget) => `${budget.ID}` === `${formValue.budgetAccountID}`)[0].name}
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>

									<tr className='componentactv-tr-child'>
										<td></td>
										<td style={{ textAlign: 'left' }}> - {formValue.name}</td>
										<td>{calculationFields.length === 0 && formValue.unitValue}</td>
										<td>
											{calculationFields.length === 0 &&
												(`${formValue.unitID}`.trim().length === 0
													? null
													: units.data.filter((unit) => `${unit.ID}` === `${formValue.unitID}`)[0].name)}
										</td>
										<td>{calculationFields.length === 0 && `Rp. ${thousandSeparator(formValue.price)}`}</td>
										<td>
											{calculationFields.length === 0 && 'Rp. '}
											{calculationFields.length === 0 &&
												(isNaN(parseInt(formValue.price) * parseInt(formValue.unitValue))
													? ''
													: thousandSeparator(parseInt(formValue.price) * parseInt(formValue.unitValue)))}
										</td>
									</tr>

									{calculationFields.length > 0 && calculationFields.filter((c) => c !== undefined).length > 0 ? (
										<tr className='componentactv-tr-child'>
											<td></td>
											<td style={{ textAlign: 'left' }}>
												{' '}
												<span style={{ marginLeft: '32px' }}>
													{calculationFields.length > 0 &&
														calculationFields
															.filter((c) => c !== undefined)
															.map(
																(calc, index) =>
																	`${calc.unitValue ? calc.unitValue : ''} ${
																		calc.unitID ? units.data.filter((u) => calc.unitID === u.ID)[0].name : ''
																	} ${index + 1 < calculationFields.filter((c) => c !== undefined).length ? ' X ' : ''}`
															)}
												</span>
											</td>
											<td>{formValue.unitValue}</td>
											<td>
												{`${formValue.unitID}`.trim().length === 0
													? null
													: units.data.filter((unit) => `${unit.ID}` === `${formValue.unitID}`)[0].name}{' '}
											</td>
											<td>Rp. {thousandSeparator(formValue.price)}</td>
											<td>
												{isNaN(parseInt(formValue.price) * parseInt(formValue.unitValue))
													? ''
													: thousandSeparator(`Rp. ${parseInt(formValue.price) * parseInt(formValue.unitValue)}`)}
											</td>
										</tr>
									) : null}
								</tbody>
							</table>

							<span style={{ color: 'red' }}>
								{calculationFields.filter((c) => c !== undefined && c.unitValue).length > 0
									? calculationFields
											.filter((c) => c !== undefined && c.unitValue)
											.reduce((acc, curr) => parseInt(acc) * parseInt(curr.unitValue), 1) !== parseInt(formValue.unitValue)
										? 'Total Volume Kalkulasi harus sama dengan Total Volume Anggaran'
										: ''
									: null}
							</span>
						</Col>
					</Row>
				</Card>
			</Content>
		</Fragment>
	)
}

export default AddSubcomponentActivity
