import React, { Fragment, useContext, useState } from 'react'

import { Layout, Col, Row, Input, Card, Table, Menu, Modal, Button, Radio, Select, notification } from 'antd'
import { format, parse } from 'date-fns'
import { id } from 'date-fns/locale'
import { DeleteFilled, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { CustomHeader } from '../../components'
import { Link } from 'react-router-dom'
import { ProgramContext } from '../../context/ProgramContext'
import thousandSeparator from '../../utils/thousandSeparator'

import _ from 'lodash'
import API from '../../services/API'

const { Content } = Layout
const { Search } = Input

const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const IS_ADMIN_PROGRAM =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Administrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Administrator')

const Program = () => {
	const programContext = useContext(ProgramContext)

	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [selectedData, setSelectedData] = useState({})

	const {
		programList: { data, isLoading, pagination },
		query,
		setQuery,
		deleteProgram,
		fetchProgramList,
	} = programContext

	const onDelete = async () => {
		if (!loadingDelete) {
			try {
				setLoadingDelete(true)
				await API.deleteProgram(selectedData.ID)
				fetchProgramList()
				setLoadingDelete(false)
				setSelectedData({})
				notification.success({ message: 'Sukses Hapus Program' })
				setModalDeleteVisible(false)
			} catch (error) {
				setLoadingDelete(false)
				notification.error({ message: 'Gagal Hapus Program' })
			}
		}
	}

	const columns =
		IS_ADMIN || IS_ADMIN_PROGRAM
			? [
				{
					title: 'Nama',
					dataIndex: 'name',
					render: (text, record) => <Link to={`/program/${record.ID}`}>{text}</Link>,
					align: 'center',
					width: '20%',
				},
				{
					title: 'Nominal',
					dataIndex: 'nominal',
					align: 'center',
					render: (text, record) => <span>Rp. {thousandSeparator(text)}</span>,
					width: '20%',
				},

				{
					title: 'Deskripsi',
					dataIndex: 'description',
					align: 'center',
				},
				{
					title: () => <span style={{ fontSize: '12px' }}>ACTION</span>,
					align: 'center',
					render: (data, record) => (
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
							<Button
								onClick={() => {
									setSelectedData(data)
									setModalDeleteVisible(true)
								}}
								style={{ marginRight: '6px' }}
								style={{ opacity: 0.5 }}
								size='small'
								type='dashed'
								danger
								icon={<DeleteFilled />}
							/>
						</div>
					),
				},
			]
			: [
				{
					title: 'Nama',
					dataIndex: 'name',
					render: (text, record) => <Link to={`/program/${record.ID}`}>{text}</Link>,
					align: 'center',
					width: '20%',
				},
				{
					title: 'Nominal',
					dataIndex: 'nominal',
					align: 'center',
					render: (text, record) => <span>Rp. {thousandSeparator(text)}</span>,
					width: '20%',
				},

				{
					title: 'Deskripsi',
					dataIndex: 'description',
					align: 'center',
				},
			]

	return (
		<Fragment>
			<CustomHeader title='Program' />

			<Modal
				title='Apakah Anda Yakin ?'
				visible={modalDeleteVisible}
				onCancel={() => setModalDeleteVisible(false)}
				footer={[
					<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} onClick={onDelete} loading={loadingDelete}>
						Ya
					</Button>,
					<Button
						key='delete'
						type='primary'
						onClick={() => {
							setModalDeleteVisible(false)
							setSelectedData({})
						}}>
						Tidak
					</Button>,
				]}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<ExclamationCircleOutlined style={{ color: '#ffd666', fontSize: '32px' }} />
					<span style={{ marginLeft: '16px' }}>Apakah anda yakin untuk menghapus data ?</span>
				</div>
			</Modal>

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[16, 16]} align='middle'>
						<Col lg={6} sm={14} xs={14} md={8}>
						<Search
							allowClear
							placeholder='Cari Data'
							onSearch={(e) => setQuery({ ...query, keyword: e, pageSize: 20, currentPage: 1 })}
							enterButton
						/>
						</Col>

						<Col lg={2} sm={4} xs={4} md={2}>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
								<span>Sort By : </span>
							</div>
						</Col>

						<Col lg={4} sm={6} xs={6} md={4}>
							<Select placeholder='Sort By' value={query.order} style={{ width: '100%' }} onChange={(value) => setQuery({ ...query, sort: 'created', order: value })}>
								<Select.Option value='desc'>Newest to Oldest</Select.Option>
								<Select.Option value='asc'>Oldest to Newest</Select.Option>
							</Select>
						</Col>

						<Col lg={8} sm={12} xs={12} md={6}>
							{(IS_ADMIN || IS_ADMIN_PROGRAM) && (
								<Content>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
										<Link to='/add-program'>
											<Button type='primary' style={{ borderRadius: '5px' }}>
												<PlusOutlined /> Tambah Program
											</Button>
										</Link>
									</div>
								</Content>
							)}
						</Col>
					</Row>
					<Table
						pagination={{
							defaultPageSize: 20,
							pageSize: query.pageSize,
							total: pagination.itemCount ?? 20,
							onChange: (page) => setQuery({ ...query, currentPage: page }),
							onShowSizeChange: (current, size) => setQuery({ ...query, currentPage: current, pageSize: size }),
							current: query.currentPage,
							defaultCurrent: 1,
						}}
						loading={isLoading}
						columns={columns}
						dataSource={data.map((program) => ({
							key: program.ID,
							...program,
						}))}
						bordered
					/>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Program
