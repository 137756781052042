import React, { useContext, useState } from 'react'
import { Divider, Row, Col, Button, Input, notification } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'

const MendikbudEmailForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee } = employeeContext

	const form = { employeeID, email: '' }
	const [email, setEmail] = useState(null)
	const [emailAdd, setEmailAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.emailMendikbud) {
			setEmail(employeeEdit.emailMendikbud)
		}
	}, [employee.data])

	const onUpdateEmail = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.updateEmailDikbud(email)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Ubah Email Kemendikbud' })
					setSubmitting(false)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Ubah Email Kemendikbud' })
				setSubmitting(false)
			}
		}
	}

	const onAddEmail = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.addEmailDikbud(emailAdd)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Menambahkan Email Kemendikbud' })
					setSubmitting(false)
					setEmailAdd(null)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Menambahkan Email Kemendikbud' })
				setSubmitting(false)
			}
		}
	}

	return (
		<div>
			<h1>Email Kemendikbud</h1>

			<Divider />
			{email ? (
				<Row gutter={[16, 16]} align='bottom' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Email Kemendikbud
							</span>
						</div>
						<Input
							placeholder='Masukan Email Kemendikbud'
							style={{ marginTop: '8px', width: '100%' }}
							value={email.email}
							onChange={(e) => setEmail({ ...email, email: e.target.value })}
						/>
					</Col>

					<Col span={12}>
						<Button onClick={onUpdateEmail} block loading={submitting} type='primary'>
							Ubah Email Kemendikbud
						</Button>
					</Col>
				</Row>
			) : emailAdd ? null : (
				<Col span={24}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setEmailAdd(form)}>
						<PlusOutlined /> Tambah Email Kemendikbud
					</Button>
				</Col>
			)}

			{emailAdd ? (
				<Row gutter={[16, 16]} align='bottom' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> Email Kemendikbud
							</span>
						</div>
						<Input
							placeholder='Masukan Email Kemendikbud'
							style={{ marginTop: '8px', width: '100%' }}
							value={emailAdd.email}
							onChange={(e) => setEmailAdd({ ...emailAdd, email: e.target.value })}
						/>
					</Col>

					<Col span={12}>
						<Button onClick={onAddEmail} block loading={submitting} type='primary'>
							Kirim Email Kemendikbud
						</Button>
					</Col>
				</Row>
			) : null}
		</div>
	)
}

export default MendikbudEmailForm
