import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Layout, Col, Row, Card, Divider, Button, Spin, Select, Input, DatePicker, Form, notification, Radio } from 'antd'
import { CustomHeader } from '../../components'
import { ArrowLeftOutlined } from '@ant-design/icons'

import { EmployeeContext } from '../../context/EmployeeContext'

import _ from 'lodash'
import MasterAPI from '../../services/MasterAPI'

import moment from 'moment'
import { Link } from 'react-router-dom'
import EducationHistoryForm from './components/EducationHistoryForm'
import BankForm from './components/BankForm'
import CareerForm from './components/CareerForm'
import NIPForm from './components/NIPForm'
import FamilyForm from './components/FamilyForm'
import FatherForm from './components/FatherForm'
import MotherForm from './components/MotherForm'
import BloodForm from './components/BloodForm'
import BPJSForm from './components/BPJSForm'
import MendikbudEmailForm from './components/MendikbudEmailForm'
import ChildrenForm from './components/ChildrenForm'
import EmployeeSpouse from './components/EmployeeSpouse'
import CourseForm from './components/CourseForm'
import TrainingForm from './components/TrainingForm'

const { Content } = Layout
const { Option } = Select

const EditEmployee = ({ history, match }) => {
	const employeeContext = useContext(EmployeeContext)

	const {
		updateEmployee,
		submitting,
		error,
		employeeForm,
		setEmployeeForm,
		fetchEmployeeList,
		employee,
		fetchDetailEmployee,
		employeeEdit,
		setEmployeeEdit,
		educationTier,
		employeePosition,
		employeeCategory,
		religion,
	} = employeeContext

	const [cities, setCities] = useState([])

	useEffect(() => {
		if (employee.isLoading) {
			fetchDetailEmployee(match.params.id)
		}

		return () => {
			setEmployeeEdit(null)
		}
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Employee' />

			<Content style={{ background: '#f8f9fa', padding: '16px', paddingLeft: '32px', paddingRight: '32px' }}>
				{employee.isLoading || Object.keys(employeeEdit).length === 0 ? (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Spin size='large' />
					</div>
				) : (
					<Card className='__main_card_table' bordered={false}>
						<Row gutter={[0, 16]} align='middle' justify='start'>
							<Col>
								<Link to={`/employee/${match.params.id}`}>
									<Button style={{ borderRadius: '4px' }} type='link' icon={<ArrowLeftOutlined />}>
										Kembali
									</Button>
								</Link>
							</Col>
						</Row>

						<Row gutter={[0, 16]} align='middle' justify='center'>
							<Col span={16}>
								<Form layout='vertical'>
									<h1>Informasi Dasar</h1>
									<Divider />
									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Jenis Karyawan
										</span>
										<Radio.Group
											value={employeeEdit.employeeType}
											buttonStyle='solid'
											onChange={(e) => setEmployeeEdit({ ...employeeEdit, employeeType: e.target.value })}>
											<Radio.Button value='PNS'>PNS</Radio.Button>
											<Radio.Button value='PPNPN'>PPNPN</Radio.Button>
										</Radio.Group>
										<span style={{ color: 'red' }}>{error ? (error.errors.employeeType ? error.errors.employeeType[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Nama
										</span>
										<Input
											name='name'
											value={employeeEdit.name}
											onChange={(e) => setEmployeeEdit({ ...employeeEdit, name: e.target.value })}
											placeholder='Tony Stark'
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.name ? error.errors.name[0] : null) : null}</span>
									</div>

									<Row gutter={[16, 16]}>
										<Col span={12}>
											<div>
												<span style={{ color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Tempat Lahir
												</span>
											</div>

											<Select
												onChange={(value) => setEmployeeEdit({ ...employeeEdit, bornPlace: value })}
												onSearch={_.debounce(
													(value) =>
														value.trim().length === 0
															? setCities([])
															: MasterAPI.getCity({ keyword: value }).then((response) => setCities(response.data.list)),
													500
												)}
												defaultActiveFirstOption
												style={{ marginTop: '8px', width: '100%' }}
												filterOption={false}
												placeholder='Pilih Tempat Lahir'
												defaultValue={employeeEdit.getBornPlace?.ID}
												showSearch>
												<Option key={'initial-born'} value={employeeEdit.getBornPlace?.ID}>
													{employeeEdit.getBornPlace?.name}
												</Option>

												{cities.map((city) => (
													<Option key={city.ID} value={city.ID}>
														{city.name}
													</Option>
												))}
											</Select>
											<div>
												<span style={{ color: 'red' }}>{error ? (error.errors.bornPlace ? error.errors.bornPlace[0] : null) : null}</span>
											</div>
										</Col>

										<Col span={12}>
											<div>
												<span style={{ color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Tanggal Lahir
												</span>
											</div>
											<DatePicker
												allowClear={false}
												format='YYYY-MM-DD'
												style={{ marginTop: '8px', width: '100%' }}
												placeholder='Pilih Tanggal Lahir'
												value={moment(employeeEdit.bornDate, 'YYYY-MM-DD')}
												onChange={(date, dateString) => setEmployeeEdit({ ...employeeEdit, bornDate: dateString })}
											/>

											<div>
												<span style={{ color: 'red' }}>{error ? (error.errors.bornDate ? error.errors.bornDate[0] : null) : null}</span>
											</div>
										</Col>
									</Row>

									<Row gutter={[16, 16]}>
										{/* <Col span={12}>
											<div>
												<span style={{ color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Status Pernikahan
												</span>
											</div>
											<Select
												onChange={(value) => setEmployeeEdit({ ...employeeEdit, isMarried: value })}
												defaultActiveFirstOption={false}
												style={{ marginTop: '8px', width: '100%' }}
												value={employeeEdit.isMarried}
												placeholder='Pilih Status Pernikahan'>
												<Option key={'married-0'} value={1}>
													Menikah
												</Option>
												<Option key={'married-1'} value={0}>
													Belum Menikah
												</Option>
											</Select>

											<div>
												<span style={{ color: 'red' }}>{error ? (error.errors.isMarried ? error.errors.isMarried[0] : null) : null}</span>
											</div>
										</Col> */}

										<Col span={12}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> No. Telpon / HP
											</span>
											<Input
												value={employeeEdit.phoneNumber}
												type='number'
												style={{ marginTop: '8px' }}
												placeholder='Input No.Telpon'
												onChange={(e) => setEmployeeEdit({ ...employeeEdit, phoneNumber: e.target.value })}
											/>
											<span style={{ color: 'red' }}>{error ? (error.errors.phoneNumber ? error.errors.phoneNumber[0] : null) : null}</span>
										</Col>

										<Col span={12}>
											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Email
												</span>
												<Input
													type='email'
													value={employeeEdit.email}
													onChange={(e) => setEmployeeEdit({ ...employeeEdit, email: e.target.value })}
													placeholder='ini@email.com'
												/>

												<span style={{ color: 'red' }}>{error ? (error.errors.email ? error.errors.email[0] : null) : null}</span>
											</div>
										</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={12}>
											<div>
												<span style={{ color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Kota
												</span>
											</div>

											<Select
												loading={cities.isLoading}
												onChange={(value) => setEmployeeForm({ ...employeeForm, cityID: value })}
												onSearch={_.debounce(
													(value) =>
														value.trim().length === 0
															? setCities([])
															: MasterAPI.getCity({ keyword: value }).then((response) => setCities(response.data.list)),
													500
												)}
												defaultActiveFirstOption
												style={{ marginTop: '8px', width: '100%' }}
												filterOption={false}
												placeholder='Pilih Kota'
												defaultValue={employeeEdit.cityID}
												showSearch>
												<Option key={'initial-city'} value={employeeEdit.city.ID}>
													{employeeEdit.city.name}
												</Option>
												{cities.map((city) => (
													<Option key={city.ID} value={city.ID}>
														{city.name}
													</Option>
												))}
											</Select>
											<div>
												<span style={{ color: 'red' }}>{error ? (error.errors.cityID ? error.errors.cityID[0] : null) : null}</span>
											</div>
										</Col>

										<Col span={12}>
											<div>
												<span style={{ color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Posisi Karyawan
												</span>
											</div>
											<Select
												onChange={(value) => setEmployeeEdit({ ...employeeEdit, employeePositionID: value })}
												defaultActiveFirstOption={false}
												style={{ marginTop: '8px', width: '100%' }}
												placeholder='Pilih Posisi Karyawan'
												value={employeeEdit.employeePositionID}>
												{employeePosition.data.map((position) => (
													<Option key={position.ID} value={position.ID}>
														{position.name}
													</Option>
												))}
											</Select>

											<div>
												<span style={{ color: 'red' }}>
													{error ? (error.errors.employeePositionID ? error.errors.employeePositionID[0] : null) : null}
												</span>
											</div>
										</Col>
									</Row>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Alamat
										</span>
										<Input.TextArea
											value={employeeEdit.address}
											placeholder='JL. Margona Raya'
											onChange={(e) => setEmployeeEdit({ ...employeeEdit, address: e.target.value })}
										/>
										<span style={{ color: 'red' }}>{error ? (error.errors.address ? error.errors.address[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> NIK
										</span>
										<Input
											type='number'
											value={employeeEdit.NIK}
											onChange={(e) => setEmployeeEdit({ ...employeeEdit, NIK: e.target.value })}
											placeholder='74711234560001'
										/>

										<span style={{ color: 'red' }}>{error ? (error.errors.NIK ? error.errors.NIK[0] : null) : null}</span>
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> NPWP
										</span>
										<Input
											type='text'
											value={employeeEdit.NPWP}
											onChange={(e) => setEmployeeEdit({ ...employeeEdit, NPWP: e.target.value })}
											placeholder='12312324'
										/>

										<span style={{ color: 'red' }}>{error ? (error.errors.NPWP ? error.errors.NPWP[0] : null) : null}</span>
									</div>

									<Row gutter={[16, 16]}>
										<Col span={12}>
											<div>
												<span style={{ color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Agama
												</span>
											</div>
											<Select
												loading={religion.isLoading}
												onChange={(value) => setEmployeeEdit({ ...employeeEdit, religionID: value })}
												defaultActiveFirstOption={false}
												style={{ marginTop: '8px', width: '100%' }}
												placeholder='Pilih Agama'
												value={employeeEdit.religionID}>
												{religion.data.map((reg) => (
													<Option key={reg.ID} value={reg.ID}>
														{reg.name}
													</Option>
												))}
											</Select>

											<div>
												<span style={{ color: 'red' }}>{error ? (error.errors.religionID ? error.errors.religionID[0] : null) : null}</span>
											</div>
										</Col>

										<Col span={12}>
											<div>
												<span style={{ color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Jenis Kelamin
												</span>
											</div>
											<Select
												onChange={(value) => setEmployeeEdit({ ...employeeEdit, gender: value })}
												defaultActiveFirstOption={false}
												style={{ marginTop: '8px', width: '100%' }}
												placeholder='Pilih Jenis Kelamin'
												value={employeeEdit.gender}>
												<Option value={'0'}>Laki - Laki</Option>
												<Option value={'1'}>Perempuan</Option>
											</Select>

											<div>
												<span style={{ color: 'red' }}>{error ? (error.errors.gender ? error.errors.gender[0] : null) : null}</span>
											</div>
										</Col>
									</Row>

									<Form.Item>
										<Row gutter={[16, 16]} justify='end'>
											<Col span={12}>
												<Button
													block
													loading={submitting}
													onClick={() => {
														updateEmployee().then((response) => {
															if (response.code === 200) {
																fetchDetailEmployee(match.params.id).then((res) => {
																	notification.success({ message: 'Sukses Update Karyawan' })
																	fetchEmployeeList()
																})
															}
														})
													}}
													type='primary'>
													Update Karyawan
												</Button>
											</Col>
										</Row>
									</Form.Item>

									{/* ============ */}
									<NIPForm employeeID={match.params.id} />
									<br />
									<BPJSForm employeeID={match.params.id} />
									<br />
									<MendikbudEmailForm employeeID={match.params.id} />
									<br />
									<BloodForm employeeID={match.params.id} />
									<br />
									<CareerForm employeeID={match.params.id} />
									<br />
									<EducationHistoryForm educationTier={educationTier} employeeID={match.params.id} />
									<br />
									<CourseForm employeeID={match.params.id} />
									<br />
									<TrainingForm employeeID={match.params.id} />
									<br />
									<BankForm employeeID={match.params.id} />
									<br />
									<FamilyForm employeeID={match.params.id} />
									<br />
									<ChildrenForm employeeID={match.params.id} />
									<br />
									<EmployeeSpouse employeeID={match.params.id} />
									<br />
									<FatherForm employeeID={match.params.id} />
									<br />
									<MotherForm employeeID={match.params.id} />
								</Form>
							</Col>
						</Row>
					</Card>
				)}
			</Content>
		</Fragment>
	)
}

export default EditEmployee
