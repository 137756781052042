import React, { useContext, useState, Fragment } from 'react'
import { Divider, Row, Col, Button, Input, notification, Select, DatePicker } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'
import moment from 'moment'

import _ from 'lodash'

const { Option } = Select

const FatherForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee, religion, cities, setCities } = employeeContext

	const form = { employeeID, name: '', bornDate: '', bornPlace: '', religionID: '', cityID: '', address: '' }
	const [fatherForm, setFatherForm] = useState(null)
	const [fatherFormAdd, setFatherFormAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.father) {
			setFatherForm(employeeEdit.father)
		}
	}, [employee.data])

	const updateFatherData = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.updateEmployeeFather(fatherForm)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Update Data Ayah' })
					setSubmitting(false)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Update Data Ayah' })
				setSubmitting(false)
			}
		}
	}

	const addFatherData = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.addEmployeeFather(fatherFormAdd)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Update Data Ayah' })
					setSubmitting(false)
					setFatherFormAdd(null)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Update Data Ayah' })
				setSubmitting(false)
			}
		}
	}

	return (
		<div>
			<h1>Data Ayah</h1>

			<Divider />

			{fatherForm ? (
				<Fragment>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Nama Ayah
								</span>
							</div>
							<Input
								placeholder='Masukkan Nama Ayah'
								style={{ marginTop: '8px', width: '100%' }}
								value={fatherForm.name}
								onChange={(e) => setFatherForm({ ...fatherForm, name: e.target.value })}
							/>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tempat Lahir
								</span>
							</div>
							<Select
								filterOption={false}
								onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
								onChange={(val) => setFatherForm({ ...fatherForm, bornPlace: val })}
								placeholder='Pilih Tempat Lahir'
								showSearch
								style={{ marginTop: '8px', width: '100%' }}
								value={fatherForm.bornPlace}>
								{cities.data.map((city) => (
									<Option key={city.ID} value={city.ID}>
										{city.name}
									</Option>
								))}
							</Select>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tanggal Lahir
								</span>
							</div>
							<DatePicker
								allowClear={false}
								format='YYYY-MM-DD'
								style={{ marginTop: '8px', width: '100%' }}
								placeholder='Pilih Tanggal Lahir'
								value={moment(fatherForm.bornDate, 'YYYY-MM-DD')}
								onChange={(date, dateString) => setFatherForm({ ...fatherForm, bornDate: dateString })}
							/>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Agama
								</span>
							</div>
							<Select
								placeholder='Pilih Agama'
								filterOption={false}
								onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
								showSearch
								style={{ marginTop: '8px', width: '100%' }}
								value={fatherForm.religionID}
								onChange={(val) => setFatherForm({ ...fatherForm, religionID: val })}>
								{religion.data.map((re) => (
									<Option key={re.ID} value={re.ID}>
										{re.name}
									</Option>
								))}
							</Select>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Kota
								</span>
							</div>
							<Select
								placeholder='Pilih Kota'
								filterOption={false}
								onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
								showSearch
								style={{ marginTop: '8px', width: '100%' }}
								value={fatherForm.cityID}
								onChange={(val) => setFatherForm({ ...fatherForm, cityID: val })}>
								{cities.data.map((city) => (
									<Option key={city.ID} value={city.ID}>
										{city.name}
									</Option>
								))}
							</Select>
						</Col>

						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Alamat
								</span>
							</div>
							<Input.TextArea
								rows={5}
								placeholder='Masukkan Alamat'
								style={{ marginTop: '8px', width: '100%' }}
								value={fatherForm.address}
								onChange={(e) => setFatherForm({ ...fatherForm, address: e.target.value })}
							/>
						</Col>
					</Row>

					<Row gutter={[16, 16]} justify='end'>
						<Col span={12}>
							<Button block type='primary' onClick={updateFatherData} loading={submitting}>
								Ubah Data Ayah
							</Button>
						</Col>
					</Row>
				</Fragment>
			) : fatherFormAdd ? null : (
				<Col span={24}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setFatherFormAdd(form)}>
						<PlusOutlined /> Tambah Data Ayah
					</Button>
				</Col>
			)}

			{fatherFormAdd ? (
				<Fragment>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Nama Ayah
								</span>
							</div>
							<Input
								placeholder='Masukkan Nama Ayah'
								style={{ marginTop: '8px', width: '100%' }}
								onChange={(e) => setFatherFormAdd({ ...fatherFormAdd, name: e.target.value })}
							/>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tempat Lahir
								</span>
							</div>
							<Select
								filterOption={false}
								onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
								onChange={(val) => setFatherFormAdd({ ...fatherFormAdd, bornPlace: val })}
								placeholder='Pilih Tempat Lahir'
								showSearch
								style={{ marginTop: '8px', width: '100%' }}>
								{cities.data.map((city) => (
									<Option key={city.ID} value={city.ID}>
										{city.name}
									</Option>
								))}
							</Select>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tanggal Lahir
								</span>
							</div>
							<DatePicker
								allowClear={false}
								format='YYYY-MM-DD'
								style={{ marginTop: '8px', width: '100%' }}
								placeholder='Pilih Tanggal Lahir'
								onChange={(date, dateString) => setFatherFormAdd({ ...fatherFormAdd, bornDate: dateString })}
							/>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Agama
								</span>
							</div>
							<Select
								placeholder='Pilih Agama'
								filterOption={false}
								onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
								showSearch
								style={{ marginTop: '8px', width: '100%' }}
								onChange={(val) => setFatherFormAdd({ ...fatherFormAdd, religionID: val })}>
								{religion.data.map((re) => (
									<Option key={re.ID} value={re.ID}>
										{re.name}
									</Option>
								))}
							</Select>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Kota
								</span>
							</div>
							<Select
								placeholder='Pilih Kota'
								filterOption={false}
								onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
								showSearch
								style={{ marginTop: '8px', width: '100%' }}
								onChange={(val) => setFatherFormAdd({ ...fatherFormAdd, cityID: val })}>
								{cities.data.map((city) => (
									<Option key={city.ID} value={city.ID}>
										{city.name}
									</Option>
								))}
							</Select>
						</Col>

						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Alamat
								</span>
							</div>
							<Input.TextArea
								rows={5}
								placeholder='Masukkan Alamat'
								style={{ marginTop: '8px', width: '100%' }}
								onChange={(e) => setFatherFormAdd({ ...fatherFormAdd, address: e.target.value })}
							/>
						</Col>
					</Row>

					<Row gutter={[16, 16]} justify='end'>
						<Col span={12}>
							<Button block type='primary' onClick={addFatherData} loading={submitting}>
								Tambah Data Ayah
							</Button>
						</Col>
					</Row>
				</Fragment>
			) : null}
		</div>
	)
}

export default FatherForm
