import React, { useEffect, useState } from 'react'
import { Modal, Table, Tag } from 'antd'
import LeaveAPI from '../../../services/LeaveAPI'
import { format, parse, add, isValid, sub } from 'date-fns'
import { id } from 'date-fns/locale'

const ModalDetail = ({ date, ...props }) => {
	const [employeeLeave, setEmployeeLeave] = useState({ data: [], isLoading: true, pagination: {} })
	const [parameters, setParameters] = useState({ order: 'desc', pageSize: 20, currentPage: 1 })

	const getEmployeeLeave = async () => {
		try {
			setEmployeeLeave({ ...employeeLeave, isLoading: true })

			const response = await LeaveAPI.getAllemployeeLeave({ startDate: date, endDate: date, ...parameters })
			if (response.code === 200) {
				setEmployeeLeave({ data: response.data.list, isLoading: false, pagination: response.data.pagination })
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (date) {
			getEmployeeLeave()
		}

		return () => {
			setEmployeeLeave({ data: [], isLoading: true, pagination: {} })
			setParameters({ order: 'desc', pageSize: 20, currentPage: 1 })
		}
	}, [date])

	const columns = [
		{
			title: 'Nama Karyawan',
			dataIndex: 'employee',
			key: 'employee',
			render: (data) => <span>{data.name}</span>,
		},
		{
			title: 'Jenis Cuti',
			dataIndex: 'typeLabel',
			key: 'typeLabel',
		},
		{
			title: 'Tanggal Cuti',
			dataIndex: 'employeeLeaveDates',
			key: 'leaveStart',
			render: (data) => <span>{format(parse(data[0].date, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', { locale: id })}</span>,
		},
		{
			title: 'Batas Cuti',
			dataIndex: 'employeeLeaveDates',
			key: 'leaveEnd',
			render: (data) => <span>{format(parse(data[data.length - 1].date, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', { locale: id })}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'employeeLeaveResponse',
			key: 'status',
			render: (data) => (
				<Tag color={data === null ? 'warning' : data.canLeave ? 'green' : 'red'}>{data === null ? 'Pending' : data.canLeave ? 'Disetujui' : 'Ditolak'}</Tag>
			),
		},
	]

	return (
		<Modal {...props} title={'Detail Cuti Tanggal :  ' + date} width='80vw'>
			<Table
				pagination={{
					defaultPageSize: 20,
					pageSize: parameters.pageSize,
					total: employeeLeave.pagination.itemCount ?? 20,
					onChange: (page) => setParameters({ ...parameters, currentPage: page }),
					onShowSizeChange: (current, size) => setParameters({ ...parameters, currentPage: current, pageSize: size }),
					current: parameters.currentPage,
					defaultCurrent: 1,
				}}
				loading={employeeLeave.isLoading}
				columns={columns}
				dataSource={employeeLeave.data.map((leave, index) => ({ ...leave, key: leave.ID }))}
				bordered
			/>
		</Modal>
	)
}

ModalDetail.defaultProps = {
	visible: false,
}

export default ModalDetail
