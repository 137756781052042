import React, { createContext, useState, useEffect } from 'react'
import AbsentAPI from '../services/AbsentApi'
import moment from 'moment'

export const AbsentContext = createContext()
export const AbsentContextConsumer = AbsentContext.Consumer

const AbsentContextProvider = ({ children }) => {
	const [attendance, setAttendance] = useState({ data: [], isLoading: true })
	const [parameters, setParameters] = useState({ date: moment().format('YYYY-MM-DD') })

	const getAttendance = async () => {
		try {
			const response = await AbsentAPI.getAllemployeeAttendant(parameters)
			setAttendance({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getAttendance()
	}, [parameters])

	return (
		<AbsentContext.Provider
			value={{
				attendance,
				setAttendance,
				parameters,
				setParameters,
			}}>
			{children}
		</AbsentContext.Provider>
	)
}

export default AbsentContextProvider
