import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Layout, Col, Row, Card, Tabs, Button, Divider, Spin, Tag, notification } from 'antd'
import { CustomHeader } from '../../components'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './styles.module.css'
import PersonalData from './components/PersonalData'
import { EmployeeContext } from '../../context/EmployeeContext'
import { LeaveContext } from '../../context/LeaveContext'

import { Link } from 'react-router-dom'
import CareerData from './components/CareerData'
import API from '../../services/API'
import FamilyData from './components/FamilyData'
import BPJScard from './components/BPJScard'
import BankCard from './components/BankCard'
import EmployeeLeave from './components/EmployeeLeave'
import EmployeeHeader from './components/EmployeeHeader'
import EducationHistory from './components/EducationHistory'
import queryString from 'query-string'

const { Content } = Layout
const { TabPane } = Tabs

const Detail = ({ match, location, history }) => {
	const employeeContext = useContext(EmployeeContext)
	const leaveContext = useContext(LeaveContext)

	const query = queryString.parse(location.search)

	const { fetchDetailEmployee, employee, submitting, error } = employeeContext
	const { fetchEmployeeLeave, employeeLeave } = leaveContext

	const [activeTab, setActiveTab] = useState(query?.tab ?? 'personal')

	useEffect(() => {
		fetchDetailEmployee(match.params.id)
		fetchEmployeeLeave({ order: 'desc', pageSize: 20, employeeID: match.params.id })
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Employee' />

			<Content style={{ background: '#f8f9fa', padding: '16px', paddingLeft: '32px', paddingRight: '32px' }}>
				{employee.isLoading ? (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Spin size='large' />
					</div>
				) : (
					<Fragment>
						<Card className='__main_card_table'>
							<Row gutter={[16, 16]} align='middle'>
								<Col span={14} style={{ borderRight: '1px solid #f0f0f0' }}>
									<Row align='middle'>
										<Col span={8}>
											<img
												className={styles['_employee-avatar']}
												src={
													employee.data.employeeAvatar?.realPath ??
													'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
												}
												alt='employee avatar'
											/>
										</Col>

										<Col span={14} style={{ marginLeft: '50px' }}>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<div style={{ display: 'flex' }}>
													<h1 style={{ fontSize: '24px', color: '#141414' }}>{employee.data.name}</h1>
												</div>

												<div style={{ display: 'flex' }}>
													{employee.data.NIK ? (
														<Tag color='#87d068'>NIK : {employee.data.NIK}</Tag>
													) : (
														<Tag>
															<PlusOutlined /> Isi NIK
														</Tag>
													)}

													{/* NIP */}

													{employee.data.NIP ? (
														<Tag color='#2db7f5'>NIP : {employee.data.NIP.number}</Tag>
													) : (
														<Tag>
															<PlusOutlined /> Isi NIP
														</Tag>
													)}
												</div>

												<div style={{ display: 'flex', flexDirection: 'row' }}>
													{!employee.data.employeeAvatar ? (
														<div style={{ display: 'flex', flexDirection: 'column' }}>
															<label
																htmlFor='avatar-upload'
																className={`ant-btn ${styles['_edit-btn-photo']} ${submitting ? 'ant-btn-loading' : ''} `}>
																{submitting && <LoadingOutlined />} Tambah Foto
															</label>
															<input
																disabled={submitting}
																accept='.jpg,.jpeg,.png'
																style={{ display: 'none' }}
																type='file'
																name=''
																id='avatar-upload'
																onChange={(e) =>
																	API.addEmployeeAvatar({ image: e.target.files[0], employeeID: match.params.id })
																		.then(() => {
																			fetchDetailEmployee(match.params.id).then((res) => {
																				notification.success({ message: 'Sukses Upload Foto' })
																			})
																		})
																		.catch((e) => notification.error({ message: 'Gagal Upload Foto' }))
																}
															/>
															<span style={{ color: 'red' }}>{error ? (error.image ? error.image[0] : null) : null}</span>
														</div>
													) : (
														<div style={{ display: 'flex', flexDirection: 'column' }}>
															<label
																htmlFor='avatar-upload'
																className={`ant-btn ${styles['_edit-btn-photo']} ${submitting ? 'ant-btn-loading' : ''} `}>
																{submitting && <LoadingOutlined />} Ubah Foto
															</label>
															<input
																disabled={submitting}
																accept='.jpg,.jpeg,.png'
																style={{ display: 'none' }}
																type='file'
																name=''
																id='avatar-upload'
																onChange={(e) =>
																	API.updateEmployeeAvatar({ image: e.target.files[0], employeeID: match.params.id })
																		.then(() => {
																			fetchDetailEmployee(match.params.id).then((res) => {
																				notification.success({ message: 'Sukses Upload Foto' })
																			})
																		})
																		.catch((e) => notification.error({ message: 'Gagal Upload Foto' }))
																}
															/>
															<span style={{ color: 'red' }}>{error ? (error.image ? error.image[0] : null) : null}</span>
														</div>
													)}
													<Link to={`${match.params.id}/edit`}>
														<Button size='middle' className={styles['_edit-btn']} style={{ marginLeft: '16px' }}>
															Ajukan Perubahan Data
														</Button>
													</Link>
												</div>
											</div>
										</Col>
									</Row>
								</Col>

								<EmployeeHeader employee={employee} />
							</Row>
						</Card>

						<Tabs
							defaultActiveKey={activeTab}
							activeKey={activeTab}
							onChange={(key) => {
								setActiveTab(key)
								history.push({ search: `?tab=${key}` })
							}}
							style={{ marginTop: '16px', padding: '16px' }}>
							<TabPane tab='Data Personal' key='personal'>
								<Row gutter={[16, 16]}>
									<Col span={6}>
										<Card className='__main_card_table' style={{ marginTop: '0' }}>
											<div>
												<h1>Status Cuti</h1>
												<Divider />
												<EmployeeLeave id={match.params.id} employeeLeave={employeeLeave} />
											</div>
										</Card>
									</Col>

									<Col span={12}>
										<PersonalData employee={employee.data} />

										<FamilyData employee={employee} />
									</Col>

									<Col span={6}>
										<BankCard employee={employee} />

										<BPJScard employee={employee} />
									</Col>
								</Row>
							</TabPane>

							<TabPane tab='Karir' key='career'>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<EducationHistory employee={employee} />
									</Col>

									<Col span={12}>
										<CareerData employee={employee} />
									</Col>
								</Row>
							</TabPane>
						</Tabs>
					</Fragment>
				)}
			</Content>
		</Fragment>
	)
}

const style = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: 16,
	textAlign: 'center',
}

export default Detail
