import React, { Fragment, useEffect, useState, useContext } from 'react'
import { CustomHeader } from '../../components'
import { Card, Layout, Descriptions, Divider, Table, Button, Tooltip, Popconfirm, DatePicker, notification, Col, Row } from 'antd'
import { ArrowLeftOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import LeaveAPI from '../../services/LeaveAPI'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { format, parse, add, isValid, sub } from 'date-fns'
import { LeaveContext } from '../../context/LeaveContext'

const today = format(new Date(), 'yyyy-MM-dd')

const { Content } = Layout

const Detail = ({ history, match }) => {
	const leaveContext = useContext(LeaveContext)
	const { fetchEmployeeLeave } = leaveContext

	const [detailLeave, setDetailLeave] = useState({ data: {}, isLoading: true })
	const [submitting, setSubmitting] = useState(false)
	const [selectedDates, setSelectedDates] = useState([])

	const getDetailLeave = async () => {
		try {
			const response = await LeaveAPI.getDetailLeave(match.params.id)
			setDetailLeave({ data: response.data, isLoading: false })
		} catch (error) {
			console.log(error)
		}
	}

	const onCreateLeave = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				const body = { createdDates: selectedDates }
				await LeaveAPI.createOrUpdateLeave(match.params.id, body)
				notification.success({ message: 'Sukses Menambahkan Cuti' })
				fetchEmployeeLeave({ order: 'desc', pageSize: 20, employeeID: match.params.id })
				getDetailLeave()
				setSubmitting(false)
				setSelectedDates([])
			} catch (error) {
				notification.error({ message: 'Gagal Menambahkan Cuti' })
				setSubmitting(false)
			}
		}
	}

	useEffect(() => {
		getDetailLeave()
	}, [])

	const columns = [
		{
			title: 'Tanggal Cuti',
			dataIndex: 'date',
			align: 'center',
			key: 'date',
			render: (text) => <span>{moment(text, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>,
		},
		{
			title: 'Action',
			align: 'center',
			render: (record) => (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Fragment>
						<Tooltip placement='bottom' title='Hapus Tanggal Cuti'>
							<Popconfirm
								title='Yakin Hapus Cuti?'
								onConfirm={async () => {
									if (!submitting) {
										setSubmitting(true)
										try {
											await LeaveAPI.createOrUpdateLeave(match.params.id, { removes: record.date })
											notification.success({ message: 'Sukses Hapus Cuti' })
											fetchEmployeeLeave({ order: 'desc', pageSize: 20, employeeID: match.params.id })
											getDetailLeave()
											setSubmitting(false)
											setSelectedDates([])
										} catch (error) {
											notification.error({ message: 'Gagal Hapus Cuti' })
											setSubmitting(false)
										}
									}
								}}>
								<Button
									loading={submitting}
									style={{ marginLeft: '6px' }}
									icon={<DeleteOutlined style={{ color: 'red' }} />}
									size='small'
									danger
									onClick={() => console.log(record.date, match.params.id)}
								/>
							</Popconfirm>
						</Tooltip>
					</Fragment>
				</div>
			),
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Detail Cuti' />
			<Content style={{ background: '#f8f9fa', padding: '16px', paddingLeft: '120px', paddingRight: '120px' }}>
				<Card className='__main_card_table' bordered={false} style={{ minHeight: '630px' }}>
					<ArrowLeftOutlined onClick={() => history.goBack()} />
					<br />
					<br />
					{detailLeave.isLoading ? null : (
						<Fragment>
							<h1>Data Karyawan</h1>
							<Descriptions bordered>
								<Descriptions.Item label='Nama'>{detailLeave.data.employee.name}</Descriptions.Item>
								<Descriptions.Item label='Email'>{detailLeave.data.employee.email}</Descriptions.Item>
								<Descriptions.Item label='Jenis Cuti'>{detailLeave.data.typeLabel}</Descriptions.Item>
							</Descriptions>
							<Divider />

							<Row gutter={[16, 16]} justify='space-around'>
								<Col span={6}>
									<div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 8px' }}>
										<h1>Tambah Cuti</h1>
										<Button disabled={selectedDates.length === 0} loading={submitting} onClick={onCreateLeave} type='primary' size='small'>
											Tambah
										</Button>
									</div>

									<DatePicker
										allowClear={false}
										style={{ width: '100%', opacity: 0, height: 0 }}
										autoFocus
										open
										onSelect={(date) => {
											let dateString = date.format('YYYY-MM-DD')
											if (selectedDates.includes(dateString)) {
												const tmp = selectedDates.filter((d) => d !== dateString)
												setSelectedDates(tmp)
											} else {
												setSelectedDates([...selectedDates, dateString])
											}
										}}
										placeholder={['Awal Cuti', 'Akhir Cuti']}
										format='YYYY-MM-DD'
										size='small'
										disabledDate={(date) => {
											return (
												detailLeave.data.employeeLeaveDates.filter((employeDate) => employeDate.date === date.format('YYYY-MM-DD')).length > 0 ||
												date.format('dddd') === 'Saturday' ||
												date.format('dddd') === 'Sunday' ||
												date.isBefore(today, 'days')
											)
										}}
										dateRender={(date) => (
											<div className='ant-picker-cell-inner'>
												{date.format('D')}
												{selectedDates.filter((d) => d === date.format('YYYY-MM-DD')).length > 0 ? <span role='img'>✅</span> : null}
											</div>
										)}
									/>
								</Col>
								<Col span={16}>
									<div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0 8px' }}>
										<h1>Tanggal Cuti</h1>
									</div>
									<br />
									<Table dataSource={detailLeave.data.employeeLeaveDates} bordered columns={columns} pagination={false} />
								</Col>
							</Row>
						</Fragment>
					)}
				</Card>
			</Content>
		</Fragment>
	)
}

export default Detail
