import React, { createContext, useState, useEffect, useContext } from 'react'
import API from '../services/API'
import showDown from 'showdown'
import TurnDown from 'turndown'
import { AuthContext } from './AuthContext'

export const ProgramContext = createContext()
export const ProgramContextConsumer = ProgramContext.Consumer

const ProgramContextProvider = ({ children }) => {
	const authContext = useContext(AuthContext)
	const { userID, setUserID } = authContext
	const turndownService = new TurnDown()
	const [programList, setProgramList] = useState({ data: [], isLoading: true, pagination: {} })
	const [program, setProgram] = useState({ data: {}, isLoading: true })
	const [discuss, setDiscuss] = useState({
		programID: '',
		comment: '',
		isAdmin: 1,
	})

	const [discussReply, setDiscussReply] = useState({
		discussID: '',
		comment: '',
		isAdmin: 1,
	})

	const [programPhaseList, setProgramPhaseList] = useState({ data: [], isLoading: true, pagination: {} })

	const [discussList, setDiscussList] = useState({ data: [], isLoading: true, pagination: {} })

	const [programDetailDesc, setProgramDetailDesc] = useState('')

	const [query, setQuery] = useState({ order: 'asc', sort: 'name', pageSize: 20, currentPage: 1, keyword: '' })
	const [queryProgramPhase, setQueryProgramPhase] = useState({ applicantID: '', order: 'desc', sort: 'ID', pageSize: 0, currentPage: 1 })
	const [queryDiscuss, setQueryDiscuss] = useState({ programID: '', order: 'desc', sort: 'ID', pageSize: 0, currentPage: 1 })

	const [activeApplicant, setActiveApplicant] = useState({ data: [], isLoading: true })

	const [submitting, setSubmitting] = useState(false)

	const [error, setError] = useState(null)

	const [programForm, setProgramForm] = useState({
		name: '',
		image: '',
		description: '',
		detail: '',
		nominal: '',
		expired: '',
		start: '',
		isPublished: '',

	})
	const [programPhaseForm, setProgramPhaseForm] = useState({
		applicantID: '',
		receiptFile: '',
		deadline: '',
		nominal: '',
	})

	const [programReq, setProgramReq] = useState({ data: [], isLoading: true })
	const [institutionReq, setInstitutionReq] = useState({ data: [], isLoading: true })
	const [programReqParams, setProgramReqParams] = useState({})

	const fetchProgramReq = async () => {
		try {
			const response = await API.getProgramReqruitment(programReqParams)
			setProgramReq({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchInstitutionReq = async () => {
		try {
			const response = await API.getProgramReqruitment(programReqParams)
			setInstitutionReq({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchProgramReq()
		fetchInstitutionReq()
	}, [programReqParams])

	const fetchProgramList = async () => {
		try {
			setProgramList({ ...programList, isLoading: true })
			const response = await API.getAllProgram(query)

			setProgramList({
				data: response.data.list,
				pagination: response.data.pagination,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchProgramList()
	}, [query])

	const getProgramDetail = (ID) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.detailProgram(ID)
					setSubmitting(false)
					setError(null)
					setProgram({ data: response.data, isLoading: false })
					setProgramDetailDesc(new showDown.Converter().makeHtml(response.data.detail))
					setQueryDiscuss({ ...queryDiscuss, programID: ID })

					setDiscuss({
						...discuss,
						programID: ID,
					})

					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}
	useEffect(() => {
		fetchProgramPhaseList()
	}, [queryProgramPhase])

	useEffect(() => {
		fetchDiscuss()
	}, [queryDiscuss])

	const fetchProgramPhaseList = async () => {
		try {
			const response = await API.getAllProgramPhase(queryProgramPhase)

			setProgramPhaseList({
				data: response.data.list,
				pagination: response.data.pagination,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const createProgram = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.createProgram({ ...programForm, detail: turndownService.turndown(programForm.detail) })
					setSubmitting(false)
					setError(null)
					setProgramForm({
						name: '',
						image: '',
						description: '',
						detail: '',
						nominal: '',
					})
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const updateProgram = (programID, body = {}) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.updateProgram(programID, body)
					setSubmitting(false)
					setError(null)
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const deleteProgram = (ID) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.deleteProgram(ID)
					setSubmitting(false)
					setError(null)
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const createProgramPhase = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.createProgramPhase(programPhaseForm)
					setSubmitting(false)
					setError(null)
					setProgramPhaseForm({
						applicantID: '',
						receiptFile: '',
						deadline: '',
						nominal: '',
					})
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const updateProgramPhase = (programPhaseID, body = {}) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.updateProgramPhase(programPhaseID, body)
					setSubmitting(false)
					setError(null)
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const deleteProgramPhase = (programPhaseID) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.deleteProgramPhase(programPhaseID)
					setSubmitting(false)
					setError(null)
					fetchProgramPhaseList()
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const applyInstitution = (applicantID, confirmed, reason = null) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.confirmApplicant(applicantID, confirmed, reason)
					setSubmitting(false)
					setError(null)
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const fetchDiscuss = async () => {
		try {
			const response = await API.getDiscusses(queryDiscuss)

			setDiscussList({
				data: response.data.list,
				pagination: response.data.pagination,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchApplicant = async (body = {}) => {
		try {
			const response = await API.getListApplicant(body)

			setActiveApplicant({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const createDiscuss = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.createDiscuss({ ...discuss, accountID: userID })
					setSubmitting(false)
					setError(null)
					setDiscuss({
						...discuss,
						comment: '',
					})
					fetchDiscuss()
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const createDiscussReply = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.createReplyDiscuss({ ...discussReply, accountID: userID })
					setSubmitting(false)
					setError(null)
					setDiscussReply({
						...discussReply,
						comment: '',
					})
					fetchDiscuss()
					resolve(response)
				} catch (error) {
					console.log(error)
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	return (
		<ProgramContext.Provider
			value={{
				programList,
				setProgramList,

				programPhaseList,
				setProgramPhaseList,

				discussList,
				setDiscussList,

				discuss,
				setDiscuss,

				query,
				setQuery,

				queryProgramPhase,
				setQueryProgramPhase,

				programForm,
				setProgramForm,

				programPhaseForm,
				setProgramPhaseForm,

				program,
				setProgram,

				submitting,
				setSubmitting,

				queryDiscuss,
				setQueryDiscuss,

				activeApplicant,
				setActiveApplicant,

				fetchApplicant,

				error,
				setError,

				fetchProgramList,
				getProgramDetail,
				fetchProgramPhaseList,
				createProgram,
				updateProgram,
				deleteProgram,
				createProgramPhase,
				updateProgramPhase,
				deleteProgramPhase,
				applyInstitution,
				fetchDiscuss,
				createDiscuss,

				programDetailDesc,
				setProgramDetailDesc,

				discussReply,
				setDiscussReply,

				createDiscussReply,

				programReqParams,
				setProgramReqParams,
				programReq,
				setProgramReq,

				institutionReq,
				setInstitutionReq,

				fetchProgramReq,
			}}>
			{children}
		</ProgramContext.Provider>
	)
}

export default ProgramContextProvider
