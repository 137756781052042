import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../../components'
import { Layout, Card, Progress, Spin, Row, Col, Button, Tooltip, Select, Form, Modal, Input, notification, Divider, Popconfirm, Space } from 'antd'
import thousandSeparator from '../../../utils/thousandSeparator'

import { QuestionCircleOutlined, MinusCircleOutlined, LoadingOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import MasterAPI from '../../../services/MasterAPI'
import NumberFormat from 'react-number-format'
import API from '../../../services/API'

const { Content } = Layout

const { Option } = Select

const UpdateSubcomponentActivity = ({ match, history }) => {
	const calcField = { detailActivityID: parseInt(match.params.id), unitID: '', unitValue: 1 }

	const [budgetAccount, setBudgetAccount] = useState({ data: [], isLoading: true })
	const [detailActivity, setDetailActivity] = useState({ data: {}, isLoading: true })
	const [budgetAccountSearch, setBudgetAccountSearch] = useState([])
	const [units, setUnits] = useState({ data: [], isLoading: true })
	const [unitSearch, setUnitSearch] = useState([])

	const [calculationFields, setCalculationFields] = useState([])
	const [calculationFieldsAdd, setCalculationFieldsAdd] = useState([])

	const [submitting, setSubmitting] = useState(false)

	const [loadingDelete, setLoadingDelete] = useState(false)

	const [formValue, setFormValue] = useState({
		subComponentID: '',
		unitID: '',
		budgetAccountID: '',
		name: '',
		unitValue: '',
		price: '',
	})

	const fetchBudgetAccount = async () => {
		try {
			setBudgetAccount({ ...budgetAccount, isLoading: true })
			const response = await MasterAPI.getBudgetAccount()

			setBudgetAccount({
				isLoading: false,
				data: response.data.list,
			})

			setBudgetAccountSearch(response.data.list)
		} catch (error) {
			console.log(error)
		}
	}

	const fetchDetailActivity = async () => {
		try {
			setDetailActivity({ ...detailActivity, isLoading: true })
			const response = await API.detailActivity(match.params.id)
			if (response.code === 200) {
				setFormValue({
					subComponentID: response.data.subComponent.ID,
					unitID: response.data.unitID,
					budgetAccountID: response.data.budgetAccountID,
					name: response.data.name,
					unitValue: response.data.unitValue,
					price: response.data.price,
				})

				setCalculationFields(response.data.calculations)
				setDetailActivity({
					data: response.data,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchUnit = async () => {
		try {
			setUnits({ ...units, isLoading: true })
			const response = await MasterAPI.getUnits()

			setUnits({
				isLoading: false,
				data: response.data.list,
			})

			setUnitSearch(response.data.list)
		} catch (error) {
			console.log(error)
		}
	}

	const onSearchBudget = (val) => {
		let temp = [...budgetAccount.data]
		let search = temp.filter((data) => `${data.name}`.toLowerCase().includes(`${val}`.toLowerCase()))
		setBudgetAccountSearch(search)
	}

	const onSearchUnit = (val) => {
		let temp = [...units.data]
		let search = temp.filter((data) => `${data.name}`.toLowerCase().includes(`${val}`.toLowerCase()))
		setUnitSearch(search)
	}

	// const onValuesChange = (val, allVal) => {
	// 	if (typeof val.calculations !== 'undefined') {
	// 		setCalculationFields(allVal.calculations)
	// 	}
	// }

	const onFinish = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				const response = await API.updateActivity(formValue, match.params.id)
				if (response.code === 200) {
					//Add Ca
					if (calculationFieldsAdd.length > 0) {
						for (let i of calculationFieldsAdd) {
							await API.addCalculations(i)
						}
					}

					//Update Calculation
					if (calculationFields.length > 0) {
						for (let i of calculationFields) {
							await API.updateCalculations(i, i.ID)
						}
					}

					setCalculationFieldsAdd([])
					notification.success({ message: 'Sukses Edit data' })
					fetchDetailActivity()
					setSubmitting(false)
				}
			} catch (error) {
				notification.error({ message: 'Gagal Edit data' })
				console.log(error)
				setSubmitting(false)
			}
		}

		// console.log(formValue)
	}

	useEffect(() => {
		fetchBudgetAccount()
		fetchUnit()
		fetchDetailActivity()
	}, [])

	const removeCalculation = (id) => {
		const tmp = calculationFields.filter((calc) => calc.ID !== id)
		setCalculationFields(tmp)
	}

	const removeCalculationEdit = (id) => {
		const tmp = calculationFieldsAdd.filter((calc, index) => index !== id)
		setCalculationFieldsAdd(tmp)
	}

	const warning = () => {
		if (!detailActivity.isLoading) {
			let field = calculationFields.concat(calculationFieldsAdd)

			let totalCalc = field.reduce((acc, curr) => parseInt(acc) * parseInt(curr.unitValue), 1)
			if (totalCalc !== parseInt(formValue.unitValue)) {
				return true
			}
			return false
		}
		return false
	}

	/**
	 * @returns {boolean}
	 */
	const disabled = () => {
		if (detailActivity.isLoading) {
			return true
		}

		if (calculationFieldsAdd.length === 0) {
			return false
		}

		let status = false

		if (!detailActivity.isLoading && calculationFieldsAdd.length > 0) {
			calculationFieldsAdd.forEach((calc) => {
				if (`${calc.unitID}`.trim().length === 0) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	const onChangeCalculationUnit = (value, props) => {
		const index = props['data-idx']
		let tmp = [...calculationFields]
		tmp[index][props.name] = value
		setCalculationFields(tmp)
	}

	const onChangeCalculationInput = (event) => {
		let tmp = [...calculationFields]
		tmp[event.target.dataset.idx][event.target.name] = event.target.value
		setCalculationFields(tmp)
	}

	const onChangeCalculationUnitEdit = (value, props) => {
		const index = props['data-idx']
		let tmp = [...calculationFieldsAdd]
		tmp[index][props.name] = value
		setCalculationFieldsAdd(tmp)
	}

	const onChangeCalculationInputEdit = (event) => {
		let tmp = [...calculationFieldsAdd]
		tmp[event.target.dataset.idx][event.target.name] = event.target.value
		setCalculationFieldsAdd(tmp)
	}

	return (
		<Fragment>
			<CustomHeader title='Update Activity' />
			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				{detailActivity.isLoading ? (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Spin size='large' />
					</div>
				) : (
					<Card className='__main_card_table' bordered={false}>
						{/* <button onClick={() => history.push('/budget/2020/4264.970.001.051')}>aa</button> */}
						<Row gutter={[0, 16]} align='middle' justify='start'>
							<Col>
								<Button style={{ borderRadius: '4px' }} type='link' icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
									Kembali
								</Button>
							</Col>
						</Row>
						<Row gutter={[32, 16]} justify='center' align='middle'>
							<Col span={12}>
								<h1>Update Data</h1>
								<Divider />

								<Form
									layout='vertical'
									// onValuesChange={onValuesChange}
									onFinish={onFinish}>
									{budgetAccount.isLoading ? null : (
										<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> Akun Anggaran
											</span>
											<Select
												onSelect={(val) => setFormValue({ ...formValue, budgetAccountID: val })}
												showSearch
												filterOption={false}
												value={formValue.budgetAccountID}
												onSearch={onSearchBudget}
												placeholder='Pilih Akun Anggaran'
												loading={budgetAccount.isLoading}>
												{budgetAccountSearch.map((budget) => (
													<Option value={budget.ID} key={budget.ID}>{`${budget.code} - ${budget.name}`}</Option>
												))}
											</Select>
											{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
										</div>
									)}
									{/* <Form.Item label='Nama' name='name' rules={[{ required: true, message: 'Nama tidak boleh kosong' }]}> */}
									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Nama
										</span>
										<Input.TextArea
											value={formValue.name}
											onChange={({ target: { value } }) => setFormValue({ ...formValue, name: value })}
											placeholder='Contoh: Administrasi, surat menyurat, penggandaan, foto copy dan Pelaporan'
										/>
										{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
									</div>
									{units.isLoading ? null : (
										<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> Satuan
											</span>
											<Select
												onSelect={(val) => setFormValue({ ...formValue, unitID: val })}
												showSearch
												filterOption={false}
												onSearch={onSearchUnit}
												placeholder='Pilih Satuan'
												value={formValue.unitID}
												loading={budgetAccount.isLoading}>
												{unitSearch.map((unit) => (
													<Option value={unit.ID} key={unit.ID}>{`${unit.name}`}</Option>
												))}
											</Select>
											{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
										</div>
									)}
									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Volume
										</span>
										<NumberFormat
											value={formValue.unitValue}
											placeholder='Contoh: 100'
											onValueChange={(e) => setFormValue({ ...formValue, unitValue: e.value })}
											className='ant-input'
											thousandSeparator={'.'}
											decimalSeparator={','}
											prefix={''}
										/>
										{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
									</div>

									<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
										<span style={{ marginBottom: '8px', color: 'black' }}>
											<span style={{ color: 'red' }}>*</span> Harga
										</span>
										<NumberFormat
											placeholder='Contoh: 10.000.000'
											onValueChange={(e) => setFormValue({ ...formValue, price: e.value })}
											className='ant-input'
											thousandSeparator={'.'}
											decimalSeparator={','}
											prefix={'Rp. '}
											value={formValue.price}
										/>
										{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
									</div>
									<Divider />

									{calculationFields.map((calculation, index) => (
										<Space key={calculation.ID} style={{ display: 'flex', marginBottom: 8 }} align='center'>
											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Satuan
												</span>
												<Select
													onChange={onChangeCalculationUnit}
													value={calculation.unitID}
													showSearch
													filterOption={false}
													onSearch={onSearchUnit}
													placeholder='Pilih Satuan'
													loading={budgetAccount.isLoading}>
													{unitSearch.map((unit) => (
														<Option name='unitID' value={unit.ID} data-idx={index} key={unit.ID}>{`${unit.name}`}</Option>
													))}
												</Select>

												{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Volume
												</span>
												<Input
													onChange={onChangeCalculationInput}
													value={calculation.unitValue}
													min='1'
													step='1'
													pattern='[0-9]'
													type='number'
													name='unitValue'
													data-idx={index}
													placeholder='Contoh: 100'
												/>

												{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
											</div>

											<Popconfirm
												disabled={loadingDelete}
												okType='dashed'
												onConfirm={() => {
													setLoadingDelete(true)
													API.deleteCalculation(calculation.ID)
														.then((response) => {
															removeCalculation(calculation.ID)
															notification.success({ message: 'Sukses Hapus Data' })
															setLoadingDelete(false)
														})
														.catch((e) => {
															setLoadingDelete(false)
															notification.error({ message: 'Gagal Hapus Data' })
														})
												}}
												title={<span>Apakah anda yakin untuk menghapus data ?</span>}
												icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
												{loadingDelete ? <LoadingOutlined /> : <MinusCircleOutlined />}
											</Popconfirm>
										</Space>
									))}

									{calculationFieldsAdd.map((calculation, index) => (
										<Space key={index} style={{ display: 'flex', marginBottom: 8 }} align='center'>
											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Satuan
												</span>
												<Select
													onChange={onChangeCalculationUnitEdit}
													value={calculation.unitID}
													showSearch
													filterOption={false}
													onSearch={onSearchUnit}
													placeholder='Pilih Satuan'
													loading={budgetAccount.isLoading}>
													{unitSearch.map((unit) => (
														<Option value={unit.ID} data-idx={index} name='unitID' key={unit.ID}>{`${unit.name}`}</Option>
													))}
												</Select>

												{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
											</div>

											<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> Volume
												</span>
												<Input
													onChange={onChangeCalculationInputEdit}
													name='unitValue'
													data-idx={index}
													value={calculation.unitValue}
													min='1'
													step='1'
													pattern='[0-9]'
													type='number'
													placeholder='Contoh: 100'
												/>

												{/* <span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span> */}
											</div>

											<MinusCircleOutlined onClick={() => removeCalculationEdit(index)} />
										</Space>
									))}

									<span style={{ color: 'red' }}>{warning() ? 'Total Volume Kalkulasi harus sama dengan Total Volume Anggaran' : null}</span>

									<Button
										style={{ marginBottom: '16px' }}
										type='dashed'
										onClick={() => {
											setCalculationFieldsAdd([...calculationFieldsAdd, { ...calcField }])
										}}
										block>
										<PlusOutlined /> Tambah Detail Kalkulasi
									</Button>
									<Form.Item>
										<Button disabled={warning() || disabled()} loading={submitting} htmlType='submit' type='primary' block>
											Submit
										</Button>
									</Form.Item>
								</Form>
							</Col>
						</Row>
					</Card>
				)}
			</Content>
		</Fragment>
	)
}

export default UpdateSubcomponentActivity
