import React, { Fragment, useState, useEffect } from 'react'

import { Layout, Col, Row, Input, Card, Table, Menu, Modal, Button, Radio, Select, notification } from 'antd'
import { format, parse } from 'date-fns'
import { id } from 'date-fns/locale'
import { DeleteFilled, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { CustomHeader } from '../../components'
import { Link } from 'react-router-dom'
import moment from 'moment'

import _ from 'lodash'
import API from '../../services/API'

const { Content } = Layout
const { Search } = Input
const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const Institution = () => {
	const [listInstitutions, setListInstitution] = useState({ data: [], isLoading: true, pagination: {} })
	const [institutionQuery, setInstitutionQuery] = useState({ order: 'asc', sort: 'name', pageSize: 20, currentPage: 1, keyword: '' })
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [selectedData, setSelectedData] = useState(null)
	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)

	const fetchListInstitution = async () => {
		const response = await API.getAllInstitution(institutionQuery)

		setListInstitution({
			data: response.data.list,
			pagination: response.data.pagination,
			isLoading: false,
		})
	}

	const onDelete = async () => {
		if (!loadingDelete) {
			if (selectedData !== null) {
				try {
					setLoadingDelete(true)
					await API.deleteInstitution(selectedData.ID)
					fetchListInstitution()
					setLoadingDelete(false)
					setSelectedData(null)
					notification.success({ message: 'Sukses Hapus Institutsi' })
					setModalDeleteVisible(false)
				} catch (error) {
					setLoadingDelete(false)
					notification.error({ message: 'Gagal Hapus Institutsi' })
				}
			}
		}
	}

	useEffect(() => {
		fetchListInstitution()
	}, [institutionQuery])

	const columns = IS_ADMIN
		? [
			{
				title: 'Nama',
				dataIndex: 'name',
				align: 'center',
			},
			{
				title: 'Manager',
				dataIndex: 'managerName',
				align: 'center',
			},

			{
				title: 'Email',
				dataIndex: 'email',
				align: 'center',
			},

			{
				title: 'Nomor Telepon',
				dataIndex: 'phone',
				align: 'center',
			},

			{
				title: 'KTP',
				dataIndex: 'institutionIdentification',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<a href={item.realPath} target='_blank'>
									<Button size='small'>Lihat Berkas</Button>
								</a>
							)}
					</div>
				),
			},
			{
				title: 'No. Rekening',
				dataIndex: 'institutionBank',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<Fragment>
									<p>{item.bank.name}</p>
									<p>{item.number}</p>
									{item.pathFile && (
										<a href={item.pathFile} target='_blank'>
											<Button size='small'>Lihat Berkas</Button>
										</a>
									)}
								</Fragment>
							)}
					</div>
				),
			},

			{
				title: 'NPWP',
				dataIndex: 'institutionNPWP',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<Fragment>
									<p>{item.number}</p>
									<a href={item.realPath} target='_blank'>
										<Button size='small'>Lihat Berkas</Button>
									</a>
								</Fragment>
							)}
					</div>
				),
			},

			{
				title: 'Akta / SK pendirian / Perubahan terakhir lembaga',
				dataIndex: 'institutionLegal',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<Fragment>
									<div>Tanggal : {item.date}</div>

									<div>Nomor Akta : {item.number}</div>

									<a href={item.realPath} target='_blank'>
										<Button size='small'>Lihat Berkas Legal</Button>
									</a>
								</Fragment>
							)}
					</div>
				),
			},

			{
				title: 'Tanggal Mendaftar',
				dataIndex: 'created',
				align: 'center',
				render: (text) => (
					<div>
						<div style={{ padding: 0, fontSize: '16px' }}>{moment(text, 'YYYY-MM-DD HH:mm:ss').format('YYYY')}</div>
						<div>{moment(text, 'YYYY-MM-DD HH:mm:ss').format('D MMM')}</div>
						<div>{moment(text, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</div>
					</div>
				),
			},
			{
				title: 'Action',
				align: 'center',
				render: (data) => {
					console.log(data)

					return (
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
							<Button
								onClick={() => {
									setSelectedData(data)
									setModalDeleteVisible(true)
								}}
								style={{ marginRight: '6px' }}
								style={{ opacity: 0.5 }}
								size='small'
								type='dashed'
								danger
								icon={<DeleteFilled />}
							/>
						</div>
					)
				},
			},
		]
		: [
			{
				title: 'Nama',
				dataIndex: 'name',
				align: 'center',
			},
			{
				title: 'Manager',
				dataIndex: 'managerName',
				align: 'center',
			},

			{
				title: 'Email',
				dataIndex: 'email',
				align: 'center',
			},

			{
				title: 'Nomor Telepon',
				dataIndex: 'phone',
				align: 'center',
			},

			{
				title: 'KTP',
				dataIndex: 'institutionIdentification',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<a href={item.realPath} target='_blank'>
									<Button size='small'>Lihat Berkas</Button>
								</a>
							)}
					</div>
				),
			},
			{
				title: 'No. Rekening',
				dataIndex: 'institutionBank',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<Fragment>
									<p>{item.bank.name}</p>
									<p>{item.number}</p>
									{item.pathFile && (
										<a href={item.pathFile} target='_blank'>
											<Button size='small'>Lihat Berkas</Button>
										</a>
									)}
								</Fragment>
							)}
					</div>
				),
			},

			{
				title: 'NPWP',
				dataIndex: 'institutionNPWP',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<Fragment>
									<p>{item.number}</p>
									<a href={item.realPath} target='_blank'>
										<Button size='small'>Lihat Berkas</Button>
									</a>
								</Fragment>
							)}
					</div>
				),
			},

			{
				title: 'Akta / SK pendirian / Perubahan terakhir lembaga',
				dataIndex: 'institutionLegal',
				align: 'center',
				render: (item) => (
					<div>
						{item === null ? (
							'-'
						) : (
								<Fragment>
									<div>Tanggal : {item.date}</div>

									<div>Nomor Akta : {item.number}</div>

									<a href={item.realPath} target='_blank'>
										<Button size='small'>Lihat Berkas Legal</Button>
									</a>
								</Fragment>
							)}
					</div>
				),
			},

			{
				title: 'Tanggal Mendaftar',
				dataIndex: 'created',
				align: 'center',
				render: (text) => (
					<div>
						<div style={{ padding: 0, fontSize: '16px' }}>{moment(text, 'YYYY-MM-DD HH:mm:ss').format('YYYY')}</div>
						<div>{moment(text, 'YYYY-MM-DD HH:mm:ss').format('D MMM')}</div>
						<div>{moment(text, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</div>
					</div>
				),
			},
		]

	return (
		<Fragment>
			<CustomHeader title='Lembaga' />

			<Modal
				title='Apakah Anda Yakin ?'
				visible={modalDeleteVisible}
				onCancel={() => {
					setModalDeleteVisible(false)
					setSelectedData(null)
				}}
				footer={[
					<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} onClick={onDelete} loading={loadingDelete}>
						Ya
					</Button>,
					<Button
						key='delete'
						type='primary'
						onClick={() => {
							setModalDeleteVisible(false)
							setSelectedData(null)
						}}>
						Tidak
					</Button>,
				]}></Modal>

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
				<Col lg={6} sm={14} xs={14} md={8}>
					<Search
						allowClear
						placeholder='Cari Data'
						onSearch={(e) => setInstitutionQuery({ ...institutionQuery, keyword: e, pageSize: 20, currentPage: 1})}
						enterButton
					/>
				</Col>
					<Row gutter={[16, 16]} justify='end'>
						<Col>
							<a href={`${process.env.REACT_APP_BASE_API_URL}/institution/export`} target='_blank' rel='noopener noreferrer'>
								<Button type='primary'>Export</Button>
							</a>
						</Col>
					</Row>
					<Table
						pagination={{
							defaultPageSize: 20,
							pageSize: institutionQuery.pageSize,
							total: listInstitutions.pagination.itemCount ?? 20,
							onChange: (page) => setInstitutionQuery({ ...institutionQuery, currentPage: page }),
							onShowSizeChange: (current, size) => setInstitutionQuery({ ...institutionQuery, currentPage: current, pageSize: size }),
							current: institutionQuery.currentPage,
							defaultCurrent: 1,
						}}
						loading={listInstitutions.isLoading}
						columns={columns}
						dataSource={listInstitutions.data.map((institution) => ({
							key: institution.ID,
							...institution,
						}))}
						bordered
						scroll={{ x: 1300 }}
					/>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Institution
