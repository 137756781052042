import React, { Fragment, useContext } from 'react'
import { CustomHeader } from '../../components'
import { Layout, Col, Row, Input, Card, Table, DatePicker } from 'antd'
import { AbsentContext } from '../../context/AbsentContext'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'

moment.locale('id')

const { Content } = Layout
const { Search } = Input

export default () => {
	const absentContext = useContext(AbsentContext)

	const { attendance, parameters, setParameters } = absentContext

	const columns = [
		{
			title: 'Nama Lengkap ',
			dataIndex: 'employee',
			render: (text) => <Link to={`/employee/${text.ID}`}>{text.name}</Link>,
			align: 'center',
		},
		{
			title: 'Jam Masuk ',
			dataIndex: 'employeeCheckIn',
			render: (text) => <span>{text ? moment(text.created, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss') : 'Belum Absen'}</span>,
			align: 'center',
			width: '15%',
		},
		{
			title: 'Jam Keluar ',
			dataIndex: 'employeeCheckOut',
			render: (text) => <span>{text ? moment(text.created, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss') : 'Belum Keluar'}</span>,
			align: 'center',
			width: '15%',
		},
		{
			title: 'Kegiatan ',
			align: 'center',
			children: [
				{
					title: 'Pagi ',
					align: 'center',
					dataIndex: 'employeeActivities',
					render: (text) => {
						const morningData = text.filter((record) => record.type === 1)
						return <div>{morningData.length > 0 ? <span>{morningData[0].activity}</span> : null}</div>
					},
				},
				{
					title: 'Siang ',
					align: 'center',
					dataIndex: 'employeeActivities',
					render: (text) => {
						const morningData = text.filter((record) => record.type === 2)
						return <div>{morningData.length > 0 ? <span>{morningData[0].activity}</span> : null}</div>
					},
				},
				{
					title: 'Sore ',
					align: 'center',
					dataIndex: 'employeeActivities',
					render: (text) => {
						const morningData = text.filter((record) => record.type === 3)
						return <div>{morningData.length > 0 ? <span>{morningData[0].activity}</span> : null}</div>
					},
				},
			],
		},
		{
			title: 'Informasi Tambahan ',
			dataIndex: 'employeeAttendanceInfo',
			render: (text) => <span>{text ? text.info : 'Tidak Ada Info'}</span>,
			align: 'center',
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Employee' />
			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[16, 16]} align='middle'>
						<Col span={12}>
							<div style={{ display: 'flex' }}>
								<span>Filter tanggal : </span>
								<DatePicker
									allowClear={false}
									style={{ width: '50%', marginLeft: '16px' }}
									format='YYYY-MM-DD'
									value={moment(parameters.date, 'YYYY-MM-DD')}
									onChange={(date, dateString) => setParameters({ ...parameters, date: dateString })}
								/>
							</div>
						</Col>
					</Row>
					{attendance.isLoading ? null : <Table dataSource={attendance.data.map((data) => ({ ...data, key: data.ID }))} bordered columns={columns} />}
				</Card>
			</Content>
		</Fragment>
	)
}
