import React, { useState, useContext, useEffect } from 'react'
import { Modal, Input, DatePicker, Row, Col, Card, Table } from 'antd'
import { LeaveContext } from '../../../context/LeaveContext'
import LeaveAPI from '../../../services/LeaveAPI'
import moment from 'moment'

const ModalAccept = ({ onSuccess, ...rest }) => {
	const leaveContext = useContext(LeaveContext)
	const [form, setForm] = useState({ numberReference: '', canLeave: 1, date: '' })
	const [detailLeave, setDetailLeave] = useState({ data: {}, isLoading: true })

	const { responseLeave, activeID } = leaveContext

	const getDetailLeave = async () => {
		if (activeID) {
			setDetailLeave({ ...detailLeave, isLoading: true })
			try {
				const response = await LeaveAPI.getDetailLeave(activeID)
				setDetailLeave({ data: response.data, isLoading: false })
			} catch (error) {
				console.log(error)
			}
		}

		if (!activeID) {
			setDetailLeave({ data: {}, isLoading: true })
		}
	}

	useEffect(() => {
		getDetailLeave()
	}, [activeID])

	const columns = [
		{
			title: 'Tanggal Cuti',
			dataIndex: 'date',
			key: 'date',
			render: (text) => <span>{moment(text, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>,
		},
	]

	return (
		<Modal
			width={'80%'}
			{...rest}
			title={'Terima Pengajuan Cuti'}
			onOk={async () => {
				const success = await responseLeave(form)
				if (success) {
					onSuccess()
				}
			}}>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<div>
						<span>
							<span style={{ color: 'red' }}>*</span> Nomor Surat
						</span>
						<Input
							style={{ marginTop: 8 }}
							placeholder='Masukkan Nomor Surat'
							onChange={(e) => {
								setForm({ ...form, numberReference: e.target.value })
							}}
						/>
					</div>
					<br />
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span>
							<span style={{ color: 'red' }}>*</span> Tanggal Surat
						</span>
						<DatePicker
							allowClear={false}
							onChange={(date, dateString) => {
								setForm({ ...form, date: dateString })
							}}
							format='YYYY-MM-DD'
							style={{ marginTop: 8 }}
							placeholder='Masukkan Tanggal Surat'
						/>
					</div>
				</Col>

				<Col span={12}>{detailLeave.isLoading ? null : <Table dataSource={detailLeave.data.employeeLeaveDates} bordered columns={columns} />}</Col>
			</Row>
		</Modal>
	)
}

export default ModalAccept
