import React, { useState, useContext, useEffect, Fragment, useRef } from 'react'

import { Col, Row, Button, DatePicker, Radio, Divider, notification } from 'antd'
import { format } from 'date-fns'
import { LeaveContext } from '../../../context/LeaveContext'
import moment from 'moment'
import LeaveAPI from '../../../services/LeaveAPI'

const today = format(new Date(), 'yyyy-MM-dd')

const radioStyle = {
	display: 'block',
	height: '30px',
	lineHeight: '30px',
}

const LeaveForm = ({ employeeID, isActive, match }) => {
	const leaveContext = useContext(LeaveContext)

	const calendarRef = useRef(null)

	const { fetchEmployeeLeave } = leaveContext
	const [selectedDates, setSelectedDates] = useState([])
	const [leaveType, setLeaveType] = useState(0)
	const [error, setError] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (leaveType === 3) {
			setSelectedDates([])
		}
	}, [leaveType])

	const onCreateLeave = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				const body = { employeeID, type: leaveType, selectedDates }
				await LeaveAPI.createEmployeeLeave(body)
				notification.success({ message: 'Sukses Mengajukan Cuti' })
				fetchEmployeeLeave({ order: 'desc', pageSize: 20, employeeID: match.params.id })
				setSubmitting(false)
				setSelectedDates([])
			} catch (error) {
				notification.error({ message: 'Gagal Mengajukan Cuti' })
				setError(error.response.data.errors)
				setSubmitting(false)
			}
		}
	}

	// useEffect(() => {
	// 	if (isActive) {
	// 		calendarRef.current.pickerRef.current.focus()
	// 	} else {
	// 		calendarRef.current.pickerRef.current.blur()
	// 	}
	// }, [isActive])

	return (
		<Fragment>
			<Row gutter={[0, 16]} justify='center' style={{ height: '90vh' }}>
				<Col span={16}>
					<div>
						<Row justify='space-between' align='middle'>
							<Col>
								<h3>Jumlah Cuti : {leaveType === 3 && selectedDates.length > 0 ? '3 bulan' : selectedDates.length + ' hari'} </h3>
								{leaveType === 3 && <span>Anda Hanya Perlu Memilih Tanggal Awal Cuti</span>}
							</Col>
							<Col>
								<Button disabled={leaveType === 0 || selectedDates.length === 0} loading={submitting} onClick={onCreateLeave} type='primary'>
									Ajukan
								</Button>
							</Col>
						</Row>
					</div>
					<Divider />
					<Row gutter={[0, 16]} justify='space-between'>
						<Col xs={24} md={24} lg={12}>
							<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ marginBottom: '8px', color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Jenis Cuti
								</span>
								<Radio.Group
									value={leaveType}
									onChange={(e) => {
										setLeaveType(e.target.value)
									}}>
									<Radio style={radioStyle} value={5}>
										Cuti Tahunan
									</Radio>
									<Radio style={radioStyle} value={2}>
										Cuti sakit
									</Radio>
									<Radio style={radioStyle} value={4}>
										Cuti Alasan Penting
									</Radio>
									<Radio style={radioStyle} value={3}>
										Cuti Hamil
									</Radio>
									<Radio style={radioStyle} value={1}>
										Cuti Besar
									</Radio>
								</Radio.Group>
								<span style={{ color: 'red' }}>{error ? error.errors[0] : null}</span>
							</div>
						</Col>

						<Col xs={24} md={24} lg={12}>
							<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
								<span style={{ marginBottom: '8px', color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tanggal
								</span>
								<DatePicker
									allowClear={false}
									autoFocus={isActive}
									ref={(ref) => (calendarRef.current = ref)}
									onSelect={(date) => {
										let dateString = date.format('YYYY-MM-DD')
										if (selectedDates.includes(dateString)) {
											const tmp = selectedDates.filter((d) => d !== dateString)
											setSelectedDates(tmp)
										} else {
											if (leaveType === 3) {
												setSelectedDates([dateString])
											} else {
												setSelectedDates([...selectedDates, dateString])
											}
										}
									}}
									open={isActive}
									placeholder={['Awal Cuti', 'Akhir Cuti']}
									format='YYYY-MM-DD'
									style={{ width: '100%', opacity: 0, height: 0 }}
									size='small'
									disabledDate={(date) => {
										if (leaveType === 2) {
											return date.format('dddd') === 'Saturday' || date.format('dddd') === 'Sunday'
										} else {
											return date.format('dddd') === 'Saturday' || date.format('dddd') === 'Sunday' || date.isBefore(today, 'days')
										}
									}}
									dateRender={(date) => (
										<div className='ant-picker-cell-inner'>
											{date.format('D')}
											{selectedDates.filter((d) => d === date.format('YYYY-MM-DD')).length > 0 ? <span role='img'>✅</span> : null}
										</div>
									)}
								/>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Fragment>
	)
}

export default LeaveForm
