import React from 'react'
import { Steps, Card, Spin } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'

const BudgetPlan = ({ budgetProgramHistory }) => {
	return (
		<div style={{ width: '100%', paddingLeft: 32 }}>
			{budgetProgramHistory.isLoading ? (
				<div>
					<br />
					<Spin />
					<br />
				</div>
			) : (
				<Steps progressDot direction='vertical' size='default' style={{ width: '100%' }}>
					{budgetProgramHistory.data.map((programHisory, index) => (
						<Steps.Step
							className='dudi-history-step'
							status={
								programHisory.programApplicantBudgetResponse === null
									? 'wait'
									: programHisory.programApplicantBudgetResponse.status === 2
									? 'error'
									: programHisory.programApplicantBudgetResponse.status === 1
									? 'process'
									: 'wait'
							}
							subTitle={
								programHisory.programApplicantBudgetResponse === null
									? 'menunggu'
									: programHisory.programApplicantBudgetResponse.status === 2
									? 'ditolak'
									: programHisory.programApplicantBudgetResponse.status === 1
									? 'diterima'
									: 'review ulang'
							}
							description={
								programHisory.programApplicantBudgetResponse === null
									? ''
									: programHisory.programApplicantBudgetResponse.status === 2
									? programHisory.programApplicantBudgetResponse.applicantBudgetReason.reason
									: ''
							}
							title={
								<Link to={`/budget-plan/${programHisory.ID}`} style={{ color: 'inherit' }}>{`Revisi ${
									budgetProgramHistory.data.length - index
								} (${moment(programHisory.created).format('DD MMM YYYY')})`}</Link>
							}></Steps.Step>
					))}
				</Steps>
			)}
		</div>
	)
}

export default BudgetPlan
