import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Layout, Col, Row, Card, Radio, Button, Divider, Select, Input, DatePicker, Form, Steps, notification } from 'antd'
import { CustomHeader } from '../../components'

import { EmployeeContext } from '../../context/EmployeeContext'

import _ from 'lodash'
import NewEmployeeClassForm from './components/NewEmployeeClassForm'
import NewEducationHistoryForm from './components/NewEducationHistoryForm'

const { Content } = Layout
const { Option } = Select
const { Step } = Steps

const Add = ({ history }) => {
	const employeeContext = useContext(EmployeeContext)

	const [currentStep, setCurrentStep] = useState(0)

	const [newUser, setNewUser] = useState(null)

	const steps = [
		{
			key: '1',
			title: 'Informasi Dasar',
		},
		{
			key: '2',
			title: 'Golongan',
		},
		{
			key: '3',
			title: 'Pendidikan',
		},
		// {
		// 	key: '4',
		// 	title: 'Akses',
		// },
	]

	const {
		addEmployee,
		submitting,
		error,
		employeeForm,
		setEmployeeForm,
		fetchEmployeeList,
		fullName,
		setFullname,
		educationTier,
		employeePosition,
		employeeCategory,
		religion,
		cities,
		setCities,
	} = employeeContext

	const onSubmit = async () => {
		try {
			if (currentStep === 0) {
				const responseNewUser = await addEmployee()
				if (responseNewUser.code === 200) {
					setNewUser(responseNewUser.data)
					fetchEmployeeList()
					setCurrentStep(currentStep + 1)
					notification.success({ message: 'Sukses Menambahkan Karyawan' })
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<Fragment>
			<CustomHeader title='Employee' />

			<Content style={{ background: '#f8f9fa', padding: '16px', paddingLeft: '32px', paddingRight: '32px' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[0, 16]} align='middle' justify='center'>
						<Col span={22}>
							<Steps current={currentStep}>
								{steps.map((step) => (
									<Step title={step.title} key={step.key} />
								))}
							</Steps>
						</Col>
					</Row>
					<Divider />
					<Row gutter={[0, 16]} align='middle' justify='center'>
						<Col span={14}>
							<Form layout='vertical'>
								{currentStep === 0 && (
									<Fragment>
										<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> Jenis Karyawan
											</span>
											<Radio.Group
												value={employeeForm.employeeType}
												buttonStyle='solid'
												onChange={(e) => setEmployeeForm({ ...employeeForm, employeeType: e.target.value })}>
												<Radio.Button value='PNS'>PNS</Radio.Button>
												<Radio.Button value='PPNPN'>PPNPN</Radio.Button>
											</Radio.Group>
											<span style={{ color: 'red' }}>{error ? (error.errors.employeeType ? error.errors.employeeType[0] : null) : null}</span>
										</div>

										<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> Nama Tanpa Gelar
											</span>
											<Input
												name='name'
												value={fullName.name}
												onChange={(e) => setFullname({ ...fullName, name: e.target.value })}
												placeholder='Tony Stark'
											/>
											<span style={{ color: 'red' }}>{error ? (error.errors.name ? error.errors.name[0] : null) : null}</span>
										</div>

										<Row gutter={[16, 16]}>
											<Col span={12}>
												<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
													<span style={{ marginBottom: '8px', color: 'black' }}>
														<span style={{ color: 'red' }}></span> Gelar Depan
													</span>
													<Input
														value={fullName.prefix}
														onChange={(e) => setFullname({ ...fullName, prefix: e.target.value })}
														placeholder='Dr.'
													/>
													<span style={{ color: 'red' }}>{error ? (error.errors.name ? error.errors.name[0] : null) : null}</span>
												</div>
											</Col>

											<Col span={12}>
												<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
													<span style={{ marginBottom: '8px', color: 'black' }}>
														<span style={{ color: 'red' }}></span> Gelar Belakang
													</span>
													<Input
														value={fullName.suffix}
														onChange={(e) => setFullname({ ...fullName, suffix: e.target.value })}
														placeholder='ST, M.Si'
													/>
													<span style={{ color: 'red' }}>{error ? (error.errors.name ? error.errors.name[0] : null) : null}</span>
												</div>
											</Col>
										</Row>

										<Row gutter={[16, 16]}>
											<Col span={12}>
												<div>
													<span style={{ color: 'black' }}>
														<span style={{ color: 'red' }}>*</span> Kota
													</span>
												</div>

												<Select
													onChange={(value) => setEmployeeForm({ ...employeeForm, cityID: value })}
													onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
													defaultActiveFirstOption={false}
													style={{ marginTop: '8px', width: '100%' }}
													filterOption={false}
													placeholder='Pilih Kota'
													showSearch>
													{cities.data.map((city) => (
														<Option key={city.ID} value={city.ID}>
															{city.name}
														</Option>
													))}
												</Select>
												<div>
													<span style={{ color: 'red' }}>{error ? (error.errors.cityID ? error.errors.cityID[0] : null) : null}</span>
												</div>
											</Col>

											<Col span={12}>
												<div>
													<span style={{ color: 'black' }}>
														<span style={{ color: 'red' }}>*</span> Posisi Karyawan
													</span>
												</div>
												<Select
													onChange={(value) => setEmployeeForm({ ...employeeForm, employeePositionID: value })}
													defaultActiveFirstOption={false}
													style={{ marginTop: '8px', width: '100%' }}
													loading={employeePosition.isLoading}
													placeholder='Pilih Posisi Karyawan'>
													{employeePosition.data.map((position) => (
														<Option key={position.ID} value={position.ID}>
															{position.name}
														</Option>
													))}
												</Select>

												<div>
													<span style={{ color: 'red' }}>
														{error ? (error.errors.employeePositionID ? error.errors.employeePositionID[0] : null) : null}
													</span>
												</div>
											</Col>
										</Row>

										<Row gutter={[16, 16]}>
											<Col span={12}>
												<div>
													<span style={{ color: 'black' }}>
														<span style={{ color: 'red' }}>*</span> Tempat Lahir
													</span>
												</div>

												<Select
													onChange={(value) => setEmployeeForm({ ...employeeForm, bornPlace: value })}
													onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
													defaultActiveFirstOption={false}
													style={{ marginTop: '8px', width: '100%' }}
													filterOption={false}
													placeholder='Pilih Tempat Lahir'
													loading={cities.isLoading}
													showSearch>
													{cities.data.map((city) => (
														<Option key={city.ID} value={city.ID}>
															{city.name}
														</Option>
													))}
												</Select>
												<div>
													<span style={{ color: 'red' }}>{error ? (error.errors.bornPlace ? error.errors.bornPlace[0] : null) : null}</span>
												</div>
											</Col>

											<Col span={12}>
												<div>
													<span style={{ color: 'black' }}>
														<span style={{ color: 'red' }}>*</span> Tanggal Lahir
													</span>
												</div>
												<DatePicker
													allowClear={false}
													format='YYYY-MM-DD'
													style={{ marginTop: '8px', width: '100%' }}
													placeholder='Pilih Tanggal Lahir'
													onChange={(date, dateString) => setEmployeeForm({ ...employeeForm, bornDate: dateString })}
												/>

												<div>
													<span style={{ color: 'red' }}>{error ? (error.errors.bornDate ? error.errors.bornDate[0] : null) : null}</span>
												</div>
											</Col>
										</Row>

										<Row gutter={[16, 16]}>
											<Col span={12}>
												<span style={{ marginBottom: '8px', color: 'black' }}>
													<span style={{ color: 'red' }}>*</span> No. Telpon / HP
												</span>
												<Input
													type='number'
													style={{ marginTop: '8px' }}
													placeholder='Input No.Telpon'
													onChange={(e) => setEmployeeForm({ ...employeeForm, phoneNumber: e.target.value })}
												/>
												<span style={{ color: 'red' }}>{error ? (error.errors.phoneNumber ? error.errors.phoneNumber[0] : null) : null}</span>
											</Col>
											<Col span={12}>
												<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
													<span style={{ marginBottom: '8px', color: 'black' }}>
														<span style={{ color: 'red' }}>*</span> Email
													</span>
													<Input
														type='email'
														onChange={(e) => setEmployeeForm({ ...employeeForm, email: e.target.value })}
														placeholder='ini@email.com'
													/>

													<span style={{ color: 'red' }}>{error ? (error.errors.email ? error.errors.email[0] : null) : null}</span>
												</div>
											</Col>
										</Row>

										<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> Alamat
											</span>
											<Input.TextArea
												value={employeeForm.address}
												placeholder='JL. Margona Raya'
												onChange={(e) => setEmployeeForm({ ...employeeForm, address: e.target.value })}
											/>
											<span style={{ color: 'red' }}>{error ? (error.errors.address ? error.errors.address[0] : null) : null}</span>
										</div>

										<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> NIK
											</span>
											<Input
												type='number'
												onChange={(e) => setEmployeeForm({ ...employeeForm, NIK: e.target.value })}
												placeholder='74711234560001'
											/>

											<span style={{ color: 'red' }}>{error ? (error.errors.NIK ? error.errors.NIK[0] : null) : null}</span>
										</div>

										<div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '8px', color: 'black' }}>
												<span style={{ color: 'red' }}>*</span> NPWP
											</span>
											<Input type='text' onChange={(e) => setEmployeeForm({ ...employeeForm, NPWP: e.target.value })} placeholder='12312324' />

											<span style={{ color: 'red' }}>{error ? (error.errors.NPWP ? error.errors.NPWP[0] : null) : null}</span>
										</div>

										<Row gutter={[16, 16]}>
											<Col span={12}>
												<div>
													<span style={{ color: 'black' }}>
														<span style={{ color: 'red' }}>*</span> Agama
													</span>
												</div>
												<Select
													loading={religion.isLoading}
													onChange={(value) => setEmployeeForm({ ...employeeForm, religionID: value })}
													defaultActiveFirstOption={false}
													style={{ marginTop: '8px', width: '100%' }}
													placeholder='Pilih Agama'>
													{religion.data.map((reg) => (
														<Option key={reg.ID} value={reg.ID}>
															{reg.name}
														</Option>
													))}
												</Select>

												<div>
													<span style={{ color: 'red' }}>{error ? (error.errors.religionID ? error.errors.religionID[0] : null) : null}</span>
												</div>
											</Col>

											<Col span={12}>
												<div>
													<span style={{ color: 'black' }}>
														<span style={{ color: 'red' }}>*</span> Jenis Kelamin
													</span>
												</div>
												<Select
													onChange={(value) => setEmployeeForm({ ...employeeForm, gender: value })}
													defaultActiveFirstOption={false}
													style={{ marginTop: '8px', width: '100%' }}
													placeholder='Pilih Jenis Kelamin'>
													<Option value={0}>Laki - Laki</Option>
													<Option value={1}>Perempuan</Option>
												</Select>

												<div>
													<span style={{ color: 'red' }}>{error ? (error.errors.gender ? error.errors.gender[0] : null) : null}</span>
												</div>
											</Col>
										</Row>
									</Fragment>
								)}

								{currentStep === 1 && <NewEmployeeClassForm currentUser={newUser} onNext={() => setCurrentStep(currentStep + 1)} />}
								{currentStep === 2 && <NewEducationHistoryForm currentUser={newUser} onNext={() => history.push('/employee/' + newUser.ID)} />}

								{currentStep === 0 && (
									<Form.Item>
										<Row gutter={[16, 16]} align='middle' justify='end' style={{ width: '100%' }}>
											<Col span={6}>
												<Button block loading={submitting} onClick={onSubmit} type='primary'>
													Lanjut
												</Button>
											</Col>
										</Row>
									</Form.Item>
								)}
							</Form>
						</Col>
					</Row>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Add
