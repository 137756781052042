import { portingAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

export default class FormAPI {
	static listForm(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/form?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addForm(body) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/form`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateForm(body) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/form/${body.id}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static archiveForm(body) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/form/${body.id}/archive`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
}
