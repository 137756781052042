import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

class LeaveAPI {
	static getAllemployeeLeave(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/leave/employee-leave?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getAllemployeeLeaveRange(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/leave/employee-leave/total-range-date?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getDetailLeave(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/leave/employee-leave/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static createOrUpdateLeave(id, body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				if (body.createdDates) {
					body.createdDates.forEach((date) => {
						formData.append('creates[]', date)
					})
				}

				if (body.removes) {
					formData.append('removes[]', body.removes)
				}

				// Object.keys(body).forEach((obj) => {
				// 	formData.append(obj, body[obj])
				// })

				const response = await Axios.post(`/leave/employee-leave/update-leave-date/${id}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAllemployeeLeaveRangeMonth(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/leave/employee-leave/total-range-month?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getTodayResume() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/leave/employee-leave/resume-today`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static responseLeave(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/leave/employee-leave-response`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static createEmployeeLeave(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('employeeID', body.employeeID)
				formData.append('type', body.type)
				body.selectedDates.forEach((date) => {
					formData.append('dates[]', date)
				})

				const response = await Axios.post(`/leave/employee-leave`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}

export default LeaveAPI
