import React, { Fragment, useContext, useState, useEffect } from 'react'

import { Layout, Col, Row, Input, Card, Table, Tooltip, Badge, Button, Tabs, Tag, DatePicker } from 'antd'
import { format, parse, add, isValid, sub } from 'date-fns'
import { id } from 'date-fns/locale'
import { MoreOutlined, EyeFilled, CloseOutlined, CheckOutlined, LoadingOutlined, EyeOutlined } from '@ant-design/icons'

import { CustomHeader } from '../../components'
import { Link } from 'react-router-dom'
import { LeaveContext } from '../../context/LeaveContext'
import ModalDetail from './Components/ModalDetail'
import queryString from 'query-string'
import ModalAccept from './Components/ModalAccept'
import ModalReject from './Components/ModalReject'
import Chart from 'react-apexcharts'
import moment from 'moment'

const { Content } = Layout
const { TabPane } = Tabs

const Leave = ({ location, history }) => {
	const leaveContext = useContext(LeaveContext)

	const { fetchEmployeeLeaveRange, fetchEmployeeLeave, employeeLeave, responseSubmitting, setActiveID, todayResume } = leaveContext

	const [parameters, setParameters] = useState({
		order: 'desc',
		pageSize: 20,
		currentPage: 1,
		startDate: moment().format('YYYY-MM-DD'),
		endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
	})
	const [modalVisibleAccept, setModalVisibleAccept] = useState(false)
	const [modalVisibleReject, setModalVisibleReject] = useState(false)
	const query = queryString.parse(location.search)

	const columns = [
		{
			title: 'Nama Karyawan',
			dataIndex: 'employee',
			key: 'employee',
			render: (data) => <span>{data.name}</span>,
		},
		{
			title: 'Jenis Cuti',
			dataIndex: 'typeLabel',
			key: 'typeLabel',
		},
		{
			title: 'Tanggal Cuti',
			dataIndex: 'employeeLeaveDates',
			key: 'leaveStart',
			render: (data) => <span>{format(parse(data[0].date, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', { locale: id })}</span>,
		},
		{
			title: 'Batas Cuti',
			dataIndex: 'employeeLeaveDates',
			key: 'leaveEnd',
			render: (data) => <span>{format(parse(data[data.length - 1].date, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', { locale: id })}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'employeeLeaveResponse',
			key: 'status',
			render: (data) => (
				<Tag color={data === null ? 'warning' : data.canLeave ? 'green' : 'red'}>{data === null ? 'Pending' : data.canLeave ? 'Disetujui' : 'Ditolak'}</Tag>
			),
		},
		{
			title: 'Action',
			key: 'action',
			width: '10%',
			render: (data, record) => {
				return (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						{record.employeeLeaveResponse === null ? (
							<Fragment>
								<Tooltip title='Lihat Surat'>
									<a href={process.env.REACT_APP_BASE_API_URL + '/leave/employee-leave/export/' + record.ID} target='_blank'>
										<Button
											style={{ marginLeft: '6px', borderColor: '#40a9ff' }}
											icon={<EyeFilled style={{ color: '#40a9ff' }} />}
											type='link'
											size='small'
										/>
									</a>
								</Tooltip>
								<Tooltip title='Lihat Detail'>
									<Link to={`/leave/${record.ID}`}>
										<Button
											style={{ marginLeft: '6px', borderColor: '#40a9ff' }}
											icon={<MoreOutlined style={{ color: '#40a9ff' }} />}
											type='link'
											size='small'
										/>
									</Link>
								</Tooltip>

								<Tooltip title='Tolak Cuti'>
									<Button
										loading={responseSubmitting}
										onClick={() => {
											// const success = await responseLeave({ employeeLeaveID: record.ID, canLeave: 0 })
											// if (success) {
											// 	fetchEmployeeLeave(parameters)
											// }
											setActiveID(record.ID)
											setModalVisibleReject(true)
										}}
										style={{ marginLeft: '6px' }}
										icon={<CloseOutlined />}
										danger
										type='default'
										size='small'
									/>
								</Tooltip>

								<Tooltip title='Terima Cuti'>
									<Button
										loading={responseSubmitting}
										onClick={() => {
											setActiveID(record.ID)
											setModalVisibleAccept(true)
										}}
										style={{ marginLeft: '6px', borderColor: '#52c41a' }}
										icon={<CheckOutlined style={{ color: '#52c41a' }} />}
										type='link'
										size='small'
									/>
								</Tooltip>
							</Fragment>
						) : (
							<Fragment>
								<Tooltip title='Lihat Surat'>
									<a href={process.env.REACT_APP_BASE_API_URL + '/leave/employee-leave/export/' + record.ID} target='_blank'>
										<Button
											style={{ marginLeft: '6px', borderColor: '#40a9ff' }}
											icon={<EyeFilled style={{ color: '#40a9ff' }} />}
											type='link'
											size='small'
										/>
									</a>
								</Tooltip>
								<Tooltip title='Lihat Detail'>
									<Link to={`/leave/${record.ID}`}>
										<Button
											style={{ marginLeft: '6px', borderColor: '#40a9ff' }}
											icon={<MoreOutlined style={{ color: '#40a9ff' }} />}
											type='link'
											size='small'
										/>
									</Link>
								</Tooltip>
							</Fragment>
						)}
					</div>
				)
			},
		},
	]

	useEffect(() => {
		fetchEmployeeLeave(parameters)
	}, [parameters])

	return (
		<Fragment>
			<CustomHeader title='Cuti' />

			<ModalAccept
				confirmLoading={responseSubmitting}
				visible={modalVisibleAccept}
				onCancel={() => {
					setModalVisibleAccept(false)
					setActiveID(null)
				}}
				onSuccess={() => {
					fetchEmployeeLeave(parameters)
					setActiveID(null)
					setModalVisibleAccept(false)
				}}
			/>

			<ModalReject
				confirmLoading={responseSubmitting}
				visible={modalVisibleReject}
				onCancel={() => {
					setModalVisibleReject(false)
					setActiveID(null)
				}}
				onSuccess={() => {
					fetchEmployeeLeave(parameters)
					setActiveID(null)
					setModalVisibleReject(false)
				}}
			/>

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[16, 16]} align='middle'>
						<Col span={12}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<span>Filter tanggal Mulai: </span>
								<DatePicker
									allowClear={false}
									style={{ marginLeft: '16px', width: '50%' }}
									format='YYYY-MM-DD'
									value={moment(parameters.startDate, 'YYYY-MM-DD')}
									onChange={(date, dateString) => setParameters({ ...parameters, startDate: dateString })}
								/>
							</div>
						</Col>
						<Col span={12}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<span>Filter tanggal Akhir: </span>
								<DatePicker
									allowClear={false}
									style={{ marginLeft: '16px', width: '50%' }}
									format='YYYY-MM-DD'
									value={moment(parameters.endDate, 'YYYY-MM-DD')}
									onChange={(date, dateString) => setParameters({ ...parameters, endDate: dateString })}
								/>
							</div>
						</Col>
					</Row>
					<Table
						pagination={{
							defaultPageSize: 20,
							pageSize: parameters.pageSize,
							total: employeeLeave.pagination.itemCount ?? 20,
							onChange: (page) => setParameters({ ...parameters, currentPage: page }),
							onShowSizeChange: (current, size) => setParameters({ ...parameters, currentPage: current, pageSize: size }),
							current: parameters.currentPage,
							defaultCurrent: 1,
						}}
						loading={employeeLeave.isLoading}
						columns={columns}
						dataSource={employeeLeave.data.map((leave, index) => ({ ...leave, key: leave.ID }))}
						bordered
					/>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Leave
