import React, { Fragment, useContext, useState, useEffect } from 'react'

import { Layout, Col, Row, Input, Card, Table, Tooltip, Badge, Button, Tabs, Tag, Calendar } from 'antd'
import { format, parse, add, isValid, sub } from 'date-fns'
import { id } from 'date-fns/locale'
import { MoreOutlined, EyeFilled, CloseOutlined, CheckOutlined, LoadingOutlined, EyeOutlined } from '@ant-design/icons'

import { CustomHeader } from '../../components'
import { Link } from 'react-router-dom'
import { LeaveContext } from '../../context/LeaveContext'
import ModalDetail from './Components/ModalDetail'
import queryString from 'query-string'
import Chart from 'react-apexcharts'
import { EmployeeContext } from '../../context/EmployeeContext'

const { Content } = Layout
const { TabPane } = Tabs

export default ({ location, history }) => {
	const leaveContext = useContext(LeaveContext)
	const employeeContext = useContext(EmployeeContext)
	const { civilianSummary, summary } = employeeContext

	const { fetchEmployeeLeaveRange, leaveRange, fetchEmployeeLeave, employeeLeave, leaveRangeMonth, responseSubmitting, setActiveID, todayResume } =
		leaveContext
	{
		/* <h1 style={{ fontSize: 38, color: '#c41d7f' }}>{summary.isLoading ? 0 : summary.data.totalEmployee}</h1> */
	}
	const [startMonth, setStartMonth] = useState(format(sub(new Date(), { months: 1 }), 'yyyy-MM'))
	const [endMonth, setEndMonth] = useState(format(add(new Date(), { months: 1 }), 'yyyy-MM'))
	const [selectedDate, setSelectedDate] = useState('')
	const [parameters, setParameters] = useState({ order: 'desc', pageSize: 20, currentPage: 1 })
	const [modalVisible, setModalVisible] = useState(false)
	const query = queryString.parse(location.search)
	const [defaultActiveKey, setDefaultActiveKey] = useState(query?.tabs ?? '3')
	const [modalDate, setModalDate] = useState('')

	useEffect(() => {
		fetchEmployeeLeave(parameters)
	}, [parameters])

	useEffect(() => {
		const date = parse(selectedDate, 'yyyy-MM-dd', new Date())
		if (isValid(date)) {
			const add1Month = format(add(date, { months: 1 }), 'yyyy-MM')
			const subtract1Month = format(sub(date, { months: 1 }), 'yyyy-MM')
			setStartMonth(subtract1Month)
			setEndMonth(add1Month)
		}
	}, [selectedDate])

	useEffect(() => {
		fetchEmployeeLeaveRange({ startMonth, endMonth })
	}, [startMonth, endMonth])

	return (
		<Fragment>
			<CustomHeader title='Cuti' />

			<ModalDetail visible={modalVisible} onCancel={() => setModalVisible(false)} onOk={() => setModalVisible(false)} date={modalDate} />

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Tabs
						defaultActiveKey={defaultActiveKey}
						activeKey={defaultActiveKey}
						onChange={(val) => {
							setDefaultActiveKey(val)
							history.push({ search: `?tabs=${val}` })
						}}>
						<TabPane tab='Dashboard' key='3'>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									{leaveRangeMonth.isLoading ? null : (
										<Chart
											type='bar'
											series={[
												{
													name: 'Jumlah Karyawan Yang Cuti',
													data: Object.keys(leaveRangeMonth.data).map((data) => leaveRangeMonth.data[data].totalEmployee),
												},
											]}
											options={{
												chart: {
													id: 'basic-bar',
												},
												xaxis: {
													categories: [
														'Januari',
														'Februari',
														'Maret',
														'April',
														'Mei',
														'Juni',
														'Juli',
														'Agustus',
														'September',
														'Oktober',
														'November',
														'Desember',
													],
												},
												title: {
													text: 'Jumlah Karyawan Yang Cuti',
													align: 'left',
												},
												zoom: {
													enabled: false,
												},
												labels: {
													formatter: (val) => {
														return Math.abs(Math.round(val))
													},
												},
												tooltip: {
													shared: false,
													y: {
														formatter: (val) => {
															return Math.abs(val)
														},
													},
												},
											}}
										/>
									)}
								</Col>

								<Col span={12}>
									{!todayResume.isLoading && (
										<Chart
											options={{
												chart: {
													id: 'employe-chart',
												},
												labels: ['Cuti', 'Belum Absen', 'Absen Masuk', 'Absen Keluar'],
												colors: ['#ff4d4f', '#f0f0f0', '#85a5ff', '#73d13d'],
												title: {
													text: 'Perbandingan Status Absensi Hari Ini',
													align: 'left',
												},
											}}
											type='donut'
											series={[
												todayResume.data.leave.canLeave,
												todayResume.data.attendance.notCheckIn,
												todayResume.data.attendance.checkIn,
												todayResume.data.attendance.checkOut,
											]}
											style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
										/>
									)}
								</Col>
							</Row>
						</TabPane>

						<TabPane tab='Calendar View' key='2'>
							<Calendar
								onPanelChange={(date) => {
									setSelectedDate(date.format('YYYY-MM-DD'))
								}}
								dateCellRender={(date) =>
									leaveRange.isLoading ? (
										<LoadingOutlined />
									) : (
										Object.keys(leaveRange.data).map((leave) =>
											date.format('YYYY-MM-DD') === leave ? (
												<div>
													<EyeOutlined
														style={{ position: 'absolute', right: 16 }}
														onClick={(a) => {
															setModalVisible(true)
															setModalDate(leave)
														}}
													/>
													<ul style={{ listStyle: 'none' }}>
														<li>
															<Tooltip title='Menunggu Persetujuan'>
																<Badge status='warning' text={leaveRange.data[leave].active} />
															</Tooltip>
														</li>
														<li>
															<Badge status='success' text={leaveRange.data[leave].canLeave} />
														</li>
														<li>
															<Badge status='error' text={leaveRange.data[leave].cannotLeave} />
														</li>
													</ul>
												</div>
											) : null
										)
									)
								}
							/>
						</TabPane>
					</Tabs>
				</Card>
			</Content>
		</Fragment>
	)
}
