import React from 'react'

import { Col, Row } from 'antd'
import { MailOutlined, BookOutlined, EnvironmentOutlined, TeamOutlined } from '@ant-design/icons'

const EmployeeHeader = ({ employee }) => {
	return (
		<Col span={10} style={{ paddingLeft: '16px' }}>
			<Row gutter={[16, 16]}>
				<Col span={8}>
					<span style={{ fontWeight: '500' }}>
						<TeamOutlined /> Jabatan (Sesuai Dengan Peta Jabatan)
					</span>
				</Col>
				<Col span={14}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<span>:</span>
						<div style={{ marginLeft: '8px' }}>{employee.data.employeePosition?.name ?? '-'}</div>
					</div>
				</Col>
			</Row>

			{/* <Row gutter={[16, 16]}>
				<Col span={8}>
					<span style={{ fontWeight: '500' }}>
						<BookOutlined /> Pendidikan
					</span>
				</Col>
				<Col span={14}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<span>:</span>
						<div style={{ marginLeft: '8px' }}>{employee.data.educationTier.name}</div>
					</div>
				</Col>
			</Row> */}

			<Row gutter={[16, 16]}>
				<Col span={8}>
					<span style={{ fontWeight: '500' }}>
						<EnvironmentOutlined /> Alamat
					</span>
				</Col>
				<Col span={14}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<span>:</span>
						<div style={{ marginLeft: '8px' }}>{employee.data.address}</div>
					</div>
				</Col>
			</Row>

			<Row gutter={[16, 16]}>
				<Col span={8}>
					<span style={{ fontWeight: '500' }}>
						<EnvironmentOutlined /> Kota
					</span>
				</Col>
				<Col span={14}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<span>:</span>
						<div style={{ marginLeft: '8px' }}>{employee.data.city?.name ?? '-'}</div>
					</div>
				</Col>
			</Row>

			<Row gutter={[16, 16]}>
				<Col span={8}>
					<span style={{ fontWeight: '500' }}>
						<MailOutlined /> Email
					</span>
				</Col>
				<Col span={14}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<span>:</span>
						<div style={{ marginLeft: '8px' }}>{employee.data.email || '-'}</div>
					</div>
				</Col>
			</Row>

			<Row gutter={[16, 16]}>
				<Col span={8}>
					<span style={{ fontWeight: '500' }}>
						<MailOutlined /> Email Kemendikbud
					</span>
				</Col>
				<Col span={14}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<span>:</span>
						<div style={{ marginLeft: '8px' }}>{employee.data.emailMendikbud?.email ?? '-'}</div>
					</div>
				</Col>
			</Row>
		</Col>
	)
}

export default EmployeeHeader
