import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

class AbsentAPI {
	static getAllemployeeAttendant(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/employee-attendance?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static getAllemployeeDuty(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/duty?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
}

export default AbsentAPI
