import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Layout, Col, Row, Card, Button, Select, Input, notification, Checkbox, Table, Modal } from 'antd'
import { CustomHeader } from '../../components'
import AccessAPI from '../../services/AccesAPI'
import { EmployeeContext } from '../../context/EmployeeContext'
import API from '../../services/API'
import _ from 'lodash'

const { Content } = Layout
const { Search } = Input
const { Option } = Select

const Access = () => {
	const employeeContext = useContext(EmployeeContext)

	// const { employeeList, setQuery , query} = employeeContext
	const [accountInstitution, setAccountInstitution] = useState({ accountID: '', institutionID: '', attachAs: 'Institution' })
	const [accountEmployee, setAccountEmployee] = useState({ accountID: '', employeeID: '', attachAs: 'Employee' })

	const [employeeList, setEmployeeList] = useState({ data: [], isLoading: true })
	const [listInstitutions, setListInstitution] = useState({ data: [], isLoading: true, pagination: {} })
	const [listAccount, setListAccount] = useState({ data: [], isLoading: true, pagination: { itemCount: 10 } })
	const [query, setQuery] = useState({ keyword: '', pageSize: 10 })
	const [account, setAccount] = useState({ email: '', password: '' })
	const [selectedAccount, setSelectedAccount] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	const [modalVisible, setModalVisible] = useState(false)
	const [modalVisibleSelectEmployee, setModalVisibleSelectEmployee] = useState(false)
	const [modalInstitutionVisible, setModalInstitutionVisible] = useState(false)
	const [modalAccountVisible, setModalAccountVisible] = useState(false)

	const fetchListInstitution = async () => {
		try {
			const response = await API.getAllInstitution({})

			setListInstitution({
				data: response.data.list,
				pagination: response.data.pagination,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchListEmployee = async (employeeQuery = { access: 0 }) => {
		try {
			const response = await API.getAllEmploye(employeeQuery)

			setEmployeeList({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getListAccount = async () => {
		try {
			const response = await AccessAPI.getListAccount(query)
			setListAccount({
				isLoading: false,
				data: response.data.list,
				pagination: response.data.pagination,
			})
			console.log(response.data.list)
		} catch (error) {
			console.log(error)
		}
	}

	const addAcces = async (as, id) => {
		if (!submitting) {
			setSubmitting(true)

			try {
				await AccessAPI.addAcces({ accountID: id, attachAs: as })
				notification.success({ message: 'Sukses Menambahkan Akses' })
				getListAccount()
				setSubmitting(false)
			} catch (error) {
				notification.error({ message: 'Gagal Menambahkan Akses' })
				setSubmitting(false)

				console.log(error)
			}
		}
	}

	const revokeAccess = async (as, id) => {
		if (!submitting) {
			setSubmitting(true)

			try {
				await AccessAPI.revokeAcces({ accountID: id, access: as })
				notification.success({ message: 'Sukses Menghapus Akses' })
				getListAccount()
				setSubmitting(false)
			} catch (error) {
				notification.error({ message: 'Gagal Menghapus Akses' })
				setSubmitting(false)

				console.log(error)
			}
		}
	}

	const onCreateAccount = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await AccessAPI.addAccount(account)
				notification.success({ message: 'Sukses Menambahkan Akun' })
				setSubmitting(false)
				getListAccount()
				setModalVisible(false)
				setAccount({ email: '', password: '' })
				employeeContext.setQuery({ ...query, keyword: '' })
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal Menambahkan Akun' })
				setSubmitting(false)
			}
		}
	}

	const onUpdateAccount = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await AccessAPI.updateAccount(selectedAccount, selectedAccount.ID)
				notification.success({ message: 'Sukses Update Akun' })
				setSubmitting(false)
				getListAccount()
				setModalAccountVisible(false)
				setSelectedAccount(null)
				employeeContext.setQuery({ ...query, keyword: '' })
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal Update Akun' })
				setSubmitting(false)
			}
		}
	}

	const onAttachEmployee = async () => {
		if (!submitting) {
			setSubmitting(true)

			try {
				await AccessAPI.attachRole(accountEmployee)
				notification.success({ message: 'Sukses Menambahkan Akses' })
				setSubmitting(false)
				setModalVisibleSelectEmployee(false)
				getListAccount()
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal Menambahkan Akses' })
				setSubmitting(false)
			}
		}
	}

	const onAttachInstitution = async () => {
		if (!submitting) {
			setSubmitting(true)

			try {
				await AccessAPI.attachRole(accountInstitution)
				notification.success({ message: 'Sukses Menambahkan Akses' })
				setSubmitting(false)
				setModalInstitutionVisible(false)
				getListAccount()
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal Menambahkan Akses' })
				setSubmitting(false)
			}
		}
	}

	useEffect(() => {
		getListAccount()
	}, [query])

	useEffect(() => {
		fetchListInstitution()
		fetchListEmployee()
	}, [])

	const columns = [
		{
			title: 'Email',
			dataIndex: 'email',
			width: '30%',
		},
		{
			title: 'Nama',
			dataIndex: 'accountName',
			render: (text, data) => {
				if (data.employeeAccess) {
					return <span>{data.employeeAccess.employee.name}</span>
				}

				return <span>{text === null ? '-' : text}</span>
			},
			width: '20%',
		},
		{
			title: 'Akses',
			render: (data) => {
				return (
					<div>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									checked={data.administratorAccess !== null}
									onChange={(e) => {
										if (e.target.checked) {
											addAcces('Administrator', data.ID)
										} else {
											revokeAccess('Administrator', data.ID)
										}
									}}>
									Program Administrator
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									checked={data.programAdministratorAccess !== null}
									onChange={(e) => {
										if (e.target.checked) {
											addAcces('ProgramAdministrator', data.ID)
										} else {
											revokeAccess('ProgramAdministrator', data.ID)
										}
									}}>
									Reviewer Program
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									onChange={(e) => {
										if (e.target.checked) {
											addAcces('SuperAdministrator', data.ID)
										} else {
											revokeAccess('SuperAdministrator', data.ID)
										}
									}}
									checked={data.superAdministratorAccess !== null}>
									Super Administrator
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									onChange={(e) => {
										if (e.target.checked) {
											addAcces('BudgetOperator', data.ID)
										} else {
											revokeAccess('BudgetOperator', data.ID)
										}
									}}
									checked={data.budgetOperatorAccess !== null}>
									Operator Anggaran
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									checked={data.leaveOperatorAccess !== null}
									onChange={(e) => {
										if (e.target.checked) {
											addAcces('LeaveOperator', data.ID)
										} else {
											revokeAccess('LeaveOperator', data.ID)
										}
									}}>
									Operator Cuti
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									onChange={(e) => {
										if (e.target.checked) {
											addAcces('EmployeeOperator', data.ID)
										} else {
											revokeAccess('EmployeeOperator', data.ID)
										}
									}}
									checked={data.operatorEmployeeAccess !== null}>
									Operator Karyawan
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									onChange={(e) => {
										if (e.target.checked) {
											addAcces('BudgetReminderOperator', data.ID)
										} else {
											revokeAccess('BudgetReminderOperator', data.ID)
										}
									}}
									checked={data.operatorReminderBudgetAccess !== null}>
									Operator Pengingat Anggaran
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									disabled={submitting}
									onChange={(e) => {
										if (e.target.checked) {
											setAccountEmployee({ ...accountEmployee, accountID: data.ID })
											setModalVisibleSelectEmployee(e.target.checked)
										} else {
											revokeAccess('Employee', data.ID)
										}
									}}
									checked={data.employeeAccess !== null}>
									Karyawan
								</Checkbox>
							</Col>
							<Col span={12}>
								<Checkbox
									checked={data.institutionAccess !== null}
									disabled={submitting}
									onChange={(e) => {
										if (e.target.checked) {
											setModalInstitutionVisible(e.target.checked)
											setAccountInstitution({ ...accountInstitution, accountID: data.ID })
										} else {
											revokeAccess('Institution', data.ID)
										}
									}}>
									Institusi
								</Checkbox>
							</Col>
						</Row>
					</div>
				)
			},
		},
		{
			title: 'Action',
			// dataIndex: 'accountName',
			render: (data) => {
				return (
					<Button
						type='primary'
						size='small'
						onClick={() => {
							setModalAccountVisible(true)
							setSelectedAccount({ email: data.email, password: '', ID: data.ID })
						}}>
						Ubah akun
					</Button>
				)
			},
			width: '10%',
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Dashboard Karyawan' />

			{/* {==IKI WES MODAL GAWE NGELEBOKNO KAREAWAN} */}
			<Modal
				title='Pilih Karyawan'
				visible={modalVisibleSelectEmployee}
				onOk={onAttachEmployee}
				onCancel={() => {
					setAccountEmployee({ accountID: '', employeeID: '', attachAs: 'Employee' })
					setModalVisibleSelectEmployee(false)
				}}
				confirmLoading={submitting}>
				<div>
					<Select
						style={{ width: '100%' }}
						showSearch
						filterOption={false}
						onChange={(value) => setAccountEmployee({ ...accountEmployee, employeeID: value })}
						placeholder='Pilih Karyawan'
						onSearch={_.debounce((value) => value.trim().length > 0 && fetchListEmployee({ access: 0, keyword: value }), 500)}>
						{employeeList.data.map((employee) => (
							<Option key={employee.ID} value={employee.ID}>
								{employee.name}
							</Option>
						))}
					</Select>
				</div>
			</Modal>

			{/* {==IKI WES MODAL GAWE NGELEBOKNO INSTITUSI} */}
			<Modal
				confirmLoading={submitting}
				title='Pilih Institusi'
				visible={modalInstitutionVisible}
				onOk={onAttachInstitution}
				onCancel={() => {
					setAccountInstitution({ accountID: '', institutionID: '', attachAs: 'Institution' })
					setModalInstitutionVisible(false)
				}}>
				<div>
					<Select
						style={{ width: '100%' }}
						showSearch
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						onChange={(value) => setAccountInstitution({ ...accountInstitution, institutionID: value })}
						placeholder='Pilih Institusi'>
						{listInstitutions.data.map((institution) => (
							<Option key={institution.ID} value={institution.ID}>
								{institution.name}
							</Option>
						))}
					</Select>
				</div>
			</Modal>

			{/* {====IKI WES MODALLL} */}
			<Modal title='Tambah Akun' visible={modalVisible} onOk={onCreateAccount} onCancel={() => setModalVisible(false)} confirmLoading={submitting}>
				<Fragment>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Email
								</span>
							</div>
							<Input
								placeholder='Masukkan Email'
								style={{ marginTop: '8px', width: '100%' }}
								type={'email'}
								value={account.email}
								onChange={(e) => setAccount({ ...account, email: e.target.value })}
							/>
						</Col>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Password
								</span>
							</div>
							<Input
								placeholder='Masukkan Password'
								style={{ marginTop: '8px', width: '100%' }}
								type={'password'}
								value={account.password}
								onChange={(e) => setAccount({ ...account, password: e.target.value })}
							/>
						</Col>
					</Row>
				</Fragment>
			</Modal>

			{/* {====IKI MODALLL GANTI AKUN ====} */}
			<Modal
				title='Ubah Akun'
				visible={modalAccountVisible}
				onOk={onUpdateAccount}
				onCancel={() => {
					setModalAccountVisible(false)
					setSelectedAccount(null)
				}}
				confirmLoading={submitting}>
				{selectedAccount && (
					<Fragment>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Email
									</span>
								</div>
								<Input
									placeholder='Masukkan Email'
									style={{ marginTop: '8px', width: '100%' }}
									type={'email'}
									value={selectedAccount.email}
									onChange={(e) => setSelectedAccount({ ...selectedAccount, email: e.target.value })}
								/>
							</Col>

							<Col span={24}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Password
									</span>
								</div>
								<Input
									placeholder='Masukkan Password'
									style={{ marginTop: '8px', width: '100%' }}
									type={'password'}
									value={selectedAccount.password}
									onChange={(e) => setSelectedAccount({ ...selectedAccount, password: e.target.value })}
								/>
							</Col>
						</Row>
					</Fragment>
				)}
			</Modal>

			{/* {==== IKI GUDUK MODALL} */}
			<Content style={{ padding: '64px', background: '#f8f9fa' }}>
				<Card>
					<Row gutter={[16, 16]} align='middle'>
						<Col lg={6} sm={14} xs={14} md={8}>
							<Search
								allowClear
								placeholder='Cari Email User'
								onSearch={(value) => setQuery({ ...query, keyword: value, pageSize: 20, currentPage: 1 })}
								enterButton
							/>
						</Col>
						<Col lg={6} sm={14} xs={14} md={8}>
							<Button type='primary' onClick={() => setModalVisible(true)}>
								Tambah Akses
							</Button>
						</Col>
					</Row>
					<Table
						pagination={{
							defaultPageSize: 10,
							pageSize: query.pageSize,
							total: listAccount.pagination.itemCount ?? 10,
							onChange: (page) => setQuery({ ...query, currentPage: page }),
							onShowSizeChange: (current, size) => setQuery({ ...query, currentPage: current, pageSize: size }),
							current: query.currentPage,
							defaultCurrent: 1,
						}}
						loading={listAccount.isLoading}
						columns={columns}
						dataSource={listAccount.data.map((list) => ({
							...list,
							key: list.ID,
						}))}
						bordered
					/>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Access
