import React, { useContext, useState } from 'react'
import { Divider, Row, Col, Button, Input, notification } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'

const BPJSForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee } = employeeContext

	const form = { employeeID, number: '' }
	const [BPJS, setBPJS] = useState(null)
	const [BPJSAdd, setBPJSAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.BPJS) {
			setBPJS(employeeEdit.BPJS)
		}
	}, [employee.data])

	const onUpdateBPJS = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.updateEmployeeBPJS(BPJS)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Ubah BPJS' })
					setSubmitting(false)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Ubah BPJS' })
				setSubmitting(false)
			}
		}
	}

	const onAddBPJS = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await MasterAPI.addEmployeeBPJS(BPJSAdd)
				fetchDetailEmployee(employeeID).then((res) => {
					notification.success({ message: 'Sukses Menambahkan BPJS' })
					setSubmitting(false)
					setBPJSAdd(null)
				})
			} catch (error) {
				notification.error({ message: 'Gagal Menambahkan BPJS' })
				setSubmitting(false)
			}
		}
	}

	return (
		<div>
			<h1>BPJS</h1>

			<Divider />
			{BPJS ? (
				<Row gutter={[16, 16]} align='bottom' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> BPJS
							</span>
						</div>
						<Input
							placeholder='Masukan BPJS'
							style={{ marginTop: '8px', width: '100%' }}
							value={BPJS.number}
							onChange={(e) => setBPJS({ ...BPJS, number: e.target.value })}
						/>
					</Col>

					<Col span={12}>
						<Button onClick={onUpdateBPJS} block loading={submitting} type='primary'>
							Ubah BPJS
						</Button>
					</Col>
				</Row>
			) : BPJSAdd ? null : (
				<Col span={24}>
					<Button block style={{ marginBottom: '16px' }} onClick={() => setBPJSAdd(form)}>
						<PlusOutlined /> Tambah BPJS
					</Button>
				</Col>
			)}

			{BPJSAdd ? (
				<Row gutter={[16, 16]} align='bottom' justify='center'>
					<Col span={12}>
						<div>
							<span style={{ color: 'black' }}>
								<span style={{ color: 'red' }}>*</span> BPJS
							</span>
						</div>
						<Input
							placeholder='Masukan BPJS'
							style={{ marginTop: '8px', width: '100%' }}
							value={BPJSAdd.number}
							onChange={(e) => setBPJSAdd({ ...BPJSAdd, number: e.target.value })}
						/>
					</Col>

					<Col span={12}>
						<Button onClick={onAddBPJS} block loading={submitting} type='primary'>
							Kirim BPJS
						</Button>
					</Col>
				</Row>
			) : null}
		</div>
	)
}

export default BPJSForm
