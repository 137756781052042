import React, { Fragment } from 'react'
import { Spin, Empty, Timeline } from 'antd'
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { parse, format } from 'date-fns'
import { Link } from 'react-router-dom'

const EmployeeLeave = ({ employeeLeave, id }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
			{employeeLeave.isLoading ? (
				<div>
					<Spin />
				</div>
			) : employeeLeave.data.length === 0 ? (
				<Fragment>
					<Empty description='Belum Ada Cuti' />
					<Link to={`${id}/leave?tabs=2`}>Ajukan Cuti</Link>
				</Fragment>
			) : (
				<Fragment>
					<Timeline mode='left'>
						{employeeLeave.data.map((leave) => (
							<Timeline.Item
								label={leave.employeeLeaveResponse === null ? 'Pending' : leave.employeeLeaveResponse.canLeave ? 'Diterima' : 'Ditolak'}
								key={leave.ID}
								dot={
									leave.employeeLeaveResponse === null ? (
										<ClockCircleOutlined />
									) : leave.employeeLeaveResponse.canLeave ? (
										<CheckCircleOutlined />
									) : (
										<ExclamationCircleOutlined />
									)
								}
								color={leave.employeeLeaveResponse === null ? 'gray' : leave.employeeLeaveResponse.canLeave ? 'green' : 'red'}>
								{leave.employeeLeaveDates.length > 0
									? `${format(parse(leave.employeeLeaveDates[0].date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy')} - 
                  ${format(parse(leave.employeeLeaveDates[leave.employeeLeaveDates.length - 1].date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy')}`
									: null}
							</Timeline.Item>
						))}
					</Timeline>
					<Link to={`${id}/leave`}>Lihat Semua Cuti</Link>
				</Fragment>
			)}
		</div>
	)
}

export default EmployeeLeave
