import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../../components'
import { Layout, Card, Table, Button, Col, Row, Modal, Calendar, Checkbox, Tabs, Spin, Progress, Badge, DatePicker, Form, notification } from 'antd'
import { PlusOutlined, LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import API from '../../../services/API'
import thousandSeparator from '../../../utils/thousandSeparator'
import moment from 'moment'

import NumberFormat from 'react-number-format'

import { parse, format } from 'date-fns'
import id_ID from 'date-fns/locale/id'

const { TabPane } = Tabs

const { Content } = Layout
const prefill = localStorage.getItem('prefill-date')

const DetailActivity = ({ match, history }) => {
	const [detailActivity, setDetailActivity] = useState({ data: {}, isLoading: true })
	const [reminderActivity, setReminderActivity] = useState({ data: [], isLoading: true })
	const [modalVisible, setModalVisible] = useState(false)
	const [totalValue, setTotalValue] = useState('')
	const [errors, setErrors] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [submittingUpdate, setSubmittingUpdate] = useState(false)

	const [formValue, setFormValue] = useState({
		detailActivityID: match.params.id,
		total: '',
		dateTime: '',
	})

	const fetchDetailActivity = async () => {
		try {
			setDetailActivity({ ...detailActivity, isLoading: true })
			const response = await API.getDetailSubComponentActivity(match.params.id)

			setDetailActivity({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchDetailActivityReminder = async () => {
		try {
			setReminderActivity({ ...reminderActivity, isLoading: true })
			const response = await API.getDetailActivityReminder({ detailActivityID: match.params.id })

			setReminderActivity({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const addReminder = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.addReminderActivity(formValue)

				setSubmitting(false)
				fetchDetailActivity()
				fetchDetailActivityReminder()
				setModalVisible(false)
				const savedDate = {
					dateTime: formValue.dateTime,
					expired: moment(formValue.dateTime, 'YYYY-MM-DD HH:mm:ss').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
				}
				localStorage.setItem('prefill-date', JSON.stringify(savedDate))
				notification.success({ message: 'Sukses Tambah Reminder' })
			} catch (error) {
				setErrors(error.response?.data.errors)
				setSubmitting(false)
			}
		}
	}

	const prefillDate = () => {
		const parsedPrefill = JSON.parse(prefill)

		if (prefill) {
			const expired = moment(parsedPrefill.expired, 'YYYY-MM-DD HH:mm:ss')
			const today = moment()
			const isExpired = today.isAfter(expired)

			if (isExpired) {
				localStorage.removeItem('prefill-date')
			} else {
				setFormValue({ ...formValue, dateTime: parsedPrefill.dateTime })
			}
		}
	}

	useEffect(() => {
		prefillDate()
	}, [prefill])

	useEffect(() => {
		fetchDetailActivity()
		fetchDetailActivityReminder()
	}, [])

	const column = [
		{
			title: 'Tanggal',
			dataIndex: 'date',
			align: 'center',
			render: (text) => <span>{format(parse(text, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMMM yyyy', { locale: id_ID })}</span>,
		},
		{
			title: 'Total',
			dataIndex: 'total',
			align: 'center',
			width: '70%',
			render: (text) => <span>Rp. {thousandSeparator(text)}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'isTaken',
			align: 'center',
			render: (data, record, index) =>
				submittingUpdate ? (
					<LoadingOutlined />
				) : (
					<Checkbox
						checked={data}
						onChange={({ target: { checked } }) => {
							let temp = [...reminderActivity.data]
							temp[index].isWithdraw = checked

							if (checked) {
								setSubmittingUpdate(true)
								API.updateReminderActivity({ isWithdraw: '1' }, record.key)
									.then((response) => {
										setSubmittingUpdate(false)
										notification.success({ message: 'Sukses Update' })
										setReminderActivity({
											data: temp,
											...reminderActivity,
										})
										fetchDetailActivity()
									})
									.catch((e) => {
										setSubmittingUpdate(false)
										notification.error({ message: 'Gagal Update' })
									})
							} else {
								setSubmittingUpdate(true)
								API.updateReminderActivity({ isWithdraw: '0' }, record.key)
									.then((response) => {
										setSubmittingUpdate(false)
										notification.success({ message: 'Sukses Update' })
										setReminderActivity({
											data: temp,
											...reminderActivity,
										})
										fetchDetailActivity()
									})
									.catch((e) => {
										setSubmittingUpdate(false)
										notification.error({ message: 'Gagal Update' })
									})
							}
						}}
					/>
				),
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Detail Activity' />

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<ArrowLeftOutlined onClick={() => history.goBack()} />
					{detailActivity.isLoading ? (
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Spin size='large' />
						</div>
					) : (
						<Fragment>
							<ModalAdd
								errors={errors}
								max={detailActivity.data.total - detailActivity.data.withdraw.allocationValue}
								visible={modalVisible}
								onCancel={() => setModalVisible(false)}
								onSubmit={() => addReminder()}
								onChangeDate={(date) => setFormValue({ ...formValue, dateTime: `${date} 00:00:00` })}
								onChangeTotal={(total) => {
									setTotalValue(`${total}`)
									setFormValue({ ...formValue, total })
								}}
								totalValue={totalValue}
								loading={submitting}
								formValue={formValue}
							/>

							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Row gutter={[16, 16]}>
										<Col span={6}>Kode</Col>
										<Col span={16}>
											{' '}
											: {detailActivity.data.subComponent.fullCode}.{detailActivity.data.budgetAccount.code}
										</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={6}>Nama Kegiatan</Col>
										<Col span={16}> : {detailActivity.data.name}</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={6}>Volume</Col>
										<Col span={16}> : {detailActivity.data.unitValue}</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={6}>Satuan</Col>
										<Col span={16}> : {detailActivity.data.unit.name}</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={6}>Harga Satuan</Col>
										<Col span={16}> : Rp. {thousandSeparator(detailActivity.data.price)}</Col>
									</Row>

									<Row gutter={[16, 16]}>
										<Col span={6}>Jumlah</Col>
										<Col span={16}> : Rp. {thousandSeparator(detailActivity.data.total)}</Col>
									</Row>
								</Col>

								<Col span={12}>
									<Row gutter={[16, 16]}>
										<Col span={12}>
											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
												<Progress
													type='circle'
													percent={(
														(isNaN(detailActivity.data.withdraw.allocationValue / detailActivity.data.total)
															? 0
															: detailActivity.data.withdraw.allocationValue / detailActivity.data.total) * 100
													).toFixed(2)}
												/>
												<span style={{ marginTop: '16px', fontSize: '18px' }}>Cakupan Rencana</span>
												<span>
													Rp. {thousandSeparator(detailActivity.data.withdraw.allocationValue)} / Rp.{' '}
													{thousandSeparator(detailActivity.data.total)}
												</span>
											</div>
										</Col>
										<Col span={12}>
											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
												<Progress
													type='circle'
													percent={(
														(isNaN(detailActivity.data.withdraw.realizationValue / detailActivity.data.withdraw.allocationValue)
															? 0
															: detailActivity.data.withdraw.realizationValue / detailActivity.data.withdraw.allocationValue) * 100
													).toFixed(2)}
												/>
												<span style={{ marginTop: '16px', fontSize: '18px' }}>Progress Pengambilan</span>
												<span>
													Rp. {thousandSeparator(detailActivity.data.withdraw.realizationValue)} / Rp.{' '}
													{thousandSeparator(detailActivity.data.withdraw.allocationValue)}
												</span>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Fragment>
					)}

					<Tabs defaultActiveKey='1'>
						<TabPane tab='Table View' key='1'>
							{reminderActivity.isLoading ? (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Spin size='large' />
								</div>
							) : (
								<Fragment>
									<Row gutter={[0, 16]} align='middle' justify='end'>
										<Col>
											{detailActivity.isLoading ? null : (
												<Button
													disabled={detailActivity.data.total - detailActivity.data.withdraw.allocationValue === 0}
													onClick={() => setModalVisible(true)}
													style={{ borderRadius: '4px' }}
													type='primary'
													icon={<PlusOutlined />}>
													Tambah Reminder
												</Button>
											)}
										</Col>
									</Row>
									<Table
										bordered
										columns={column}
										dataSource={reminderActivity.data.map((reminder) => ({
											key: reminder.ID,
											date: reminder.dateTime,
											total: reminder.total,
											isTaken: reminder.isWithdraw,
										}))}
										loading={reminderActivity.isLoading}
									/>
								</Fragment>
							)}
						</TabPane>
						<TabPane tab='Calendar View' key='2'>
							{reminderActivity.isLoading ? (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Spin size='large' />
								</div>
							) : (
								<Calendar
									dateCellRender={(value) => {
										return (
											<div>
												<ul style={{ listStyle: 'none' }}>
													{reminderActivity.isLoading
														? null
														: reminderActivity.data.map((reminder) =>
																value.format('YYYY-MM-DD') ===
																format(parse(reminder.dateTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'yyyy-MM-dd') ? (
																	<li>
																		<Badge
																			status={reminder.isWithdraw ? 'success' : 'error'}
																			text={`Rp. ${thousandSeparator(reminder.total)}`}
																		/>
																	</li>
																) : null
														  )}
												</ul>
											</div>
										)
									}}
								/>
							)}
						</TabPane>
					</Tabs>
				</Card>
			</Content>
		</Fragment>
	)
}

const ModalAdd = ({ visible, formValue, onCancel, onChangeDate, onSubmit, onChangeTotal, max = 0, totalValue = '', errors, loading = false }) => {
	return (
		<Modal onCancel={onCancel} visible={visible} title='Tambah Reminder' onOk={onSubmit} confirmLoading={loading}>
			<Form layout='vertical'>
				<Form.Item
					validateStatus={errors ? (errors.dateTime || errors.errors ? 'error' : undefined) : undefined}
					help={errors ? (errors.dateTime ? errors.dateTime[0] : errors.errors ? errors.errors[0] : null) : null}
					label='Pilih Tanggal Pengambilan'
					required
					rules={[{ required: true, message: 'Silahkan Pilih Tanggal' }]}>
					<DatePicker
						allowClear={false}
						value={moment(formValue.dateTime, 'YYYY-MM-DD HH:mm:ss')}
						format='YYYY-MM-DD'
						onChange={(moment, dateString) => onChangeDate(dateString)}
					/>
				</Form.Item>

				<Form.Item
					validateStatus={errors ? (errors.total || errors.errors ? 'error' : undefined) : undefined}
					help={errors ? (errors.total ? errors.total[0] : errors.errors ? errors.errors[0] : null) : null}
					label={`Input Jumlah (max : Rp. ${thousandSeparator(max)})`}
					required
					rules={[{ required: true, message: 'Silahkan Masukan Total' }]}>
					<NumberFormat
						onValueChange={(e) => onChangeTotal(e.value)}
						className='ant-input'
						value={totalValue}
						thousandSeparator={'.'}
						decimalSeparator={','}
						prefix={'Rp. '}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default DetailActivity
