import React from 'react'

import { Card, Divider, Empty, Timeline, Row, Col, Button } from 'antd'

const EducationHistory = ({ employee }) => {
	return (
		<Card className='__main_card_table' style={{ marginTop: '16px' }}>
			<div>
				<h1>Pendidikan</h1>

				<Divider />

				{employee.data.historyEducation.length > 0 ? (
					<Timeline mode='left'>
						{employee.data.historyEducation.map((education) => (
							<Timeline.Item label={education.schoolName} key={education.ID} color='green'>
								{education.educationTier?.name} {education.employeeEducationSubject?.subject}
							</Timeline.Item>
						))}
					</Timeline>
				) : (
					<Empty description='Belum Ada Data Pendidikan' />
				)}
			</div>
		</Card>
	)
}

export default EducationHistory
