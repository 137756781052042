import React, { useContext, useState } from 'react'

import { Row, Col, Card, Divider } from 'antd'

import Chart from 'react-apexcharts'
import { EmployeeContext } from '../../../context/EmployeeContext'
import { useEffect } from 'react'

const BirthChart = ({ history }) => {
	const employeeContext = useContext(EmployeeContext)
	const { summary } = employeeContext
	const [maxThreshold, setMaxThreshold] = useState(0)

	useEffect(() => {
		if (!summary.isLoading) {
			setMaxThreshold(
				Object.keys(summary.data.bornDate)
					.map((key) => [summary.data.bornDate[key].L, summary.data.bornDate[key].P])
					.flat()
					.reduce((a, b) => Math.max(a, b)) + 1
			)
		}
	}, [summary.isLoading])

	return (
		<Col span={24}>
			{summary.isLoading ? null : (
				<Card bordered={false} style={styles.bigCard}>
					<h1>Prespektif Bulan Kelahiran</h1>
					<Divider />

					<Row gutter={[16, 16]} align='middle' justify='center'>
						<Col span={18}>
							<Chart
								options={{
									plotOptions: {
										bar: {
											horizontal: true,
										},
									},
									yaxis: {
										min: -maxThreshold,
										max: maxThreshold,
										title: {
											// text: 'Age',
										},
									},
									xaxis: {
										categories: [
											'Januari',
											'Februari',
											'Maret',
											'April',
											'Mei',
											'Juni',
											'Juli',
											'Agustus',
											'September',
											'Oktober',
											'November',
											'Desember',
										],
										labels: {
											formatter: (val) => {
												return Math.abs(Math.round(val))
											},
										},
									},
									dataLabels: {
										enabled: false,
									},
									tooltip: {
										shared: false,
										x: {
											formatter: (val) => {
												return val
											},
										},
										y: {
											formatter: (val) => {
												return Math.abs(val)
											},
										},
									},
									chart: {
										events: {
											dataPointSelection: (a, b, c) => {
												history.push(`/employee?monthBorn=${c.dataPointIndex + 1}&gender=${c.seriesIndex}`)
											},
										},
										stacked: true,
									},
								}}
								type='bar'
								series={[
									{
										name: 'Laki-Laki',
										data: Object.keys(summary.data.bornDate).map((key) => summary.data.bornDate[key].L),
									},
									{
										name: 'Perempuan',
										data: Object.keys(summary.data.bornDate).map((key) => -summary.data.bornDate[key].P),
									},
								]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>
					</Row>
				</Card>
			)}
		</Col>
	)
}
const styles = {
	magentaCard: {
		background: '#ffadd2',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #ffd6e7',

		borderColor: 'none',
	},
	blueCard: {
		background: '#91d5ff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #bae7ff',
	},
	greenCard: {
		background: '#b7eb8f',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #d9f7be',
	},

	bigCard: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
		// height: '520px',
	},
	bigCard1: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
	},
}
export default BirthChart
