import React from 'react'
import { Col, Row, Card, Divider, Timeline, Empty } from 'antd'
import { parse, format } from 'date-fns'
import idLocale from 'date-fns/locale/id'

import { DownloadOutlined } from '@ant-design/icons'

const CareerData = ({ employee }) => {
	return (
		<Card className='__main_card_table' style={{ marginTop: '16px' }}>
			<div>
				<h1>Karir</h1>
				<Divider />

				{employee.data.historyEmployeeCivilianCategory.length > 0 ? (
					<Timeline mode='alternate'>
						{employee.data.historyEmployeeCivilianCategory.map((category) => (
							<Timeline.Item label={category.civilianCategory?.civilianName} key={category.ID} color='green'>
								{format(parse(category.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy', { locale: idLocale })}
								<Card>
									<p> File :</p>
									<a href={category.realPath} target='_blank' rel='noreferrer noopener'>
										{category.file.split('/')[category.file.split('/').length - 1]} <DownloadOutlined />
									</a>
								</Card>
							</Timeline.Item>
						))}
					</Timeline>
				) : (
					<Empty description='Belum Ada Data Karir' />
				)}
			</div>
		</Card>
	)
}

export default CareerData
