import React, { useContext, useState, Fragment } from 'react'
import { DatePicker, Col, Row, Select, Divider, Upload, Button, Form, notification, Input } from 'antd'
import { EmployeeContext } from '../../../context/EmployeeContext'

import { InboxOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import MasterAPI from '../../../services/MasterAPI'

const { Dragger } = Upload
const { Option } = Select

const NewEmployeeClassForm = ({ currentUser, onNext }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeCategory } = employeeContext
	const form = { employeeID: currentUser.ID, civilianCategoryID: '', date: '', file: null, currentFile: null, numberReference: '' }
	const [submitting, setSubmitting] = useState(false)

	const [categoryFrom, setCategoryForm] = useState([{ ...form }])

	const reduceCategory = (index) => {
		let tmp = categoryFrom.filter((_, idx) => index !== idx)
		setCategoryForm(tmp)
	}

	const onSubmit = async () => {
		if (!submitting) {
			setSubmitting(true)
			for (let sendForm of categoryFrom) {
				try {
					const response = await MasterAPI.addCivilianCategory(sendForm)
					if (response.code === 200) {
						notification.success({ message: 'Sukses Menambahkan Golongan' })
					}
				} catch (error) {
					notification.error({ message: 'Gagal Menambahkan Golongan' })
					console.log(error.response.data)
				}
			}
			onNext()
		}
	}

	/**
	 * @returns {boolean}
	 */
	const disabled = () => {
		let status = false
		const merge = categoryFrom

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach((calc) => {
				if (`${calc.civilianCategoryID}`.trim().length === 0 || `${calc.date}`.trim().length === 0 || calc.file === null) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	return (
		<div>
			{categoryFrom.map((catform, index) => (
				<Fragment key={index}>
					{index > 0 && (
						<Row align='middle' justify='end'>
							<Col>
								<Button danger type='link' onClick={() => reduceCategory(index)}>
									<DeleteOutlined />
								</Button>
							</Col>
						</Row>
					)}

					<Row gutter={[16, 16]} justify='center' align='middle'>
						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Golongan
								</span>
							</div>
							<Select
								onChange={(value) => {
									let tmp = [...categoryFrom]
									tmp[index].civilianCategoryID = value
									setCategoryForm(tmp)
								}}
								loading={employeeCategory.isLoading}
								defaultActiveFirstOption={false}
								style={{ marginTop: '8px', width: '100%' }}
								placeholder='Pilih Golongan'>
								{employeeCategory.data.map((category) => (
									<Option name='civilianCategoryID' key={category.ID} value={category.ID}>
										{category.civilianName}
									</Option>
								))}
							</Select>
						</Col>

						<Col span={12}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> Tanggal
								</span>
							</div>

							<DatePicker
								name='date'
								allowClear={false}
								format='YYYY-MM-DD'
								onChange={(val, dateString) => {
									let tmp = [...categoryFrom]
									tmp[index].date = dateString
									setCategoryForm(tmp)
								}}
								style={{ marginTop: '8px', width: '100%' }}
							/>
						</Col>
					</Row>

					<Row gutter={[16, 16]} justify='end'>
						<Col span={24}>
							<div>
								<span style={{ color: 'black' }}>
									<span style={{ color: 'red' }}>*</span> No. SK
								</span>
							</div>

							<Input
								onChange={(e) => {
									let tmp = [...categoryFrom]
									tmp[index].numberReference = e.target.value
									setCategoryForm(tmp)
								}}
								style={{ marginTop: '8px', width: '100%' }}
							/>
						</Col>
					</Row>

					<div style={{ marginBottom: '8px', width: '100%' }}>
						<span style={{ color: 'black' }}>
							<span style={{ color: 'red' }}>*</span> Surat
						</span>
					</div>

					<Dragger
						name='file'
						multiple={false}
						showUploadList={false}
						style={{ marginBottom: '16px' }}
						onChange={(e) => {
							let tmp = [...categoryFrom]
							tmp[index].file = e.file.originFileObj
							tmp[index].currentFile = e.file
							setCategoryForm(tmp)
						}}>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />{' '}
						</p>
						{catform.currentFile ? <p>{catform.currentFile.name}</p> : <p className='ant-upload-text'>Pilih File</p>}
					</Dragger>

					<Divider />
				</Fragment>
			))}

			<Button block type='dashed' style={{ marginBottom: '16px' }} onClick={() => setCategoryForm([...categoryFrom, { ...form }])}>
				<PlusOutlined /> Tambah Golongan
			</Button>

			<Form.Item>
				<Row gutter={[16, 16]} align='middle' justify='end' style={{ width: '100%' }}>
					<Col span={6}>
						<Button block onClick={onNext}>
							Lewati
						</Button>
					</Col>

					<Col span={6}>
						<Button disabled={disabled()} block loading={submitting} onClick={onSubmit} type='primary'>
							Lanjut
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</div>
	)
}

export default NewEmployeeClassForm
