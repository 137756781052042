import React from 'react'

import { Row, Col, Card, Divider } from 'antd'

import Chart from 'react-apexcharts'

const CivilianCategoryChart = ({ civilianSummary, summary, history }) => {
	return (
		<Col span={24}>
			{civilianSummary.isLoading && summary.isLoading ? null : (
				<Card bordered={false} style={styles.bigCard}>
					<h1>Prespektif Golongan / Ruang</h1>
					<Divider />

					<Row gutter={[16, 16]} align='middle' justify='center'>
						<Col span={8}>
							<Chart
								options={{
									plotOptions: {
										pie: {
											donut: {
												labels: {
													show: true,
													name: {
														show: true,
													},
													value: {
														show: true,
													},
													total: {
														show: true,
														label: 'Golongan IV',
													},
												},
											},
										},
									},

									chart: {
										id: 'employe-chart',
										events: {
											dataPointSelection: (a, b, c) => {
												switch (c.dataPointIndex) {
													case 0:
														history.push('/employee?civilianCategory=IV/A')
														break

													case 1:
														history.push('/employee?civilianCategory=IV/B')
														break

													default:
														break
												}
											},
										},
									},
									labels: ['Ruang Pangkat A', 'Ruang Pangkat B'],
								}}
								type='donut'
								series={[civilianSummary.data['IV/A'], civilianSummary.data['IV/B']]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>
						<Col span={8}>
							<Chart
								options={{
									plotOptions: {
										pie: {
											donut: {
												labels: {
													show: true,
													name: {
														show: true,
													},
													value: {
														show: true,
													},
													total: {
														show: true,
														label: 'Golongan III',
													},
												},
											},
										},
									},
									chart: {
										id: 'employe-chart',
										events: {
											dataPointSelection: (a, b, c) => {
												switch (c.dataPointIndex) {
													case 0:
														history.push('/employee?civilianCategory=III/A')
														break
													case 1:
														history.push('/employee?civilianCategory=III/B')
														break
													case 2:
														history.push('/employee?civilianCategory=III/C')
														break

													default:
														break
												}
											},
										},
									},
									labels: ['Ruang Pangkat A', 'Ruang Pangkat B', 'Ruang Pangkat C'],
								}}
								type='donut'
								series={[civilianSummary.data['III/A'], civilianSummary.data['III/B'], civilianSummary.data['III/C']]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>
						<Col span={8}>
							<Chart
								options={{
									plotOptions: {
										pie: {
											donut: {
												labels: {
													show: true,
													name: {
														show: true,
													},
													value: {
														show: true,
													},
													total: {
														show: true,
														label: 'Golongan II',
													},
												},
											},
										},
									},
									chart: {
										id: 'employe-chart',
										events: {
											dataPointSelection: (a, b, c) => {
												switch (c.dataPointIndex) {
													case 0:
														history.push('/employee?civilianCategory=II/A')
														break
													case 1:
														history.push('/employee?civilianCategory=II/B')
														break
													case 2:
														history.push('/employee?civilianCategory=II/C')
														break
													case 3:
														history.push('/employee?civilianCategory=III/D')
														break
													default:
														break
												}
											},
										},
									},
									labels: ['Ruang Pangkat A', 'Ruang Pangkat B', 'Ruang Pangkat C', 'Ruang Pangkat D'],
								}}
								type='donut'
								series={[civilianSummary.data['II/A'], civilianSummary.data['II/B'], civilianSummary.data['II/C'], civilianSummary.data['II/D']]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>
						<Col span={8} style={{ textAlign: 'center' }}>
							<h1 style={{ fontSize: '18px' }}>Tidak Terdata: {civilianSummary.data['notDefined']}</h1>
						</Col>
					</Row>
				</Card>
			)}
		</Col>
	)
}
const styles = {
	magentaCard: {
		background: '#ffadd2',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #ffd6e7',

		borderColor: 'none',
	},
	blueCard: {
		background: '#91d5ff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #bae7ff',
	},
	greenCard: {
		background: '#b7eb8f',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #d9f7be',
	},

	bigCard: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
		// height: '520px',
	},
	bigCard1: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
	},
}
export default CivilianCategoryChart
