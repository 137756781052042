import React, { Fragment, useContext, useState, useEffect } from 'react'

import { Layout, Col, Row, Input, Card, Table, Menu, Modal, Button, Radio, Select, notification } from 'antd'
import { format, parse } from 'date-fns'
import { id } from 'date-fns/locale'
import { DeleteFilled, ExclamationCircleOutlined, PlusOutlined, FilterFilled, ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons'

import { CustomHeader } from '../../components'
import { Link } from 'react-router-dom'
import { EmployeeContext } from '../../context/EmployeeContext'

import _ from 'lodash'
import API from '../../services/API'

import moment from 'moment'
import queryString from 'query-string'

const { Content } = Layout
const { Search } = Input

const Employee = ({ location, history }) => {
	const employeeContext = useContext(EmployeeContext)
	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [selectedData, setSelectedData] = useState({})
	const [modalFilterVisible, setModalFilterVisible] = useState(false)
	const [localQuery, setLocalQuery] = useState({ order: 'asc', sort: 'name', pageSize: 20, currentPage: 1, keyword: '' })
	const [showClear, setShowClear] = useState(false)

	const {
		employeeList: { data, isLoading, pagination },
		query,
		setQuery,
		fetchEmployeeList,
		employeeCategory,
		educationTier,
	} = employeeContext

	useEffect(() => {
		const queryParse = queryString.parse(location.search)
		setQuery({ ...query, ...queryParse })
		if (location.search.length !== 0) {
			setShowClear(true)
		}
		return () => {
			setShowClear(false)
		}
	}, [location.search])

	const onDelete = async () => {
		if (!loadingDelete) {
			try {
				setLoadingDelete(true)
				await API.deleteEmployee(selectedData.ID)
				fetchEmployeeList()
				setLoadingDelete(false)
				setSelectedData({})
				notification.success({ message: 'Sukses Hapus Karyawan' })
				setModalDeleteVisible(false)
			} catch (error) {
				setLoadingDelete(false)
				notification.error({ message: 'Gagal Hapus Karyawan' })
			}
		}
	}

	const columns = [
		{
			title: 'Nama Lengkap ',
			dataIndex: 'name',
			render: (text, record) => <Link to={`/employee/${record.ID}`}>{text}</Link>,
			align: 'center',
		},
		{
			title: 'PNS/PPNPN',
			dataIndex: 'employeeType',
			// render: (text, record) => <Link to={`/employee/${record.ID}`}>{text}</Link>,
			align: 'center',
		},

		{
			title: 'NIK',
			dataIndex: 'NIK',
			align: 'center',
		},
		{
			title: 'Alamat',
			dataIndex: 'address',
			width: '30%',
			align: 'center',
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>ACTION</span>,
			align: 'center',
			render: (data, record) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
					<Button
						onClick={() => {
							setSelectedData(data)
							setModalDeleteVisible(true)
						}}
						style={{ marginRight: '6px' }}
						style={{ opacity: 0.5 }}
						size='small'
						type='dashed'
						danger
						icon={<DeleteFilled />}
					/>
				</div>
			),
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Employee' />

			<Modal
				title='Apakah Anda Yakin ?'
				visible={modalDeleteVisible}
				onCancel={() => setModalDeleteVisible(false)}
				footer={[
					<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} onClick={onDelete} loading={loadingDelete}>
						Ya
					</Button>,
					<Button
						key='delete'
						type='primary'
						onClick={() => {
							setModalDeleteVisible(false)
							setSelectedData({})
						}}>
						Tidak
					</Button>,
				]}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<ExclamationCircleOutlined style={{ color: '#ffd666', fontSize: '32px' }} />
					<span style={{ marginLeft: '16px' }}>Apakah anda yakin untuk menghapus data ?</span>
				</div>
			</Modal>

			<Modal
				title='Filter'
				visible={modalFilterVisible}
				onCancel={() => setModalFilterVisible(false)}
				onOk={() => {
					setQuery({ ...query, ...localQuery })
					setModalFilterVisible(false)
					setShowClear(true)
				}}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>Urut Berdasarkan : </span>
					<Select
						placeholder='Sort By'
						value={localQuery.sort}
						style={{ width: '100%' }}
						onChange={(value) => setLocalQuery({ ...localQuery, sort: value })}>
						<Select.Option value='name'>Nama</Select.Option>
						<Select.Option value='created'>Terbaru</Select.Option>
					</Select>
					<br />
					<span>Golongan </span>
					<Select
						onChange={(val) => {
							setLocalQuery({ ...localQuery, civilianCategory: val })
						}}
						loading={employeeCategory.isLoading}
						defaultActiveFirstOption={false}
						style={{ marginTop: '8px', width: '100%' }}
						placeholder='Pilih Golongan'>
						{employeeCategory.data.map((category) => (
							<Select.Option name='civilianCategoryID' key={category.ID} value={category.civilianName}>
								{category.civilianName}
							</Select.Option>
						))}
					</Select>
					<br />
					<span>Pendidikan Terakhir</span>
					<Select
						onChange={(val) => {
							setLocalQuery({ ...localQuery, lastEducation: val })
						}}
						loading={employeeCategory.isLoading}
						defaultActiveFirstOption={false}
						style={{ marginTop: '8px', width: '100%' }}
						placeholder='Pilih Pendidikan Terakhir'>
						{educationTier.data.map((education) => (
							<Select.Option name='educationTierID' key={education.ID} value={education.name}>
								{education.name}
							</Select.Option>
						))}
					</Select>
					<br />
					<span>Jenis Karyawan </span>
					<Radio.Group buttonStyle='solid' onChange={(e) => setLocalQuery({ ...localQuery, employeeType: e.target.value })}>
						<Radio.Button value='PNS'>PNS</Radio.Button>
						<Radio.Button value='PPNPN'>PPNPN</Radio.Button>
					</Radio.Group>
					<br />
					<span>Jenis Kelamin </span>
					<Radio.Group buttonStyle='solid' onChange={(e) => setLocalQuery({ ...localQuery, gender: e.target.value })}>
						<Radio.Button value='1'>Perempuan</Radio.Button>
						<Radio.Button value='0'>Laki-Laki</Radio.Button>
					</Radio.Group>
					<br />
					<span>Golongan Darah </span>
					<Radio.Group buttonStyle='solid' onChange={(e) => setLocalQuery({ ...localQuery, bloodType: e.target.value })}>
						<Radio.Button value='A'>A</Radio.Button>
						<Radio.Button value='AB'>AB</Radio.Button>
						<Radio.Button value='B'>B</Radio.Button>
						<Radio.Button value='O'>O</Radio.Button>
					</Radio.Group>

					<br />
					<span>BPJS </span>
					<Radio.Group buttonStyle='solid' onChange={(e) => setLocalQuery({ ...localQuery, bpjs: e.target.value })}>
						<Radio.Button value='0'>Belum Terdata</Radio.Button>
						<Radio.Button value='1'>Sudah Terdata</Radio.Button>
					</Radio.Group>

					<br />
					<span>Bulan Lahir </span>
					<Select
						onChange={(val) => {
							setLocalQuery({ ...localQuery, monthBorn: val })
						}}
						defaultActiveFirstOption={false}
						style={{ marginTop: '8px', width: '100%' }}
						placeholder='Pilih Bulan Lahir'>
						{Array.apply(0, Array(12))
							.map(function (_, i) {
								return moment().month(i).format('MMMM')
							})
							.map((month, index) => (
								<Select.Option value={index + 1} key={index + 1}>
									{month}
								</Select.Option>
							))}
					</Select>
				</div>
			</Modal>

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[0, 16]} align='middle' justify='start'>
						<Col>
							<Link to={`/`}>
								<Button style={{ borderRadius: '4px' }} type='link' icon={<ArrowLeftOutlined />}>
									Kembali
								</Button>
							</Link>
						</Col>
					</Row>
					<Row gutter={[16, 16]} align='middle'>
						<Col lg={6} sm={14} xs={14} md={8}>
							<Search
								allowClear
								placeholder='Cari Karyawan'
								onSearch={(value) => setQuery({ ...query, keyword: value, pageSize: 20, currentPage: 1 })}
								enterButton
							/>
						</Col>

						<Col lg={4} sm={6} xs={6} md={4}>
							<Button type='primary' style={{ borderRadius: '5px' }} onClick={() => setModalFilterVisible(true)}>
								<FilterFilled /> Filter
							</Button>
						</Col>

						<Col lg={6} sm={12} xs={12} md={6}>
							<Radio.Group onChange={(e) => setQuery({ ...query, order: e.target.value })} value={query.order}>
								<Radio value='asc'>ASC</Radio>
								<Radio value='desc'>DESC</Radio>
							</Radio.Group>
						</Col>

						<Col lg={8} sm={12} xs={12} md={6}>
							<Content>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
									<Link to='/add-employee'>
										<Button type='primary' style={{ borderRadius: '5px' }}>
											<PlusOutlined /> Tambah Karyawan
										</Button>
									</Link>
								</div>
							</Content>
						</Col>
					</Row>
					<Row gutter={[0, 16]} align='middle' justify='start'>
						<Col>
							<p>
								Filter : {query.sort === 'name' ? 'Nama' : 'Terbaru'}
								{query.gender && (parseInt(query.gender) === 0 ? ', Laki-Laki' : ', Perempuan')}
								{query.civilianCategory && `, ${query.civilianCategory}`}
								{query.lastEducation && `, ${query.lastEducation}`}
								{query.employeeType && `, ${query.employeeType}`}
								{query.bloodType && `, Golongan Darah ${query.bloodType}`}
								{query.monthBorn && `, Bulan Lahir ${moment(`${query.monthBorn}`, 'M').format('MMMM')}`}
								{query.bpjs && (parseInt(query.bpjs) === 0 ? ', BPJS Belum Terdata' : ', BPJS Sudah Terdata')} &nbsp;
								{showClear && (
									<CloseOutlined
										style={{ color: 'red' }}
										onClick={() => {
											setLocalQuery({ order: 'asc', sort: 'name', pageSize: 20, currentPage: 1, keyword: '' })
											setQuery({ order: 'asc', sort: 'name', pageSize: 20, currentPage: 1, keyword: '' })
											setShowClear(false)
											history.push('/employee')
										}}
									/>
								)}
							</p>
						</Col>
					</Row>
					<Table
						pagination={{
							defaultPageSize: 20,
							pageSize: query.pageSize,
							total: pagination.itemCount ?? 20,
							onChange: (page) => setQuery({ ...query, currentPage: page }),
							onShowSizeChange: (current, size) => setQuery({ ...query, currentPage: current, pageSize: size }),
							current: query.currentPage,
							defaultCurrent: 1,
						}}
						loading={isLoading}
						columns={columns}
						dataSource={data.map((employee) => ({
							key: employee.ID,
							...employee,
						}))}
						bordered
					/>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Employee
