import React, { useContext, useState, Fragment } from 'react'
import { Divider, Row, Col, Button, Input, notification, Select, DatePicker, Popconfirm } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'
import moment from 'moment'

import _ from 'lodash'

const { Option } = Select

const TrainingForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee } = employeeContext

	const form = { employeeID, name: '', trainingType: 0, year: moment().format('YYYY'), number: '', promoter: '' }
	const [courseForm, setCourSeForm] = useState([])
	const [courseFormAdd, setCourSeFormAdd] = useState([])
	const [submitting, setSubmitting] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)

	useEffect(() => {
		if (employeeEdit.employeeTraining.length > 0) {
			setCourSeForm(employeeEdit.employeeTraining)
		}
	}, [employee.data])

	const onChangeAdd = (index, key, value) => {
		let temp = [...courseFormAdd]
		temp[index][key] = value
		setCourSeFormAdd(temp)
	}

	const onChange = (index, key, value) => {
		let temp = [...courseForm]
		temp[index][key] = value
		setCourSeForm(temp)
	}

	const onDelete = async (id, index) => {
		if (!loadingDelete) {
			setLoadingDelete(true)
			try {
				await MasterAPI.deleteEmployeeTraining(id)
				fetchDetailEmployee(employeeID).then((res) => {
					let tmp = courseForm.filter((__, idx) => idx !== index)
					setCourSeForm(tmp)
					setLoadingDelete(false)
					notification.success({ message: 'Sukses Hapus Data' })
				})
			} catch (error) {
				setLoadingDelete(false)
				notification.error({ message: 'Gagal Hapus Data' })
			}
		}
	}

	const onSubmit = async () => {
		if (!submitting) {
			setSubmitting(true)

			if (courseForm.length > 0) {
				for (let course of courseForm) {
					try {
						await MasterAPI.updateEmployeeTraining(course, course.ID)
						notification.success({ message: 'Sukses Ubah Pelatihan' })
					} catch (error) {
						notification.error({ message: 'Gagal Ubah Pelatihan' })
					}
				}
			}

			if (courseFormAdd.length > 0) {
				for (let course of courseFormAdd) {
					try {
						await MasterAPI.addEmployeeTraining(course)
						notification.success({ message: 'Sukses Tambah Data Pelatihan' })
					} catch (error) {
						notification.error({ message: 'Gagal Tambah Data Pelatihan' })
					}
				}
			}
			fetchDetailEmployee(employeeID).then((res) => {
				setCourSeFormAdd([])
				setSubmitting(false)
			})
		}
	}

	return (
		<div>
			<h1>Data Pelatihan</h1>

			<Divider />

			{courseForm.length > 0 ? (
				<Fragment>
					{courseForm.map((course, courseIndex) => (
						<Row gutter={[16, 16]} key={courseIndex}>
							<Col span={24}>
								{loadingDelete ? (
									<LoadingOutlined style={{ position: 'absolute', right: '8px' }} />
								) : (
									<Popconfirm title='Apakah anda yakin untuk menghapus ?' onConfirm={() => onDelete(course.ID, courseIndex)}>
										<DeleteOutlined style={{ position: 'absolute', right: '8px', color: 'red' }} />
									</Popconfirm>
								)}
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Pelatihan
									</span>
								</div>
								<Input
									placeholder='Masukkan Nama Pelatihan'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.name}
									onChange={(e) => onChange(courseIndex, 'name', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Lembaga
									</span>
								</div>
								<Input
									placeholder='Masukkan Nama Lembaga'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.promoter}
									onChange={(e) => onChange(courseIndex, 'promoter', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Tahun
									</span>
								</div>
								<DatePicker
									picker='year'
									allowClear={false}
									value={moment(course.year, 'YYYY')}
									style={{ marginTop: '8px', width: '100%' }}
									placeholder='Pilih Tahun'
									onChange={(moment, dateString) => onChange(courseIndex, 'year', dateString)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Jenis Pelatihan
									</span>
								</div>
								<Select
									placeholder='Pilih Jenis Pelatihan'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.trainingType}
									onChange={(val) => onChange(courseIndex, 'trainingType', val)}>
									<Option value={1}>Struktural</Option>
									<Option value={2}>Fungsional</Option>
								</Select>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>No. Sertifikat</span>
								</div>
								<Input
									placeholder='Masukkan No. Sertifikat'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.number}
									onChange={(e) => onChange(courseIndex, 'number', e.target.value)}
								/>
							</Col>
							<Divider />
						</Row>
					))}
				</Fragment>
			) : null}

			{courseFormAdd.length > 0 ? (
				<Fragment>
					{courseFormAdd.map((course, courseIndex) => (
						<Row gutter={[16, 16]} key={courseIndex}>
							<Col span={24}>
								<DeleteOutlined
									style={{ position: 'absolute', right: '8px', color: 'red' }}
									onClick={() => {
										let temp = courseFormAdd.filter((__, idx) => idx !== courseIndex)
										setCourSeFormAdd(temp)
									}}
								/>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Pelatihan
									</span>
								</div>
								<Input
									placeholder='Masukkan Nama Pelatihan'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.name}
									onChange={(e) => onChangeAdd(courseIndex, 'name', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Lembaga
									</span>
								</div>
								<Input
									placeholder='Nama Lembaga'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.promoter}
									onChange={(e) => onChangeAdd(courseIndex, 'promoter', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Tahun
									</span>
								</div>
								<DatePicker
									picker='year'
									allowClear={false}
									value={moment(course.year, 'YYYY')}
									style={{ marginTop: '8px', width: '100%' }}
									placeholder='Pilih Tahun'
									onChange={(moment, dateString) => onChangeAdd(courseIndex, 'year', dateString)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Jenis Pelatihan
									</span>
								</div>
								<Select
									placeholder='Pilih Jenis Pelatihan'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.trainingType}
									onChange={(val) => onChangeAdd(courseIndex, 'trainingType', val)}>
									<Option value={0}>Pilih Type</Option>
									<Option value={1}>Struktural</Option>
									<Option value={2}>Fungsional</Option>
								</Select>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>No. Sertifikat</span>
								</div>
								<Input
									placeholder='Masukkan No. Sertifikat'
									style={{ marginTop: '8px', width: '100%' }}
									value={course.number}
									onChange={(e) => onChangeAdd(courseIndex, 'number', e.target.value)}
								/>
							</Col>
							<Divider />
						</Row>
					))}
				</Fragment>
			) : null}
			<Row gutter={[16, 16]} justify='end'>
				<Col span={12}>
					<Button block onClick={() => setCourSeFormAdd([...courseFormAdd, form])} loading={submitting}>
						<PlusOutlined /> Tambah Data Pelatihan
					</Button>
				</Col>
				<Col span={12}>
					<Button block type='primary' onClick={onSubmit} disabled={submitting}>
						Simpan Data Pelatihan
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default TrainingForm
