import React, { useContext, useState, Fragment } from 'react'
import { Divider, Row, Col, Button, Input, notification, Select, DatePicker } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'
import moment from 'moment'

import _ from 'lodash'

const { Option } = Select

const ChildrenForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee, religion, cities, setCities } = employeeContext

	const form = { employeeID, name: '', bornDate: moment().format('YYYY-MM-DD'), bornPlace: '', status: '', gender: '' }
	const [childrenForm, setChildrenForm] = useState([])
	const [childrenFormAdd, setChildrenFormAdd] = useState([])
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.employeeChildren.length > 0) {
			setChildrenForm(employeeEdit.employeeChildren)
		}
	}, [employee.data])

	const onChangeTextAdd = (index, key, value) => {
		let temp = [...childrenFormAdd]
		temp[index][key] = value
		setChildrenFormAdd(temp)
	}

	const onChangeText = (index, key, value) => {
		let temp = [...childrenForm]
		temp[index][key] = value
		setChildrenForm(temp)
	}

	/**
	 * @returns {boolean}
	 */
	const disabled = () => {
		let status = false
		const merge = childrenForm.concat(childrenFormAdd)

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach((calc) => {
				if (
					`${calc.name}`.trim().length === 0 ||
					`${calc.gender}`.trim().length === 0 ||
					`${calc.bornDate}`.trim().length === 0 ||
					`${calc.bornPlace}`.trim().length === 0 ||
					`${calc.status}`.trim().length === 0
				) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	const onAddChildren = async () => {
		if (!submitting) {
			setSubmitting(true)

			if (childrenForm.length > 0) {
				for (let children of childrenForm) {
					try {
						await MasterAPI.updateEmployeeChildren(children, children.ID)
						notification.success({ message: 'Sukses Ubah Data Anak' })
					} catch (error) {
						notification.error({ message: 'Gagal Ubah Data Anak' })
					}
				}
			}

			if (childrenFormAdd.length > 0) {
				for (let children of childrenFormAdd) {
					try {
						await MasterAPI.addEmployeeChildren(children)
						notification.success({ message: 'Sukses Tambah Data Anak' })
					} catch (error) {
						notification.error({ message: 'Gagal Tambah Data Anak' })
					}
				}
			}
			fetchDetailEmployee(employeeID).then((res) => {
				setChildrenFormAdd([])
				setSubmitting(false)
			})
		}
	}

	return (
		<div>
			<h1>Data Anak</h1>

			<Divider />

			{childrenForm.length > 0 ? (
				<Fragment>
					{childrenForm.map((data, childrenIndex) => (
						<Row gutter={[16, 16]} key={childrenIndex}>
							<Col span={24}>
								{/* <DeleteOutlined
									style={{ position: 'absolute', right: '8px', color: 'red' }}
									onClick={() => {
										let temp = childrenForm.filter((__, idx) => idx !== childrenIndex)
										setChildrenForm(temp)
									}}
								/> */}

								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Anak
									</span>
								</div>
								<Input
									placeholder='Masukkan Nama Anak'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.name}
									onChange={(e) => onChangeText(childrenIndex, 'name', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Tempat Lahir
									</span>
								</div>
								<Select
									filterOption={false}
									onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
									onChange={(val) => onChangeText(childrenIndex, 'bornPlace', val)}
									placeholder='Pilih Tempat Lahir'
									showSearch
									style={{ marginTop: '8px', width: '100%' }}
									value={data.bornPlace}>
									{cities.data.map((city) => (
										<Option key={city.ID} value={city.ID}>
											{city.name}
										</Option>
									))}
								</Select>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Tanggal Lahir
									</span>
								</div>
								<DatePicker
									allowClear={false}
									format='YYYY-MM-DD'
									style={{ marginTop: '8px', width: '100%' }}
									placeholder='Pilih Tanggal Lahir'
									value={moment(data.bornDate, 'YYYY-MM-DD')}
									onChange={(moment, dateString) => onChangeText(childrenIndex, 'bornDate', dateString)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Jenis Kelamin
									</span>
								</div>
								<Select
									placeholder='Pilih Jenis Kelamin'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.gender}
									onChange={(val) => onChangeText(childrenIndex, 'gender', val)}>
									<Option value={0}>Laki-Laki</Option>
									<Option value={1}>Perempuan</Option>
								</Select>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Status Anak
									</span>
								</div>
								<Select
									filterOption={false}
									onChange={(val) => onChangeText(childrenIndex, 'status', val)}
									placeholder='Pilih Status Anak'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.status}>
									<Option value={1}>Anak Kandung</Option>
									<Option value={2}>Anak Tiri</Option>
									<Option value={3}>Anak Angkat</Option>
								</Select>
							</Col>
							<Divider />
						</Row>
					))}
				</Fragment>
			) : null}

			{childrenFormAdd.length > 0 ? (
				<Fragment>
					{childrenFormAdd.map((data, childrenIndex) => (
						<Row gutter={[16, 16]} key={childrenIndex}>
							<Col span={24}>
								<DeleteOutlined
									style={{ position: 'absolute', right: '8px', color: 'red' }}
									onClick={() => {
										let temp = childrenFormAdd.filter((__, idx) => idx !== childrenIndex)
										setChildrenFormAdd(temp)
									}}
								/>

								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Nama Anak
									</span>
								</div>
								<Input
									placeholder='Masukkan Nama Anak'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.name}
									onChange={(e) => onChangeTextAdd(childrenIndex, 'name', e.target.value)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Tempat Lahir
									</span>
								</div>
								<Select
									filterOption={false}
									onSearch={_.debounce((value) => setCities({ ...cities, parameters: { ...cities.parameters, keyword: value } }), 500)}
									onChange={(val) => onChangeTextAdd(childrenIndex, 'bornPlace', val)}
									placeholder='Pilih Tempat Lahir'
									showSearch
									style={{ marginTop: '8px', width: '100%' }}
									value={data.bornPlace}>
									{cities.data.map((city) => (
										<Option key={city.ID} value={city.ID}>
											{city.name}
										</Option>
									))}
								</Select>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Tanggal Lahir
									</span>
								</div>
								<DatePicker
									allowClear={false}
									format='YYYY-MM-DD'
									style={{ marginTop: '8px', width: '100%' }}
									placeholder='Pilih Tanggal Lahir'
									value={moment(data.bornDate, 'YYYY-MM-DD')}
									onChange={(moment, dateString) => onChangeTextAdd(childrenIndex, 'bornDate', dateString)}
								/>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Jenis Kelamin
									</span>
								</div>
								<Select
									placeholder='Pilih Jenis Kelamin'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.gender}
									onChange={(val) => onChangeTextAdd(childrenIndex, 'gender', val)}>
									<Option value={0}>Laki-Laki</Option>
									<Option value={1}>Perempuan</Option>
								</Select>
							</Col>

							<Col span={12}>
								<div>
									<span style={{ color: 'black' }}>
										<span style={{ color: 'red' }}>*</span> Status Anak
									</span>
								</div>
								<Select
									filterOption={false}
									onChange={(val) => onChangeTextAdd(childrenIndex, 'status', val)}
									placeholder='Pilih Status Anak'
									style={{ marginTop: '8px', width: '100%' }}
									value={data.status}>
									<Option value={1}>Anak Kandung</Option>
									<Option value={2}>Anak Tiri</Option>
									<Option value={3}>Anak Angkat</Option>
								</Select>
							</Col>
							<Divider />
						</Row>
					))}
				</Fragment>
			) : null}
			<Row gutter={[16, 16]} justify='end'>
				<Col span={12}>
					<Button block onClick={() => setChildrenFormAdd([...childrenFormAdd, form])} loading={submitting}>
						<PlusOutlined /> Tambah Data Anak
					</Button>
				</Col>
				<Col span={12}>
					<Button block type='primary' loading={submitting} onClick={onAddChildren} disabled={disabled()}>
						Simpan Data Anak
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default ChildrenForm
