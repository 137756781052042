import React from 'react'
import { Redirect } from 'react-router-dom'

const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const IS_LEAVE_OPERATOR =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'LeaveOperator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'LeaveOperator')

const IS_CONTRACT_OPERATOR =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Contract-Operator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Contract-Operator')

const Home = () => {
	if (IS_ADMIN) {
		return <Redirect to='/dashboard' />
	}

	if (IS_LEAVE_OPERATOR) {
		return <Redirect to='/leave' />
	}

	if (IS_CONTRACT_OPERATOR) {
		return <Redirect to='/contract' />
	}

	return <Redirect to='/program' />
}

export default Home
