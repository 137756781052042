import React, { createContext, useState, useEffect } from 'react'
import decode from 'jwt-decode'
import API from '../services/API'

export const AuthContext = createContext()
export const AuthContextConsumer = AuthContext.Consumer

const localUser = localStorage.getItem('@user_token')
const localRole = localStorage.getItem('@role')
const sessionUser = sessionStorage.getItem('@user_token')
const sessionRole = sessionStorage.getItem('@role')
const roles = [
	'Administrator',
	'ProgramAdministrator',
	'SuperAdministrator',
	'Employee',
	'LeaveOperator',
	'BudgetReminderOperator',
	'BudgetOperator',
	'EmployeeOperator',
	'Contract-Operator',
]

const AuthContextProvider = ({ children }) => {
	const [userID, setUserID] = useState(null)

	const getUserID = async () => {
		if (localRole) {
			if (!roles.includes(localRole)) {
				localStorage.clear()
			}
		}

		if (sessionRole) {
			if (!roles.includes(sessionRole)) {
				sessionStorage.clear()
			}
		}

		if (localUser) {
			try {
				await API.checkAccount()
				const decoded = await decode(localUser)
				setUserID(decoded.data.ID)
			} catch (error) {
				if (error.response.data.code === 403) {
					localStorage.clear()
				}
			}
		}
		if (sessionUser) {
			try {
				await API.checkAccount()
				const decoded = await decode(sessionUser)
				setUserID(decoded.data.ID)
			} catch (error) {
				if (error.response.data.code === 403) {
					sessionStorage.clear()
				}
			}
		}
	}

	useEffect(() => {
		getUserID()
	}, [])

	return <AuthContext.Provider value={{ userID, setUserID }}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
