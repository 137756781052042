import React, { Fragment, useEffect, useState } from 'react'
import { CustomHeader } from '../../components'
import { Layout, Row, Col, Card, Progress, Divider, Button, Tabs } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import thousandSeparator from '../../utils/thousandSeparator'
import moment from 'moment'
import BudgetApi from '../../services/BudgetAPI'
import { Link } from 'react-router-dom'
import DashboardTableComponent from './Components/DashboardTableComponent'
import DashboardTableSubComponent from './Components/DashboardTableSubComponent'
import DashboardTableActivity from './Components/DashboardTableActivity'

const { Content } = Layout

export default () => {
	const [componentThisWeek, setComponentThisWeek] = useState({ data: {}, isLoading: true })
	const [componentNextWeek, setComponentNextWeek] = useState({ data: {}, isLoading: true })
	const [componentNextMonth, setComponentNextMonth] = useState({ data: {}, isLoading: true })

	const getComponentThisWeek = async () => {
		try {
			setComponentThisWeek({ ...componentThisWeek, isLoading: true })
			const startOfWeek = moment().startOf('week').format('YYYY-MM-DD 00:00:00')
			const endOfWeek = moment().endOf('week').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeComponentReminder({ date: `ge${startOfWeek},le${endOfWeek}` })
			setComponentThisWeek({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getComponentNextWeek = async () => {
		try {
			setComponentNextWeek({ ...componentNextWeek, isLoading: true })
			const startOfWeek = moment().add(1, 'week').startOf('week').format('YYYY-MM-DD 00:00:00')
			const endOfWeek = moment().add(1, 'week').endOf('week').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeComponentReminder({ date: `ge${startOfWeek},le${endOfWeek}` })
			setComponentNextWeek({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getComponentNextMonth = async () => {
		try {
			setComponentNextWeek({ ...componentNextMonth, isLoading: true })
			const startOfMonth = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00')
			const endOfMonth = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeComponentReminder({ date: `ge${startOfMonth},le${endOfMonth}` })
			setComponentNextMonth({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getComponentThisWeek()
		getComponentNextWeek()
		getComponentNextMonth()
	}, [])

	//==================

	const [subComponentThisWeek, setSubComponentThisWeek] = useState({ data: {}, isLoading: true })
	const [subComponentNextWeek, setSubComponentNextWeek] = useState({ data: {}, isLoading: true })
	const [subComponentNextMonth, setSubComponentNextMonth] = useState({ data: {}, isLoading: true })

	const getSubComponentThisWeek = async () => {
		try {
			setSubComponentThisWeek({ ...subComponentThisWeek, isLoading: true })
			const startOfWeek = moment().startOf('week').format('YYYY-MM-DD 00:00:00')
			const endOfWeek = moment().endOf('week').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeSubComponentReminder({ date: `ge${startOfWeek},le${endOfWeek}` })
			setSubComponentThisWeek({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getSubComponentNextWeek = async () => {
		try {
			setSubComponentNextWeek({ ...subComponentNextWeek, isLoading: true })
			const startOfWeek = moment().add(1, 'week').startOf('week').format('YYYY-MM-DD 00:00:00')
			const endOfWeek = moment().add(1, 'week').endOf('week').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeSubComponentReminder({ date: `ge${startOfWeek},le${endOfWeek}` })
			setSubComponentNextWeek({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getSubComponentNextMonth = async () => {
		try {
			setSubComponentNextMonth({ ...subComponentNextMonth, isLoading: true })
			const startOfMonth = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00')
			const endOfMonth = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeSubComponentReminder({ date: `ge${startOfMonth},le${endOfMonth}` })
			setSubComponentNextMonth({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getSubComponentThisWeek()
		getSubComponentNextWeek()
		getSubComponentNextMonth()
	}, [])

	//==================

	const [activityThisWeek, setActivityThisWeek] = useState({ data: {}, isLoading: true })
	const [activityNextWeek, setActivityNextWeek] = useState({ data: {}, isLoading: true })
	const [activityNextMonth, setActivityNextMonth] = useState({ data: {}, isLoading: true })

	const getActivityThisWeek = async () => {
		try {
			setActivityThisWeek({ ...activityThisWeek, isLoading: true })
			const startOfWeek = moment().startOf('week').format('YYYY-MM-DD 00:00:00')
			const endOfWeek = moment().endOf('week').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeActivityReminder({ date: `ge${startOfWeek},le${endOfWeek}` })
			setActivityThisWeek({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getActivityNextWeek = async () => {
		try {
			setActivityNextWeek({ ...activityNextWeek, isLoading: true })
			const startOfWeek = moment().add(1, 'week').startOf('week').format('YYYY-MM-DD 00:00:00')
			const endOfWeek = moment().add(1, 'week').endOf('week').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeActivityReminder({ date: `ge${startOfWeek},le${endOfWeek}` })
			setActivityNextWeek({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getActivityNextMonth = async () => {
		try {
			setActivityNextMonth({ ...activityNextMonth, isLoading: true })
			const startOfMonth = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00')
			const endOfMonth = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD 00:00:00')

			const response = await BudgetApi.getResumeActivityReminder({ date: `ge${startOfMonth},le${endOfMonth}` })
			setActivityNextMonth({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getActivityThisWeek()
		getActivityNextWeek()
		getActivityNextMonth()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Dashboard Anggaran' />

			<Content style={{ padding: '0 16px', background: '#f8f9fa', paddingTop: 64 }}>
				<Row gutter={[16, 16]}>
					<Col xs={24} md={8}>
						<div style={{ textAlign: 'center' }}>
							<h1>Minggu Ini</h1>
							<br />
						</div>

						<Card bordered={false} style={styles.bigCard}>
							{componentThisWeek.isLoading ? null : (
								<div style={{ display: 'flex' }}>
									<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
										<Progress
											type='dashboard'
											percent={
												componentThisWeek.data.withdrawTotal !== 0
													? ((componentThisWeek.data.withdrawPaid / componentThisWeek.data.withdrawTotal) * 100).toFixed(2)
													: 0
											}
											size='default'
										/>
									</div>

									<div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
										<span style={{ marginTop: '16px', fontSize: '12px' }}>Sudah Terambil</span>
										<span>Rp. {thousandSeparator(componentThisWeek.data.withdrawPaid)}</span>

										<span style={{ marginTop: '16px', fontSize: '12px' }}>Belum Terambil</span>
										<span>
											Rp. {thousandSeparator(componentThisWeek.data.withdrawLeft)} / Rp. {thousandSeparator(componentThisWeek.data.withdrawTotal)}
										</span>
									</div>
								</div>
							)}
						</Card>
					</Col>

					<Col xs={24} md={8}>
						<div style={{ textAlign: 'center' }}>
							<h1>Minggu Yang Akan Datang</h1>
							<br />
						</div>
						<Card bordered={false} style={styles.bigCard}>
							{componentNextWeek.isLoading ? null : (
								<div style={{ display: 'flex' }}>
									<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
										<Progress
											type='dashboard'
											percent={
												componentNextWeek.data.withdrawTotal !== 0
													? ((componentNextWeek.data.withdrawPaid / componentNextWeek.data.withdrawTotal) * 100).toFixed(2)
													: 0
											}
											size='default'
										/>
									</div>

									<div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
										<span style={{ marginTop: '16px', fontSize: '12px' }}>Sudah Terambil</span>
										<span>Rp. {thousandSeparator(componentNextWeek.data.withdrawPaid)}</span>

										<span style={{ marginTop: '16px', fontSize: '12px' }}>Belum Terambil</span>
										<span>
											Rp. {thousandSeparator(componentNextWeek.data.withdrawLeft)} / Rp. {thousandSeparator(componentNextWeek.data.withdrawTotal)}
										</span>
									</div>
								</div>
							)}
						</Card>
					</Col>

					<Col xs={24} md={8}>
						<div style={{ textAlign: 'center' }}>
							<h1>Bulan Depan</h1>
							<br />
						</div>
						<Card bordered={false} style={styles.bigCard}>
							{componentNextMonth.isLoading ? null : (
								<div style={{ display: 'flex' }}>
									<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
										<Progress
											type='dashboard'
											percent={
												componentNextMonth.data.withdrawTotal !== 0
													? ((componentNextMonth.data.withdrawPaid / componentNextMonth.data.withdrawTotal) * 100).toFixed(2)
													: 0
											}
											size='default'
										/>
									</div>

									<div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
										<span style={{ marginTop: '16px', fontSize: '12px' }}>Sudah Terambil</span>
										<span>Rp. {thousandSeparator(componentNextMonth.data.withdrawPaid)}</span>

										<span style={{ marginTop: '16px', fontSize: '12px' }}>Belum Terambil</span>
										<span>
											Rp. {thousandSeparator(componentNextMonth.data.withdrawLeft)} / Rp. {thousandSeparator(componentNextMonth.data.withdrawTotal)}
										</span>
									</div>
								</div>
							)}
						</Card>
					</Col>
				</Row>

				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card bordered={false} style={styles.bigCard}>
							<Row gutter={[16, 16]} justify='space-between' align='middle'>
								<Col>
									<h1>Agenda Penyerapan Anggaran Minggu Ini</h1>
								</Col>

								<Col>
									<Link to='/budget/2020/4264'>
										<Button type='link'>
											Lihat Semua <ArrowRightOutlined />{' '}
										</Button>
									</Link>
								</Col>
							</Row>

							<DashboardTableComponent />
						</Card>
					</Col>
				</Row>
			</Content>
		</Fragment>
	)
}

const styles = {
	bigCard: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
		// height: '520px',
	},
	bigCard1: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
	},
}
