import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../components'
import { Layout, List, Button, Modal, Input, notification, Checkbox } from 'antd'

import Select from 'react-select/creatable'

import ContractAPI from '../../services/ContractAPI'
import ClauseGroup from './Components/ClauseGroup'

const { Content } = Layout

const Contract = () => {
	const [listGroupClause, setListGroupClause] = useState({ data: [], isLoading: true })
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [clauseGroupTitle, setClauseGroupTitle] = useState('')
	const [submitting, setSubmitting] = useState(false)
	const [showTemplateForm, setShowTemplateForm] = useState(false)
	const [templateFormValue, setTemplateFormValue] = useState('')
	const [listTemplate, setListTemplate] = useState({ data: [], isLoading: true })

	const fetchListGroupClause = async () => {
		try {
			const response = await ContractAPI.listTemplateClauseGroup({ isTemplate: '0' })
			setListGroupClause({
				isLoading: false,
				data: response.data.list,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchListTemplate = async () => {
		try {
			const response = await ContractAPI.listTemplate()
			setListTemplate({
				isLoading: false,
				data: response.data.list.map((list) => ({ value: list.name, label: list.name })),
			})
		} catch (error) {
			console.log(error)
		}
	}

	const addGroupClause = async () => {
		if (!submitting) {
			setSubmitting(true)
		}
		try {
			const response = await ContractAPI.addGroupClause(clauseGroupTitle, templateFormValue)
			notification.success({ message: 'Berhasil menambahkan pasal' })
			setSubmitting(false)
			setIsModalVisible(false)
			fetchListGroupClause()
			setClauseGroupTitle('')
		} catch (error) {
			console.log(error)
			notification.error({ message: 'Gagal menambahkan pasal' })
			setSubmitting(false)
		}
	}

	useEffect(() => {
		fetchListGroupClause()
		fetchListTemplate()
	}, [])

	useEffect(() => {
		if (!showTemplateForm) {
			setTemplateFormValue('')
		}
	}, [showTemplateForm])

	return (
		<Fragment>
			<CustomHeader title='Contract' />

			<Content style={{ padding: '32px 16px', background: '#fff' }}>
				<List
					header={
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
							<h3>Pasal</h3>

							<Button type='primary' onClick={() => setIsModalVisible(true)}>
								Tambah Pasal
							</Button>
						</div>
					}
					loading={listGroupClause.isLoading}
					bordered
					dataSource={listGroupClause.data}
					renderItem={(item) => (
						<List.Item>
							<ClauseGroup item={item} />
						</List.Item>
					)}
				/>
			</Content>

			<Modal title='Tambah pasal' visible={isModalVisible} onCancel={() => setIsModalVisible(false)} confirmLoading={submitting} onOk={addGroupClause}>
				<div style={{ marginBottom: 10 }}>
					<label htmlFor='clauseGroup'>Nama pasal</label>
					<Input
						name='clauseGroup'
						id='clauseGroup'
						placeholder='Nama pasal'
						value={clauseGroupTitle}
						onChange={(event) => setClauseGroupTitle(event.target.value)}
						style={{ marginTop: '8px' }}
					/>
				</div>

				<div style={{ marginBottom: 10 }}>
					<Checkbox
						onChange={() => {
							setShowTemplateForm((prev) => !prev)
						}}>
						Masukkan ke template ?
					</Checkbox>
				</div>

				{showTemplateForm && (
					<Select
						isLoading={listTemplate.isLoading}
						placeholder='Pilih atau Tambah Template'
						isMulti={false}
						options={listTemplate.data}
						onChange={(val) => {
							setTemplateFormValue(val.value)
						}}
					/>
				)}
			</Modal>
		</Fragment>
	)
}

export default Contract
