import React, { Fragment, useContext, useState, useEffect } from 'react'

import { Layout, Col, Row, Input, Card, Table, Menu, Dropdown, Button, Tabs, Tag, Calendar } from 'antd'
import { format, parse, add, isValid, sub } from 'date-fns'
import { id } from 'date-fns/locale'
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import { CustomHeader } from '../../components'
import { Link } from 'react-router-dom'
import { LeaveContext } from '../../context/LeaveContext'
import LeaveForm from './components/LeaveForm'

import queryString from 'query-string'

const { Content } = Layout
const { TabPane } = Tabs

const Leave = ({ match, location, history }) => {
	const query = queryString.parse(location.search)

	const leaveContext = useContext(LeaveContext)

	const [defaultActiveKey, setDefaultActiveKey] = useState(query?.tabs ?? '1')

	const { fetchEmployeeLeave, employeeLeave } = leaveContext

	const columns = [
		{
			title: 'Nama Karyawan',
			dataIndex: 'employee',
			key: 'employee',
			render: (data) => <span>{data.name}</span>,
		},
		{
			title: 'Jenis Cuti',
			dataIndex: 'typeLabel',
			key: 'typeLabel',
		},
		{
			title: 'Tanggal Cuti',
			dataIndex: 'employeeLeaveDates',
			key: 'leaveStart',
			render: (data) => <span>{format(parse(data[0].date, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', { locale: id })}</span>,
		},
		{
			title: 'Batas Cuti',
			dataIndex: 'employeeLeaveDates',
			key: 'leaveEnd',
			render: (data) => <span>{format(parse(data[data.length - 1].date, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', { locale: id })}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'employeeLeaveResponse',
			key: 'status',
			render: (data) => (
				<Tag color={data === null ? 'warning' : data.canLeave ? 'green' : 'red'}>{data === null ? 'Pending' : data.canLeave ? 'Disetujui' : 'Ditolak'}</Tag>
			),
		},
	]

	useEffect(() => {
		fetchEmployeeLeave({ order: 'desc', pageSize: 20, employeeID: match.params.id })
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Employee' />

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[0, 16]} align='middle' justify='space-between'>
						<Col>
							<Link to={`/employee/${match.params.id}`}>
								<Button style={{ borderRadius: '4px' }} type='link' icon={<ArrowLeftOutlined />}>
									Kembali
								</Button>
							</Link>
						</Col>
						<Col>
							<Button onClick={() => setDefaultActiveKey('2')} style={{ borderRadius: '4px' }} type='primary' icon={<PlusOutlined />}>
								Ajukan Cuti
							</Button>
						</Col>
					</Row>
					<Tabs
						defaultActiveKey={defaultActiveKey}
						activeKey={defaultActiveKey}
						onTabClick={(key) => {
							setDefaultActiveKey(key)
							history.push({ search: `?tabs=${key}` })
						}}>
						<TabPane tab='Daftar Cuti' key='1'>
							<Table
								loading={employeeLeave.isLoading}
								columns={columns}
								dataSource={employeeLeave.data.map((leave, index) => ({ ...leave, key: leave.ID }))}
								bordered
							/>
						</TabPane>
						<TabPane tab='Ajukan Cuti' key='2'>
							<LeaveForm employeeID={match.params.id} match={match} isActive={defaultActiveKey === '2'} />
						</TabPane>
					</Tabs>
				</Card>
			</Content>
		</Fragment>
	)
}

export default Leave
