import React, { useContext, useState } from 'react'
import { Divider, Row, Col, Button, Radio, notification } from 'antd'

import { EmployeeContext } from '../../../context/EmployeeContext'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import MasterAPI from '../../../services/MasterAPI'
import API from '../../../services/API'

const BloodForm = ({ employeeID }) => {
	const employeeContext = useContext(EmployeeContext)
	const { employeeEdit, fetchDetailEmployee, employee } = employeeContext

	const [bloodType, setBloodType] = useState({ employeeID, type: '' })
	const [initialBlood, setInitialBlood] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeEdit.employeeBloodType) {
			setBloodType({ ...bloodType, type: employeeEdit.employeeBloodType.type })
			setInitialBlood(employeeEdit.employeeBloodType)
		}
	}, [employee.data])

	const onSubmit = async () => {
		if (!submitting) {
			setSubmitting(true)
			if (initialBlood === null) {
				try {
					await API.addEmployeeBlood(employeeID, bloodType.type)
					fetchDetailEmployee(employeeID).then((res) => {
						notification.success({ message: 'Sukses Tambah Golongan Darah' })
						setSubmitting(false)
					})
				} catch (error) {
					notification.error({ message: 'Gagal Tambah Golongan Darah' })
					setSubmitting(false)
				}
			} else {
				try {
					await API.updateEmployeeBlood(employeeID, bloodType.type)
					fetchDetailEmployee(employeeID).then((res) => {
						notification.success({ message: 'Sukses Ubah Golongan Darah' })
						setSubmitting(false)
					})
				} catch (error) {
					notification.error({ message: 'Gagal Ubah Golongan Darah' })
					setSubmitting(false)
				}
			}
		}
	}

	return (
		<div>
			<h1>Golongan Darah</h1>

			<Divider />

			<Row gutter={[16, 16]} align='bottom' justify='center'>
				<Col span={12}>
					<Radio.Group
						value={bloodType.type}
						buttonStyle='solid'
						onChange={(e) => {
							setBloodType({ ...bloodType, type: e.target.value })
						}}>
						<Radio.Button value='A'>A</Radio.Button>
						<Radio.Button value='AB'>AB</Radio.Button>
						<Radio.Button value='B'>B</Radio.Button>
						<Radio.Button value='O'>O</Radio.Button>
					</Radio.Group>
				</Col>

				<Col span={12}>
					<Button type='primary' onClick={onSubmit} block disabled={bloodType.type === '' || submitting}>
						Ubah Golongan Darah
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default BloodForm
