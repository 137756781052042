import { Layout, Input, Divider, Button, notification, Spin, Modal } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { CustomHeader } from '../../components'
import ContractAPI from '../../services/ContractAPI'
import { ArrowLeftOutlined } from '@ant-design/icons'

const Quill = ReactQuill.Quill
let Font = Quill.import('formats/font')
Font.whitelist = ['TimesNewRoman', 'Arial']
Quill.register(Font, true)

const { Content } = Layout

const formats = [
	'background',
	'bold',
	'color',
	'font',
	'code',
	'italic',
	'link',
	'size',
	'strike',
	'underline',
	'blockquote',
	'header',
	'indent',
	'list',
	'align',
	'direction',
	'formula',
]

const modules = {
	toolbar: [[{ font: Font.whitelist }], ['bold', 'italic', 'underline'], [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]],
}

const DetailClause = ({ match, history }) => {
	const [detailClauseGroup, setDetailClauseGroup] = useState({ data: null, isLoading: true })

	const [clauseGroupEditValue, setClauseGroupEditValue] = useState('')
	const [clauseEditValue, setClauseEditValue] = useState([])
	const [clauseAddValue, setClauseAddValue] = useState('')

	const [submitting, setSubmitting] = useState(false)
	const [showAdd, setShowAdd] = useState(false)

	const fetchDetailTemplateClauseGroup = async () => {
		try {
			const response = await ContractAPI.detailTemplateClauseGroup(match.params.id, { expand: 'templateClauses' })
			setDetailClauseGroup({
				isLoading: false,
				data: response.data,
			})

			setClauseGroupEditValue(response.data.title)
			setClauseEditValue(response.data.templateClauses)
		} catch (error) {
			console.log(error)
		}
	}

	const onUpdateClauseGroup = async () => {
		if (!submitting) {
			setSubmitting(true)

			try {
				await ContractAPI.updateGroupClause(match.params.id, clauseGroupEditValue)
				setSubmitting(false)
				notification.success({ message: 'Sukses ubah pasal' })
				fetchDetailTemplateClauseGroup()
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal ubah pasal' })
				console.log(error)
			}
		}
	}

	const onCreateClause = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await ContractAPI.createClause(match.params.id, clauseAddValue)
				setSubmitting(false)
				notification.success({ message: 'Sukses tambah ayat' })
				fetchDetailTemplateClauseGroup()
				setShowAdd(false)
				setClauseAddValue('')
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal tambah ayat' })
				console.log(error)
			}
		}
	}

	const onUpdateClause = async (clause, index) => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await ContractAPI.updateClause(clause.ID, clauseEditValue[index].content)
				setSubmitting(false)
				notification.success({ message: 'Sukses ubah ayat' })
				fetchDetailTemplateClauseGroup()
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal ubah ayat' })
				console.log(error)
			}
		}
	}

	const onDeleteClause = async (clause) => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await ContractAPI.deleteClause(clause.ID)
				setSubmitting(false)
				notification.success({ message: 'Sukses hapus ayat' })
				fetchDetailTemplateClauseGroup()
				Modal.destroyAll()
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal hapus ayat' })
				console.log(error)
			}
		}
	}

	const onDeleteClauseGroup = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await ContractAPI.deleteClauseGroup(match.params.id)
				setSubmitting(false)
				notification.success({ message: 'Sukses hapus pasal' })
				Modal.destroyAll()
				history.replace('/contract/clause-group')
			} catch (error) {
				setSubmitting(false)
				notification.error({ message: 'Gagal hapus pasal' })
				console.log(error)
			}
		}
	}

	const modalDelete = (clause) => {
		Modal.confirm({
			title: 'Apakah anda yakin ingin menghapus ayat ?',
			content: 'Setelah hapus, anda tidak dapat mengembalikan',
			okButtonProps: {
				loading: submitting,
				disabled: submitting,
			},
			cancelText: 'Batal',
			okText: 'Hapus',
			onOk: () => onDeleteClause(clause),
		})
	}

	const modalDeleteClauseGroup = (clause) => {
		Modal.confirm({
			title: 'Apakah anda yakin ingin menghapus pasal ?',
			content: 'Setelah hapus, anda tidak dapat mengembalikan',
			okButtonProps: {
				loading: submitting,
				disabled: submitting,
			},
			cancelText: 'Batal',
			okText: 'Hapus',
			onOk: () => onDeleteClauseGroup(clause),
		})
	}

	useEffect(() => {
		fetchDetailTemplateClauseGroup()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Contract' />
			<Content style={{ padding: '64px', background: '#fff' }}>
				{detailClauseGroup.isLoading ? (
					<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Spin />
					</div>
				) : (
					<div className='dudi_asp_container'>
						<ArrowLeftOutlined onClick={() => history.goBack()} />
						<div>
							<label htmlFor='clauseGroupEdit'>Nama Pasal</label>
							<Input
								name='clauseGroupEdit'
								id='clauseGroupEdit'
								placeholder='Nama pasal'
								style={{ marginTop: '8px' }}
								value={clauseGroupEditValue}
								onChange={(event) => setClauseGroupEditValue(event.target.value)}
							/>
						</div>

						<br />
						<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '8px' }}>
							<Button type='dashed' loading={submitting} onClick={modalDeleteClauseGroup}>
								Hapus
							</Button>
							<Button type='primary' loading={submitting} onClick={onUpdateClauseGroup}>
								Ubah
							</Button>
						</div>

						{detailClauseGroup.isLoading
							? null
							: clauseEditValue.map((clause, iClause) => (
									<div key={clause.ID}>
										<Divider />
										<ReactQuill
											theme='snow'
											placeholder='Masukan detail Ayat'
											formats={formats}
											modules={modules}
											className='clause-container'
											value={clause.content}
											onChange={(value) => {
												let temp = [...clauseEditValue]
												temp[iClause].content = value
												setClauseEditValue(temp)
											}}
										/>
										<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '8px', gap: '8px' }}>
											<Button type='dashed' onClick={() => modalDelete(clause)}>
												Hapus ayat
											</Button>
											<Button type='primary' loading={submitting} onClick={() => onUpdateClause(clause, iClause)}>
												Ubah ayat
											</Button>
										</div>
									</div>
							  ))}

						<br />

						<Divider />

						{showAdd && (
							<div>
								<ReactQuill
									theme='snow'
									placeholder='Masukan detail Ayat'
									formats={formats}
									modules={modules}
									className='clause-container'
									onChange={(value) => setClauseAddValue(value)}
								/>
								<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '8px', gap: '8px' }}>
									<Button type='dashed' onClick={() => setShowAdd(false)}>
										Batal
									</Button>
									<Button type='primary' loading={submitting} onClick={onCreateClause}>
										Simpan
									</Button>
								</div>
							</div>
						)}

						{!showAdd && (
							<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '16px' }}>
								<Button type='primary' onClick={() => setShowAdd(true)}>
									Tambah ayat
								</Button>
							</div>
						)}
					</div>
				)}
			</Content>
		</Fragment>
	)
}

export default DetailClause
