import React, { Fragment, useEffect, useContext, useState } from 'react'

import { Layout, Row, Col, Card, Divider, Tabs } from 'antd'
import { CustomHeader } from '../../components'

import { UserOutlined } from '@ant-design/icons'

import ApexChart from 'apexcharts'
import { EmployeeContext } from '../../context/EmployeeContext'
import GeoChart from './Components/GeoChart'

import data from './Components/indonesia-province-simple.json'
import Chart from 'react-apexcharts'
import CivilianCategoryChart from './Components/CivilianCategoryChart'
import EducationChart from './Components/EducationChart'
import queryString from 'query-string'
import BPJSChart from './Components/BPJSChart'
import BirthChart from './Components/BirthChart'

const { Content } = Layout
const { TabPane } = Tabs

const Dashboard = ({ location, history }) => {
	const employeeContext = useContext(EmployeeContext)

	const query = queryString.parse(location.search)
	const [defaultActiveKey, setDefaultActiveKey] = useState(query?.tabs ?? '1')

	const { civilianSummary, summary } = employeeContext

	return (
		<Fragment>
			<CustomHeader title='Dashboard' />

			<Content style={{ padding: '0 10%', background: '#f8f9fa' }}>
				<Row gutter={[16, 16]} justify='space-between' align='middle' style={{ marginTop: 64 }}>
					<Col md={8} lg={8} xl={8} sm={24} xs={24}>
						<Card bordered={false} style={styles.magentaCard}>
							<h1>Total Pegawai</h1>
							<div style={{ display: 'flex', flexDirection: 'row', marginTop: 16, alignItems: 'center', justifyContent: 'space-between' }}>
								<UserOutlined style={{ fontSize: 64, color: '#c41d7f' }} />
								<h1 style={{ fontSize: 38, color: '#c41d7f' }}>{summary.isLoading ? 0 : summary.data.totalEmployee}</h1>
							</div>
						</Card>
					</Col>
					<Col md={8} lg={8} xl={8} sm={24} xs={24}>
						<Card bordered={false} style={styles.blueCard}>
							<h1>PNS</h1>
							<div style={{ display: 'flex', flexDirection: 'row', marginTop: 16, alignItems: 'center', justifyContent: 'space-between' }}>
								<UserOutlined style={{ fontSize: 64, color: '#0050b3' }} />
								<h1 style={{ fontSize: 38, color: '#0050b3' }}>{summary.data?.employeeType?.PNS ?? '0'}</h1>
							</div>
						</Card>
					</Col>
					<Col md={8} lg={8} xl={8} sm={24} xs={24}>
						<Card bordered={false} style={styles.greenCard}>
							<h1>PPNPN</h1>
							<div style={{ display: 'flex', flexDirection: 'row', marginTop: 16, alignItems: 'center', justifyContent: 'space-between' }}>
								<UserOutlined style={{ fontSize: 64, color: '#237804' }} />
								<h1 style={{ fontSize: 38, color: '#237804' }}>{summary.data?.employeeType?.PPNPN ?? '0'}</h1>
							</div>
						</Card>
					</Col>
				</Row>

				{/* ------- */}

				<Tabs
					defaultActiveKey={defaultActiveKey}
					activeKey={defaultActiveKey}
					onChange={(val) => {
						history.push(`/dashboard?tabs=${val}`)
						setDefaultActiveKey(val)
					}}>
					<TabPane tab='Klasifikasi Pegawai' key='1'>
						<Row gutter={[16, 16]} justify='space-between' style={{ marginTop: 32, marginBottom: 16 }}>
							<CivilianCategoryChart civilianSummary={civilianSummary} summary={summary} history={history} />
							<Col md={12} sm={24} xs={24}>
								<Card bordered={false} style={styles.bigCard}>
									<h1>Jenis Karyawan</h1>
									<Divider />
									{!summary.isLoading && (
										<Chart
											options={{
												chart: {
													id: 'employe-chart',
													events: {
														dataPointSelection: (a, b, c) => {
															switch (c.dataPointIndex) {
																case 0:
																	history.push('/employee?employeeType=PNS')
																	break
																case 1:
																	history.push('/employee?employeeType=PPNPN')
																	break

																default:
																	break
															}
														},
													},
												},
												labels: ['PNS', 'PPNPN'],
											}}
											type='pie'
											height='250vh'
											width='100%'
											series={[summary.data.employeeType.PNS, summary.data.employeeType.PPNPN]}
											style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
										/>
									)}
								</Card>
							</Col>
							<Col md={12} sm={24} xs={24}>
								<Card bordered={false} style={styles.bigCard}>
									<h1>Golongan Darah</h1>
									<Divider />
									{!summary.isLoading && (
										<Chart
											options={{
												chart: {
													id: 'employe-chart',
													events: {
														dataPointSelection: (a, b, c) => {
															switch (c.dataPointIndex) {
																case 0:
																	history.push('/employee?bloodType=A')
																	break

																case 1:
																	history.push('/employee?bloodType=AB')
																	break

																case 2:
																	history.push('/employee?bloodType=B')
																	break
																case 3:
																	history.push('/employee?bloodType=O')
																	break

																default:
																	break
															}
														},
													},
												},
												labels: ['A', 'AB', 'B', 'O'],
											}}
											type='pie'
											height='250vh'
											width='100%'
											series={[summary.data.blood_type.A, summary.data.blood_type.AB, summary.data.blood_type.B, summary.data.blood_type.O]}
											style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
										/>
									)}
								</Card>
							</Col>
						</Row>
					</TabPane>
					<TabPane tab='Klasifikasi Pegawai (ALL)' key='2'>
						<Row gutter={[16, 16]} justify='center' align='middle' style={{ marginTop: 32, marginBottom: 16 }}>
							<Col span={24}>
								<Card bordered={false} style={styles.bigCard}>
									<h1>Jenis Kelamin</h1>
									<Divider />
									<Row align='middle' justify='center'>
										<Col span={8}>
											{!summary.isLoading && (
												<Chart
													options={{
														plotOptions: {
															pie: {
																donut: {
																	labels: {
																		show: true,
																		name: {
																			show: true,
																		},
																		value: {
																			show: true,
																		},
																		total: {
																			show: true,
																			label: 'Laki Laki',
																		},
																	},
																},
															},
														},
														chart: {
															id: 'employe-chart',
															events: {
																dataPointSelection: (a, b, c) => {
																	switch (c.dataPointIndex) {
																		case 0:
																			history.push('/employee?gender=0&employeeType=PNS')
																			break
																		case 1:
																			history.push('/employee?gender=0&employeeType=PPNPN')
																			break
																		default:
																			break
																	}
																},
															},
														},
														labels: ['PNS', 'PPNPN'],
													}}
													type='donut'
													series={[summary.data.gender[0].PNS, summary.data.gender[0].PPNPN]}
													style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
												/>
											)}
										</Col>
										<Col span={8}>
											{!summary.isLoading && (
												<Chart
													options={{
														plotOptions: {
															pie: {
																donut: {
																	labels: {
																		show: true,
																		name: {
																			show: true,
																		},
																		value: {
																			show: true,
																		},
																		total: {
																			show: true,
																			label: 'Perempuan',
																		},
																	},
																},
															},
														},
														colors: ['#9C27B0', '#E91E63'],
														chart: {
															id: 'employe-chart',
															events: {
																dataPointSelection: (a, b, c) => {
																	switch (c.dataPointIndex) {
																		case 0:
																			history.push('/employee?gender=1&employeeType=PNS')
																			break
																		case 1:
																			history.push('/employee?gender=1&employeeType=PPNPN')
																			break
																		default:
																			break
																	}
																},
															},
														},
														labels: ['PNS', 'PPNPN'],
													}}
													type='donut'
													series={[summary.data.gender[1].PNS, summary.data.gender[1].PPNPN]}
													style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
												/>
											)}
										</Col>
									</Row>
								</Card>
							</Col>

							<EducationChart history={history} />

							<BPJSChart history={history} />

							<BirthChart history={history} />
						</Row>
					</TabPane>
				</Tabs>

				{/* <Row gutter={[16, 16]} justify='space-between' style={{ marginTop: 32 }}>
					<Col span={24}>
						<Card bordered={false} style={styles.bigCard1}>
							<div style={{ width: '100%' }}>
								<GeoChart data={data} property='ID' />
							</div>
						</Card>
					</Col>
				</Row> */}
			</Content>
		</Fragment>
	)
}

const styles = {
	magentaCard: {
		background: '#ffadd2',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #ffd6e7',

		borderColor: 'none',
	},
	blueCard: {
		background: '#91d5ff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #bae7ff',
	},
	greenCard: {
		background: '#b7eb8f',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #d9f7be',
	},

	bigCard: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
		// height: '520px',
	},
	bigCard1: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
	},
}

export default Dashboard
