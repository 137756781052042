import React, { Fragment } from 'react'
import { Card, Row, Col, Avatar, Button, notification, Empty, Tooltip } from 'antd'
import { DownloadOutlined, FileFilled, DeleteFilled } from '@ant-design/icons'
import API from '../../../services/API'
import { FileIcon, defaultStyles } from 'react-file-icon'

const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const IS_ADMIN_PROGRAM =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Administrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Administrator')

const Files = ({ program, initialFetch, setSelectedData, setModalDeleteVisible }) => {
	return (
		<Fragment>
			<h4>Lampiran Berkas</h4>
			<br />
			{program.data.programFiles.length < 1 ? (
				<Empty />
			) : (
				program.data.programFiles.map((file, key) => (
					<Row gutter={[12, 12]} key={key}>
						<Col sm={2}>
							<div style={{ width: 30, height: 30 }}>
								<FileIcon
									extension={file.name.split('.')[file.name.split('.').length - 1]}
									{...defaultStyles[file.name.split('.')[file.name.split('.').length - 1]]}
								/>
							</div>
						</Col>
						<Col sm={18} style={{ paddingTop: 15, paddingLeft: 17 }}>
							<a href={file.realPath} target='_blank'>
								<p style={{ fontSize: 14, fontWeight: 500 }}>{file.name}</p>
							</a>
						</Col>
						<Col sm={2} style={{ paddingTop: 15 }}>
							<Tooltip title='Lihat Berkas'>
								<a href={file.realPath} target='_blank'>
									<DownloadOutlined style={{ fontSize: 18 }} />
								</a>
							</Tooltip>
						</Col>
						{(IS_ADMIN || IS_ADMIN_PROGRAM) && (
							<Col sm={2} style={{ paddingTop: 15 }}>
								<Tooltip title='Hapus Berkas'>
									<DeleteFilled
										style={{ color: 'red' }}
										onClick={() => {
											setSelectedData({
												state: 'attachment',
												data: file,
											})
											setModalDeleteVisible(true)
										}}
									/>
								</Tooltip>
							</Col>
						)}
					</Row>
				))
			)}

			<br />
			{(IS_ADMIN || IS_ADMIN_PROGRAM) && (
				<Button
					style={{ width: '100%' }}
					onClick={(e) => {
						document.getElementById('upload-program-files').click()
					}}>
					Tambah Berkas
				</Button>
			)}

			<input
				type={'file'}
				multiple={'multiple'}
				style={{ display: 'none' }}
				id={'upload-program-files'}
				onChange={(e) => {
					if (e.target.files[0]) {
						API.programAttachment(program.data.ID, e.target.files)
							.then((response) => {
								initialFetch()

								notification.success({ message: 'Sukses menambahkan berkas' })
							})
							.catch((e) => notification.error({ message: 'Gagal Upload berkas' }))
					}
				}}
			/>
		</Fragment>
	)
}

export default Files
