import React, { useState, useContext, Fragment } from 'react'
import { Input, Row, Col, Checkbox, Button, Divider, notification, Spin, Tooltip } from 'antd'
import { FileOutlined, FileFilled, CheckSquareOutlined, CloseSquareOutlined, RollbackOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { ProgramContext } from '../../../context/ProgramContext'
import API from '../../../services/API'

const InstitutionReq = ({ activeInstitutionID }) => {
	const programContext = useContext(ProgramContext)
	const { programReqParams, setProgramReqParams, programReq, fetchProgramReq } = programContext
	const form = { description: '', isNeedFile: false }
	const [reqruitments, setReqruitments] = useState([
		{ description: '', isNeedFile: false },
		{ description: '', isNeedFile: false },
		{ description: '', isNeedFile: false },
	])
	const [submitting, setSubmitting] = useState(false)

	const onChangeReq = (e, index) => {
		let temp = [...reqruitments]
		temp[index].description = e.target.value
		setReqruitments(temp)
	}

	const onUpdateRequirement = async (req, isChecked) => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.updateInstitutionRequirement({ programRequirementID: req.ID, institutionID: activeInstitutionID, isChecked })
				switch (isChecked) {
					case '1':
						notification.success({ message: 'Sukses terima persyaratan Lembaga' })
						break
					case '2':
						notification.success({ message: 'Sukses tolak persyaratan Lembaga' })
						break
					case '0':
						notification.success({ message: 'Sukses mengembalikan persyaratan Lembaga' })
						break
					default:
						break
				}
				fetchProgramReq()
				setSubmitting(false)
			} catch (e) {
				if (e.errors.file) {
					notification.error({ message: e.errors.file })
				} else {
					notification.error({ message: 'Gagal Update Persyaratan Lembaga' })
				}
				setSubmitting(false)
			}
		}
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{programReq.isLoading ? (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Spin />
				</div>
			) : (
				programReq.data.map((req, index) => (
					<Row gutter={[16, 16]} justify='center' align='top' key={index}>
						<Col span={1}>
							<span>{index + 1}. </span>
						</Col>
						<Col span={18}>
							<p>{req.description}</p>
						</Col>
						<Col span={2}>
							{req.isNeedFile &&
								(req.institutionRequirement !== null && req.institutionRequirement.requirementFile.length > 0 ? (
									<Tooltip title='Lihat Berkas'>
										<a href={req.institutionRequirement.requirementFile[0].realPath} target='_blank'>
											<Button type='primary' size='small' icon={<FileOutlined />} />
										</a>
									</Tooltip>
								) : (
									<Tooltip title='Belum Ada Berkas'>
										<Button type='dashed' size='small' icon={<FileOutlined />} />
									</Tooltip>
								))}
						</Col>
						<Col span={3}>
							{req.institutionRequirement === null || (req.institutionRequirement !== null && req.institutionRequirement.isChecked === '0') ? (
								<Fragment>
									<Tooltip title='Terima Berkas'>
										<Button size='middle' icon={<CheckSquareOutlined />} type='link' onClick={() => onUpdateRequirement(req, '1')} />
									</Tooltip>

									<Tooltip title='Tolak Berkas'>
										<Button size='middle' danger icon={<CloseSquareOutlined />} type='link' onClick={() => onUpdateRequirement(req, '2')} />
									</Tooltip>
								</Fragment>
							) : req.institutionRequirement !== null && req.institutionRequirement.isChecked === '1' ? (
								<Fragment>
									<Tooltip title='Berkas Diterima'>
										<Button disabled size='middle' icon={<CheckOutlined />} type='link' />
									</Tooltip>
									<Tooltip title='Batalkan Terima'>
										<Button
											size='middle'
											style={{ color: 'orange' }}
											icon={<RollbackOutlined color='orange' />}
											onClick={() => onUpdateRequirement(req, '0')}
											type='link'
										/>
									</Tooltip>
								</Fragment>
							) : req.institutionRequirement !== null && req.institutionRequirement.isChecked === '2' ? (
								<Fragment>
									<Tooltip title='Berkas Ditolak'>
										<Button disabled size='middle' danger icon={<CloseOutlined />} type='link' />
									</Tooltip>

									<Tooltip title='Batalkan Tolak'>
										<Button
											size='middle'
											style={{ color: 'orange' }}
											icon={<RollbackOutlined color='orange' />}
											onClick={() => onUpdateRequirement(req, '0')}
											type='link'
										/>
									</Tooltip>
								</Fragment>
							) : null}

							{/* <Checkbox
							checked={req.institutionRequirement !== null && req.institutionRequirement.isChecked}
							onChange={(e) => {
								API.updateInstitutionRequirement({ programRequirementID: req.ID, institutionID: activeInstitutionID, isChecked: e.target.checked })
									.then(() => {
										notification.success({ message: 'Sukses update persyaratan Lembaga' })
										fetchProgramReq()
									})
									.catch((e) => {
										if (e.errors.file) {
											notification.error({ message: e.errors.file })
										} else {
											notification.error({ message: 'Gagal Update Persyaratan Lembaga' })
										}
									})
							}}
						/> */}
						</Col>
					</Row>
				))
			)}
		</div>
	)
}

export default InstitutionReq
