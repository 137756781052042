import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../../components'
import { Layout, Card, Progress, Spin, Row, Col, Button, Tooltip, Select, Form, Modal, Input, notification, Popconfirm } from 'antd'
import API from '../../../services/API'
import ComponentActivity from './ComponentActivity'
import thousandSeparator from '../../../utils/thousandSeparator'

import {
	MoneyCollectOutlined,
	PlusOutlined,
	DeleteFilled,
	EditFilled,
	ExclamationCircleOutlined,
	QuestionCircleOutlined,
	ArrowLeftOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Content } = Layout

const { Option } = Select
const SubComponent = ({ match, history }) => {
	const [subComponent, setSubComponent] = useState({ data: [], isLoading: true })
	const [components, setComponents] = useState({ data: [], isLoading: true })

	const [modalVisible, setModalVisible] = useState(false)
	const [errors, setErrors] = useState(null)

	const [modalVisibleEdit, setModalVisibleEdit] = useState(false)

	const [submitting, setSubmitting] = useState(false)

	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [loadingArchive, setLoadingArchive] = useState(false)

	const [subComponentData, setSubComponentData] = useState({})

	const [formValue, setFormValue] = useState({
		componentID: null,
		code: '',
		name: '',
	})

	const fetchSubComponent = async () => {
		try {
			setSubComponent({ ...subComponent, isLoading: true })
			const response = await API.getSubComponent({
				code: `${match.params.activityID}.${match.params.outputID}.${match.params.subOutputID}.${match.params.componentID}`,
				isDelete: 0,
			})

			if (response.code === 200) {
				setSubComponent({
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchComponent = async () => {
		try {
			setComponents({ ...components, isLoading: true })
			const response = await API.getComponent({
				code: `${match.params.activityID}.${match.params.outputID}.${match.params.subOutputID}`,
				year: '2020',
				isDelete: 0,
			})
			const fullCode = `${match.params.activityID}.${match.params.outputID}.${match.params.subOutputID}.${match.params.componentID}`

			if (response.code === 200) {
				setComponents({
					data: response.data.list,
					isLoading: false,
				})

				setFormValue({
					...formValue,
					componentID: response.data.list.filter((resp) => resp.fullCode === fullCode)[0].ID,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const submit = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.addSubComponent(formValue)
				fetchSubComponent()
				setSubmitting(false)
				setModalVisible(false)
				setFormValue({ ...formValue, code: '', name: '' })
				notification.success({ message: 'Sukses Tambah Data' })
			} catch (error) {
				setSubmitting(false)
				setErrors(error.errors)
				notification.error({ message: 'Gagal Tambah Data' })
			}
		}
	}

	const onDelete = async () => {
		if (!loadingDelete) {
			try {
				setLoadingDelete(true)
				const response = await API.deleteSubComponent(subComponentData.ID)
				if (response.code === 200) {
					fetchSubComponent()
					setSubComponentData({})
					setModalDeleteVisible(false)
					setLoadingDelete(false)
					notification.success({ message: 'Sukses Hapus Data' })
				}
			} catch (error) {
				setLoadingDelete(false)
				notification.error({ message: 'Gagal Hapus Data' })
				console.log(error)
			}
		}
	}

	const onArchive = async () => {
		if (!loadingArchive) {
			try {
				setLoadingArchive(true)
				const response = await API.updateSubComponent({ isDelete: 1 }, subComponentData.ID)
				if (response.code === 200) {
					fetchSubComponent()
					setSubComponentData({})
					setModalDeleteVisible(false)
					setLoadingArchive(false)
					notification.success({ message: 'Sukses Arsip Data' })
				}
			} catch (error) {
				setLoadingArchive(false)
				notification.error({ message: 'Gagal Arsip Data' })
				console.log(error)
			}
		}
	}

	const onUpdate = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				const response = await API.updateSubComponent({ name: subComponentData.name, code: subComponentData.code }, subComponentData.ID)
				if (response.code === 200) {
					fetchSubComponent()
					setSubComponentData({})
					setModalVisibleEdit(false)
					setSubmitting(false)
					notification.success({ message: 'Sukses Edit Data' })
				}
			} catch (error) {
				setSubmitting(false)
				setErrors(error.errors)
				console.log(error)
			}
		}
	}

	useEffect(() => {
		fetchSubComponent()
		fetchComponent()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Sub Komponen' />

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					{/* DELETE MODAL */}
					<ArrowLeftOutlined onClick={() => history.goBack()} />
					<Modal
						title='Apakah Anda Yakin ?'
						visible={modalDeleteVisible}
						onCancel={() => setModalDeleteVisible(false)}
						footer={[
							<Popconfirm
								placement='bottomRight'
								okType='dashed'
								onConfirm={onDelete}
								title={
									<span>
										Apakah anda yakin untuk menghapus <strong>PERMANEN</strong> data ?
									</span>
								}
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
								<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} loading={loadingDelete}>
									Hapus Permanen
								</Button>
							</Popconfirm>,
							<Popconfirm
								placement='bottomRight'
								okType='dashed'
								onConfirm={onArchive}
								title={<span>Apakah anda yakin untuk arsip data ?</span>}
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
								<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} loading={loadingArchive}>
									Arsip
								</Button>
							</Popconfirm>,
							<Button
								key='submit'
								type='primary'
								onClick={() => {
									setModalDeleteVisible(false)
									setSubComponentData({})
								}}>
								Tidak
							</Button>,
						]}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<ExclamationCircleOutlined style={{ color: '#ffd666', fontSize: '32px' }} />
							<span style={{ marginLeft: '16px' }}>Apakah anda yakin untuk menghapus data ?</span>
						</div>
					</Modal>
					{/* MODAL EDIT */}
					<ModalEdit
						subComponentData={subComponentData}
						formValue={formValue}
						onChangeName={({ target: { value } }) => setSubComponentData({ ...subComponentData, name: value })}
						onChangeCode={({ target: { value } }) => setSubComponentData({ ...subComponentData, code: value })}
						onChangeSelect={(val) => setFormValue({ ...formValue, componentID: val })}
						components={components}
						onCancel={() => {
							setSubComponentData({})
							setModalVisibleEdit(false)
							setErrors(null)
						}}
						submit={onUpdate}
						submitting={submitting}
						errors={errors}
						modalVisible={modalVisibleEdit}
						setModalVisible={setModalVisible}
					/>
					{/* ADD MODAL */}
					<Modal confirmLoading={submitting} title='Tambah Data Output' visible={modalVisible} onCancel={() => setModalVisible(false)} onOk={submit}>
						<Form style={{ paddingLeft: 16, paddingRight: 16 }} layout='vertical'>
							<Form.Item
								label='Kode Komponen'
								help={errors ? errors.activityID && errors.activityID[0] : null}
								validateStatus={errors ? errors.activityID && 'error' : undefined}
								name='activityID'
								rules={[{ required: true, message: 'Silahkan Masukkan Kode Komponen' }]}>
								{components.isLoading ? null : (
									<Select
										placeholder='Pilih Kode Sub Output'
										value={formValue.componentID}
										defaultValue={formValue.componentID}
										onSelect={(val) => setFormValue({ ...formValue, componentID: val })}>
										{components.data.map((sub) => (
											<Option key={sub.ID} value={sub.ID}>
												{sub.fullCode}
											</Option>
										))}
									</Select>
								)}
							</Form.Item>

							<div style={{ marginBottom: '16px' }}>
								<span>
									<span style={{ color: 'red' }}>*</span> Kode Sub Komponen
								</span>
								<Input
									type='text'
									placeholder='Contoh: A'
									value={formValue.code}
									onChange={({ target: { value } }) => setFormValue({ ...formValue, code: value })}
								/>
								<span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span>
							</div>

							<div>
								<span>
									<span style={{ color: 'red' }}>*</span> Nama Sub Komponen
								</span>
								<Input.TextArea
									type='text'
									value={formValue.name}
									placeholder='Contoh: Pengolah Data dan Informasi '
									onChange={({ target: { value } }) => setFormValue({ ...formValue, name: value })}
								/>
								<span style={{ color: 'red' }}>{errors ? errors.name && errors.name[0] : null}</span>
							</div>
						</Form>
					</Modal>
					<Row gutter={[0, 16]} align='middle' justify='end'>
						<Col>
							{components.isLoading ? null : (
								<Button onClick={() => setModalVisible(true)} style={{ borderRadius: '4px' }} type='primary' icon={<PlusOutlined />}>
									Tambah Data
								</Button>
							)}
						</Col>
					</Row>
					<table className='custom-budget-table'>
						<thead>
							<tr>
								<th rowSpan={2}>Kode</th>
								<th rowSpan={2} style={{ width: '40%' }}>
									Uraian
								</th>
								<th colSpan={5}>Perhitungan Tahun Anggaran 2020</th>
								<th rowSpan={2}>Action</th>
							</tr>
							<tr>
								<th>Volume</th>
								<th>Satuan</th>
								<th>Harga Satuan</th>
								<th>Jumlah</th>
								<th>Pengambilan</th>
							</tr>
						</thead>

						<tbody>
							{subComponent.isLoading ? (
								<tr>
									<td colSpan={8}>
										<Spin />
									</td>
								</tr>
							) : (
								subComponent.data.map((data) => (
									<Fragment key={data.ID}>
										<tr className='component-detail-main'>
											<td>{data.code}</td>
											<td className='component-detail-name'> {data.name}</td>
											<td></td>
											<td></td>
											<td></td>
											<td>Rp. {thousandSeparator(data.total)}</td>
											<td></td>
											<td>
												<Tooltip title='Hapus Data'>
													<Button
														onClick={() => {
															setModalDeleteVisible(true)
															setSubComponentData(data)
														}}
														style={{ marginRight: '6px' }}
														style={{ opacity: 0.5 }}
														size='small'
														type='dashed'
														danger
														icon={<DeleteFilled />}
													/>
												</Tooltip>

												{components.isLoading ? null : (
													<Tooltip title='Edit Data'>
														<Button
															onClick={() => {
																setSubComponentData(data)
																setModalVisibleEdit(true)
															}}
															style={{ marginLeft: '6px' }}
															className='custom-edit-btn'
															icon={<EditFilled />}
															type='dashed'
															size='small'
														/>
													</Tooltip>
												)}
												<Tooltip title='Tambah Data Activity'>
													<Link to={`/budget/sub-component/activity/add/${data.ID}`}>
														<Button
															color='#1890ff'
															style={{ marginLeft: '6px' }}
															// className='custom-edit-btn'
															icon={<PlusOutlined />}
															type='dashed'
															size='small'
														/>
													</Link>
												</Tooltip>
											</td>
										</tr>
										<ComponentActivity subComponentID={data.ID} />
									</Fragment>
								))
							)}
						</tbody>
					</table>
				</Card>
			</Content>
		</Fragment>
	)
}

const ModalEdit = ({ modalVisible, onCancel, components, subComponentData, submitting, onChangeName, onChangeCode, errors, submit, formValue }) => {
	return (
		<Modal confirmLoading={submitting} title='Edit Data Output' visible={modalVisible} onCancel={onCancel} onOk={submit}>
			<Form style={{ paddingLeft: 16, paddingRight: 16 }} layout='vertical'>
				<Form.Item
					label='Kode Activity'
					help={errors ? errors.componentID && errors.componentID[0] : null}
					validateStatus={errors ? errors.componentID && 'error' : undefined}
					name='componentID'
					rules={[{ required: true, message: 'Silahkan Masukkan Kode Activity' }]}>
					{components.isLoading ? null : (
						<Select disabled placeholder='Pilih Kode Activity' value={formValue.componentID} defaultValue={formValue.componentID}>
							{components.data.map((component) => (
								<Option key={component.ID} value={component.ID}>
									{component.fullCode}
								</Option>
							))}
						</Select>
					)}
				</Form.Item>

				<div style={{ marginBottom: '16px' }}>
					<span>
						<span style={{ color: 'red' }}>*</span> Kode Sub Komponen
					</span>
					<Input type='text' placeholder='Contoh: 712' value={subComponentData.code} onChange={onChangeCode} />
					<span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span>
				</div>

				<div>
					<span>
						<span style={{ color: 'red' }}>*</span> Nama Sub Komponen
					</span>
					<Input.TextArea type='text' placeholder='Contoh: Layanan Dukungan' onChange={onChangeName} value={subComponentData.name} />
					<span style={{ color: 'red' }}>{errors ? errors.name && errors.name[0] : null}</span>
				</div>
			</Form>
		</Modal>
	)
}

export default SubComponent
