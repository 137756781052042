import React, { Fragment, useEffect, useContext, useState } from 'react'

import { Layout, Row, Col, Card, notification, Button, Avatar, Typography, Divider } from 'antd'
import { CustomHeader } from '../../components'
import AspirationAPI from '../../services/AspirationAPI'
import { CalendarOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import gravatar from 'gravatar'

import Quill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const { Content } = Layout
const { Title } = Typography

const AspirationDetail = ({ match, history }) => {
	const [aspiration, setAspiration] = useState({ data: null, isLoading: true })
	const [threads, setThreads] = useState({ data: [], isLoading: true })
	const [submiting, setSubmitting] = useState(false)
	const [message, setMessage] = useState('')

	const fetchAspirationDetail = async () => {
		try {
			const response = await AspirationAPI.getAspirationDetail(match.params.id)
			setAspiration({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchThreadsDetail = async () => {
		try {
			const response = await AspirationAPI.getThread(match.params.id)
			setThreads({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchAspirationDetail()
		fetchThreadsDetail()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Aspirasi' />
			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				{aspiration.isLoading ? null : (
					<div className='dudi_asp_container'>
						<h1>
							<ArrowLeftOutlined onClick={() => history.goBack()} />
						</h1>
						<Title level={3}>{aspiration.data.subject}</Title>
						<small>
							<CalendarOutlined /> {moment(aspiration.data.created, 'YYYY-MM-DD').format('DD MMM YYYY')} <strong>&#903;</strong> &nbsp;
							{aspiration.data.aspirationReject
								? 'Ditolak ❌'
								: aspiration.data.aspirationConfirmed === null
								? 'Menunggu konfirmasi 🔄'
								: 'Terkonfirmasi ✅'}
						</small>
						<br />

						<br />
						<div dangerouslySetInnerHTML={{ __html: aspiration.data.description }} />

						{aspiration.data.aspirationFiles.length > 0 && (
							<div style={{ marginTop: 16 }}>
								<span>Berkas / Lampiran :</span>

								<ul>
									{aspiration.data.aspirationFiles.map((file) => (
										<li key={file.ID} style={{ fontSize: '0.8rem' }}>
											<a href={file.fileURL} target='_blank' rel='noopener noreferrer'>
												{file.file.split('/')[file.file.split('/').length - 1]}
											</a>
										</li>
									))}
								</ul>
							</div>
						)}

						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{aspiration.data.aspirationReject === null && aspiration.data.aspirationConfirmed == null ? (
									<Fragment>
										<Button
											loading={submiting}
											type='dashed'
											style={{ marginTop: 16, marginLeft: 12 }}
											size='small'
											onClick={() => {
												if (!submiting) {
													setSubmitting(true)
													AspirationAPI.responseAspiration({ status: 'reject' }, match.params.id)
														.then(() => {
															setSubmitting(false)
															fetchAspirationDetail()
															fetchThreadsDetail()
															notification.success({ message: 'Sukses menolak' })
														})
														.catch(() => {
															setSubmitting(false)
															notification.success({ message: 'Gagal menolak' })
														})
												}
											}}>
											Tolak
										</Button>
										<Button
											loading={submiting}
											type='primary'
											style={{ marginTop: 16, marginLeft: 12 }}
											size='small'
											onClick={() => {
												if (!submiting) {
													setSubmitting(true)
													AspirationAPI.responseAspiration({ status: 'confirm' }, match.params.id)
														.then(() => {
															setSubmitting(false)
															fetchAspirationDetail()
															fetchThreadsDetail()
															notification.success({ message: 'Sukses menerima' })
														})
														.catch(() => {
															setSubmitting(false)
															notification.success({ message: 'Gagal menerima' })
														})
												}
											}}>
											Terima
										</Button>
									</Fragment>
								) : null}
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Avatar src={gravatar.url(aspiration.data.employee.email, { d: 'identicon', s: 36 })} />
								<span style={{ marginLeft: '12px' }}>{aspiration.data.employee.name}</span>
							</div>
						</div>
						<br />

						<Divider />
					</div>
				)}

				<div style={{ display: 'flex', flexDirection: 'column' }} className='dudi_asp_container'>
					{threads.isLoading
						? null
						: threads.data.map((thread) => (
								<div style={{ display: 'flex', marginLeft: thread.isAdmin !== 0 ? 'auto' : 0, marginBottom: 16 }} key={thread.ID}>
									<Card>
										<div className='d-flex'>
											<Avatar
												src={
													thread.isAdmin === 0
														? gravatar.url(thread.account.email, {
																d: 'identicon',
																s: '200',
														  })
														: gravatar.url('mitrasdudi@gmail.com', { d: 'identicon', s: '200' })
												}
											/>
											<div className='ml-2 '>
												<div>
													<span style={{ fontSize: '0.9rem', fontWeight: '500' }}>
														{thread.isAdmin === 0 ? thread.account.employeeAccess?.employee.name : 'Admin'}
													</span>
													<span style={{ fontSize: '0.7rem' }} className='ml-2'>
														&nbsp; <strong>&#903;</strong> &nbsp; {moment(thread.created).format('DD MMM YYYY')}
													</span>
												</div>

												<div className='mt-2 thread-wrapper' dangerouslySetInnerHTML={{ __html: thread.message }}></div>
											</div>
										</div>
									</Card>
								</div>
						  ))}
				</div>

				{aspiration.isLoading && threads.isLoading ? null : (
					<div className='dudi_asp_container'>
						<Divider />

						<div className='discuss-input-wrapper'>
							<div>
								<Title level={4}>Tulis balasan anda</Title>
								<Quill onChange={(value) => setMessage(value)} />

								<div className='float-right mt-3'>
									<Button
										disabled={submiting}
										type='primary'
										onClick={(e) => {
											e.preventDefault()
											if (!submiting) {
												setSubmitting(true)
												AspirationAPI.replyAspiration({ message }, match.params.id)
													.then(() => {
														fetchAspirationDetail()
														fetchThreadsDetail()
														setSubmitting(false)
													})
													.catch(() => {
														notification.error({ message: 'Gagal kirim balasan' })
														setSubmitting(false)
													})
											}
										}}
										style={{ marginTop: 16 }}>
										Kirim Balasan
									</Button>
								</div>
							</div>
						</div>
					</div>
				)}
			</Content>
		</Fragment>
	)
}

export default AspirationDetail
