import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../../components'
import { Layout, Card, Table, Button, Col, Row, Modal, Form, Input, Select, Progress, notification } from 'antd'

import { PlusOutlined, DeleteFilled, EditFilled, ExclamationCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'

import thousandSeparator from '../../../utils/thousandSeparator'
import API from '../../../services/API'

const { Content } = Layout
const { Option } = Select

const Components = ({ match, history }) => {
	const [components, setComponents] = useState({ data: [], isLoading: true })
	const [subOutput, setSubOutput] = useState({ data: [], isLoading: true })
	const [modalVisible, setModalVisible] = useState(false)
	const [errors, setErrors] = useState(null)
	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
	const [modalVisibleEdit, setModalVisibleEdit] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [componentData, setComponentData] = useState({})

	const [submitting, setSubmitting] = useState(false)
	const [componentsFormValue, setComponentsFormValue] = useState({
		subOutputID: null,
		code: '',
		name: '',
	})

	const fetchSubOutput = async () => {
		try {
			setSubOutput({ ...subOutput, isLoading: true })
			const response = await API.getAllSubOutput({ year: '2020', isDelete: 0 })

			if (response.code === 200) {
				setSubOutput({
					isLoading: false,
					data: response.data.list,
				})

				setComponentsFormValue({
					...componentsFormValue,
					subOutputID: response.data.list.filter((data) => data.code === match.params.subOutputID)[0].ID,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const submit = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.addComponent(componentsFormValue)
				fetchComponent()
				setSubmitting(false)
				setModalVisible(false)
				setComponentsFormValue({ ...componentsFormValue, code: '', name: '' })
			} catch (error) {
				setSubmitting(false)
				setErrors(error.errors)
			}
		}
	}

	const onDelete = async () => {
		if (!loadingDelete) {
			try {
				setLoadingDelete(true)
				await API.deleteComponent(componentData.ID)
				notification.success({ message: 'Sukses Hapus Data' })
				fetchComponent()
				setModalDeleteVisible(false)
				setComponentData({})
				setLoadingDelete(false)
			} catch (error) {
				console.log(error)
				setLoadingDelete(false)
				notification.error({ message: 'Gagal Hapus Data' })
			}
		}
	}

	const fetchComponent = async () => {
		try {
			setComponents({ ...components, isLoading: true })
			const response = await API.getComponent({
				code: `${match.params.activityID}.${match.params.outputID}.${match.params.subOutputID}`,
				year: '2020',
				isDelete: 0,
			})

			if (response.code === 200) {
				setComponents({
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const onUpdate = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.updateComponent(componentData.ID, { name: componentData.nomenklatur, code: componentData.kode })
				fetchComponent()
				setComponentData({})
				setModalVisibleEdit(false)
				setSubmitting(false)
				notification.success({ message: 'Sukses Edit Data' })
			} catch (error) {
				console.log(error)
				setSubmitting(false)
				notification.error({ message: 'Gagal Edit Data' })
			}
		}
	}

	useEffect(() => {
		fetchSubOutput()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		fetchComponent()
		// eslint-disable-next-line
	}, [])

	const columns = [
		{
			title: <span style={{ fontSize: '12px' }}>No.</span>,
			dataIndex: 'no',
			align: 'center',
			render: (text) => <span style={{ fontSize: '12px' }}>{text}</span>,
		},
		{
			title: <span style={{ fontSize: '12px' }}>KODE</span>,
			dataIndex: 'kode',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', textAlign: 'start', fontSize: '12px' }}>
					<Link to={`${match.params.activityID}.${match.params.outputID}.${match.params.subOutputID}.${data.kode}`}>
						{match.params.activityID}.{match.params.outputID}.{match.params.subOutputID}.{text}
					</Link>
				</div>
			),
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>NOMENKLATUR OUTPUT KEGIATAN</span>,
			dataIndex: 'nomenklatur',
			align: 'center',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', textAlign: 'start', fontSize: '12px' }}>
					<Link to={`${match.params.activityID}.${match.params.outputID}.${match.params.subOutputID}.${data.kode}`}>{text}</Link>
				</div>
			),
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>TOTAL</span>,
			dataIndex: 'total',
			align: 'center',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
					<span>Rp. {thousandSeparator(text)}</span>
				</div>
			),
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>SISA</span>,
			dataIndex: 'withdraw',
			align: 'center',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
					<span>Rp. {thousandSeparator(text.totalMoney - text.totalWithdraw)}</span>
				</div>
			),
		},

		{
			title: () => <span style={{ fontSize: '12px' }}>ACTION</span>,
			align: 'center',
			render: (text, record) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
					<Button
						onClick={() => {
							setComponentData(record)
							setModalDeleteVisible(true)
						}}
						style={{ marginRight: '6px' }}
						style={{ opacity: 0.5 }}
						size='small'
						type='dashed'
						danger
						icon={<DeleteFilled />}
					/>
					{subOutput.isLoading ? null : (
						<Button
							onClick={() => {
								setComponentData(record)
								setModalVisibleEdit(true)
							}}
							style={{ marginLeft: '6px' }}
							className='custom-edit-btn'
							icon={<EditFilled />}
							type='dashed'
							size='small'
						/>
					)}
				</div>
			),
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Komponen' />
			<ModalEdit
				componentData={componentData}
				componentsFormValue={componentsFormValue}
				onChangeName={({ target: { value } }) => setComponentData({ ...componentData, nomenklatur: value })}
				onChangeCode={({ target: { value } }) => setComponentData({ ...componentData, kode: value })}
				onChangeSelect={(val) => setComponentsFormValue({ ...componentsFormValue, subOutputID: val })}
				subOutput={subOutput}
				onCancel={() => {
					setComponentData({})
					setModalVisibleEdit(false)
					setErrors(null)
				}}
				submit={onUpdate}
				submitting={submitting}
				errors={errors}
				modalVisible={modalVisibleEdit}
				setModalVisible={setModalVisible}
			/>

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Modal
					title='Apakah Anda Yakin ?'
					visible={modalDeleteVisible}
					onCancel={() => setModalDeleteVisible(false)}
					footer={[
						<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} onClick={() => onDelete()} loading={loadingDelete}>
							Ya
						</Button>,
						<Button
							key='cancel'
							type='primary'
							onClick={() => {
								setModalDeleteVisible(false)
								setComponentData({})
							}}>
							Tidak
						</Button>,
					]}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<ExclamationCircleOutlined style={{ color: '#ffd666', fontSize: '32px' }} />
						<span style={{ marginLeft: '16px' }}>Apakah anda yakin untuk menghapus data ?</span>
					</div>
				</Modal>

				<Modal confirmLoading={submitting} title='Tambah Data Output' visible={modalVisible} onCancel={() => setModalVisible(false)} onOk={submit}>
					<Form style={{ paddingLeft: 16, paddingRight: 16 }} layout='vertical'>
						<Form.Item
							label='Kode Sub Output'
							help={errors ? errors.activityID && errors.activityID[0] : null}
							validateStatus={errors ? errors.activityID && 'error' : undefined}
							name='activityID'
							rules={[{ required: true, message: 'Silahkan Masukkan Kode Sub Output' }]}>
							{subOutput.isLoading ? null : (
								<Select
									placeholder='Pilih Kode Sub Output'
									value={componentsFormValue.subOutputID}
									defaultValue={componentsFormValue.subOutputID}
									onSelect={(val) => setComponentsFormValue({ ...componentsFormValue, subOutputID: val })}>
									{subOutput.data.map((sub) => (
										<Option key={sub.ID} value={sub.ID}>
											{sub.fullCode}
										</Option>
									))}
								</Select>
							)}
						</Form.Item>

						<div style={{ marginBottom: '16px' }}>
							<span>
								<span style={{ color: 'red' }}>*</span> Kode Komponen
							</span>
							<Input
								type='number'
								placeholder='Contoh: 712'
								value={componentsFormValue.code}
								onChange={({ target: { value } }) => setComponentsFormValue({ ...componentsFormValue, code: value })}
							/>
							<span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span>
						</div>

						<div>
							<span>
								<span style={{ color: 'red' }}>*</span> Nama Komponen
							</span>
							<Input.TextArea
								type='text'
								value={componentsFormValue.name}
								placeholder='Contoh: Layanan Dukungan'
								onChange={({ target: { value } }) => setComponentsFormValue({ ...componentsFormValue, name: value })}
							/>
							<span style={{ color: 'red' }}>{errors ? errors.name && errors.name[0] : null}</span>
						</div>
					</Form>
				</Modal>

				<Card className='__main_card_table' bordered={false}>
					<ArrowLeftOutlined onClick={() => history.goBack()} />
					<Row gutter={[0, 16]} align='middle' justify='end'>
						<Col>
							{subOutput.isLoading ? null : (
								<Button onClick={() => setModalVisible(true)} style={{ borderRadius: '4px' }} type='primary' icon={<PlusOutlined />}>
									Tambah Data
								</Button>
							)}
						</Col>
					</Row>
					<Table
						size='small'
						style={{ fontSize: '12px' }}
						columns={columns}
						loading={components.isLoading}
						dataSource={components.data.map((data, index) => ({
							key: index,
							no: index + 1,
							ID: data.ID,
							kode: data.code,
							nomenklatur: data.name,
							total: data.total,
							withdraw: data.withdraw,
						}))}
						bordered
					/>
				</Card>
			</Content>
		</Fragment>
	)
}

const ModalEdit = ({
	modalVisible,
	onCancel,
	subOutput,
	componentData,
	submitting,
	onChangeName,
	onChangeCode,
	errors,
	onChangeSelect,
	submit,
	componentsFormValue,
}) => {
	return (
		<Modal confirmLoading={submitting} title='Edit Data Output' visible={modalVisible} onCancel={onCancel} onOk={submit}>
			<Form style={{ paddingLeft: 16, paddingRight: 16 }} layout='vertical'>
				<Form.Item
					label='Kode Output'
					help={errors ? errors.subOutputID && errors.subOutputID[0] : null}
					validateStatus={errors ? errors.subOutputID && 'error' : undefined}
					name='activityID'
					rules={[{ required: true, message: 'Silahkan Masukkan Kode Output' }]}>
					{subOutput.isLoading ? null : (
						<Select
							disabled
							placeholder='Pilih Kode Activity'
							value={componentsFormValue.subOutputID}
							defaultValue={componentsFormValue.subOutputID}
							onSelect={onChangeSelect}>
							{subOutput.data.map((activity) => (
								<Option key={activity.ID} value={activity.ID}>
									{activity.code}
								</Option>
							))}
						</Select>
					)}
				</Form.Item>

				<div style={{ marginBottom: '16px' }}>
					<span>
						<span style={{ color: 'red' }}>*</span> Kode Sub Output
					</span>
					<Input type='number' placeholder='Contoh: 712' value={componentData.kode} onChange={onChangeCode} />
					<span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span>
				</div>

				<div>
					<span>
						<span style={{ color: 'red' }}>*</span> Nama Sub Output
					</span>
					<Input.TextArea type='text' placeholder='Contoh: Layanan Dukungan' onChange={onChangeName} value={componentData.nomenklatur} />
					<span style={{ color: 'red' }}>{errors ? errors.name && errors.name[0] : null}</span>
				</div>
			</Form>
		</Modal>
	)
}

export default Components
