import React, { Fragment, useEffect, useContext, useState } from 'react'

import { Layout, Row, Col, notification, Button, Table, Typography, Modal, Select } from 'antd'
import { CustomHeader } from '../../components'
import { CheckOutlined, BranchesOutlined } from '@ant-design/icons'

import _ from 'lodash'
import JobFairAPI from '../../services/JobFairAPI'
import { da } from 'date-fns/locale'

const { Content } = Layout
const { Title } = Typography
const { Option } = Select

const Skill = () => {
	const [confirmedSkills, setConfirmedSkill] = useState({ data: [], isLoading: true })
	const [unconfirmedSkills, setUnconfirmedSkill] = useState({ data: [], isLoading: true })
	const [mergedSkills, setMergedSkills] = useState({ source: '', target: '' })
	const [modalVisible, setModalVisible] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const fetchConfirmedSkills = async () => {
		try {
			const response = await JobFairAPI.getSkills({ confirmed: 1 })
			setConfirmedSkill({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchUnconfirmedSkills = async () => {
		try {
			const response = await JobFairAPI.getSkills({ confirmed: 0 })
			setUnconfirmedSkill({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchConfirmedSkills()
		fetchUnconfirmedSkills()
	}, [])

	const columnSkillConfirmed = [
		{
			title: 'Skill',
			dataIndex: 'label',
			key: 'label',
			width: '70%',
			align: 'center',
		},
	]

	const columnSkillUnconfirmed = [
		{
			title: 'Skill',
			dataIndex: 'label',
			key: 'label',
			width: '70%',
			align: 'center',
		},
		{
			title: 'Action',
			render: (data) => (
				<div>
					<Button
						loading={submitting}
						style={{ marginLeft: '6px', borderColor: '#40a9ff' }}
						icon={<CheckOutlined style={{ color: '#40a9ff' }} />}
						type='link'
						size='small'
						onClick={() => {
							if (!submitting) {
								setSubmitting(true)
								JobFairAPI.mergedSkills({ source: data.ID })
									.then(() => {
										notification.success({ message: 'Sukses konfirmasi skill' })
										fetchConfirmedSkills()
										fetchUnconfirmedSkills()
										setSubmitting(false)
									})
									.catch(() => {
										notification.error({ message: 'Gagal konfirmasi skill' })
										setSubmitting(false)
									})
							}
						}}
					/>
					<Button
						loading={submitting}
						style={{ marginLeft: '6px', borderColor: '#fff' }}
						icon={<BranchesOutlined style={{ color: '#fff' }} />}
						type='primary'
						size='small'
						onClick={() => {
							setMergedSkills({ ...mergedSkills, source: data.ID })
							setModalVisible(true)
						}}
					/>
				</div>
			),
			key: 'action',
			align: 'center',
		},
	]

	return (
		<Fragment>
			<Modal
				onOk={() => {
					if (!submitting) {
						setSubmitting(true)
						JobFairAPI.mergedSkills(mergedSkills)
							.then(() => {
								notification.success({ message: 'Sukses konfirmasi skill' })
								fetchConfirmedSkills()
								fetchUnconfirmedSkills()
								setSubmitting(false)
								setModalVisible(false)
							})
							.catch(() => {
								notification.error({ message: 'Gagal konfirmasi skill' })
								setSubmitting(false)
							})
					}
				}}
				title='Pilih skill yang akan di gabung'
				visible={modalVisible}
				onCancel={() => {
					setMergedSkills({ target: '', source: '' })
					setModalVisible(false)
				}}
				confirmLoading={submitting}>
				<Select
					onChange={(val) => setMergedSkills({ ...mergedSkills, target: val })}
					loading={confirmedSkills.isLoading}
					placeholder='Pilih skill'
					showSearch
					style={{ marginTop: '8px', width: '100%' }}>
					{confirmedSkills.data.map((skill) => (
						<Option key={skill.ID} value={skill.ID}>
							{skill.label}
						</Option>
					))}
				</Select>
			</Modal>

			<CustomHeader title='Mitra Vokasi' />
			<Content style={{ padding: '64px', background: '#fff' }}>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Title level={4}>Skill belum terkonfirmasi</Title>

						<Table
							bordered
							dataSource={unconfirmedSkills.data.map((skill, index) => ({ ...skill, key: index }))}
							loading={unconfirmedSkills.isLoading}
							columns={columnSkillUnconfirmed}
							pagination={false}
						/>
					</Col>
					<Col span={12}>
						<Title level={4}>Skill terkonfirmasi</Title>
						<Table
							bordered
							dataSource={confirmedSkills.data.map((skill, index) => ({ ...skill, key: index }))}
							loading={confirmedSkills.isLoading}
							columns={columnSkillConfirmed}
							pagination={false}
						/>
					</Col>
				</Row>
			</Content>
		</Fragment>
	)
}

export default Skill
