import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../components'
import { Layout, List } from 'antd'

import ContractAPI from '../../services/ContractAPI'
import TemplateItem from './Components/TemplateItem'

const { Content } = Layout

const Template = () => {
	const [listTemplate, setListTemplate] = useState({ data: [], isLoading: true })

	const fetchListTemplate = async () => {
		try {
			const response = await ContractAPI.listTemplate({ expand: 'templateClauseGroups' })
			setListTemplate({
				isLoading: false,
				data: response.data.list,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchListTemplate()
	}, [])

	return (
		<Fragment>
			<CustomHeader title='Contract' />

			<Content style={{ padding: '32px 16px', background: '#fff' }}>
				<List
					header={
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
							<h3>Template</h3>
						</div>
					}
					loading={listTemplate.isLoading}
					bordered
					dataSource={listTemplate.data}
					renderItem={(item) => (
						<List.Item>
							<TemplateItem item={item} templateClauseGroups={item.templateClauseGroups} onSuccesUpdate={() => fetchListTemplate()} />
						</List.Item>
					)}
				/>
			</Content>
		</Fragment>
	)
}

export default Template
