import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../components'
import { Layout, Col, Row, Input, Card, Table, DatePicker } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'
import AbsentAPI from '../../services/AbsentApi'

moment.locale('id')

const { Content } = Layout
const { Search } = Input

export default () => {
	const [duties, setDuties] = useState({ data: [], isLoading: true })
	const [parameters, setParameters] = useState({})

	const getDuties = async () => {
		try {
			const response = await AbsentAPI.getAllemployeeDuty(parameters)
			setDuties({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getDuties()
	}, [parameters])

	const columns = [
		{
			title: 'Nama Lengkap ',
			dataIndex: 'employee',
			render: (text) => <Link to={`/employee/${text.ID}`}>{text.name}</Link>,
			align: 'center',
		},
		{
			title: 'Tanggal Dinas ',
			dataIndex: 'created',
			render: (text) => <span>{text ? moment(text, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss') : 'Belum Absen'}</span>,
			align: 'center',
		},
		{
			title: 'No. Surat ',
			dataIndex: 'number',
			align: 'center',
		},
		{
			title: 'Keterangan ',
			dataIndex: 'description',
			align: 'center',
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Employee' />
			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[16, 16]} align='middle'>
						<Col span={12}>
							<div style={{ display: 'flex' }}>
								<span>Filter tanggal : </span>
								<DatePicker
									allowClear={false}
									style={{ width: '50%', marginLeft: '16px' }}
									format='YYYY-MM-DD'
									value={moment(parameters.date, 'YYYY-MM-DD')}
									onChange={(date, dateString) => setParameters({ ...parameters, date: dateString })}
								/>
							</div>
						</Col>
					</Row>
					{duties.isLoading ? null : <Table dataSource={duties.data.map((data) => ({ ...data, key: data.ID }))} bordered columns={columns} />}
				</Card>
			</Content>
		</Fragment>
	)
}
