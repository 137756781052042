import React, { createContext, useState, useEffect } from 'react'
import API from '../services/API'
import MasterAPI from '../services/MasterAPI'

export const EmployeeContext = createContext()
export const EmployeeContextConsumer = EmployeeContext.Consumer

const EmployeeContextProvider = ({ children }) => {
	const [employeeList, setEmployeeList] = useState({ data: [], isLoading: true, pagination: {} })
	const [employee, setEmployee] = useState({ data: {}, isLoading: true })
	const [employeeEdit, setEmployeeEdit] = useState({})
	const [summary, setSummary] = useState({ data: {}, isLoading: true })
	const [query, setQuery] = useState({ order: 'asc', sort: 'name', pageSize: 20, currentPage: 1, keyword: '' })
	const [cities, setCities] = useState({ data: [], isLoading: true, parameters: { keyword: '' } })
	const [fullName, setFullname] = useState({ prefix: '', name: '', suffix: '' })

	const [educationTier, setEducationTier] = useState({ data: [], isLoading: true })
	const [employeePosition, setEmployeePosition] = useState({ data: [], isLoading: true })
	const [employeeCategory, setEmployeeCategory] = useState({ data: [], isLoading: true })
	const [religion, setReligion] = useState({ data: [], isLoading: true })

	const [civilianSummary, setCivilianSummary] = useState({ data: {}, isLoading: true })
	const [educationSummary, setEducationSummary] = useState({ data: {}, isLoading: true })
	const [BPJSSummary, setBPJSSummary] = useState({ data: {}, isLoading: true })

	const [banks, setBanks] = useState({ data: [], isLoading: true })

	const [submitting, setSubmitting] = useState(false)

	const [error, setError] = useState(null)
	const [employeeForm, setEmployeeForm] = useState({
		name: '',
		bornDate: '',
		bornPlace: '',
		address: '',
		religionID: '',
		gender: '',
		NIK: '',
		employeePositionID: '',
		cityID: '',
		NPWP: '',
		employeeType: 'PNS',
		phoneNumber: '',
		email: '',
	})

	useEffect(() => {
		setEmployeeForm({
			...employeeForm,
			name: `${fullName.prefix} ${fullName.name} ${fullName.suffix}`,
		})
	}, [fullName])

	const fetchCities = async () => {
		try {
			const response = await MasterAPI.getCity(cities.parameters)
			if (response.code === 200) {
				setCities({
					...cities,
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchCities()
	}, [cities.parameters])

	const getEducationTier = async () => {
		try {
			setEducationTier({ ...educationTier, isLoading: true })
			const response = await MasterAPI.getEducationTier()
			setEducationTier({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			setEducationTier({
				data: [],
				isLoading: false,
			})
			console.log(error)
		}
	}

	const fetchAllBank = async () => {
		try {
			setBanks({ ...banks, isLoading: true })

			const response = await MasterAPI.getAllBank()

			if (response.code === 200) {
				setBanks({
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const getEmployeePosition = async () => {
		try {
			setEmployeePosition({ ...employeePosition, isLoading: true })
			const response = await MasterAPI.getEmployeePosition()
			setEmployeePosition({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			setEmployeePosition({
				data: [],
				isLoading: false,
			})
			console.log(error)
		}
	}

	const getEmployeeCategory = async () => {
		try {
			setEmployeeCategory({ ...employeeCategory, isLoading: true })
			const response = await MasterAPI.getEmployeeCategory()
			setEmployeeCategory({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			setEmployeeCategory({
				data: [],
				isLoading: false,
			})
			console.log(error)
		}
	}

	const getReligion = async () => {
		try {
			setReligion({ ...religion, isLoading: true })
			const response = await MasterAPI.getReligion({ pageSize: 10 })
			setReligion({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			setReligion({
				data: [],
				isLoading: false,
			})
			console.log(error)
		}
	}

	const getCivilianSummary = async () => {
		try {
			setCivilianSummary({ ...civilianSummary, isLoading: true })
			const response = await API.getSummaryCivilianCategory()
			setCivilianSummary({
				data: response,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getEducationSummary = async () => {
		try {
			setEducationSummary({ ...educationSummary, isLoading: true })
			const response = await API.getSummaryEducation()
			setEducationSummary({
				data: response,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getBPJSResume = async () => {
		try {
			setBPJSSummary({ ...BPJSSummary, isLoading: true })
			const response = await API.getBPJSResume()
			setBPJSSummary({
				data: response,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getEducationTier()
		getEmployeePosition()
		getEmployeeCategory()
		getReligion()
		fetchAllBank()
		getCivilianSummary()
		getEducationSummary()
		getBPJSResume()
	}, [])

	const fetchDetailEmployee = (id) => {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await API.getDetailEmploye(id)
				setEmployeeEdit(response.data)
				setEmployee({
					data: response.data,
					isLoading: false,
				})
				resolve(response.data)
			} catch (error) {
				console.log(error)
				reject(error)
			}
		})
	}

	const fetchEmployeeList = async () => {
		try {
			setEmployeeList({ ...employeeList, isLoading: true })
			const response = await API.getAllEmploye(query)

			setEmployeeList({
				data: response.data.list,
				pagination: response.data.pagination,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const addEmployeeAvatar = (body = {}) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.addEmployeeAvatar(body)
					setSubmitting(false)
					setError(null)
					resolve(response)
				} catch (error) {
					setSubmitting(false)
					setError(error.errors)
					reject(error)
				}
			}
		})
	}

	const updateEmployeeAvatar = (body = {}) => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.updateEmployeeAvatar(body)
					setSubmitting(false)
					setError(null)
					resolve(response)
				} catch (error) {
					setSubmitting(false)
					setError(error.errors)
					reject(error)
				}
			}
		})
	}

	const addEmployee = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.addEmployee(employeeForm)
					setSubmitting(false)
					setError(null)
					setEmployeeForm({
						name: '',
						nameWithDegree: '',
						bornDate: '',
						bornPlace: '',
						address: '',
						religionID: '',
						gender: '',
						educationTierID: '',
						NIK: '',
						employeePositionID: '',
						cityID: '',
						NPWP: '',
						employeeTypeID: '',
						isMarried: '',
						phoneNumber: '',
						email: '',
					})
					resolve(response)
				} catch (error) {
					setSubmitting(false)
					setError(error)
					setTimeout(() => {
						setError(null)
					}, 5000)
					reject(error)
				}
			}
		})
	}

	const fetchSummary = async () => {
		try {
			setSummary({ ...summary, isLoading: true })
			const response = await API.getEmployeeSummary()
			setSummary({
				data: response,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const updateEmployee = () => {
		return new Promise(async (resolve, reject) => {
			if (!submitting) {
				try {
					setSubmitting(true)
					const response = await API.updateEmployee(employeeEdit)
					setSubmitting(false)
					setError(null)

					resolve(response)
				} catch (error) {
					setSubmitting(false)
					setError(error)
					reject(error)
				}
			}
		})
	}

	useEffect(() => {
		fetchEmployeeList()
	}, [query])

	useEffect(() => {
		fetchSummary()
	}, [])

	return (
		<EmployeeContext.Provider
			value={{
				employeeList,
				fetchDetailEmployee,
				employee,
				fetchEmployeeList,
				setEmployee,
				employeeEdit,
				setEmployeeEdit,

				employeeForm,
				setEmployeeForm,

				addEmployee,
				submitting,
				error,

				fullName,
				setFullname,

				addEmployeeAvatar,
				updateEmployeeAvatar,
				fetchSummary,
				summary,

				query,
				setQuery,

				updateEmployee,

				educationTier,
				setEducationTier,
				employeePosition,
				setEmployeePosition,
				employeeCategory,
				setEmployeeCategory,
				religion,
				setReligion,

				cities,
				setCities,

				banks,
				setBanks,
				fetchAllBank,

				civilianSummary,
				setCivilianSummary,

				educationSummary,
				setEducationSummary,

				BPJSSummary,
				setBPJSSummary,
			}}>
			{children}
		</EmployeeContext.Provider>
	)
}

export default EmployeeContextProvider
