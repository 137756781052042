import React, { useContext } from 'react'

import { Row, Col, Card, Divider } from 'antd'

import Chart from 'react-apexcharts'
import { EmployeeContext } from '../../../context/EmployeeContext'

const EducationChart = ({ history }) => {
	const employeeContext = useContext(EmployeeContext)
	const { educationSummary } = employeeContext

	return (
		<Col span={24}>
			{educationSummary.isLoading ? null : (
				<Card bordered={false} style={styles.bigCard}>
					<h1>Perspektif Background Pendidikan</h1>
					<Divider />

					<Row gutter={[16, 16]} align='middle' justify='center'>
						<Col span={8}>
							<Chart
								options={{
									plotOptions: {
										pie: {
											donut: {
												labels: {
													show: true,
													name: {
														show: true,
													},
													value: {
														show: true,
													},
													total: {
														show: true,
														label: 'PNS',
													},
												},
											},
										},
									},

									chart: {
										id: 'employe-chart',
										events: {
											dataPointSelection: (a, b, c) => {
												switch (c.dataPointIndex) {
													case 0:
														history.push('/employee?lastEducation=S3-Doktor&employeeType=PNS')
														break
													case 1:
														history.push('/employee?lastEducation=S2-Magister&employeeType=PNS')
														break
													case 2:
														history.push('/employee?lastEducation=S1-Sarjana&employeeType=PNS')
														break
													case 3:
														history.push('/employee?lastEducation=SMA/SMK&employeeType=PNS')
														break
													case 4:
														history.push('/employee?lastEducation=SD&employeeType=PNS')
														break
													case 5:
														history.push('/employee?lastEducation=SMP&employeeType=PNS')
														break
													default:
														break
												}
											},
										},
									},
									labels: ['S3', 'S2', 'S1', 'SMA/SMK', 'SD', 'SMP'],
								}}
								type='donut'
								series={[
									educationSummary.data['S3-Doktor']['PNS'],
									educationSummary.data['S2-Magister']['PNS'],
									educationSummary.data['S1-Sarjana']['PNS'],
									educationSummary.data['SMA/SMK']['PNS'],
									educationSummary.data['SD']['PNS'],
									educationSummary.data['SMP']['PNS'],
								]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>
						<Col span={8}>
							<Chart
								options={{
									plotOptions: {
										pie: {
											donut: {
												labels: {
													show: true,
													name: {
														show: true,
													},
													value: {
														show: true,
													},
													total: {
														show: true,
														label: 'PPNPN',
													},
												},
											},
										},
									},

									chart: {
										id: 'employe-chart',
										events: {
											dataPointSelection: (a, b, c) => {
												switch (c.dataPointIndex) {
													case 0:
														history.push('/employee?lastEducation=S3-Doktor&employeeType=PPNPN')
														break
													case 1:
														history.push('/employee?lastEducation=S2-Magister&employeeType=PPNPN')
														break
													case 2:
														history.push('/employee?lastEducation=S1-Sarjana&employeeType=PPNPN')
														break
													case 3:
														history.push('/employee?lastEducation=SMA/SMK&employeeType=PPNPN')
														break
													case 4:
														history.push('/employee?lastEducation=SD&employeeType=PPNPN')
														break
													case 5:
														history.push('/employee?lastEducation=SMP&employeeType=PPNPN')
														break
													default:
														break
												}
											},
										},
									},
									labels: ['S3', 'S2', 'S1', 'SMA/SMK', 'SD', 'SMP'],
								}}
								type='donut'
								series={[
									educationSummary.data['S3-Doktor']['PPNPN'],
									educationSummary.data['S2-Magister']['PPNPN'],
									educationSummary.data['S1-Sarjana']['PPNPN'],
									educationSummary.data['SMA/SMK']['PPNPN'],
									educationSummary.data['SD']['PPNPN'],
									educationSummary.data['SMP']['PPNPN'],
								]}
								style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
							/>
						</Col>

						<Col span={8} style={{ textAlign: 'center' }}>
							<h1 style={{ fontSize: '18px' }}>Tidak Terdata: {educationSummary.data['notDefined']}</h1>
						</Col>
					</Row>
				</Card>
			)}
		</Col>
	)
}
const styles = {
	magentaCard: {
		background: '#ffadd2',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #ffd6e7',

		borderColor: 'none',
	},
	blueCard: {
		background: '#91d5ff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #bae7ff',
	},
	greenCard: {
		background: '#b7eb8f',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #d9f7be',
	},

	bigCard: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
		// height: '520px',
	},
	bigCard1: {
		background: '#fff',
		borderRadius: '4px',
		boxShadow: '-12px 12px 24px #f0f0f0',
	},
}
export default EducationChart
