import React, { Fragment, useState, useEffect } from 'react'
import { CustomHeader } from '../../../components'
import { Layout, Card, Table, Button, Col, Row, Modal, Form, Input, Select, notification } from 'antd'
import { EditFilled, DeleteFilled, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import API from '../../../services/API'
import thousandSeparator from '../../../utils/thousandSeparator'

import { Link } from 'react-router-dom'

const { Content } = Layout
const { Option } = Select

const Outputs = ({ match }) => {
	const [output, setOutput] = useState({ data: [], isLoading: true })
	const [activities, setActivities] = useState({ data: [], isLoading: true })
	const [modalVisible, setModalVisible] = useState(false)
	const [modalVisibleEdit, setModalVisibleEdit] = useState(false)
	const [errors, setErrors] = useState(null)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
	const [outputData, setOutputData] = useState({})

	const [submitting, setSubmitting] = useState(false)
	const [outputFormValue, setOutputFormValue] = useState({
		activityID: null,
		code: '',
		name: '',
	})

	const fetchOutput = async () => {
		try {
			setOutput({ ...output, isLoading: true })
			const response = await API.getAllOutput({ code: match.params.activityID, isDelete: 0 })

			if (response.code === 200) {
				setOutput({
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchActivities = async () => {
		try {
			setActivities({ ...activities, isLoading: true })

			const response = await API.getAllMainActivity({ order: 'asc', sort: 'total', isDelete: 0 })

			setActivities({
				data: response.data.list,
				isLoading: false,
			})

			setOutputFormValue({
				...outputFormValue,
				activityID: response.data.list[0].ID,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const submit = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.addOutput(outputFormValue)
				fetchOutput()
				setSubmitting(false)
				setModalVisible(false)
				setOutputFormValue({ ...outputFormValue, code: '', name: '' })
				setErrors(null)
				notification.success({ message: 'Sukses Tambah Data' })
			} catch (error) {
				setSubmitting(false)
				setErrors(error.errors)
			}
		}
	}

	const deleteOutput = async () => {
		if (!loadingDelete) {
			try {
				setLoadingDelete(true)
				await API.deleteOutput(outputData.ID)
				notification.success({ message: 'Sukses Hapus Data' })
				setLoadingDelete(false)
				fetchOutput()
				setModalDeleteVisible(false)
				setOutputData({})
			} catch (error) {
				console.log(error)
				notification.error({ message: 'Gagal Hapus Data' })
				setLoadingDelete(false)
				setModalDeleteVisible(false)
			}
		}
	}

	const onUpdate = async () => {
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.updateOutput(outputData.ID, { name: outputData.nomenklatur, code: outputData.kode })
				fetchOutput()
				setSubmitting(false)
				setModalVisibleEdit(false)
				setOutputData({})
				notification.success({ message: 'Sukses Edit Data' })
			} catch (error) {
				setSubmitting(false)
				setErrors(error.errors)
			}
		}
	}

	useEffect(() => {
		fetchActivities()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		fetchOutput()
		// eslint-disable-next-line
	}, [])

	const columns = [
		{
			title: <span style={{ fontSize: '12px' }}>No.</span>,
			dataIndex: 'no',
			align: 'center',
			render: (text) => <span style={{ fontSize: '12px' }}>{text}</span>,
		},
		{
			title: <span style={{ fontSize: '12px' }}>KODE</span>,
			dataIndex: 'kode',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', textAlign: 'start', fontSize: '12px' }}>
					<Link to={`${match.params.activityID}.${data.kode}`}>
						{match.params.activityID}.{text}
					</Link>
				</div>
			),
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>NOMENKLATUR OUTPUT KEGIATAN</span>,
			dataIndex: 'nomenklatur',
			align: 'center',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', textAlign: 'start', fontSize: '12px' }}>
					<Link to={`${match.params.activityID}.${data.kode}`}>{text}</Link>
				</div>
			),
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>TOTAL</span>,
			dataIndex: 'total',
			align: 'center',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
					<span>Rp. {thousandSeparator(text)}</span>
				</div>
			),
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>SISA</span>,
			dataIndex: 'withdraw',
			align: 'center',
			render: (text, data) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
					<span>Rp. {thousandSeparator(text.totalMoney - text.totalWithdraw)}</span>
				</div>
			),
		},
		{
			title: () => <span style={{ fontSize: '12px' }}>ACTION</span>,
			align: 'center',
			render: (text, record) => (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', fontSize: '12px' }}>
					<Button
						onClick={() => {
							setOutputData(record)
							setModalDeleteVisible(true)
						}}
						style={{ marginRight: '6px' }}
						style={{ opacity: 0.5 }}
						size='small'
						type='dashed'
						danger
						icon={<DeleteFilled />}
					/>
					{activities.isLoading ? null : (
						<Button
							onClick={() => {
								setOutputData(record)
								setModalVisibleEdit(true)
							}}
							style={{ marginLeft: '6px' }}
							className='custom-edit-btn'
							icon={<EditFilled />}
							type='dashed'
							size='small'
						/>
					)}
				</div>
			),
		},
	]

	return (
		<Fragment>
			<CustomHeader title='Output' />

			<ModalAdd
				outputFormValue={outputFormValue}
				onChangeName={({ target: { value } }) => setOutputFormValue({ ...outputFormValue, name: value })}
				onChangeCode={({ target: { value } }) => setOutputFormValue({ ...outputFormValue, code: value })}
				onChangeSelect={(val) => setOutputFormValue({ ...outputFormValue, activityID: val })}
				activities={activities}
				onCancel={() => {
					setModalVisible(false)
					setOutputFormValue({ ...outputFormValue, code: '', name: '' })
					setErrors(null)
				}}
				submit={submit}
				submitting={submitting}
				errors={errors}
				modalVisible={modalVisible}
			/>

			<ModalEdit
				outputData={outputData}
				outputFormValue={outputFormValue}
				onChangeName={({ target: { value } }) => setOutputData({ ...outputData, nomenklatur: value })}
				onChangeCode={({ target: { value } }) => setOutputData({ ...outputData, kode: value })}
				onChangeSelect={(val) => setOutputFormValue({ ...outputFormValue, activityID: val })}
				activities={activities}
				onCancel={() => {
					setOutputData({})
					setModalVisibleEdit(false)
					setErrors(null)
				}}
				submit={onUpdate}
				submitting={submitting}
				errors={errors}
				modalVisible={modalVisibleEdit}
				setModalVisible={setModalVisible}
			/>

			<Modal
				title='Apakah Anda Yakin ?'
				visible={modalDeleteVisible}
				onCancel={() => setModalDeleteVisible(false)}
				footer={[
					<Button key='submit' type='dashed' danger style={{ opacity: 0.5 }} onClick={() => deleteOutput()} loading={loadingDelete}>
						Ya
					</Button>,
					<Button
						key='submit'
						type='primary'
						onClick={() => {
							setModalDeleteVisible(false)
							setOutputData({})
						}}>
						Tidak
					</Button>,
				]}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<ExclamationCircleOutlined style={{ color: '#ffd666', fontSize: '32px' }} />
					<span style={{ marginLeft: '16px' }}>Apakah anda yakin untuk menghapus data ?</span>
				</div>
			</Modal>

			<Content style={{ padding: '16px', background: '#f8f9fa' }}>
				<Card className='__main_card_table' bordered={false}>
					<Row gutter={[0, 16]} align='middle' justify='end'>
						<Col>
							{activities.isLoading ? null : (
								<Button onClick={() => setModalVisible(true)} style={{ borderRadius: '4px' }} type='primary' icon={<PlusOutlined />}>
									Tambah Data
								</Button>
							)}
						</Col>
					</Row>

					<Table
						size='small'
						style={{ fontSize: '12px' }}
						columns={columns}
						loading={output.isLoading}
						dataSource={output.data.map((data, index) => ({
							key: index,
							ID: data.ID,
							no: index + 1,
							kode: data.code,
							nomenklatur: data.name,
							total: data.total,
							withdraw: data.withdraw,
						}))}
						bordered
					/>
				</Card>
			</Content>
		</Fragment>
	)
}

const ModalAdd = ({ submitting, modalVisible, onCancel, submit, errors, activities, onChangeSelect, onChangeName, onChangeCode, outputFormValue }) => {
	return (
		<Modal confirmLoading={submitting} title='Tambah Data Output' visible={modalVisible} onCancel={onCancel} onOk={submit}>
			<Form style={{ paddingLeft: 16, paddingRight: 16 }} layout='vertical'>
				<Form.Item
					label='Kode Activity'
					help={errors ? errors.activityID && errors.activityID[0] : null}
					validateStatus={errors ? errors.activityID && 'error' : undefined}
					name='activityID'
					rules={[{ required: true, message: 'Silahkan Masukkan Kode Activity' }]}>
					{activities.isLoading ? null : (
						<Select
							placeholder='Pilih Kode Activity'
							value={outputFormValue.activityID}
							defaultValue={outputFormValue.activityID}
							onSelect={onChangeSelect}>
							{activities.data.map((activity) => (
								<Option key={activity.ID} value={activity.ID}>
									{activity.code}
								</Option>
							))}
						</Select>
					)}
				</Form.Item>

				<div style={{ marginBottom: '16px' }}>
					<span>
						<span style={{ color: 'red' }}>*</span> Kode Output
					</span>
					<Input type='number' placeholder='Contoh: 712' value={outputFormValue.code} onChange={onChangeCode} />
					<span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span>
				</div>

				<div>
					<span>
						<span style={{ color: 'red' }}>*</span> Nama Output
					</span>
					<Input.TextArea type='text' placeholder='Contoh: Layanan Dukungan' value={outputFormValue.name} onChange={onChangeName} />
					<span style={{ color: 'red' }}>{errors ? errors.name && errors.name[0] : null}</span>
				</div>
			</Form>
		</Modal>
	)
}

const ModalEdit = ({
	modalVisible,
	onCancel,
	activities,
	outputData,
	submitting,
	onChangeName,
	onChangeCode,
	errors,
	onChangeSelect,
	submit,
	outputFormValue,
}) => {
	return (
		<Modal confirmLoading={submitting} title='Edit Data Output' visible={modalVisible} onCancel={onCancel} onOk={submit}>
			<Form style={{ paddingLeft: 16, paddingRight: 16 }} layout='vertical'>
				<Form.Item
					label='Kode Activity'
					help={errors ? errors.activityID && errors.activityID[0] : null}
					validateStatus={errors ? errors.activityID && 'error' : undefined}
					name='activityID'
					rules={[{ required: true, message: 'Silahkan Masukkan Kode Activity' }]}>
					{activities.isLoading ? null : (
						<Select
							disabled
							placeholder='Pilih Kode Activity'
							value={outputFormValue.activityID}
							defaultValue={outputFormValue.activityID}
							onSelect={onChangeSelect}>
							{activities.data.map((activity) => (
								<Option key={activity.ID} value={activity.ID}>
									{activity.code}
								</Option>
							))}
						</Select>
					)}
				</Form.Item>

				<div style={{ marginBottom: '16px' }}>
					<span>
						<span style={{ color: 'red' }}>*</span> Kode Output
					</span>
					<Input type='number' placeholder='Contoh: 712' value={outputData.kode} onChange={onChangeCode} />
					<span style={{ color: 'red' }}>{errors ? (errors.code ? errors.code[0] : errors.errors ? errors.errors[0] : null) : null}</span>
				</div>

				<div>
					<span>
						<span style={{ color: 'red' }}>*</span> Nama Output
					</span>
					<Input.TextArea type='text' placeholder='Contoh: Layanan Dukungan' onChange={onChangeName} value={outputData.nomenklatur} />
					<span style={{ color: 'red' }}>{errors ? errors.name && errors.name[0] : null}</span>
				</div>
			</Form>
		</Modal>
	)
}

export default Outputs
