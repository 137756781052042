import React, { useState, Fragment, useContext } from 'react'
import { Input, Row, Col, Checkbox, Button, Divider, Popconfirm, notification, Select } from 'antd'
import { PlusOutlined, DeleteOutlined, DeleteFilled } from '@ant-design/icons'
import { ProgramContext } from '../../../context/ProgramContext'
import API from '../../../services/API'

const IS_ADMIN =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'SuperAdministrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'SuperAdministrator')

const IS_ADMIN_PROGRAM =
	(localStorage.getItem('@role') && localStorage.getItem('@role') === 'Administrator') ||
	(sessionStorage.getItem('@role') && sessionStorage.getItem('@role') === 'Administrator')

const ProgramReq = ({ match }) => {
	const programContext = useContext(ProgramContext)
	const { programReqParams, setProgramReqParams, programReq, setProgramReq, fetchProgramReq } = programContext

	const form = { programID: match.params.id, description: '', isNeedFile: false }
	const [reqruitments, setReqruitments] = useState([{ programID: match.params.id, description: '', isNeedFile: false, type: '' }])
	const [isSubmitting, setIsSubmitting] = useState(false)

	const onChangeReq = (e, index) => {
		let temp = [...reqruitments]
		temp[index].description = e.target.value
		setReqruitments(temp)
	}

	const onChangeReqDefault = (e, index) => {
		let temp = [...programReq.data]
		temp[index].description = e.target.value
		setProgramReq({ ...programReq, data: temp })
	}

	const onSubmitReqruitments = async () => {
		let concatReq = reqruitments.concat(programReq.data).filter((req) => req.description.trim())

		if (!isSubmitting) {
			setIsSubmitting(true)
			for (let req of concatReq) {
				try {
					await API.saveProgramReqruitment(req)
					notification.success({ message: 'Sukses simpan persyaratan' })
				} catch (error) {
					notification.error({ message: 'Gagal simpan persyaratan' })
				}
			}
			fetchProgramReq()
			setIsSubmitting(false)
			setReqruitments([form])
		}
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{programReq.data.map((req, index) => (
				<Row gutter={[16, 16]} justify='center' align='middle' key={index}>
					<Col span={1}>
						<span>{index + 1}. </span>
					</Col>
					<Col span={11}>
						<Input.TextArea
							disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false}
							value={req.description}
							placeholder='Masukkan persyaratan'
							onChange={(e) => onChangeReqDefault(e, index)}
						/>
					</Col>
					<Col span={4}>
						<span>Butuh Berkas ? &nbsp;</span>
						<Checkbox
							disabled={!IS_ADMIN}
							checked={req.isNeedFile}
							onChange={(e) => {
								let tmp = [...programReq.data]
								tmp[index].isNeedFile = e.target.checked
								setProgramReq({ ...programReq, data: tmp })
							}}
						/>
					</Col>

					<Col span={6}>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
							{req.isNeedFile && (
								<Fragment>
									<span>Jenis Berkas</span>
									<Select
										disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false}
										onChange={(value) => {
											let tmp = [...programReq.data]
											tmp[index].type = value
											setProgramReq({ ...programReq, data: tmp })
										}}
										style={{ width: '100%' }}
										placeholder='Pilih Jenis Berkas'
										value={req.type}>
										<Select.Option value={1}>Semua File</Select.Option>
										<Select.Option value={2}>Dokumen (excel, docx, ppt, pdf)</Select.Option>
										<Select.Option value={4}>Dokumen (excel)</Select.Option>
										<Select.Option value={5}>Dokumen (docx)</Select.Option>
										<Select.Option value={6}>Dokumen (doc)</Select.Option>
										<Select.Option value={7}>Dokumen (ppt)</Select.Option>
										<Select.Option value={8}>Dokumen (pdf)</Select.Option>
										<Select.Option value={3}>Gambar</Select.Option>
									</Select>
								</Fragment>
							)}
						</div>
					</Col>

					<Col span={2}>
						<Popconfirm
							title='Yakin menghapus persyaratan?'
							trigger='click'
							onConfirm={() => {
								API.deleteProgramReqruitment(req.ID)
									.then(() => {
										fetchProgramReq()
										notification.success({ message: 'Sukses hapus persyaratan' })
									})
									.catch((e) => {
										notification.error({ message: 'Gagal hapus persyaratan' })
									})
							}}>
							<Button disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false} danger size='small' icon={<DeleteFilled />} />
						</Popconfirm>
					</Col>
				</Row>
			))}
			{reqruitments.map((req, index) => (
				<Row gutter={[16, 16]} justify='center' align='middle' key={index}>
					<Col span={1}>
						<span>{index + 1 + programReq.data.length}. </span>
					</Col>
					<Col span={11}>
						<Input.TextArea
							disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false}
							onChange={(e) => onChangeReq(e, index)}
							rows={1}
							value={req.description}
							placeholder='Masukkan persyaratan'
						/>
					</Col>
					<Col span={4}>
						<span>Butuh Berkas ? &nbsp;</span>
						<Checkbox
							disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false}
							checked={req.isNeedFile}
							onChange={(e) => {
								let tmp = [...reqruitments]
								tmp[index].isNeedFile = e.target.checked
								setReqruitments(tmp)
							}}
						/>
					</Col>

					<Col span={6}>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
							{req.isNeedFile && (
								<Fragment>
									<span>Jenis Berkas</span>
									<Select
										disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false}
										onChange={(value) => {
											let tmp = [...reqruitments]
											tmp[index].type = value
											setReqruitments(tmp)
										}}
										style={{ width: '100%' }}
										placeholder='Pilih Jenis Berkas'
										value={req.type}>
										<Select.Option value={1}>Semua File</Select.Option>
										<Select.Option value={2}>Dokumen (excel, docx, ppt, pdf)</Select.Option>
										<Select.Option value={4}>Dokumen (excel)</Select.Option>
										<Select.Option value={5}>Dokumen (docx)</Select.Option>
										<Select.Option value={6}>Dokumen (doc)</Select.Option>
										<Select.Option value={7}>Dokumen (ppt)</Select.Option>
										<Select.Option value={8}>Dokumen (pdf)</Select.Option>
										<Select.Option value={3}>Gambar</Select.Option>
									</Select>
								</Fragment>
							)}
						</div>
					</Col>

					{index === 0 ? (
						<Col span={2}>
							<Button
								disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false}
								size='small'
								icon={<PlusOutlined />}
								onClick={() => setReqruitments([...reqruitments, form])}
							/>
						</Col>
					) : (
						<Col span={2}>
							<Button
								disabled={!IS_ADMIN && !IS_ADMIN_PROGRAM ? true : false}
								danger
								size='small'
								icon={<DeleteOutlined />}
								onClick={() => {
									let temp = reqruitments.filter((req, idx) => index !== idx)
									setReqruitments(temp)
								}}
							/>
						</Col>
					)}
				</Row>
			))}

			<Divider />

			<Row gutter={[16, 16]} justify='center' align='middle'>
				<Col span={8}>
					{(IS_ADMIN || IS_ADMIN_PROGRAM) && (
						<Button block type='primary' loading={isSubmitting} onClick={onSubmitReqruitments}>
							Simpan
						</Button>
					)}
				</Col>
			</Row>
		</div>
	)
}

export default ProgramReq
