import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

export default class BudgetAPI {
	static getResumeComponentReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/component/resume?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getResumeSubComponentReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/sub-component/resume?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getResumeActivityReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/detail-activity/resume?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getComponentReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/component?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getSubComponentReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/sub-component?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getActivityReminder(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/detail-activity?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
