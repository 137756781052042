import React, { useState, useEffect, Fragment } from 'react'
import API from '../../../services/API'
import { Button, Progress, Spin, Tooltip, Modal, notification } from 'antd'
import { PlusOutlined, MinusOutlined, MoneyCollectOutlined, EditFilled, DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import thousandSeparator from '../../../utils/thousandSeparator'
import { Link } from 'react-router-dom'

const ComponentActivity = ({ subComponentID }) => {
	const [activity, setActivity] = useState({ data: [], isLoading: true })
	const [budgetActivity, setBudgetActivity] = useState({})
	const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [deleteData, setDeleteData] = useState({})

	const fetchActivity = async () => {
		try {
			setActivity({ ...activity, isLoading: true })
			const response = await API.getSubComponentActivity({ subComponentID, isDelete: 0 })

			let newGroup = new Map()

			for (let i in response.data) {
				const budget = response.data[i]
				if (!newGroup.has(budget.budgetAccount.code)) {
					newGroup.set(budget.budgetAccount.code, {
						code: budget.budgetAccount.code,
						name: budget.budgetAccount.name,
						data: [],
					})
				}

				let group = newGroup.get(budget.budgetAccount.code)

				group.data.push(budget)

				newGroup.set(budget.budgetAccount.code, group)
			}

			setBudgetActivity(Array.from(newGroup))

			setActivity({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const onDelete = async () => {
		if (!loadingDelete) {
			try {
				setLoadingDelete(true)
				await API.deleteActivity(deleteData.ID)
				setLoadingDelete(false)
				notification.success({ message: 'Sukses Hapus Data' })
				fetchActivity()
				setModalDeleteVisible(false)
			} catch (error) {
				console.log(error)
				setLoadingDelete(false)
				notification.error({ message: 'Gagal Hapus Data' })
			}
		}
	}

	useEffect(() => {
		fetchActivity()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Modal
				title='Apakah Anda Yakin ?'
				visible={modalDeleteVisible}
				onCancel={() => {
					setModalDeleteVisible(false)
					setDeleteData({})
				}}
				footer={[
					<Button
						key='submit'
						type='dashed'
						danger
						style={{ opacity: 0.5 }}
						onClick={() => {
							onDelete()
						}}
						loading={loadingDelete}>
						Ya
					</Button>,
					<Button
						key='submit'
						type='primary'
						onClick={() => {
							setModalDeleteVisible(false)
							setDeleteData({})
						}}>
						Tidak
					</Button>,
				]}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<ExclamationCircleOutlined style={{ color: '#ffd666', fontSize: '32px' }} />
					<span style={{ marginLeft: '16px' }}>Apakah anda yakin untuk menghapus data ?</span>
				</div>
			</Modal>

			{activity.isLoading ? (
				<tr>
					<td colSpan={8}>
						<Spin />
					</td>
				</tr>
			) : activity.data.length === 0 ? (
				<tr>
					<td colSpan={8}></td>
				</tr>
			) : (
				budgetActivity.map(([code, budget]) => (
					<BudgetComponent
						key={code}
						code={code}
						budget={budget}
						onDelete={(data) => {
							setModalDeleteVisible(true)
							setDeleteData(data)
						}}
					/>
				))
			)}
		</Fragment>
	)
}

const BudgetComponent = ({ code, budget, onDelete }) => {
	const [hidden, setHidden] = useState(false)

	return (
		<Fragment>
			<tr className='componentactv-tr'>
				<td>
					<Button size='small' onClick={() => setHidden(!hidden)} icon={hidden ? <PlusOutlined /> : <MinusOutlined />} /> {code}
				</td>
				<td className='component-detail-name' style={{ textDecoration: 'underline' }}>
					{budget.name}
				</td>
				<td></td>
				<td></td>
				<td></td>
				<td>Rp. {thousandSeparator(budget.data.length < 1 ? 0 : budget.data.reduce((acc, next) => acc + next.total, 0))}</td>
				<td></td>
				<td></td>
			</tr>

			{budget.data.map((detail, index1) => (
				<Fragment key={index1}>
					<tr style={{ display: hidden ? 'none' : '' }} className='componentactv-tr-child'>
						<td></td>
						<td className='component-detail-name' style={{ paddingLeft: '24px' }}>
							{' '}
							- {detail.name}
						</td>
						<td>{detail.calculations.length === 0 ? detail.unitValue : null}</td>
						<td>{detail.calculations.length === 0 ? detail.unit.name : null}</td>
						<td>{detail.calculations.length === 0 ? `Rp. ${thousandSeparator(detail.price)}` : null} </td>
						<td>{detail.calculations.length === 0 ? `Rp. ${thousandSeparator(detail.total)}` : null} </td>
						<td>
							{detail.calculations.length > 0 ? null : detail.withdraw.allocationCunt === 0 ? (
								<Link to={`/budget/activity/${detail.ID}`}>
									<PlusOutlined style={{ opacity: 0.4 }} /> <span style={{ fontWeight: '400', opacity: 0.4 }}>Tambah Pengingat</span>{' '}
								</Link>
							) : (
								<Fragment>
									<Link to={`/budget/activity/${detail.ID}`}>
										<MoneyCollectOutlined /> <span style={{ fontWeight: '400' }}>{detail.withdraw.allocationCunt} Pengambilan</span>
									</Link>
									<div>
										<Progress
											percent={((detail.withdraw.realizationValue / detail.withdraw.allocationValue) * 100).toFixed(0)}
											size='small'
											trailColor='#d3adf7'
											strokeColor='#531dab'
										/>
									</div>
								</Fragment>
							)}
						</td>
						<td>
							{detail.calculations.length > 0 ? null : (
								<Fragment>
									<Tooltip title='Hapus Data'>
										<Button
											onClick={() => {
												// setOutputData(record)
												// setModalDeleteVisible(true)
												onDelete(detail)
											}}
											style={{ marginRight: '6px' }}
											style={{ opacity: 0.5 }}
											size='small'
											type='dashed'
											danger
											icon={<DeleteFilled />}
										/>
									</Tooltip>

									{/* {activities.isLoading ? null : ( */}
									<Link to={`/budget/sub-component/activity/update/${detail.ID}`}>
										<Button style={{ marginLeft: '6px' }} className='custom-edit-btn' icon={<EditFilled />} type='dashed' size='small' />
									</Link>

									{/* )} */}
								</Fragment>
							)}
						</td>
					</tr>
					{detail.calculations.length > 0 ? (
						<tr style={{ display: hidden ? 'none' : '' }} className='componentactv-tr-child'>
							<td></td>
							<td className='component-detail-name' style={{ paddingLeft: '64px' }}>
								{' '}
								{detail.calculations.map((calc, index) => (
									<span key={index}>{` ${calc.unitValue} ${calc.unit.name} ${index + 1 < detail.calculations.length ? 'X' : ''}`}</span>
								))}
							</td>
							<td>{detail.unitValue}</td>
							<td>{detail.unit.name}</td>
							<td>Rp. {thousandSeparator(detail.price)}</td>
							<td>Rp. {thousandSeparator(detail.total)}</td>
							<td>
								{detail.withdraw.allocationCunt === 0 ? (
									<Link to={`/budget/activity/${detail.ID}`}>
										<PlusOutlined style={{ opacity: 0.4 }} /> <span style={{ fontWeight: '400', opacity: 0.4 }}>Tambah Pengingat</span>{' '}
									</Link>
								) : (
									<Fragment>
										<Link to={`/budget/activity/${detail.ID}`}>
											<MoneyCollectOutlined /> <span style={{ fontWeight: '400' }}>{detail.withdraw.allocationCunt} Pengambilan</span>
										</Link>
										<div>
											<Progress
												percent={((detail.withdraw.realizationValue / detail.withdraw.allocationValue) * 100).toFixed(0)}
												size='small'
												trailColor='#d3adf7'
												strokeColor='#531dab'
											/>
										</div>
									</Fragment>
								)}
							</td>
							<td>
								<Tooltip title='Hapus Data'>
									<Button
										onClick={() => {
											// setOutputData(record)
											// setModalDeleteVisible(true)
											onDelete(detail)
										}}
										style={{ marginRight: '6px' }}
										style={{ opacity: 0.5 }}
										size='small'
										type='dashed'
										danger
										icon={<DeleteFilled />}
									/>
								</Tooltip>

								{/* {activities.isLoading ? null : ( */}

								<Link to={`/budget/sub-component/activity/update/${detail.ID}`}>
									<Button style={{ marginLeft: '6px' }} className='custom-edit-btn' icon={<EditFilled />} type='dashed' size='small' />
								</Link>

								{/* )} */}
							</td>
						</tr>
					) : null}
				</Fragment>
			))}
		</Fragment>
	)
}

export default ComponentActivity
