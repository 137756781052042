import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

export default class AccessAPI {
	static getListAccount(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/access/account?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addAcces(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/access/add-access`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static revokeAcces(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/access/revoke`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addAccount(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/access/create/account`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static attachRole(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/access/add-access`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static updateAccount(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					if (body[obj]) {
						formData.append(obj, body[obj])
					}
				})

				const response = await Axios.post(`/access/account/update/${id}`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
