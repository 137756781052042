import { defaultAxios as Axios } from '../utils/axios'
import queryString from 'query-string'

class MasterAPI {
	static getBudgetAccount(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get('/data-master/budget-account')

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getUnits(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get('/data-master/unit')

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getCity(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/city?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static marriedStatus(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/married-status?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getReligion(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/religion?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEducationTier(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/education-tier?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEmployeePosition(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/employee-position?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEmployeeCategory(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/civilian-category?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addNIP(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/nip`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static addCivilianCategory(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-civilian-category`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeCivilianCategory(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-civilian-category/update/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeCivilianCategory(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-civilian-category/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateNIP(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/nip/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAllBank(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/bank?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeEducation(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-education`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeEducation(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-education/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeEducation(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-education/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeBank(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-bank`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeBank(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-bank/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeBPJS(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/bpjs`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static updateEmployeeBPJS(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/bpjs/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmailDikbud(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/email-mendikbud`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static updateEmailDikbud(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/email-mendikbud/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeNIP(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/nip`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeNIP(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/nip/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeFamily(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-family`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeFamily(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-family/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeFather(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/father/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeFather(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/father`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeMother(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/mother/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeMother(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/mother`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeChildren(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-child`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeChildren(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-child/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeSpouse(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('employeeID', body.employeeID)
				formData.append('name', body.name)
				formData.append('isCivilian', body.isCivilian)
				if (body.isCivilian === 1) {
					formData.append('NIP', body.NIP)
				} else {
					formData.append('NIP', null)
				}
				const response = await Axios.post(`/employee/employee-spouse`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeSpouse(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('employeeID', body.employeeID)
				formData.append('name', body.name)
				formData.append('isCivilian', body.isCivilian)
				if (body.isCivilian === 1) {
					formData.append('NIP', body.NIP)
				} else {
					formData.append('NIP', null)
				}
				const response = await Axios.post(`/employee/employee-spouse/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getRetiringEmployee(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/retiring?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getPromotionEmployee(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/reminder/promotion?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeCourse(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-course`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeCourse(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-course/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeCourse(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-course/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeTraining(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-training`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeTraining(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-training/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeTraining(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach((obj) => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-training/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}

export default MasterAPI
